/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useRef, useEffect } from "react";
import { PLANNING_PROPERTY_INFO } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api, generic_post_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";
import { CodeSectionsBySection, AdminBulletins, CodeSectionsByRoom } from "utils/code_sections";
import plan from 'assets/temp/plan.png'


var get_yesterday_date = () => {
    var today = new Date();
    today.setDate(today.getDate() - 1);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
}

const Stages = {
    START: 'start',
    SEARCH_FLOW: 'search_flow',
    SEARCH_RESULT: 'search_result',
    ADDRESS_FLOW: 'address_flow',
}

class PlanningCodeSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current_stage: Stages.START,
        };
        this.setStage = this.setStage.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_search'});
    }

    setStage(stage) {
        this.setState({ current_stage: stage });
    }

    render() {
        const {current_stage} = this.state;

        return(
            <div class="planningCodeSearchEnvelope mt-5">
                <div class="container planningCodeSearchPage">
                    {
                        current_stage === Stages.START &&
                            <StartComponent 
                                setStage={this.setStage}
                            />
                    }
                    
                    {
                        current_stage === Stages.SEARCH_FLOW &&
                            <SearchComponent 
                                setStage={this.setStage}
                                current_stage={this.state.current_stage}
                            />
                    }
                    {
                        current_stage === Stages.ADDRESS_FLOW &&
                            <AddressFlowComponent 
                                setStage={this.setStage}
                                current_stage={this.state.current_stage}
                            />
                    }
                </div>
                
            </div>
        )
    }
}

function StartComponent(props) {

    const {setStage} = props;

    return (
        <div class="container containerWrapper startComponent">
             <Card className="p-4">
                <Card.Body>
                    <>
                        <div class="row">
                            <div class="col text-center display-6">SF Planning Code</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col text-center fst-italic fw-light">Last updated: {get_yesterday_date()}</div>
                        </div>
                        <div class="row mt-5">
                                <div class="row">
                                    <div class="col text-center"><Button variant="warning" className="actionButton" onClick={() => setStage(Stages.ADDRESS_FLOW)}>I know the property address</Button></div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="warning" className="actionButton" onClick={() => setStage(Stages.SEARCH_FLOW)}>I have a general code question</Button></div>    
                                </div>
                        </div>
                    </>
                </Card.Body>
            </Card>
            
        </div>
    )
}

function AddressFlowComponent(props) {
    const {current_stage, setStage} = props;

    const [address, setAddress] = useState(null);
    const [searching_address, setSearchingAddress] = useState(false);
    const [height_bulk, setHeightBulk] = useState('');
    const [use_district, setUseDistrict] = useState('');
    const [special_use_districts, setSpecialUseDistricts] = useState('');
    const [formatted_address, setFormattedAddress] = useState('');
    const [code_sections, setCodeSections] = useState(null);
    const [visible_sections, setVisibleSections] = useState({});

    const setVisibleSectionHelper = (section_id) => {

        let current_value = visible_sections[section_id];

        setVisibleSections(prevState => ({
            ...prevState,
            [section_id]: !current_value
        }));
    }

    const searchButtonClick = () => {
        setSearchingAddress(true);

        let request = {
            "request_type": 'property_info',
            "request_address": address,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setFormattedAddress(data.formatted_address);
            setHeightBulk(data.height_bulk);
            setUseDistrict(data.use_district);
            setSpecialUseDistricts(data.special_use_districts);
            setSearchingAddress(false);
        })
        .catch(error => {
            setSearchingAddress(false);
        });
    }

    const heightButtonClick = () => {

        let request = {
            "request_type": 'height_info',
            'height_bulk': height_bulk,
            'use_district': use_district,
            'special_use_districts': special_use_districts,
        }

        generic_post_api(request, PLANNING_PROPERTY_INFO)
        .then(
            result => {
                return result.data.json();
               
            })
        .then(data => {
            setCodeSections(data)
        })
        .catch(error => {
        });
    }

    return (
        <div class="container searchComponent">
            <div class="row">
                <div class="col text-center display-6">Get property's planning code</div>
            </div>
            { !formatted_address && 
                <div class="row mt-4">
                    <Card>
                        <Card.Body>
                            <Card.Text className="ms-3 fw-semibold">
                                <p>Start with property's address</p>
                            </Card.Text>
                            <div class="container">
                                <div class="row ">
                                <AddressSection 
                                    setAddress={ setAddress }
                                    // address={ this.state.address }
                                    searchButtonClick={ searchButtonClick }
                                    searching_address={ searching_address }
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            { use_district && height_bulk && special_use_districts &&

            <Card className="mt-5">
                <Card.Body>
                    <>
                        <div class="row mt-2">
                            <div class="col fw-semibold fs-5">{formatted_address}</div>
                        </div>    
                        <div class="row mt-3">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12 fw-semibold text-success">Use district zoning</div>
                                    <div class="col-12"><i class="fa-solid fa-minus fa-2xs"/> {use_district}</div>
                                </div>   
                                <div class="row mt-2">
                                    <div class="col-12 fw-semibold text-success">Height and bulk district</div>
                                    <div class="col-12"><i class="fa-solid fa-minus fa-2xs"/> {height_bulk}</div>
                                </div>   
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12  fw-semibold text-success">Special districts</div>
                                    {
                                        special_use_districts.map(special_district => {
                                            return <div class="col-12"><i class="fa-solid fa-minus fa-2xs"/> {special_district}</div>
                                        })
                                    }
                                </div>   
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <hr></hr>
                            </div>
                        </div>
                        <div class="row mt-2 topicSection">
                            <div class="container">
                                <div class="row ">
                                <div class="col"><Button className="topicButton" variant="primary" onClick={heightButtonClick}>Height</Button></div>
                                <div class="col"><Button className="topicButton" variant="primary" disabled>Bulk</Button></div>
                                <div class="col"><Button className="topicButton" variant="primary" disabled>Set-back</Button></div>
                                <div class="col"><Button className="topicButton" variant="primary" disabled>Yard</Button></div>
                                </div>
                            </div>
                        </div>
                    </>
                </Card.Body>
            </Card>
            }
            
            { code_sections && code_sections.limit && code_sections.limit.length > 0 && 
                <div class="codeSectionList">
                    <div class="row mt-4 mb-2">
                        <div class="col fw-semibold">Height limitations</div>
                    </div>
                    {
                        code_sections.limit.map(section => {
                            return  <div class="mt-2 bg-light p-2">
                                        <div class="row">
                                            <div class="col">
                                                {
                                                    visible_sections[section.section_id] ? 
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-minus fa-lg"/></a>
                                                    :
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-plus fa-lg"/></a>
                                                }

                                                &nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                            </div>
                                        </div>
                                        {
                                            visible_sections[section.section_id] && 
                                            <div class="row mt-1 p-2 codeSection"><span dangerouslySetInnerHTML={{__html: section.formatted_section_text}} /></div>
                                        }
                                    </div>
                        })
                    }
                </div>
            }
            { code_sections && code_sections.exception && code_sections.exception.length > 0 && 
                <div class="codeSectionList">
                    <div class="row mt-4 mb-2">
                        <div class="col fw-semibold">Height exceptions</div>
                    </div>
                    {
                        code_sections.exception.map(section => {
                            return  <div class="mt-2 bg-light p-2">
                                        <div class="row">
                                            <div class="col">
                                                {
                                                    visible_sections[section.section_id] ? 
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-minus fa-lg"/></a>
                                                    :
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-plus fa-lg"/></a>
                                                }

                                                &nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                            </div>
                                        </div>
                                        {
                                            visible_sections[section.section_id] && 
                                            <div class="row mt-1 p-2 codeSection"><span dangerouslySetInnerHTML={{__html: section.formatted_section_text}} /></div>
                                        }
                                    </div>
                        })
                    }
                </div>
            }
            { code_sections && code_sections.general && code_sections.general.length > 0 && 
                <div class="codeSectionList">
                    <div class="row mt-4 mb-2">
                        <div class="col fw-semibold">General height sections</div>
                    </div>
                    {
                        code_sections.general.map(section => {
                            return  <div class="mt-2 bg-light p-2">
                                        <div class="row">
                                            <div class="col">
                                                {
                                                    visible_sections[section.section_id] ? 
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-minus fa-lg"/></a>
                                                    :
                                                        <a onClick={() => setVisibleSectionHelper(section.section_id)}><i class="fa-regular fa-square-plus fa-lg"/></a>
                                                }

                                                &nbsp;&nbsp;SEC. {section.section_id} {section.section_name}
                                            </div>
                                        </div>
                                        {
                                            visible_sections[section.section_id] && 
                                            <div class="row mt-1 p-2 codeSection"><span dangerouslySetInnerHTML={{__html: section.formatted_section_text}} /></div>
                                        }
                                    </div>
                        })
                    }
                </div>
            }
        </div>

    )
}

class AddressSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let long = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            long: long,
        });

        this.setState({
            searchButtonEnabled: true,
        })
    }

    render() {
        const { searchButtonClick, searching_address } = this.props;
        
        return(
            <div class="container">
                <div class="row gy-3">
                    <div class="col-12 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="Property address" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                            <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                <i class="fa fa-times"/>
                            </Button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        {searching_address? 
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                            <Button variant="primary" disabled={ !this.state.searchButtonEnabled } onClick={ searchButtonClick }>
                                Search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function SearchComponent (props) {

    const {current_stage, setStage} = props;

    const [text_box_text, setTextBoxText] = useState('');
    const [result, setResult] = useState('');
    const [waiting_for_result, setWaitingForResult] = useState(false);

    const search = () => {
        setResult('');
        setWaitingForResult(true);

        const interval = setInterval(() => {
            setWaitingForResult(false);
            let answer = ` <body>
                        <p>Hi,</p>
                        <p>Thank you for taking the time to speak with me today. Our conversation was immensely helpful. If you encounter any additional challenges with the code, please let me know.</p>
                        <p>We are currently working on a few solutions and I will share them with you once we have a prototype ready.</p>
                        <p>If you know any other architects or real estate attorneys I could speak with, I would appreciate a warm introduction. The conversation would be similar to ours today.</p>
                        <p>Enjoy your trip to Montana! I’ll reach out again in a few weeks to learn more from you.</p>
                        <p>Thanks again,</p>
                        <p>Ali</p>
                    </body>`;
            setResult(answer);
        }, 2000);
    }

    return (
        <div class="container searchComponent">
            <div class="row">
                <div class="col text-center display-6">Search SF Planning Code</div>
            </div>
            <div class="row mt-4">
                <div class="input-group">
                    <input type="search" 
                        placeHolder="Type your question"
                        class="form-control"
                        name="search" 
                        id="search"
                        value={text_box_text}
                        onChange={ (e) => setTextBoxText(e.target.value) }
                    />
                    {   waiting_for_result ?
                            <Button variant="warning" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                        <Button variant="warning" onClick={search}>Search</Button>    
                    }
                    
                </div>
            </div>
            
            <div class="row mt-4 p-3">
                {
                    result && <div class="col"><span dangerouslySetInnerHTML={{__html: result}} /></div>
                }
            </div>
        </div>
    )
}

export default PlanningCodeSearch;