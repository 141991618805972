import React, { Component} from "react";
import { CONTACT_US_URL } from 'utils/constants';
import {Button, Spinner, Modal} from 'react-bootstrap';
import { isValidEmail } from "utils/helpers";
import { generic_post_api } from "api/generic_api";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: {
                name: null,
                phone: null,
                email: null,
                category: null,
                description: null
            },
            submitted: false,
            showSpinner: false
        };
        this.inputChanged = this.inputChanged.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.continue = this.continue.bind(this);
    }

    continue() {
        this.setState({
            question: {},
            submitted: false,
            showSpinner: false
        });
        this.props.changeDisplayStatus(false);
    }

    handleSubmitRequest() {
        
        this.setState({ showSpinner: true })

        var request = { 
            question: this.state.question,
        }

        generic_post_api(request, CONTACT_US_URL)
        .then(
            (result) => {
                if (result.status === 201) {
                    this.setState({
                        submitted: true,
                        showSpinner: false
                    })
                }
                else {
                    this.setState({
                        showSpinner: false
                    })
                }
            }
        );
    }

    canSubmit() {
        let currentQuestion = this.state.question;
        return currentQuestion.email && isValidEmail(currentQuestion.email) && currentQuestion.description && currentQuestion.category;
    }

    inputChanged(e, type) {
        if (type === "phone") {
            const re = /^[0-9\b]+$/; // only allow numbers for phone and zip
            if (e.target.value !== '' && !re.test(e.target.value)) {
                return;
            }
        }

        var currentQuestion = this.state.question;
        currentQuestion[type] = e.target.value;
        this.setState( { question: currentQuestion });
    }

    render() {

        if (!this.props.displayStatus) {
            return null;
        }

        return(
            <Modal 
                show={this.props.displayStatus} 
                onHide={this.continue}
                size="md"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                { ! this.state.submitted ?
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>We'd love to hear from you</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div class="container">
                                <div class="row mb-2 gy-2">
                                    <div class="col-12 col-lg-6">
                                        <input type="text" class="form-control" placeHolder="Name / Business *" 
                                            onChange={ (e) => this.inputChanged(e, 'name') }
                                            value={ this.state.question.name }
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                    <div class="email">
                                        <input type="text" class="form-control" placeHolder="Email *" 
                                            onChange={ (e) => this.inputChanged(e, 'email') }
                                            value={ this.state.question.email }
                                        />
                                    </div> 
                                    </div>
                                </div>
                                <div class="row mb-2 gy-2">
                                    <div class="col-12 col-lg-6">
                                    <input type="text" class="form-control" placeHolder="Phone number" 
                                            onChange={ (e) => this.inputChanged(e, 'phone') }
                                            value={ this.state.question.phone }
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                    <div class="email">
                                        <select name="questionCategory" class="form-select" onChange={(e) => this.inputChanged(e, 'category')}>
                                            <option disabled selected value>Type of Request *</option>
                                            <option value="general">General Inquiry</option>
                                            <option value="cancellation">Cancelation Request</option>
                                            <option value="feedback">Update account</option>
                                            <option value="general">Request access</option>
                                            <option value="feedback">Feedback</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div> 
                                    </div>
                                </div>
                                <div class="row mb-2 gy-2">
                                    <div class="col-12"> 
                                        <textarea class="form-control" cols="40" rows="5" placeholder="Question *" value={this.state.question.description} onChange={(e) => this.inputChanged(e, 'description')}/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <small>* Required fields</small>
                                    </div>
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        { this.state.showSpinner ?  
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting...
                            </Button>
                        :
                            <>
                            { ! this.state.submitted &&
                                <>
                                    <Button variant="secondary" onClick={ this.continue }> Cancel </Button>
                                    <Button  variant="primary"  onClick={ this.handleSubmitRequest } disabled={!this.canSubmit()} > Submit </Button>
                                </>
                            }
                            </>
                        }               
                        
                    </Modal.Footer>
                </>
                :
                <>
                    <Modal.Header closeButton>
                            <Modal.Title>We have received your request</Modal.Title>
                        </Modal.Header>
                    <Modal.Body>
                        <div class="container">
                            <div class="row mb-2 gy-2">
                                <div class="col-12">
                                    We will respond to your inquiry within 1-2 business days.
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </>
                }

            </Modal>
    );

    }
}

export default ContactUs;