import React, { Component } from "react";


import { INVITE_INFO_URL, SIGN_UP_URL, EntityRole } from 'utils/constants'
import {trackEvent, EventNames} from 'utils/mixpanel';
import { isValidEmail, isValidPassword, isValidPhoneNumber, isVendor } from "utils/helpers";
import {Button, Spinner, Card} from 'react-bootstrap';
import {AuthContext} from 'auth_context';


var fieldsMatch = (field1, field2) => {
    if (!field1 || !field2) { return true; }

    return field1 === field2;
} 

var emailAddressMatching = (email_1, email_2) => {
    // have not started typing yet
    if (!email_1 || !email_2) { return true; }

    return email_1.toLowerCase() === email_2.toLowerCase();
}

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            phone: '', // must be set to empty string or user can type non-digits on first try.
            error_message: null,
        };

        this.formChanged = this.formChanged.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.fetchInvitationInfo = this.fetchInvitationInfo.bind(this);
    }

    handleSubmitRequest()
    {
        this.setState({ 
            submitting: true,
            error_message: null,
         });

        var request = { 
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            brokerage: this.state.brokerage,
            phone: this.state.phone,
            invitation_id: this.state.invitation_id,
            invitation_type: this.state.invitation_type,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(SIGN_UP_URL);
        this.setState({ submitting: true })

        trackEvent(SIGN_UP_URL)

        fetch(url, requestOptions)
        .then(
            (result) => {
                window.scrollTo(0, -100);
                if (result.status === 201) {
                    return result.json();
                }
                else if (result.status === 409) {
                    return result.json().then(text => { throw new Error(text)} )
                }
                else {
                    this.setState({ submitting: false })
                }
            }
        ).then(
            result => {
                if (result){
                    trackEvent(EventNames.SIGN_UP_URL, { 'success': true })
                    
                    if (result.token && result.role && result.role === EntityRole.AGENT) {
                        this.context.setLoggedInEntityRole(EntityRole.AGENT);
                        localStorage.setItem('token', result.token);
                        this.props.history.push('/agent/start');
                    }
                    else if (result.token && result.role && isVendor(result.role)) {
                        this.context.setLoggedInEntityRole(result.role);
                        localStorage.setItem('token', result.token);

                        if (result.checkout_session_url) {
                            window.location.href = result.checkout_session_url;
                        }
                    }
                } 
                this.setState({ submitting: false });
                
            }
        ).catch(
            error =>  {
                this.setState({ 
                    submitting: false,
                    error_message: error.message,
                 })
            }
        );
    }

    canSubmit() {
        const {first_name, last_name, email, email_confirm, password, password_confirm, phone} = this.state;

        let basic_info_valid = first_name && last_name;
        let email_valid = isValidEmail(email) && email_confirm && (email.toLowerCase() === email_confirm.toLowerCase());
        let password_valid = isValidPassword(password) && (password ==password_confirm);
        let phone_valid = isValidPhoneNumber(phone);

        return basic_info_valid && email_valid && password_valid && phone_valid;
    }

    formChanged(e, target) {
        this.setState({ [target]: e.target.value })
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'new_account' });
        document.title = "Sign up for a new account";

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length === 3) {
            
            if (pathSplit[1] && pathSplit[2] && 
                (pathSplit[1] === 'agent_invite' || pathSplit[1] === 'renolition_agent_invite' || pathSplit[1] === 'vendor_invite')) {
                    this.setState({
                        invitation_id: pathSplit[2],
                        invitation_type: pathSplit[1],
                    });
                    this.fetchInvitationInfo(pathSplit[1], pathSplit[2]);
            }
        }
    }

    fetchInvitationInfo(invitation_type, invitation_id) {
        if (!invitation_id) {
            return
        }

        var url = new URL(INVITE_INFO_URL);
        url.searchParams.append("invitation_id", invitation_id);
        url.searchParams.append("invitation_type", invitation_type);
        
        trackEvent(INVITE_INFO_URL, { invitation_id: invitation_id,  invitation_type: invitation_type})

        fetch(url)
        .then(
            (result) => {
                if (result.status == 200) {
                    return result.json();       
                }
            }
        ).then(
            data => {
                if (data.processed) {
                   this.setState({
                        error_message: 'This invitation was already processed',
                   });
                }
                else {
                    this.setState({
                        first_name: data.first_name,
                        last_name: data.last_name,
                        email: data.email,
                    });
                }
            }
        );
    }

    render() {
        const {first_name, last_name, email, email_confirm, phone, invitation_type ,brokerage, password, password_confirm, submitting} = this.state;


    const setPhoneNumber = (value) => {
        if( value === '' || (/^\d+$/.test(value) && value.length <= 10)) {
            this.setState({phone: value});
        }
    }

        return(
            <div class="signUpEnvelope">
                <div class="signUpPage">
                    <div class="signUpPageSection container">
                        <div class="row"><div class="col display-6">Sign up</div></div>
                        <div class="row"><div class="col mt-3"> Already have an account? <a role="button" onClick={ () => this.props.history.push('/login')}><strong>Log in here</strong></a></div></div>
                            <Card className="mt-3">
                                <Card.Body>
                                    <div class="container mt-2">
                                        
                                        { this.state.error_message && 
                                            <div class="row mb-3">
                                                <div class="col text-danger text-center">
                                                    { this.state.error_message }
                                            </div>
                                            </div>
                                        }
                                        <div class="row gy-2">
                                            <div class="col-12 col-md-6">
                                                <input type="text" 
                                                    placeHolder="First name" 
                                                    onChange={ (e) => this.formChanged(e, 'first_name') }
                                                    value= { first_name }
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <input type="text" 
                                                    placeHolder="Last name" 
                                                    onChange={ (e) => this.formChanged(e, 'last_name') }
                                                    value= { last_name }
                                                    class="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6">
                                                <input type="email" 
                                                    placeHolder="Email" 
                                                    onChange={ (e) => this.formChanged(e, 'email') }
                                                    value= { email }
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="input-group">
                                                    <input type="email" 
                                                        placeHolder="Confirm email" 
                                                        onChange={ (e) => this.formChanged(e, 'email_confirm') }
                                                        value= { email_confirm }
                                                        disabled = { !isValidEmail(email) }
                                                        class="form-control"
                                                        id="password-confirm"
                                                    />
                                                </div>
                                                {!emailAddressMatching(email, email_confirm) &&
                                                    <div class="form-text text-danger" id="password-confirm">Emails do not match</div>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6">
                                                <input type="tel" 
                                                    placeHolder="Phone number" 
                                                    onChange={ (e) => setPhoneNumber(e.target.value) }
                                                    value= { phone }
                                                    class="form-control"
                                                />
                                            </div>
                                        { (invitation_type === 'agent_invite'||  invitation_type === 'renolition_agent_invite') &&
                                             <div class="col-12 col-md-6">
                                                <input type="url" 
                                                    placeHolder="Affiliated brokerage company"
                                                    onChange={ (e) => this.formChanged(e, 'brokerage') }
                                                    value= { brokerage }
                                                    class="form-control"
                                                />
                                            </div>
                                        }   
                                        </div>
                                        
                                        <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6">
                                                <input type="password" 
                                                    placeHolder="Password" 
                                                    onChange={ (e) => this.formChanged(e, 'password') }
                                                    value= { password }
                                                    class="form-control"
                                                    id="passwordField"
                                                />
                                                <div class="form-text" id="passwordField">Must contain at least eight characters, including at least 1 upper case letter, 1 lower case letter, 1 number and a special character from !@#$%^&*_</div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <input type="password" 
                                                    placeHolder="Confirm password" 
                                                    value= { password_confirm }
                                                    onChange={ (e) => this.formChanged(e, 'password_confirm') }
                                                    disabled={ !isValidPassword(password)}
                                                    class="form-control"
                                                    id="confirmPasswordField"
                                                />
                                                { !fieldsMatch(password, password_confirm) && 
                                                    <div class="form-text text-danger" id="confirmPasswordField">Passwords do not match</div>
                                                }
                                            </div>
                                        </div>
                                        {/* <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-12">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    <small>I agree to receiving text messages from Renolition Inc. when a new client request has been received. Data rates may apply. I can disable this option in my settings.</small>
                                                </label>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div class="row mt-5">
                                            <div class="col text-center">
                                                {submitting ?
                                                    <Button variant="primary">
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Submitting...
                                                    </Button>
                                                    :
                                                    <Button variant="primary" onClick={() => this.handleSubmitRequest()} 
                                                    disabled={ !this.canSubmit() }>Sign up</Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div class="row mt-3">
                                <div class="col text-center">
                                    <small>
                                        <a class="text-decoration-underline" href="/privacy_policy" role="button" target="_blank">Privacy Policy</a>
                                        &nbsp;and&nbsp; 
                                        <a class="text-decoration-underline" href="/tos" role="button" target="_blank">Terms of Service</a>
                                    </small>
                                </div>
                                    
                            </div>
                    </div>  
                </div>
                
            </div>
        );
    }
}

SignUp.contextType = AuthContext;
export default SignUp;