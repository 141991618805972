import React, { Component } from "react";

import { INFO_REQUEST_URL } from 'utils/constants'
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import rocket from 'assets/reputation/rocket.svg';
import pay from 'assets/reputation/pay.svg';
import renovation from 'assets/reputation/renovation.svg';
import kitchen from 'assets/reputation/kitchen_color.svg';
import sofa from 'assets/reputation/sofa.svg';
import team from 'assets/reputation/team.svg';
import ai from 'assets/reputation/ai_computer.svg';
import send_mail from 'assets/reputation/send_mail.svg';
import podium from 'assets/reputation/podium.svg';
import mailbox from 'assets/reputation/mailbox.svg';
import spinnerGif from 'assets/reputation/spinner_40.gif';
import pig from 'assets/reputation/piggy-bank.svg';


class AdsLanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            displayContactUs: false,
            name: "",
            company: "",
            email: "",
            phone: "",
            submitted: false,
            showSpinner: false,
        };

        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
    }

    handleSubmitRequest() {

        var request = { 
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(INFO_REQUEST_URL);
        this.setState({ showSpinner: true })

        trackEvent(EventNames.INFO_REQUEST_URL, {'request': request })

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setState({
                        submitted: true,
                        showSpinner: false
                    })
                }
                else {
                    this.setState({
                        showSpinner: false
                    })
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'ads'})
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }

    formChanged(e, element) {
        this.setState({ [element]: e.target.value })
    }

    render() {
        return(
            <div class="adsLandingEnvelope">
                
                {/* <div class="adsLandingHeader">
                    <div class="logo"><img src={logo} /></div>
                    <div></div>
                    <div class="contactUs"><input type="submit" value="Contact us" onClick={() => this.setContactUsDisplay(true)}/></div>
                </div> */}

                <div class="adsLandingMain">
                    <div class="filler"/>
                    <div class="rocket "><img src={rocket}/></div>
                    <div class="mainText">
                        <div class="large">GROW YOUR BUSINESS</div>
                        <div class="small">Connect to thousands of homeowners looking for your products</div>
                    </div>
                    <div class="signupForm">
                        { !this.state.submitted ?
                            <>
                                <div class="formTitle">
                                    <div><span class="highlight">SIGN UP</span> FOR</div>
                                    <div>MORE INFORMATION</div>
                                </div>
                                
                                <div class="input"><input type="text" placeholder="Name" value={ this.name } onChange={ (e) => this.formChanged(e, 'name') }/></div>
                                <div class="input"><input type="text" placeholder="Email"  value={ this.email } onChange={ (e) => this.formChanged(e, 'email') }/></div>
                                <div class="input"><input type="text" placeholder="Phone"  value={ this.phone } onChange={ (e) => this.formChanged(e, 'phone') }/></div>
                                <div class="input"><input type="text" placeholder="Company"  value={ this.company } onChange={ (e) => this.formChanged(e, 'company') }/></div>
                                <div class="input submit">
                                    { this.state.showSpinner ? 
                                        <input 
                                            type="image" 
                                            src={spinnerGif}
                                        />
                                    :
                                        <input type="submit" value="SUBMIT NOW" onClick={ this.handleSubmitRequest }/>
                                    }
                                </div>
                            </>
                            :
                            <div class="submitConfirmation">
                                <div>Thank you for your subumission. </div>
                                <div>We will be in touch very soon.</div>
                            </div>

                        }
                    </div>
                    <div class="filler"/>
                </div>
                <div class="adsLandingHowitWorks">
                    <div class="howItWorsGrid">
                        <div class="howItWorksTitle">WHAT WE DO</div>
                        <div class="box point_1">
                            <div class="icon"><img src={renovation} /></div>
                            <div class="text">
                                Depending on your business, Renolition connects you to homeowners either 
                                <b> at the start or at the end</b> of their renovation journey.
                            </div>
                        </div>
                        <div class="box point_2">
                            <div class="icon"><img src={kitchen} /></div>
                            <div class="text">Homeowners purchase products &amp; finishes within the first 4-6 weeks of starting a renovation project.</div>
                        </div>
                        <div class="box point_3">
                            <div class="icon"><img src={sofa} /></div>
                            <div class="text">Additionally, most households upgrade their furniture &amp; décor after a remodeling project.</div>
                        </div>
                        <div class="box point_4">
                            <div class="icon"><img src={pay} /></div>
                            <div class="text">With Renolition's platform, you reach homeowners who are redesigning their homes and spending more.</div>
                        </div>
                    </div>
                </div>

                <div class="howWeDoIt">

                    <div class="howWeDoItGrid">
                        <div class="howWeDoItTitle">HOW WE DO IT</div>
                        <div class="box point_1">
                            <div class="icon"><img src={ai} /></div>
                            <div class="text">
                                <div>Renolition analyzes recent remodeling permits in San Francisco and selects project types that fit your business.</div>
                            </div>
                        </div>
                        <div class="box point_2">
                            <div class="icon"><img src={send_mail} /></div>
                            <div class="text">
                                <div>Our <b>Makeover Kit</b>, with your marketing material, arrives by mail at the start or at the end of the remodel.</div>
                            </div>
                        </div>
                        <div class="box point_3">
                            <div class="icon"><img src={podium} /></div>
                            <div class="text">
                                <div>Your brand becomes front-and-center as homeowners shops for new products &amp; finishes.</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="benefits">

                    <div class="benefitsGrid">
                        <div class="benefitsTitle">YOUR BENEFITS</div>
                        <div class="box point_1">
                            <div class="icon"><img src={team} /></div>
                            <div class="text">
                                <div class="emph">MASSIVE REACH</div>
                                <div>Renolition's unique targeting means your brand can <b>reach over 25,000 homeowners</b> in San Francisco per year.</div>
                            </div>
                        </div>
                        <div class="box point_2">
                            <div class="icon"><img src={mailbox} /></div>
                            <div class="text">
                                <div class="emph">CENTER STAGE</div>
                                <div>With Renolition, you get premium placement in the homeowner's mailbox.</div>
                                </div>
                        </div>
                        <div class="box point_3">
                            <div class="icon"><img src={pig} /></div>
                            <div class="text">
                                <div class="emph">EFFECTIVE &amp; EFFICIENT</div>
                                <div>Reach customers in this critical period of increased spending, all within your budget.</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="getStarted">
                    <div class="getStartedGrid">
                        <div class="getStartedTitle">Start risk-free</div>
                        <div class="getStartedText bold">Reach your first 1000 customers, free of charge.</div>
                        <div class="getStartedText bold">Marketing material design &amp; production included.</div>
                        <div class="getStartedText">Continue only if satisifed. Starting at as low as $50/month.</div>
                        <div class="getStartedText">No long-term contracts, commitments, or hidden fees.</div>
                        
                    </div>

                </div>
                <div class="faq">
                    <div class="faqGrid">
                        <div class="faqTitle">FAQ</div>
                        <div class="faqItem item_1">
                            <div class="question">How much does it cost? How do I get charged?</div>
                            <div class="answer">You pay for each address that receives your ad. 
                            Your account is charged once per week for all actual mail sent out that week.
                            </div>
                        </div>
                        <div class="faqItem item_2">
                            <div class="question">Does the number of new construction projects change each week?</div>
                            <div class="answer">Yes! Construction project counts vary from week to week. You will be charged for the actual number 
                            of relevant projects that received your ads for each week.</div>
                        </div>
                        <div class="faqItem item_3">
                            <div class="question">Can I set a maximum budget per month?</div>
                            <div class="answer">Yes! You can set a maximum budget that you want to spend per month. Once the budget is reached, your 
                            ads will no longer be included in the mailings until the next month. Minimum monthly budget is $50.</div>
                        </div>
                        <div class="faqItem item_4">
                            <div class="question">Can I target homes in the entire Bay Area?</div>
                            <div class="answer">Currently, we target homes in San Francisco only. But the rest of the Bay Area is coming soon. We are working on it!</div>
                        </div>
                        <div class="faqItem item_5">
                            <div class="question">Can I narrow down what kind of properties or locations to target?</div>
                            <div class="answer">Yes. We categorize projects into type of project, type of property (e.g. single-family home, or apartment building), 
                            neighborhood, and commercial/residential. You can use any of these factors to narrow down your targetting.</div>
                        </div>
                        <div class="faqItem item_6">
                            <div class="question">Can I provide my own flyer or will you produce one for me?</div>
                            <div class="answer">Both. If you submit your own flyer, it must be fit into an A10 envelope, without folding. We can design and produce
                             a flyer for you.</div>
                        </div>
                        <div class="faqItem item_7">
                            <div class="question">Do I have to sign a long-term contract with you?</div>
                            <div class="answer">Absolutely not! We hate long-term contracts. You can cancel anytime with a 15 day notice. </div>
                        </div>
                        <div class="faqItem item_8">
                            <div class="question">What are typical project counts for bathroom and kitchen remodels?</div>
                            <div class="answer">In San Francisco, there are usually between 200 to 400 kitchen and bathroom remodels per month. 
                            Contact us for more details.</div>
                        </div>
                    </div>

                </div>

                <ContactUs 
                    displayStatus={this.state.displayContactUs} 
                    changeDisplayStatus={this.setContactUsDisplay}
                />

                
            </div>
        )
    }
}

export default AdsLanding;