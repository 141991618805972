import React, { Component } from "react";

import { CONTACT_LOGIN_URL, EntityRole } from "utils/constants";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { generic_get_api } from "api/generic_api";
import {AuthContext} from 'auth_context';
import { basicAccountInfo } from "api/basic_account_info";

class ContactLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'contact_login'});

        let pathSplit = window.location.pathname.split('/');

        if (pathSplit.length == 3) {
            let contact_id = pathSplit[2];

            basicAccountInfo()
            .then(data=>{
                // we have success if the logged in agent calls this or this is the 2+ times that contact has requested the page
                if (data.role === EntityRole.AGENT) {

                    // this is necessary because on 'agent_vendors' we must know whether an agent requested this page,
                    // and what the contact_id is since we cannot use the token.
                    this.props.history.push('/contact/agent_vendors', 
                        {role: EntityRole.AGENT, contact_id: contact_id });
                }
                else if (data.role === EntityRole.AGENT_CONTACT) {
                    this.props.history.push('/contact/agent_vendors');
                } else {
                    this.props.history.push('/login');
                }
            })
            .catch(error => {
                // when page is requested for the first time by someone who is not currently logged in
                generic_get_api(CONTACT_LOGIN_URL, {contact_id: contact_id})
                .then(data => {
                    localStorage.setItem('token', data);
                    this.context.setLoggedInEntityRole(EntityRole.AGENT_CONTACT);
                    this.props.history.push('/contact/agent_vendors');
                })
                .catch(error =>  
                {
                    if (error?.name === 'LOGIN_ERROR') {
                        this.props.history.push('/login');
                    }
                })
            });
        }
    }

    render() {
        return(<div></div>)
    }
}

ContactLogin.contextType = AuthContext;
export default ContactLogin;