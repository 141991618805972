/* global google */
import React, { Component, useState } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { PROJECT_INTAKE, PropertyClassMap } from 'utils/constants';
import bay_electric from 'assets/reputation/bay_electric.png';

import { Spinner, Modal, Card} from 'react-bootstrap';
import { generic_get_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { answerFromBool } from "utils/helpers";

const noneResponse = (value) => {
    if (value === null || value === undefined || value === "") {
        return 'Not specified';
    }
    return value;
}

const DeviceTypes = {
    hard_Wired: 'Hard wired',
    nema_14_50: 'Nema 14-50 compatible',
    na: "I am not sure",
}

class EvProjectView extends Component {
    constructor(props) {
        super(props);
        this.state = {
           fetching_quote: false,
        };

        this.fetchQuote = this.fetchQuote.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'ev_project_view'});
        document.title = "Your EV project request";

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length === 4) {
            this.setState({project_id: pathSplit[3]}, this.fetchQuote);
        }
    }

    fetchQuote() {

        this.setState({fetching_quote: true});

        generic_get_api(PROJECT_INTAKE, {project_id: this.state.project_id})
        .then(
            data=> {
                this.setState({
                    fetching_quote: false,
                    project: data,
                });
            }
        )
        .catch(error => {
           
        });
    }

    render() {
        return  <div class="projectViewPage mt-2 container">
                    {
                        this.state.fetching_quote ?
                            <div class="container">
                                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                                <div class="row mt-5">
                                    <div class="col text-center">
                                        <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                        :
                            <OverviewSection
                                project={ this.state.project }
                            />
                    }   
                </div>;
    }
}

const OverviewSection = (props) => {
    const {project} = props;

    const [photo_preview, setPhotoPreview] = useState(null);
    
    const getClickablePhone = () => {
        return "tel:" + project.phone; 
    }

    const format_value = (value) =>{
        if (!value || value === 0 || value === '0') {
            return 'N/A';
        }

        return value;
    }

    if (!project) {
        return null;
    }

    return(
        <div class="container text-center">
            <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
            <div class="row mt-4"><div class="col text-center fw-bold fs-4">New project request</div></div>
            <Card className="mt-2">
                <Card.Body>
                    <>
                        <div class="row"><div class="col fw-bold">Client name</div></div>
                        <div class="row"><div class="col">{project.first_name} {project.last_name}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Email</div></div>
                        <div class="row"><div class="col">{project.email}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Phone</div></div>
                        <div class="row"><div class="col"><a class="text-decoration-none" href={getClickablePhone()}>{formatPhoneNumber(project.phone)}</a></div></div>
                        <div class="row mt-2"><div class="col fw-bold">Project address</div></div>
                        <div class="row"><div class="col">{project.address.street_address}</div></div>
                        {
                            project.address.unit && <div class="row"><div class="col">{project.address.unit}</div></div>
                        }
                        <div class="row">
                            <div class="col">{project.address.city} {project.address.zipCode}</div>
                        </div>
                    </>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    <>
                        <div class="row"><div class="col fw-bold">Number of chargers requested</div></div>
                        <div class="row"><div class="col">{noneResponse(project.num_chargers)}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">EV vehicle models</div></div>
                        <div class="row"><div class="col">{noneResponse(project.vehicle_types)}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Charging device ordered?</div></div>
                        <div class="row"><div class="col">{noneResponse(answerFromBool( project.is_device_ordered))}</div></div>
                        {
                            project.is_device_ordered &&
                            <>
                                <div class="row mt-2"><div class="col fw-bold">Ordered charger type</div></div>
                                <div class="row"><div class="col">{noneResponse(DeviceTypes[project.ordered_charger_type])}</div></div>
                            </>
                        }
                    </>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    {
                        project.panels && project.panels.length > 0 ?
                        <>
                            {
                                project.panels.map((panel, index) => {
                                    return  <> 
                                                <div class="row"><div class="col fw-bold">Electrical panel {index+1} photos</div></div>
                                                <div class="row">
                                                    <div class="col">
                                                    {
                                                        panel.close_up && panel.close_up.photo && 
                                                            <a role="button" onClick={() => setPhotoPreview(panel.close_up.photo)}><img src={panel.close_up.thumb_nail} className="me-2"/></a>
                                                    }
                                                    {
                                                        panel.distance && panel.distance.photo && 
                                                            <a role="button" onClick={() => setPhotoPreview(panel.distance.photo)}><img src={panel.distance.thumb_nail} /></a>
                                                    }
                                                    </div>
                                                </div>
                                            </>
                                })
                            }
                        </>
                        :
                        <>
                            <div class="row"><div class="col fw-bold">Electrical panel photos</div></div>
                            <div class="row"><div class="col">None submitted</div></div>
                        </>
                    }
                    <div class="row mt-4"><div class="col fw-bold">PG&E meter photo</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.meter ? 
                                    <a role="button" onClick={() => setPhotoPreview(project.meter.photo)}><img src={project.meter.thumb_nail} /></a>
                                :
                                    <span>None submitted</span>
                            }
                        </div>
                    </div>

                    <div class="row mt-4"><div class="col fw-bold">Garage photo</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.garage ? 
                                    <a role="button" onClick={() => setPhotoPreview(project.garage.photo)}><img src={project.garage.thumb_nail} /></a>
                                :
                                <span>None submitted</span>
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    <div class="row"><div class="col fw-bold">Additional note</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.note ? 
                                    <span>{project.note}</span>
                                :
                                    <span>None</span>
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {
                project.property_details &&  (project.property_details.tax_info || project.property_details.electrical_permits) &&
                <Card className="mt-2">
                    <Card.Body>
                        <div class="row"><div class="col fw-bold fs-5">Property details</div></div>
                        { (project.property_details.tax_info && project.property_details.tax_info.year_property_built != 0) &&
                            <>
                                <div class="row mt-2"><div class="col fw-bold">Year built</div></div>
                                <div class="row"><div class="col">{format_value(project.property_details.tax_info.year_property_built)}</div></div>
                            </>
                        }
                        {
                            project.property_details.tax_info && project.property_details.tax_info.property_class_code &&
                            <>
                                <div class="row mt-2"><div class="col fw-bold">Property type</div></div>
                                <div class="row"><div class="col">{PropertyClassMap[format_value(project.property_details.tax_info.property_class_code)]}</div></div>
                            </>
                        }
                        {
                            project.property_details.electrical_permits && project.property_details.electrical_permits.length > 0 &&
                            <>
                            <div class="row mt-4"><div class="col fw-bold">Electrical permits</div></div>
                            {   
                                project.property_details.electrical_permits.map(permit => {
                                    return <div class="mt-2"><hr></hr><Permit permit={permit}/></div>
                                })
                            }
                            </>
                        }
                    </Card.Body>
                </Card>
            }
            <div class="row mt-5"><div class="col"></div></div>
            <Modal                        
                show={photo_preview !== null } 
                onHide={ () => setPhotoPreview(null) }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="projectViewPageModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-6 fw-bold"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="col text-center">
                        {   photo_preview && 
                            <img src={photo_preview} class="projectPhoto" />
                        }
                        
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

function Permit(props) {
    const { permit } = props;

    if (!permit) { return null; }

    return(
            <div class="container gx-0 text-start">
                <div class="row gy-3">
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row fw-bold">Permit number</div>
                            <div class="row">{permit.permit_number}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row fw-bold">Issued date</div>
                            <div class="row">{permit.issued_date}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row fw-bold">Completed date</div>
                            <div class="row">{permit.completed_date}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                    <div class="container">
                            <div class="row fw-bold">Permit status</div>
                            <div class="row">{permit.status}</div>
                        </div>
                    </div>
                </div>
                <div class="row gy-3 mt-1">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row fw-bold">Contractor</div>
                            <div class="row">
                            {permit.contractor ?
                                <div class="col gx-0">
                                   {permit.contractor.business_name}&nbsp;
                                </div>
                                :
                                 <span>N/A</span>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gy-3 mt-1">
                    <div class="col-12">
                        <div class="container">
                            <div class="row">{permit.description}</div>
                        </div>
                    </div>
                </div>
            </div>
        
    )
}

export default EvProjectView;