export const CodeSectionsBySection = {
    sections: [
        {
            title: 'Light, Ventilation and heating',
            number: '303',
            id: '303',
            total: 6,
            subsections: [
                {
                    title: 'Habitable rooms',
                    number: '303.1',
                    id: '303_1',
                    text: 'Habitable rooms shall have an aggregate glazing area of not less than 8 percent of the floor area of such rooms. Natural ventilation shall be through windows, skylights, doors, louvers or other approved openings to the outdoor air. Such openings shall be provided with ready access or shall otherwise be readily controllable by the building occupants. The openable area to the outdoors shall be not less than 4 percent of the floor area being ventilated.'
                },
                {
                    title: 'Bathrooms',
                    number: '303.3',
                    id: '303_3',
                    text: '	Bathrooms, water closet compartments and other similar rooms shall be provided with aggregate glazing area in windows of not less than 3 square feet (0.3 m2), one-half of which shall be openable.'
                },
                {
                    title: 'Bathroom exhaust fans',
                    number: '303.3.1',
                    id: '303_3_1',
                    text: 'Each bathroom containing a bathtub, shower or tub/shower combination shall be mechanically ventilated for purposes of humidity control in accordance with the California Mechanical Code, Chapter 4; and the California Green Building Standards Code, Chapter 4, Division 4.5. Note: Window operation is not a permissible method of providing bathroom exhaust for humidity control.'
                },
                {
                    title: 'Ventilation',
                    number: '303.4',
                    id: '303_4',
                    text: 'Ventilation air rates shall be in compliance with the California Mechanical Code.'
                },
                {
                    title: 'Interior Stairway Illumination',
                    number: '303.7',
                    id: '303_7',
                    text: 'Interior stairways shall be provided with an artificial light source to illuminate the landings and treads. The light source shall be capable of illuminating treads and landings to levels of not less than 1 footcandle (11 lux) as measured at the center of treads and landings. There shall be a wall switch at each floor level to control the light source where the stairway has six or more risers.'
                },
                {
                    title: 'Required Glazed Openings',
                    number: '303.9',
                    id: '303_9',
                    text: 'Required glazed openings shall open directly onto a street or public alley, or a yard or court located on the same lot as the building.'
                },

            ]
        },
        {
            title: 'Minimum Room Areas',
            number: '304',
            id: '304',
            total: 3,
            subsections: [
                {
                    title: 'Minimum Area',
                    number: '304.1',
                    id: '304_1',
                    text: 'Habitable rooms shall have a floor area of not less than 70 square feet (6.5 m2).'
                },
                {
                    title: 'Minimum Dimensions',
                    number: '304.2',
                    id: '304_2',
                    text: 'Habitable rooms shall be not less than 7 feet (2134 mm) in any horizontal dimension.'
                },
                {
                    title: 'Height Effect on Room Area',
                    number: '304.3',
                    id: '304_3',
                    text: 'Portions of a room with a sloping ceiling measuring less than 5 feet (1524 mm) or a furred ceiling measuring less than 7 feet (2134 mm) from the finished floor to the finished ceiling shall not be considered as contributing to the minimum required habitable area for that room.'
                },
            ]
        },
        {
            title: 'Emergency Escape and Rescue Openings',
            number: '310',
            id: '310',
            total: 9,
            subsections: [
                {
                    title: 'Emergency Escape and Rescue Opening Required',
                    number: '310.1',
                    id: '310_1',
                    text: 'Basements, habitable attics and every sleeping room shall have not less than one operable emergency escape and rescue opening. Where basements contain one or more sleeping rooms, an emergency escape and rescue opening shall be required in each sleeping room. Emergency escape and rescue openings shall open directly into a public way or to a yard or court that opens to a public way.'
                },
                {
                    title: 'Operational Constraints and Opening Control Devices',
                    number: '310.1.1',
                    id: '310_1_1',
                    text: 'Emergency escape and rescue openings shall be maintained free of any obstructions other than those allowed by this section and shall be operational from the inside of the room without the use of keys, tools or special knowledge. Window opening control devices and fall prevention devices complying with ASTM F2090 shall be permitted for use on windows serving as a required emergency escape and rescue opening and shall be not more than 70 inches (178 cm) above the finished floor. The release mechanism shall be maintained operable at all times. Such bars, grills, grates or any similar devices shall be equipped with an approved exterior release device for use by the fire department only when required by the authority having jurisdiction. Where security bars (burglar bars) are installed on emergency egress and rescue windows or doors, on or after July 1, 2000, such devices shall comply with California Building Standards Code, Part 12, Chapter 12-3 and other applicable provisions of this code.'
                },
                {
                    title: 'Emergency Escape and Rescue Openings',
                    number: '310.2',
                    id: '310_2',
                    text: 'Emergency escape and rescue openings shall have minimum dimensions in accordance with Sections R310.2.1 through R310.2.4.'
                },
                {
                    title: 'Minimum Size',
                    number: '310.2.1',
                    id: '310_2_1',
                    text: 'Emergency escape and rescue openings shall have a net clear opening of not less than 5.7 square feet (0.530 m2).'
                },
                {
                    title: 'Minimum Dimensions',
                    number: '310.2.2',
                    id: '310_2_2',
                    text: 'The minimum net clear opening height dimension shall be 24 inches (610 mm). The minimum net clear opening width dimension shall be 20 inches (508 mm). The net clear opening dimensions shall be the result of normal operation of the opening.'
                },
                {
                    title: 'Maximum Height From Floor',
                    number: '310.2.3',
                    id: '310_2_3',
                    text: 'Emergency escape and rescue openings shall have the bottom of the clear opening not greater than 44 inches (1118 mm) measured from the floor.'
                },
                {
                    title: 'Emergency Escape and Rescue Openings Under Decks, Porches and Cantilevers',
                    number: '310.2.4',
                    id: '310_2_4',
                    text: 'Emergency escape and rescue openings installed under decks, porches and cantilevers shall be fully openable and provide a path not less than 36 inches (914 mm) in height and 36 inches (914 mm) in width to a yard or court.'
                },
                {
                    title: 'Replacement Windows for Emergency Escape and Rescue Openings',
                    number: '310.5',
                    id: '310_5',
                    text: "Replacement windows installed in buildings meeting the scope of this code shall be exempt from Sections R310.2 and R310.4.4, provided that the replacement window meets the following conditions: The replacement window is the manufacturer's largest standard size window that will fit within the existing frame or existing rough opening. The replacement window is of the same operating style as the existing window or a style that provides for an equal or greater window opening area than the existing window. The replacement window is not part of a change of occupancy."
                },
                {
                    title: 'Dwelling Additions',
                    number: '310.6',
                    id: '310_6',
                    text: 'Where dwelling additions contain sleeping rooms, an emergency escape and rescue opening shall be provided in each new sleeping room. Where dwelling additions have basements, an emergency escape and rescue opening shall be provided in the new basement.'
                },
                
            ]
        },
        {
            title: 'Means of Egress',
            number: '311',
            id: '311',
            total: 13,
            subsections: [
                {
                    title: 'Means of Egress',
                    number: '311.1',
                    id: '311_1',
                    text: 'Dwellings shall be provided with a means of egress in accordance with this section. The means of egress shall provide a continuous and unobstructed path of vertical and horizontal egress travel from all portions of the dwelling to the required egress door without requiring travel through a garage. The required egress door shall open directly into a public way or to a yard or court that opens to a public way.'
                },
                {
                    title: 'Vertical Egress',
                    number: '311.4',
                    id: '311_4',
                    text: 'Egress from habitable levels including habitable attics and basements that are not provided with an egress door in accordance with Section R311.2 shall be by one or more ramps in accordance with Section R311.8 or one or more stairways in accordance with Section R311.7 or both. For habitable levels or basements located more than one story above or more than one story below an egress door, the maximum travel distance from any occupied point to a stairway or ramp that provides egress from such habitable level or basement, shall not exceed 50 feet (15 240 mm).'
                },
                {
                    title: 'Hallways',
                    number: '311.6',
                    id: '311_6',
                    text: 'The width of a hallway shall be not less than 3 feet (914 mm).'
                },
                {
                    title: 'Stairways',
                    number: '311.7',
                    id: '311_7',
                    text: 'Where required by this code or provided, stairways shall comply with this section.'
                },
                {
                    title: 'Width',
                    number: '311.7.1',
                    id: '311_7_1',
                    text: 'Stairways shall be not less than 36 inches (914 mm) in clear width at all points above the permitted handrail height and below the required headroom height. The clear width of stairways at and below the handrail height, including treads and landings, shall be not less than 311/2 inches (787 mm) where a handrail is installed on one side and 27 inches (698 mm) where handrails are installed on both sides.'
                },
                {
                    title: 'Headroom',
                    number: '311.7.2',
                    id: '311_7_2',
                    text: 'The headroom in stairways shall be not less than 6 feet 8 inches (2032 mm) measured vertically from the sloped line adjoining the tread nosing or from the floor surface of the landing or platform on that portion of the stairway.'
                },
                {
                    title: 'Vertical rise',
                    number: '311.7.3',
                    id: '311_7_3',
                    text: 'A flight of stairs shall not have a vertical rise greater than 12 feet 7 inches (3835 mm) between floor levels or landings.'
                },
                {
                    title: 'Walkline',
                    number: '311.7.4',
                    id: '311_7_4',
                    text: 'The walkline across winder treads and landings shall be concentric to the turn and parallel to the direction of travel entering and exiting the turn. The walkline shall be located 12 inches (305 mm) from the inside of the turn. The 12-inch (305 mm) dimension shall be measured from the widest point of the clear stair width at the walking surface. Where winders are adjacent within a flight, the point of the widest clear stair width of the adjacent winders shall be used.'
                },
                {
                    title: 'Stair threads and risers',
                    number: '311.7.5',
                    id: '311_7_5',
                    text: 'Stair treads and risers shall meet the requirements of this section. For the purposes of this section, dimensions and dimensioned surfaces shall be exclusive of carpets, rugs or runners.'
                },
                {
                    title: 'Landings for Stairways',
                    number: '311.7.6',
                    id: '311_7_6',
                    text: 'There shall be a floor or landing at the top and bottom of each stairway. The width perpendicular to the direction of travel shall be not less than the width of the flight served. For landings of shapes other than square or rectangular, the depth at the walk line and the total area shall be not less than that of a quarter circle with a radius equal to the required landing width. Where the stairway has a straight run, the depth in the direction of travel shall be not less than 36 inches (914 mm).'
                },
                {
                    title: 'Stairway Walking Surface',
                    number: '311.7.7',
                    id: '311_7_7',
                    text: 'The walking surface of treads and landings of stairways shall be sloped not steeper than 1 unit vertical in 48 units horizontal (2-percent slope).'
                },
                {
                    title: 'Handrails',
                    number: '311.7.8',
                    id: '311_7_8',
                    text: 'Handrails shall be provided on not less than one side of each flight of stairs with four or more risers.'
                },
                {
                    title: 'Illumination',
                    number: '311.7.9',
                    id: '311_7_9',
                    text: 'Stairways shall be provided with illumination in accordance with Sections R303.7 and R303.8.'
                },
            ]
        },
    ]
}

export const AdminBulletins = {
    sections: [
        {
            title: 'Emergency Escape and Rescue Openings (EEROs) to Yard or Court for Existing or New Buildings of R-3 Occupancies',
            number: 'EG-02',
            id: 'EG_02',
            text: 'To clarify local equivalency requirements for emergency escape and rescue openings that open to a yard or court that does not open to a public way for R3 occupancies',
            date: 'November 28, 2023',
            url: "https://www.sf.gov/sites/default/files/2024-01/EERO%20Rev%20231128.pdf",
        },
        {
            title: 'Local Equivalency for Approval of Emergency Escape or Rescue Windows at Courts and Light Wells',
            number: 'AB-018',
            id: 'AB_018',
            text: 'The purpose of this Administrative Bulletin is to provide standards and procedures for the application and case-by-case review of requests for a local equivalency to allow exiting from emergency escape or rescue windows into enclosed courts or Light wells where such means of escape or rescue do not strictly comply with the provisions of the 2007 San Francisco Building Code (SFBC) regarding emergency escape or rescue windows.',
            date: 'September 18, 2002',
            url: "https://sfdbi.org/ftp/uploadedfiles/dbi/downloads/AB018_2007.pdf",
        },
       
    ]
}

export const CodeSectionsByRoom = {
    rooms: [
        {
            title: 'Whole structure',
            id: 'whole_house',
            total: 2,
            sections: [
                {
                    title: 'Means of Egress',
                    number: 'CA-BC Section 1003',
                    id: '1003',
                    total: 1,
                    subsections: [
                        {
                            title: 'Ceiling height',
                            number: '1003.2',
                            id: '1003_2',
                            text: 'The means of egress shall have a ceiling height of not less than 7 feet 6 inches (2286 mm) above the finished floor.'
                        },
                    ]
                },
            ]
        },
        {
            title: 'All habitable rooms',
            id: 'habitable_rooms',
            sections: [
                {
                    title: 'Ventilation',
                    number: 'CA-BC Section 1202',
                    id: '1202',
                    total: 2,
                    subsections: [
                        {
                            title: 'Ventilation Area Required',
                            number: '1202.5.1',
                            id: '1202_5_1',
                            text: 'The openable area of the openings to the outdoors shall be not less than 4 percent of the floor area being ventilated.'
                        },
                        {
                            title: 'Openings Below Grade',
                            number: '1202.5.1.2',
                            id: '1202_5_1_2',
                            text: 'Where openings below grade provide required natural ventilation, the outside horizontal clear space measured perpendicular to the opening shall be one and one-half times the depth of the opening. The depth of the opening shall be measured from the average adjoining ground level to the bottom of the opening.'
                        },
                    ]
                },
                {
                    title: 'Lighting',
                    number: 'CA-BC Section 1204',
                    id: '1204',
                    total: 2,
                    subsections: [
                        {
                            title: 'Natural Light',
                            number: '1204.2',
                            id: '1204_2',
                            text: 'The minimum net glazed area shall be not less than 8 percent of the floor area of the room served.'
                        },
                        {
                            title: 'Exterior Openings',
                            number: '1204.2.2',
                            id: '1204_2_2',
                            text: 'Exterior openings required by Section 1204.2 for natural light shall open directly onto a public way, yard or court, as set forth in Section 1205.'
                        },
                    ]
                },
                {
                    title: 'Yards and courts',
                    number: 'CA-BC Section 1205',
                    id: '1205',
                    total: 2,
                    subsections: [
                        {
                            title: 'General',
                            number: '1205.1',
                            id: '1205_1',
                            text: 'This section shall apply to yards and courts adjacent to exterior openings that provide natural light or ventilation. Such yards and courts shall be on the same lot as the building.'
                        },
                        {
                            title: 'Yards',
                            number: '1205.2',
                            id: '1205_2',
                            text: 'Yards shall be not less than 3 feet (914 mm) in width for buildings two stories or less above grade plane. For buildings more than two stories above grade plane, the minimum width of the yard shall be increased at the rate of 1 foot (305 mm) for each additional story. For buildings exceeding 14 stories above grade plane, the required width of the yard shall be computed on the basis of 14 stories above grade plane.'
                        },
                    ]
                },
                {
                    title: 'Interior space dimensions',
                    number: 'CA-BC Section 1208',
                    id: '1208',
                    total: 5,
                    subsections: [
                        {
                            title: 'Minimum room widths',
                            number: '1208.1',
                            id: '1208_01',
                            text: 'Habitable spaces, other than a kitchen, shall be not less than 7 feet (2134 mm) in any plan dimension. Kitchens shall have a clear passageway of not less than 3 feet (914 mm) between counter fronts and appliances or counter fronts and walls.'
                        },
                        {
                            title: 'Minimum Ceiling Heights',
                            number: '1208.2',
                            id: '1208_02',
                            text: 'Occupiable spaces, habitable spaces and corridors shall have a ceiling height of not less than 7 feet 6 inches (2286 mm) above the finished floor. Bathrooms, toilet rooms, kitchens, storage rooms and laundry rooms shall have a ceiling height of not less than 7 feet (2134 mm) above the finished floor.'
                        },
                        {
                            title: 'Minimum Ceiling Heights E1',
                            number: '1208.2.E1',
                            id: '1208_02_e1',
                            text: 'If any room in a building has a sloped ceiling, the prescribed ceiling height for the room is required in one-half the area thereof. Any portion of the room measuring less than 5 feet (1524 mm) from the finished floor to the ceiling shall not be included in any computation of the minimum area thereof.'
                        },
                        {
                            title: 'Minimum Ceiling Heights E4',
                            number: '1208.2.E4',
                            id: '1208_02_e4',
                            text: 'Corridors contained within a dwelling unit or sleeping unit in a Group R occupancy shall have a ceiling height of not less than 7 feet (2134 mm) above the finished floor.'
                        },
                        {
                            title: 'Room area',
                            number: '1208.3',
                            id: '1208_03',
                            text: 'Every dwelling unit shall have not less than one room that shall have not less than 120 square feet (11.2 m2) of net floor area. Other habitable rooms shall have a net floor area of not less than 70 square feet (6.5 m2).'
                        },
                    ]
                },
            ]
        },
        {
            title: 'Bedrooms',
            id: 'bedrooms',
            sections: [
                {
                    title: 'Emergency Escape and Rescue Openings',
                    number: 'SF bulletins / info sheets',
                    id: 'bulletins',
                    total: 1,
                    subsections: [
                        {
                            title: 'Emergency Escape and Rescue Openings (EEROs) to Yard or Court for Existing or New Buildings of R-3 Occupancies',
                            number: 'EG-02',
                            id: 'EG_02',
                            text: 'To clarify local equivalency requirements for emergency escape and rescue openings that open to a yard or court that does not open to a public way for R3 occupancies',
                            date: 'November 28, 2023',
                            url: "https://www.sf.gov/sites/default/files/2024-01/EERO%20Rev%20231128.pdf",
                        },
                    ],
                  
                },
                {
                    title: 'Emergency Escape and Rescue',
                    number: 'CA-BC Section 1031',
                    id: '1031',
                    total: 5,
                    subsections: [
                        {
                            title: 'General',
                            number: '1031.2',
                            id: '1031.2',
                            text: 'In addition to the means of egress required by this chapter, emergency escape and rescue openings shall be provided in Group R occupancies. Basements and sleeping rooms below the fourth story above grade plane shall have not fewer than one emergency escape and rescue opening in accordance with this section. Where basements contain one or more sleeping rooms, an emergency escape and rescue opening shall be required in each sleeping room, but shall not be required in adjoining areas of the basement. Such openings shall open directly into a public way or to a yard or court that opens to a public way.'
                        },
                        {
                            title: 'Operational Constraints and Opening Control Devices',
                            number: '1031.2.1',
                            id: '1031_2_1',
                            text: 'Emergency escape and rescue openings shall be maintained free of any obstructions other than those allowed by this section and shall be operational from the inside of the room without the use of keys, tools or special knowledge. Window opening control devices and fall prevention devices complying with ASTM F2090 shall be permitted for use on windows serving as a required emergency escape and rescue opening and shall be not more than 70 inches (178 cm) above the finished floor. The release mechanism shall be maintained operable at all times. Such bars, grills, grates or any similar devices shall be equipped with an approved exterior release device for use by the fire department only when required by the authority having jurisdiction. Where security bars (burglar bars) are installed on emergency egress and rescue windows or doors, on or after July 1, 2000, such devices shall comply with California Building Standards Code, Part 12, Chapter 12-3 and other applicable provisions of this code.'
                        },
                        {
                            title: 'Minimum Size',
                            number: '1031.3.1',
                            id: '1031_3_1',
                            text: 'Emergency escape and rescue openings shall have a net clear opening of not less than 5.7 square feet (0.530 m2).'
                        },
                        {
                            title: 'Minimum Dimensions',
                            number: '1031.3.2',
                            id: '1031_3_2',
                            text: 'The minimum net clear opening height dimension shall be 24 inches (610 mm). The minimum net clear opening width dimension shall be 20 inches (508 mm). The net clear opening dimensions shall be the result of normal operation of the opening.'
                        },
                        {
                            title: 'Maximum Height From Floor',
                            number: '1031.3.3',
                            id: '1031_3_3',
                            text: 'Emergency escape and rescue openings shall have the bottom of the clear opening not greater than 44 inches (1118 mm) measured from the floor.'
                        },
                    ]
                },
            ]
        },
        {
            title: 'Bathrooms',
            id: 'bathrooms',
            sections: [
                {
                    title: 'Ventilation',
                    number: 'CA-BC Section 1202',
                    id: '1202',
                    total: 1,
                    subsections: [
                        {
                            title: 'Bathrooms',
                            number: '1202.5.2.1',
                            id: '1202_5_2_1',
                            text: 'Rooms containing bathtubs, showers, spas and similar bathing fixtures shall be mechanically ventilated in accordance with the California Mechanical Code. The minimum exhaust rate shall not be less than that established by Table 403.7 "Minimum Exhaust Rates." See California Mechanical Code, Chapter 5, for additional provisions related to environmental air ducts. [HCD 1] In addition to the requirements in this section and in the California Mechanical Code, bathrooms in Group R occupancies shall be mechanically ventilated in accordance with the California Green Building Standards Code (CALGreen), Chapter 4, Division 4.5.'
                        },
                    ]
                },
            ]
        },
        {
            title: 'Internal stairs',
            id: 'internal_stairs',
            sections: [
                {
                    title: 'Lighting',
                    number: 'CA-BC Section 1204',
                    id: '1204',
                    total: 1,
                    subsections: [
                        {
                            title: 'Stairway Illumination',
                            number: '1204.4',
                            id: '1204_4',
                            text: 'Stairways within dwelling units and exterior stairways serving a dwelling unit shall have an illumination level on tread runs of not less than 1 footcandle (11 lux). Stairways in other occupancies shall be governed by Chapter 10.'
                        },
                    ]
                },
                {
                    title: 'Means of Egress',
                    number: 'CA-BC Section 1011',
                    id: '1011',
                    total: 7,
                    subsections: [
                        {
                            title: 'Width',
                            number: '1011.2',
                            id: '1011_2',
                            text: 'Stairways shall be not less than 36 inches (914 mm) in clear width at all points above the permitted handrail height and below the required headroom height. The clear width of stairways at and below the handrail height, including treads and landings, shall be not less than 311/2 inches (787 mm) where a handrail is installed on one side and 27 inches (698 mm) where handrails are installed on both sides.'
                        },
                        {
                            title: 'Headroom',
                            number: '1011.3',
                            id: '1011_3',
                            text: 'The headroom in stairways shall be not less than 6 feet 8 inches (2032 mm) measured vertically from the sloped line adjoining the tread nosing or from the floor surface of the landing or platform on that portion of the stairway.'
                        },
                        {
                            title: 'Vertical rise',
                            number: '1011.8',
                            id: '1011_8',
                            text: 'A flight of stairs shall not have a vertical rise greater than 12 feet 7 inches (3835 mm) between floor levels or landings.'
                        },
                        {
                            title: 'Walkline',
                            number: '1011.4',
                            id: '1011_4',
                            text: 'The walkline across winder treads and landings shall be concentric to the turn and parallel to the direction of travel entering and exiting the turn. The walkline shall be located 12 inches (305 mm) from the inside of the turn. The 12-inch (305 mm) dimension shall be measured from the widest point of the clear stair width at the walking surface. Where winders are adjacent within a flight, the point of the widest clear stair width of the adjacent winders shall be used.'
                        },
                        {
                            title: 'Stair threads and risers',
                            number: '1011.5',
                            id: '1011_5',
                            text: 'Stair treads and risers shall meet the requirements of this section. For the purposes of this section, dimensions and dimensioned surfaces shall be exclusive of carpets, rugs or runners.'
                        },
                        {
                            title: 'Landings for Stairways',
                            number: '1011.6',
                            id: '1011_6',
                            text: 'There shall be a floor or landing at the top and bottom of each stairway. The width perpendicular to the direction of travel shall be not less than the width of the flight served. For landings of shapes other than square or rectangular, the depth at the walk line and the total area shall be not less than that of a quarter circle with a radius equal to the required landing width. Where the stairway has a straight run, the depth in the direction of travel shall be not less than 36 inches (914 mm).'
                        },
                        {
                            title: 'Spiral Stairways',
                            number: '1011.10',
                            id: '1011_10',
                            text: 'Spiral stairways are permitted to be used as a component in the means of egress only within dwelling units or from a space not more than 250 square feet (23 m2) in area and serving not more than five occupants, or from technical production areas in accordance with Section 410.5.'
                        },
                        {
                            title: 'Handrails',
                            number: '1011.11',
                            id: '1011_11',
                            text: 'Handrails shall be provided on not less than one side of each flight of stairs with four or more risers.'
                        },
                    ]
                },
            ]
        },
        {
            title: 'Hallways',
            id: 'hallways',
            sections: [
                {
                    title: 'Means of Egress',
                    number: 'CA-BC Section 1020',
                    id: '1020',
                    total: 1,
                    subsections: [
                        {
                            title: 'Corridors',
                            number: '1020.3',
                            id: '1020_3',
                            text: 'The width of a hallway shall be not less than 36 inches (914 mm).'
                        },
                    ]
                },
            ]
        },
    ],

    sections: [
        {
            title: 'Light, Ventilation and heating',
            number: '303',
            id: '303',
            total: 6,
            subsections: [
                {
                    title: 'Habitable rooms',
                    number: '303.1',
                    id: '303_1',
                    text: 'Habitable rooms shall have an aggregate glazing area of not less than 8 percent of the floor area of such rooms. Natural ventilation shall be through windows, skylights, doors, louvers or other approved openings to the outdoor air. Such openings shall be provided with ready access or shall otherwise be readily controllable by the building occupants. The openable area to the outdoors shall be not less than 4 percent of the floor area being ventilated.'
                },
                {
                    title: 'Bathrooms',
                    number: '303.3',
                    id: '303_3',
                    text: '	Bathrooms, water closet compartments and other similar rooms shall be provided with aggregate glazing area in windows of not less than 3 square feet (0.3 m2), one-half of which shall be openable.'
                },
                {
                    title: 'Bathroom exhaust fans',
                    number: '303.3.1',
                    id: '303_3_1',
                    text: 'Each bathroom containing a bathtub, shower or tub/shower combination shall be mechanically ventilated for purposes of humidity control in accordance with the California Mechanical Code, Chapter 4; and the California Green Building Standards Code, Chapter 4, Division 4.5. Note: Window operation is not a permissible method of providing bathroom exhaust for humidity control.'
                },
                {
                    title: 'Ventilation',
                    number: '303.4',
                    id: '303_4',
                    text: 'Ventilation air rates shall be in compliance with the California Mechanical Code.'
                },
                {
                    title: 'Interior Stairway Illumination',
                    number: '303.7',
                    id: '303_7',
                    text: 'Interior stairways shall be provided with an artificial light source to illuminate the landings and treads. The light source shall be capable of illuminating treads and landings to levels of not less than 1 footcandle (11 lux) as measured at the center of treads and landings. There shall be a wall switch at each floor level to control the light source where the stairway has six or more risers.'
                },
                {
                    title: 'Required Glazed Openings',
                    number: '303.9',
                    id: '303_9',
                    text: 'Required glazed openings shall open directly onto a street or public alley, or a yard or court located on the same lot as the building.'
                },

            ]
        },
        {
            title: 'Minimum Room Areas',
            number: '304',
            id: '304',
            total: 3,
            subsections: [
                {
                    title: 'Minimum Area',
                    number: '304.1',
                    id: '304_1',
                    text: 'Habitable rooms shall have a floor area of not less than 70 square feet (6.5 m2).'
                },
                {
                    title: 'Minimum Dimensions',
                    number: '304.2',
                    id: '304_2',
                    text: 'Habitable rooms shall be not less than 7 feet (2134 mm) in any horizontal dimension.'
                },
                {
                    title: 'Height Effect on Room Area',
                    number: '304.3',
                    id: '304_3',
                    text: 'Portions of a room with a sloping ceiling measuring less than 5 feet (1524 mm) or a furred ceiling measuring less than 7 feet (2134 mm) from the finished floor to the finished ceiling shall not be considered as contributing to the minimum required habitable area for that room.'
                },
            ]
        },
        {
            title: 'Emergency Escape and Rescue Openings',
            number: '310',
            id: '310',
            total: 9,
            subsections: [
                {
                    title: 'Emergency Escape and Rescue Opening Required',
                    number: '310.1',
                    id: '310_1',
                    text: 'Basements, habitable attics and every sleeping room shall have not less than one operable emergency escape and rescue opening. Where basements contain one or more sleeping rooms, an emergency escape and rescue opening shall be required in each sleeping room. Emergency escape and rescue openings shall open directly into a public way or to a yard or court that opens to a public way.'
                },
                {
                    title: 'Operational Constraints and Opening Control Devices',
                    number: '310.1.1',
                    id: '310_1_1',
                    text: 'Emergency escape and rescue openings shall be maintained free of any obstructions other than those allowed by this section and shall be operational from the inside of the room without the use of keys, tools or special knowledge. Window opening control devices and fall prevention devices complying with ASTM F2090 shall be permitted for use on windows serving as a required emergency escape and rescue opening and shall be not more than 70 inches (178 cm) above the finished floor. The release mechanism shall be maintained operable at all times. Such bars, grills, grates or any similar devices shall be equipped with an approved exterior release device for use by the fire department only when required by the authority having jurisdiction. Where security bars (burglar bars) are installed on emergency egress and rescue windows or doors, on or after July 1, 2000, such devices shall comply with California Building Standards Code, Part 12, Chapter 12-3 and other applicable provisions of this code.'
                },
                {
                    title: 'Emergency Escape and Rescue Openings',
                    number: '310.2',
                    id: '310_2',
                    text: 'Emergency escape and rescue openings shall have minimum dimensions in accordance with Sections R310.2.1 through R310.2.4.'
                },
                {
                    title: 'Minimum Size',
                    number: '310.2.1',
                    id: '310_2_1',
                    text: 'Emergency escape and rescue openings shall have a net clear opening of not less than 5.7 square feet (0.530 m2).'
                },
                {
                    title: 'Minimum Dimensions',
                    number: '310.2.2',
                    id: '310_2_2',
                    text: 'The minimum net clear opening height dimension shall be 24 inches (610 mm). The minimum net clear opening width dimension shall be 20 inches (508 mm). The net clear opening dimensions shall be the result of normal operation of the opening.'
                },
                {
                    title: 'Maximum Height From Floor',
                    number: '310.2.3',
                    id: '310_2_3',
                    text: 'Emergency escape and rescue openings shall have the bottom of the clear opening not greater than 44 inches (1118 mm) measured from the floor.'
                },
                {
                    title: 'Emergency Escape and Rescue Openings Under Decks, Porches and Cantilevers',
                    number: '310.2.4',
                    id: '310_2_4',
                    text: 'Emergency escape and rescue openings installed under decks, porches and cantilevers shall be fully openable and provide a path not less than 36 inches (914 mm) in height and 36 inches (914 mm) in width to a yard or court.'
                },
                {
                    title: 'Replacement Windows for Emergency Escape and Rescue Openings',
                    number: '310.5',
                    id: '310_5',
                    text: "Replacement windows installed in buildings meeting the scope of this code shall be exempt from Sections R310.2 and R310.4.4, provided that the replacement window meets the following conditions: The replacement window is the manufacturer's largest standard size window that will fit within the existing frame or existing rough opening. The replacement window is of the same operating style as the existing window or a style that provides for an equal or greater window opening area than the existing window. The replacement window is not part of a change of occupancy."
                },
                {
                    title: 'Dwelling Additions',
                    number: '310.6',
                    id: '310_6',
                    text: 'Where dwelling additions contain sleeping rooms, an emergency escape and rescue opening shall be provided in each new sleeping room. Where dwelling additions have basements, an emergency escape and rescue opening shall be provided in the new basement.'
                },
                
            ]
        },
        {
            title: 'Means of Egress',
            number: '311',
            id: '311',
            total: 13,
            subsections: [
                {
                    title: 'Means of Egress',
                    number: '311.1',
                    id: '311_1',
                    text: 'Dwellings shall be provided with a means of egress in accordance with this section. The means of egress shall provide a continuous and unobstructed path of vertical and horizontal egress travel from all portions of the dwelling to the required egress door without requiring travel through a garage. The required egress door shall open directly into a public way or to a yard or court that opens to a public way.'
                },
                {
                    title: 'Vertical Egress',
                    number: '311.4',
                    id: '311_4',
                    text: 'Egress from habitable levels including habitable attics and basements that are not provided with an egress door in accordance with Section R311.2 shall be by one or more ramps in accordance with Section R311.8 or one or more stairways in accordance with Section R311.7 or both. For habitable levels or basements located more than one story above or more than one story below an egress door, the maximum travel distance from any occupied point to a stairway or ramp that provides egress from such habitable level or basement, shall not exceed 50 feet (15 240 mm).'
                },
                {
                    title: 'Hallways',
                    number: '311.6',
                    id: '311_6',
                    text: 'The width of a hallway shall be not less than 3 feet (914 mm).'
                },
                {
                    title: 'Stairways',
                    number: '311.7',
                    id: '311_7',
                    text: 'Where required by this code or provided, stairways shall comply with this section.'
                },
                {
                    title: 'Width',
                    number: '311.7.1',
                    id: '311_7_1',
                    text: 'Stairways shall be not less than 36 inches (914 mm) in clear width at all points above the permitted handrail height and below the required headroom height. The clear width of stairways at and below the handrail height, including treads and landings, shall be not less than 311/2 inches (787 mm) where a handrail is installed on one side and 27 inches (698 mm) where handrails are installed on both sides.'
                },
                {
                    title: 'Headroom',
                    number: '311.7.2',
                    id: '311_7_2',
                    text: 'The headroom in stairways shall be not less than 6 feet 8 inches (2032 mm) measured vertically from the sloped line adjoining the tread nosing or from the floor surface of the landing or platform on that portion of the stairway.'
                },
                {
                    title: 'Vertical rise',
                    number: '311.7.3',
                    id: '311_7_3',
                    text: 'A flight of stairs shall not have a vertical rise greater than 12 feet 7 inches (3835 mm) between floor levels or landings.'
                },
                {
                    title: 'Walkline',
                    number: '311.7.4',
                    id: '311_7_4',
                    text: 'The walkline across winder treads and landings shall be concentric to the turn and parallel to the direction of travel entering and exiting the turn. The walkline shall be located 12 inches (305 mm) from the inside of the turn. The 12-inch (305 mm) dimension shall be measured from the widest point of the clear stair width at the walking surface. Where winders are adjacent within a flight, the point of the widest clear stair width of the adjacent winders shall be used.'
                },
                {
                    title: 'Stair threads and risers',
                    number: '311.7.5',
                    id: '311_7_5',
                    text: 'Stair treads and risers shall meet the requirements of this section. For the purposes of this section, dimensions and dimensioned surfaces shall be exclusive of carpets, rugs or runners.'
                },
                {
                    title: 'Landings for Stairways',
                    number: '311.7.6',
                    id: '311_7_6',
                    text: 'There shall be a floor or landing at the top and bottom of each stairway. The width perpendicular to the direction of travel shall be not less than the width of the flight served. For landings of shapes other than square or rectangular, the depth at the walk line and the total area shall be not less than that of a quarter circle with a radius equal to the required landing width. Where the stairway has a straight run, the depth in the direction of travel shall be not less than 36 inches (914 mm).'
                },
                {
                    title: 'Stairway Walking Surface',
                    number: '311.7.7',
                    id: '311_7_7',
                    text: 'The walking surface of treads and landings of stairways shall be sloped not steeper than 1 unit vertical in 48 units horizontal (2-percent slope).'
                },
                {
                    title: 'Handrails',
                    number: '311.7.8',
                    id: '311_7_8',
                    text: 'Handrails shall be provided on not less than one side of each flight of stairs with four or more risers.'
                },
                {
                    title: 'Illumination',
                    number: '311.7.9',
                    id: '311_7_9',
                    text: 'Stairways shall be provided with illumination in accordance with Sections R303.7 and R303.8.'
                },
            ]
        },
    ]
}