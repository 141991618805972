import React, { Component } from "react";

import { INFO_REQUEST_URL } from 'utils/constants'
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import rocket from 'assets/reputation/rocket.svg';
import document from 'assets/reputation/document.svg';
import architect_color from 'assets/reputation/architect_color.svg';
import seo from 'assets/reputation/seo.png';
import { Button, Spinner, Card } from "react-bootstrap";
import { isValidEmail } from "utils/helpers";

class CodeLanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            show_contact_us_modal: false,
            name: "",
            company: "",
            email: "",
            phone: "",
            submitted: false,
            showSpinner: false,
        };

        this.formChanged = this.formChanged.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
    }
    
    setShowContactUsModal(value) {
        this.setState({show_contact_us_modal: value});
    }

    handleSubmitRequest() {

        var request = { 
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(INFO_REQUEST_URL);
        this.setState({ showSpinner: true })

        trackEvent(EventNames.API_REQUEST, {'data_1': request, "data_2": INFO_REQUEST_URL})

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setState({
                        submitted: true,
                        showSpinner: false
                    })
                }
                else {
                    this.setState({
                        showSpinner: false
                    })
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'code_landing'})
    }

    formChanged(e, element) {
        this.setState({ [element]: e.target.value })
    }

    render() {
        const canSubmit = () => {
            return this.state.name && this.state.email && this.state.phone && isValidEmail(this.state.email) && this.state.company;
        }

        return(
            <div class="agentLandingEnvelope"  id="top-of-page">
                <div class="agentLandingMain bg-success p-5">
                    <div class="container">
                        <div class="row gy-5">
                            <div class="col-lg-2 rocket"><img src={rocket}/></div>
                            <div class="col-12 col-md-6 col-lg-6  position-relative">
                                <div class="container">
                                    <div class="row text-white display-6">
                                        Streamline planning &amp; building code compliance
                                    </div>
                                    <div class="row mt-3 text-black fs-2">
                                        Get plans approved faster, with fewer iterations
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="container bg-secondary p-3">
                                    { !this.state.submitted ?
                                        <div class="row">
                                            <div class="col-12 text-warning fs-5 fw-bold"> 
                                                GET ACCESS
                                            </div>
                                            <div class="col-12 mt-3"><input type="text" class="form-control" placeholder="Name" value={ this.name } onChange={ (e) => this.formChanged(e, 'name') }/></div>
                                            <div class="col-12 mt-2"><input type="text" class="form-control"  placeholder="Email"  value={ this.email } onChange={ (e) => this.formChanged(e, 'email') }/></div>
                                            <div class="col-12 mt-2"><input type="text" class="form-control"  placeholder="Phone"  value={ this.phone } onChange={ (e) => this.formChanged(e, 'phone') }/></div>
                                            <div class="col-12 mt-2">
                                                <select class="form-select" name="questionCategory" onChange={(e)=>this.formChanged(e, 'company' )}>
                                                    <option disabled selected value>Your profession</option>
                                                    <option value="Agent" >Architect</option>
                                                    <option value="Vendor" >Designer</option>
                                                    <option value="Vendor" >Engineer</option>
                                                    <option value="Vendor" >Contractor</option>
                                                    <option value="Vendor" >Other</option>
                                                </select></div>
                                            <div class="col-12 mt-4 text-center">
                                                { this.state.showSpinner ? 
                                                    <Button variant="warning" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        SUBMITTING</Button>
                                                :
                                                    <Button variant="warning" onClick={ this.handleSubmitRequest } disabled={!canSubmit()}>SUBMIT NOW</Button>
                                                }
                                                
                                            </div>
                                        </div>
                                    :
                                        <div class="row">
                                            <div class="col text-white">Thank you for your submission. We will be in touch very soon.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-5 offer">
                    <div class="container">
                        <div class="row"> <div class="col text-center display-6">With AI, seamlessly work with building and planning codes</div></div>
                        <div class="row mt-5 gy-3">
                            <div class="col-12 col-md-4">
                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row text-center"><div class="col"><img src={seo} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3 text-center"><div class="col h4 text-secondary">Planning code, demystified</div></div>
                                            <div class="row mt-2"><div class="col">- Find crucial planning codes for your project in seconds</div></div>
                                            <div class="row mt-2"><div class="col">- Ask your AI assistant all your code-related questions</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row text-center"><div class="col"><img src={document} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3 text-center"><div class="col h4 text-secondary">Verified plans</div></div>
                                            <div class="row mt-2"><div class="col">- Automatically check PDF construction plans for code omissions and errors</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row text-center"><div class="col"><img src={architect_color} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3 text-center"><div class="col h4 text-secondary">Code checklist</div></div>
                                            <div class="row mt-2"><div class="col">- Save time with a pre-compiled and custom list of building code sections for your plans</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="p-5 bg-light">
                    <div class="container">
                        <div class="row"> <div class="col text-center display-6">Who is Renolition for?</div></div>
                        <div class="row mt-5 gy-3">
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Architects</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Land-use lawyers</div></div>
                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Engineers</div></div>                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                        </div>
                        <div class="row gy-3 mt-1">
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Developers</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Urban planners</div></div>
                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="">
                                    <Card.Body>
                                        <div class="container">
                                            
                                            <div class="row mt-2 text-center"><div class="col h4 text-secondary">Surveyors</div></div>
                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="container problemDescription p-5">
                        <div class="row">
                            <div class="col text-center">
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <div class="display-6 mb-5">Accurate and complete plans are essential to your business’s success</div>
                                                <div>Whether you need to identify the most relevant codes for your project or automatically check your plans for 
                                                    compliance, <strong>Renolition</strong> streamlines the process, helping you save time and accelerate business growth.</div>
                                                <div class="text-center mt-5">
                                                    <a href="#top-of-page"><Button variant="primary" size="lg">Request access</Button></a>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <ContactUs 
                    displayStatus={this.state.show_contact_us_modal} 
                    changeDisplayStatus={this.setShowContactUsModal}
                />
            </div>
        )
    }
}

export default CodeLanding;