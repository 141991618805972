import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {trackEvent, EventNames} from 'utils/mixpanel';
import { titleCase } from "utils/formatters";
import VendorComponent from "./vendor_component";
import { generic_get_api  } from "api/generic_api";
import {AuthContext} from 'auth_context';
import { EntityRole } from "utils/constants";
import {  isVendor } from "utils/helpers";
import { FavoriteModals } from "./agent_favorite_vendor_list";
import { VendorModalModes } from "./agent_favorite_vendor_list";

import {Button, Spinner, Modal, ButtonGroup, OverlayTrigger, ListGroup, Popover, Card} from 'react-bootstrap';

import {
    VETTED_VENDORS_URL,
} from 'utils/constants';

class VettedVendors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search_result: null,
            current_view_vendors: [],
            searching: false,
          
            selected_vendor: null,
            show_learn_more_modal: false,
        };

        this.fetchVendors = this.fetchVendors.bind(this);
        this.setModalVisibility = this.setModalVisibility.bind(this);
        this.setSelectedVendor = this.setSelectedVendor.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);
        this.setCategory = this.setCategory.bind(this);
    }

    static contextType = AuthContext;

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    setSelectedVendor(vendor) {
        this.setState({selected_vendor: vendor});
    }

    setModalVisibility(modal, is_visible, modal_mode=null) {
        this.setState({ [modal]: is_visible, modal_mode: modal_mode });
    }

    fetchVendors() {
        this.setState({searching: true});

        generic_get_api(VETTED_VENDORS_URL, {})
        .then(
            data => {
                window.scrollTo(0, 0);
                this.setState({ 
                        search_result: data,
                        searching: false,
                }, () => this.setCategory('General Building'));
            }
        ).catch(error => {
            if (error.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
            this.setState({searching: false});
        });

    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vetted_vendors'})
        document.title = "Our exclusive list of vetted vendors who like to work with real estate agents";

        this.fetchVendors();
    }

    navigateToVendorDetail(vendor_id, role) {
        // to make back button clicks work. Maybe this can be moved into fetch search results
        this.props.history.replace(
            this.props.history.location.pathname, 
            {
                search_result: this.state.search_result,
            }
        );

        let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
        this.props.history.push(details_url);
    }
    
    setCategory(category) {
        this.state.search_result.forEach(element => {
            if (element.category === category) {
                this.setState({current_view_vendors: element.members})
            }
        }) 

    }

    render() {
        const {search_result, searching, show_other_vendor_modal, current_view_vendors} = this.state;

        const vendorComponentDisplayMode = () => {
            if (this.context.logged_in_entity_role === EntityRole.AGENT) {
                return 'search';
            }
            else if (this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) {
                return 'contact_vendors_list';
            }
        }

        return(
            <div class="vendorSearchEnvelope">
                <div class="vendorSearchPage">
                    <div class="container vendorSearchHeader">
                        <div class="row"> <div class="display-6">Trusted vendors</div></div>
                        <div class="row mt-4">
                                <div class="col">List of vendors who have attested to a high customer service for real estate agents and their clients. Select a category from a list below.&nbsp;
                                    {/* <a class="text-decoration-underline" role="button" onClick={ () => this.setState({ show_learn_more_modal: true})}>Learn more</a> */}
                                </div>
                        </div>
                        <LearnMoreModal 
                            show_learn_more_modal={this.state.show_learn_more_modal}
                            hideLearnMoreModal={this.hideLearnMoreModal}
                        />
                    </div>
                    <div class="container searchBoxWrapper mt-5">
                        <div class="row mt-6">
                            <div class="col fw-bold text-center">
                                <select name="questionCategory" class="form-select" 
                                    onChange={ (e) => this.setCategory(e.target.value)}>
                                        <option selected value="General Building">General Building</option>
                                        <option value="Roofing">Roofing</option>
                                        <option value="Plumbing">Plumbing</option>
                                        <option value="Electrical">Electrical</option>
                                        <option value="Engineer">Engineer</option>
                                    </select>
                            </div>
                        </div>
                       
                    </div>

                    <div class="container searchResultWrapper mt-4">
                        {/* <OtherVendorModal 
                            show_other_vendor_modal={show_other_vendor_modal}
                            setModalVisibility={this.setModalVisibility}
                            modal_mode={this.state.modal_mode}
                            vendor_entity_name={titleCase(this.state.search_string)}
                        /> */}

                       
                        { search_result && search_result.length > 0 &&
                            <div class="container p-0">

                                <ListGroup className="mt-4">
                                     <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                        <div class="container header">
                                            <div class="row align-items-center">
                                                <div class="col-4 text-truncate"><small>Name <br></br>Company</small></div>
                                                <div class="col-auto text-truncate reviews"><small>Reviews</small></div>
                                                <div class="col-2 gx-0 medium-container "><small>Category</small></div>
                                                <div class="col-2 gx-0 medium-container"><small>Phone</small></div>
                                                <div class="col-2 gx-0 small-container"><small>Category <br></br> Phone</small></div>
                                            </div>
                                        </div>
                                        </ListGroup.Item>
                                        {
                                            current_view_vendors.map((vendor) => { 
                                                    return <VendorComponent 
                                                                vendor={ vendor } 
                                                                display_mode='contact_vendors_list'
                                                                setModalVisibility={this.setModalVisibility}
                                                                setSelectedVendor={ this.setSelectedVendor }
                                                                navigateToVendorDetail={ this.navigateToVendorDetail }
                                                            />
                                            })
                                        }
                                </ListGroup>
                                {/* <ExistingVendorModal
                                    setModalVisibility={this.setModalVisibility}
                                    vendor={this.state.selected_vendor}
                                    show_existing_vendor_modal={ this.state.show_existing_vendor_modal}
                                    modal_mode={this.state.modal_mode}
                                    setSelectedVendor={ this.setSelectedVendor }
                                />     */}
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}


function LearnMoreModal(props) {
    const {show_learn_more_modal, hideLearnMoreModal } = props;

    if (!show_learn_more_modal) { return null; }

    return(
        <Modal
            show={show_learn_more_modal} 
            onHide={ hideLearnMoreModal }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Search &amp; research vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container lh">
                    <div class="row">
                        <div class="col fw-bold">Which vendors are in your database?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Our database includes all vendors that are licensed by the California Contractors State License Board (e.g. contractors, plumbers, electricians etc.) and those licensed
                            by the Department of Consumer Affairs (DCA) (e.g. Architects, Engineers, etc.). 

                            Overtime we will also include businesses registered in San Francisco (e.g. cleaning businesses, moving etc.).
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">How can I search for a vendor?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Search for vendors by name or license number. If you want to discover new vendors by category (e.g. a new plumber) then head to 
                            our <strong>Search by category</strong> section. 
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">What kind of information is available about vendors?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            For licensed vendors (e.g. contractors, engineers, architects), we display license, insurance, bond, and workers compensation insurance (if applicable) information and the 
                            last date that the data was verified. 
                            <br/> In addition, for vendors in construction, we pull their permit history, categorize the permits based on job type, and show on a map where the job was performed.
                            <br/> Renolition also collects verified feedback for completed, permitted jobs. We reach out directly to the homeowner once a job is completed and ask for 
                            feedback for the construction vendor. 
                        </div>
                    </div>
                  
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VettedVendors;