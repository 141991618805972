import { generic_get_api } from './generic_api';

import {
    BASIC_CONTACT_DETAILS_URL,
} from 'utils/constants';

    
export const basicContactDetails = () => {

    return generic_get_api(BASIC_CONTACT_DETAILS_URL, {})
    .then(data => data);
}