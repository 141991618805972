import React, { Component, useState } from "react";
import { ACCOUNT_URL, EntityRole, ACCOUNT_PHOTO_URL, AGENT_SETTINGS_URL } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
            updating_notification_settings: false,
            new_helpful_hints: '',
            saving_helpful_hints: false,
            helpful_hints_saved: false,

            new_disclaimer: '',
            saving_disclaimer: false,
            disclaimer_saved: false,
            
            // account update related
            contact_details_update_mode: false,
            temp_contact_details_data: {},
            contact_details_update_error: false,
            updating_contact_details: false,
        };
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
        this.fetchAccount = this.fetchAccount.bind(this);
        this.updateNotificationSettings = this.updateNotificationSettings.bind(this);
        this.fetchContactSettings = this.fetchContactSettings.bind(this);
        this.saveHelpfulHints = this.saveHelpfulHints.bind(this);
        this.saveDisclaimer = this.saveDisclaimer.bind(this);

        this.startContactDetailsUpdate = this.startContactDetailsUpdate.bind(this);
        this.updateAccountDetailsField = this.updateAccountDetailsField.bind(this);
        this.updatePhoneDisplay = this.updatePhoneDisplay.bind(this);
        this.canSaveContactDetails = this.canSaveContactDetails.bind(this);
        this.saveContactDetails = this.saveContactDetails.bind(this);
    }

    startContactDetailsUpdate() {
        this.setState({
            contact_details_update_mode: true,
            contact_details_update_error: false,
            updating_contact_details: false,
            temp_contact_details_data: {
                first_name: this.state.account.first_name,
                last_name: this.state.account.last_name,
                phone: this.state.account.phone,
                email: this.state.account.email,
                website: this.state.account.website,
                company: this.state.account.company,
            }
        }, this.updatePhoneDisplay);
    }

    canSaveContactDetails() {
        let temp_contact_details_data = this.state.temp_contact_details_data;
        
        let phone_ok = true;
        if (temp_contact_details_data.phone && !isValidPhoneNumber(temp_contact_details_data.phone)) {
            phone_ok = false;
        }

        return temp_contact_details_data.first_name && 
            temp_contact_details_data.last_name && 
            isValidEmail(temp_contact_details_data.email) &&
            phone_ok;
    }

    updateAccountDetailsField(field_name, value) {
        let new_temp_account_data = { ...this.state.temp_contact_details_data } ;
        new_temp_account_data[field_name] = value;

        this.setState({ temp_contact_details_data: new_temp_account_data}, this.updatePhoneDisplay);
    } 

    setContactUsDisplay(value) {
        this.setState({ displayContactUs: value });
    }

    fetchAccount() {
        generic_get_api(ACCOUNT_URL, {})
            .then(
                data => {
                    this.setState(
                        { 
                            account: data,
                        })
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    fetchContactSettings() {
        generic_get_api(AGENT_SETTINGS_URL)
            .then(
                data => {
                    this.setState({
                        settings: data.settings,
                        new_helpful_hints: data.settings.helpful_hints,
                        new_disclaimer: data.settings .disclaimer,
                    })
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    saveHelpfulHints() {

        this.setState({ saving_helpful_hints: true, helpful_hints_saved: false });

        generic_put_api(AGENT_SETTINGS_URL, { helpful_hints: this.state.new_helpful_hints })
            .then(
                data => {
                    this.setState({ saving_helpful_hints: false, helpful_hints_saved: true });
                    this.fetchContactSettings();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    saveDisclaimer() {

        this.setState({ 
            saving_disclaimer: true,
            disclaimer_saved: false,
         });

        generic_put_api(AGENT_SETTINGS_URL, { disclaimer: this.state.new_disclaimer })
            .then(
                data => {
                    this.setState({ 
                        saving_disclaimer: false,
                        disclaimer_saved: true,
                     });
                    this.fetchContactSettings();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    updateNotificationSettings(type, value) {

        let notification_settings = this.state.account.notification_settings;
        notification_settings[type] = value;

        this.setState({ updating_notification_settings: true });

        generic_put_api(ACCOUNT_URL, { notification_settings: notification_settings, update_type: 'notification_settings' })
            .then(
                data => {
                    this.fetchAccount();
                    this.setState({ updating_notification_settings: false });
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    saveContactDetails() {

        this.setState({ 
            updating_contact_details: true,
            contact_details_update_error: false,
        });

        generic_put_api(ACCOUNT_URL, { contact_details: this.state.temp_contact_details_data, update_type: 'contact_details' })
            .then(
                (result) => {
                    if(result.status === 204) {
                        this.fetchAccount();
                        this.setState({ updating_contact_details: false, contact_details_update_mode: false });
                    }
                    else if (result.status === 400) {
                        this.setState({ updating_contact_details: false, contact_details_update_error: true });
                    }
                }
                
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, { 'data_1': 'account' });
        document.title = "Account - Manage your current and prospective clients";
        this.fetchAccount();
        this.fetchContactSettings();
    }

    updatePhoneDisplay() {
        if (this.state.temp_contact_details_data.phone != null && this.state.temp_contact_details_data.phone.length >= 0) {
            let new_phone = this.state.temp_contact_details_data.phone.replace(/\D/g,'');
            let formatted_number = "";

            if (isValidPhoneNumber(new_phone)) {
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
            } else if (new_phone.length < 10) {
                formatted_number = new_phone;
            }
            else if (new_phone.length > 10) {
                new_phone = new_phone.substring(0, 10);
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);
            }

            let new_temp_account_data = { ...this.state.temp_contact_details_data } ;
            new_temp_account_data['phone'] = new_phone;
            new_temp_account_data['phone_display'] = formatted_number;
            this.setState({ temp_contact_details_data: new_temp_account_data });
        }
    }

    render() {
        const { account, disclaimer_saved, helpful_hints_saved, temp_contact_details_data, contact_details_update_mode } = this.state;

        const getNotificationString = () => {
            if (this.state.account.role === EntityRole.AGENT_CONTACT) {
                return "Receive a weekly summary email of important updates to your agents's vendors";
            }

            if (this.state.account.role === EntityRole.AGENT) {
                return "Receive a weekly summary email of important updates to  your vendors";
            }
        }

        const getHelpfulHints = () => {

            if (!this.state.settings) {
                return '';
            }

            if (this.state.settings.helpful_hints !== this.state.new_helpful_hints) {
                return this.state.new_helpful_hints;
            }

            return this.state.settings.helpful_hints;
        }

        const getDisclaimer = () => {

            if (!this.state.settings) {
                return '';
            }

            if (this.state.settings.disclaimer !== this.state.new_disclaimer) {
                return this.state.new_disclaimer;
            }

            return this.state.settings.disclaimer;
        }

        if (!account) {
            return null;
        }

        return (
            <div class="accountPageEnvelope">
                <div class="accountPage">

                    <div class="container accountSection">
                        <div class="row"><div class="col display-6">Account</div></div>
                        <div class="row mt-5">
                            <div class="col">
                                <Card>
                                    <Card.Title className="ps-3 pt-3 h6 fw-bold">Contact details</Card.Title>
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row gy-3">
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>First name</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!temp_contact_details_data.first_name ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.first_name} 
                                                                        onChange={(e) => this.updateAccountDetailsField('first_name', e.target.value)}/>

                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.first_name}</div></div>
                                                        }
                                                            
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Last name</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!temp_contact_details_data.last_name ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.last_name} 
                                                                        onChange={(e) => this.updateAccountDetailsField('last_name', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.last_name}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Phone number</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${temp_contact_details_data.phone && temp_contact_details_data.phone.length > 0 && !isValidPhoneNumber(temp_contact_details_data.phone) ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.phone_display} 
                                                                        onChange={(e) => this.updateAccountDetailsField('phone', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{formatPhoneNumber(account.phone)}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Email</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!isValidEmail(temp_contact_details_data.email) ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.email} 
                                                                        onChange={(e) => this.updateAccountDetailsField('email', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.email}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Brokerage</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class='form-control'
                                                                        value={temp_contact_details_data.company} 
                                                                        onChange={(e) => this.updateAccountDetailsField('company', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                            <div class="row"><div class="col text-nowrap ">{account.company}</div></div>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Website</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class='form-control'
                                                                        value={temp_contact_details_data.website} 
                                                                        onChange={(e) => this.updateAccountDetailsField('website', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                            <div class="row"><div class="col text-nowrap ">{account.website}</div></div>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 text-start">
                                                    { !this.state.contact_details_update_mode ?
                                                        <Button variant="primary" onClick={ this.startContactDetailsUpdate }>Edit</Button>
                                                    :
                                                    <>
                                                        <Button 
                                                            variant="secondary" 
                                                            className="me-3" 
                                                            disabled={this.state.updating_contact_details} 
                                                            onClick={() => this.setState({contact_details_update_mode: false, contact_details_update_error: false})}>
                                                                Cancel</Button>
                                                        {
                                                            this.state.updating_contact_details ?
                                                                <Button variant="primary" disabled>
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    Saving ...
                                                                </Button>
                                                            :
                                                                <Button variant="primary"  disabled={!this.canSaveContactDetails()} onClick={this.saveContactDetails}>Save</Button>
                                                        }
                                                       
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            {this.state.contact_details_update_error && 
                                                <div class="row mt-2">
                                                    <div class="col-12 text-start text-danger">
                                                        There was an error updating your contact details.
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        {/* <div class="row mt-4">
                            <div class="col text-center">To edit your details, <a role='button' class="text-decoration-underline" onClick={() => this.setContactUsDisplay(true)}><strong>contact us</strong></a>
                                <ContactUs
                                    displayStatus={this.state.displayContactUs}
                                    changeDisplayStatus={this.setContactUsDisplay}
                                />
                            </div>

                        </div> */}
                        {this.state.account.role === EntityRole.AGENT &&
                            <>
                                <div class="row mt-5" id="#photo">
                                    <div class="col">
                                        <PhotoComponent
                                            account={account}
                                            fetchAccount={this.fetchAccount}
                                        />
                                    </div>
                                </div>
                                <div class="row mt-5" id="#disclaimer">
                                    <div class="col">
                                        <Card>
                                            <Card.Title className="ps-3 pt-3 h6 fw-bold">Custom legal disclaimer</Card.Title>
                                            <Card.Body>
                                                <div class="container">
                                                    <div class="row gy-3">
                                                        <div class="col-12">
                                                            Add your custom legal disclaimers. Your clients will see the disclaimer when you share vendors with them.
                                                        </div>
                                                        <div class="col-12 ">
                                                            <textarea
                                                                class="form-control"
                                                                rows="6"
                                                                onChange={(e) => this.setState({ new_disclaimer: e.target.value, disclaimer_saved: false })}
                                                                value={getDisclaimer()}
                                                                placeHolder="Sample: Vendors are shared for informational purpose only. 
                                                                Users are advised to interview each vendor and
                                                                compare multiple bids. Vendors are independent 
                                                                businesses and/or contractors and agent is not 
                                                                responsible for the quality of service, work or cost."
                                                            />
                                                        </div>
                                                        {disclaimer_saved &&
                                                            <div class="col-12 text-danger mt-0">Saved</div>
                                                        }
                                                        <div class="col-auto">
                                                            {this.state.saving_disclaimer ?
                                                                <Button variant="primary" disabled>
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    Saving ...
                                                                </Button>
                                                                :
                                                                <Button variant="primary"
                                                                    onClick={this.saveDisclaimer}
                                                                    disabled={!this.state.settings || (this.state.settings.disclaimer === this.state.new_disclaimer)}>
                                                                    Save
                                                                </Button>
                                                            }
                                                        </div>
                                                        <div class="col-auto">
                                                            {
                                                                this.state.settings && this.state.settings.disclaimer !== this.state.new_disclaimer && 

                                                                <Button variant="secondary" onClick={() => this.setState({ new_disclaimer: this.state.settings.disclaimer })}>
                                                                    Cancel
                                                                </Button>
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                {/* <div class="row mt-5">
                                    <div class="col">
                                        <Card>
                                            <Card.Title className="ps-3 pt-3 h6 fw-bold">Helpful hints for your clients</Card.Title>
                                            <Card.Body>
                                                <div class="container">
                                                    <div class="row gy-3">
                                                        <div class="col-12">
                                                            Add hints and comments for your clients that have access to your vendor network. Your clients
                                                            will be able to see this information under resources in the <strong>guides section</strong>.
                                                        </div>
                                                        <div class="col-12 ">
                                                            <textarea
                                                                class="form-control"
                                                                rows="6"
                                                                onChange={(e) => this.setState({ new_helpful_hints: e.target.value, helpful_hints_saved: false })}
                                                                value={getHelpfulHints()}
                                                            />
                                                        </div>
                                                        {helpful_hints_saved &&
                                                            <div class="col-12 text-danger mt-0">Saved</div>
                                                        }
                                                        <div class="col-auto">
                                                            {this.state.saving_helpful_hints ?
                                                                <Button variant="primary" disabled>
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    Saving ...
                                                                </Button>
                                                                :
                                                                <Button variant="primary"
                                                                    onClick={this.saveHelpfulHints}
                                                                    disabled={!this.state.settings || (this.state.settings.helpful_hints === this.state.new_helpful_hints)}>
                                                                    Save
                                                                </Button>
                                                            }
                                                        </div>
                                                        <div class="col-auto">
                                                            {
                                                                this.state.settings && this.state.settings.helpful_hints !== this.state.new_helpful_hints && 
                                                                <Button variant="secondary" onClick={() => this.setState({ new_helpful_hints: this.state.settings.helpful_hints })}>
                                                                    Cancel
                                                                </Button>
                                                            }
                                                            
                                                        </div>

                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div> */}
                                <div class="row mt-5">
                                    <div class="col">
                                        <Card>
                                            <Card.Title className="ps-3 pt-3 h6 fw-bold">Information from licensing authority</Card.Title>
                                            <Card.Body>
                                                <div class="container">
                                                    <div class="row gy-3">
                                                        <div class="col-12 col-md-6">
                                                            <div class="container gx-0">
                                                                <div class="row"><div class="col text-nowrap fw-bold">Registered name</div></div>
                                                                <div class="row"><div class="col text-nowrap ">{account.licensing_info.name}</div></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="container gx-0">
                                                                <div class="row"><div class="col text-nowrap fw-bold">License number</div></div>
                                                                <div class="row"><div class="col text-nowrap ">{account.licensing_info.license_number}</div></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div class="container gx-0">
                                                                <div class="row"><div class="col text-nowrap fw-bold">License expiry Date</div></div>
                                                                <div class="row"><div class="col text-nowrap ">{account.licensing_info.license_expiry_date}</div></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="container gx-0">
                                                                <div class="row"><div class="col text-nowrap fw-bold">Address</div></div>
                                                                <div class="row"><div class="col text-nowrap ">{account.licensing_info.address},&nbsp;{account.licensing_info.city}&nbsp;{account.licensing_info.zip_code}</div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </>
                        }

                        {/* <div class="row mt-5">
                            <div class="col">
                                <Card>
                                    <Card.Title className="ps-3 pt-3 h6 fw-bold">Notification settings {this.state.updating_notification_settings && <Spinner animation="border" />} </Card.Title>
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row gy-3">
                                                <div class="col-12">
                                                    {getNotificationString()}
                                                </div>
                                                <div class="col-12 form-check ms-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        checked={account.notification_settings.credentials_changed}
                                                        value=""
                                                        id="licensingInfo"
                                                        onChange={(e) => this.updateNotificationSettings('credentials_changed', e.currentTarget.checked)}
                                                        disabled={this.state.updating_notification_settings}
                                                    />
                                                    <label class="form-check-label" for="licensingInfo">
                                                        Credentials (e.g. license) changed
                                                    </label>
                                                </div>
                                                <div class="col-12 form-check ms-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        checked={account.notification_settings.new_verified_review}
                                                        value=""
                                                        id="reviewReceived"
                                                        onChange={(e) => this.updateNotificationSettings('new_verified_review', e.currentTarget.checked)}
                                                        disabled={this.state.updating_notification_settings}
                                                    />
                                                    <label class="form-check-label" for="reviewReceived">
                                                        New verified review received
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>
        );
    }
}

function PhotoComponent(props) {
    const { account, fetchAccount } = props;
    const [showSpinner, setShowSpinner] = useState(false);
    const [show_banner_preview, setShowBannerPreview] = useState(false);

    const fileRef = React.createRef();
    const onPhotoBoxClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev) => {

        if (fileRef.current && fileRef.current.files.length > 0) {
            uploadFile();
            ev.target.value = null;
        }
    }

    const getPhoto = () => {
        if (account.profile_photo) {
            return account.profile_photo;
        }
        return user_profile_place_holder;
    }

    const hasPhoto = () => {
        if (account.profile_photo) {
            return true;
        }
        return false;
    }

    const uploadFile = () => {
        setShowSpinner(true);

        var data = new FormData();
        data.append('file', fileRef.current.files[0]);

        generic_put_api_file(ACCOUNT_PHOTO_URL, data)
            .then(
                (result) => {
                    if (result.status === 201) {
                        setShowSpinner(false);
                        fetchAccount();
                    }
                    else {
                        setShowSpinner(false);
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    const deleteFile = () => {
        setShowSpinner(true);

        generic_delete_api(ACCOUNT_PHOTO_URL, {})
            .then(
                (result) => {
                    setShowSpinner(false);
                    fetchAccount();
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    const getPhoneHref = () => { return "tel:"+ account.phone; }

    const getPhoneNumber = () => {
        if (formatPhoneNumber(account.phone).length > 0) { return formatPhoneNumber(account.phone); }
        else return <> &nbsp;</>
    }

    return (
        <>
            <Card>
                <Card.Title className="ps-3 pt-3 h6 fw-bold">Profile photo</Card.Title>
                <Card.Body>
                    <div class="container">
                        <div class="row gy-3">
                            <div class="col">
                                We recommend a square photo, <strong>300 pixel x 300 pixel</strong>. Your photo will appear as part of your branding banner when you share vendors.
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6">

                                <img src={getPhoto()} class="userProfilePhoto" />

                                <input type="file" id="actual-btn"
                                    ref={fileRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => filePickerChanged(e)}
                                />

                            </div>
                            <div class="col-6">
                                {
                                    showSpinner ?
                                        <Button variant="outline-primary">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                        </Button>

                                        :
                                        <>
                                            {
                                                account.profile_photo ?
                                                    <Button variant="secondary" size="sm" onClick={deleteFile}>Remove</Button>
                                                    :
                                                    <Button variant="primary" size="sm" onClick={onPhotoBoxClick}>Upload</Button>
                                            }
                                            <div class="mt-3">
                                                <a class="text-decoration-underline" role="button" onClick={() => setShowBannerPreview(true)}><small>Preview banner</small></a>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Modal
                show={show_banner_preview}
                onHide={() => setShowBannerPreview(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Banner Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="container agentBannerPreview">
                        <div class="row justify-content-center">
                            <Card>
                                <Card.Body>
                                    <div class="container gx-0">
                                        <div class="row d-flex justify-content-center ">
                                            {hasPhoto() &&
                                                <div class="col-auto gx-0 text-center photoContainer"><img src={account.profile_photo} class="agentPhoto" /></div>
                                            }
                                            <div class="col">
                                                <div class="container text-center">
                                                    <div class="row fs-4"><div class="col">{account.first_name} {account.last_name}</div></div>
                                                    <div class="row fw-semibold"><div class="col">{account.company}</div></div>
                                                    <div class="row"><div class="col"><small>CA DRE #: {account.licensing_info.license_number}</small></div></div>
                                                    <div class="row"><div class="col"><a role="button" class="text-decoration-none" href={"mailto:" + account.email}><small>{account.email}</small></a></div></div>
                                                    <div class="row"><div class="col fw-light">
                                                        <a className="text-decoration-none" href={getPhoneHref()}><small>{getPhoneNumber()}</small></a>
                                                    </div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Account;