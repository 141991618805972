import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Card, Button} from 'react-bootstrap';
import { basicAccountInfo } from "api/basic_account_info";


class VendorIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching_data: false,
            about_renolition_expanded: false,
        };
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
    }

    setShowContactUsModal(value) {
        this.setState({show_contact_us_modal: value});
    }

    componentDidMount() {
        document.title = "Connect with 1000s of real estate agents and their clients";

        basicAccountInfo()
        .then(
            data => {
                this.setState({ user: data });
                trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_index', 'data_2': data.first_name + " " + data.last_name});
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    render() {
        const {user} = this.state;

        return(
            <div class="vendorIndexEnvelope">
                <div class="vendorIndexPage">
                    
                { this.state.user &&
                    <>
                        <div class="container">
                            <div class="display-6 mb-5">
                                { this.state.user.first_name && <span>Hi {this.state.user.first_name}!</span>}
                            </div>    
                        </div>
                        <div class="container mt-5">
                            <div class="row gy-4 justify-content-center">
                                <div class="col-12 col-md-8 col-lg-6 indexCarWrapper">
                                    <Card bg="light">
                                        <Card.Body>
                                            <Card.Title>Update your account</Card.Title>
                                            <Card.Text className="indexCardText">
                                                <p>Keep your account, services, hours and prices up-to-date to stand out and make it easier for agents to find you.</p>
                                            </Card.Text>
                                        
                                            <div class="container mt-3">
                                                <div class="row ">
                                                    <div class="col-auto gx-1">
                                                        <Button variant="primary" onClick={ () => this.props.history.push('/vendor/account') }>My account <i class="fa-solid fa-arrow-right"/></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                            <div class="row gy-4 justify-content-center mt-5">
                                <div class="col-12 col-md-8 col-lg-6 ">
                                    <Card bg="light" >
                                        <Card.Body>
                                            <Card.Title>Requests</Card.Title>
                                            <Card.Text>
                                                <p>View pending and confirmed requests from agents.</p>
                                            </Card.Text>
                                        
                                            <div class="container mt-3">
                                                <div class="row ">
                                                    <div class="col-auto gx-1">
                                                        <Button variant="secondary" onClick={ () => this.props.history.push('/vendor/requests') }>Requests <i class="fa-solid fa-arrow-right"/></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>        
                            </div>
                            <div class="row gy-4 justify-content-center mt-5">
                                <div class="col-12 text-center">
                                    <Button variant="secondary" onClick={ () => this.props.history.push('/vendor_detail/?ID=' + user.entity_id + "&role=" + user.role) }>View your details page <i class="fa-solid fa-arrow-right"/></Button>              
                                </div>        
                            </div>
                        </div>



                        <div class="container learnMoreContainer mt-5">
                            <div class="row justify-content-center">
                                <Card>
                                    <Card.Body>
                                        <Card.Title>About Renolition</Card.Title>
                                        <Card.Text>
                                            <div class="container g-0">
                                                <div class="row gy-3 lh-lg">
                                                    <div class="col">
                                                        {
                                                            !this.state.about_renolition_expanded ?
                                                            <>
                                                                With Renolition, your business gets exposed to 1000s of local real estate professionals and their clients. <br/>
                                                                <strong>Your benefits:</strong> <br/>
                                                                <ul>
                                                                    <li>
                                                                        Grow your business with valuable referral business instead of expensive and unreliable internet advertising. 
                                                                        ...&nbsp;
                                                                        <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: true})}><small>Show more</small></a>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </>
                                                            :
                                                            <>
                                                                With Renolition, your business gets exposed to 1000s of local real estate professionals and their clients. <br/>
                                                                
                                                                <strong>Your benefits:</strong> <br/>
                                                                <ul>
                                                                    <li>
                                                                        Grow your business with valuable referral business instead of expensive and unreliable internet advertising. 
                                                                    </li>
                                                                    <li>
                                                                        Providing a good service pays off. Renolition makes it easy for agents to <i>favorite</i> vendors and share their 
                                                                        favorites with their clients and other agents. 
                                                                    </li>
                                                                    <li>
                                                                        You can rest assured that your credentials and experience show. Renolition automatically verifies and displays your license, 
                                                                        workers compensation and bond status (if applicable), analyses and categorizes 
                                                                        your permit history (if applicable), and collects verified reviews for you.
                                                                    </li>
                                                                    <li>
                                                                        Agents and their clients can quickly find your services. Specify details like availability for small/service jobs and inspections, 
                                                                        range of prices, and easy only scheduling.&nbsp;
                                                                        <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: false})}><small>Show less</small></a>
                                                                    </li>
                                                                </ul>
                                                            </>
                                                        }                                                    
                                                    </div>
                                                    <div class="row">
                                                    <div class="col text-end">
                                                        
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        <div class="container contactUsContainer mt-5">
                            <div class="row"><div class="col">We are here for you</div></div>
                            <div class="row mt-2"><div class="col"><Button variant="outline-primary" onClick={() => this.setShowContactUsModal(true)}><i class="fa-solid fa-envelope"/>&nbsp;Contact us</Button></div></div>
                            <ContactUs 
                                displayStatus={ this.state.show_contact_us_modal } 
                                changeDisplayStatus={ this.setShowContactUsModal }
                            />
                        </div>
                        
                    </>
                }
                </div>
            </div>
        )
    }
}

export default VendorIndex;