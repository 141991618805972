import { Component } from "react";
import { withRouter } from 'react-router-dom';
import { basicAccountInfo } from "api/basic_account_info";
import {AuthContext} from 'auth_context';
import { BASIC_CONTACT_DETAILS_URL } from "utils/constants";
import { generic_get_api } from "api/generic_api";

class Authentication extends Component {

    constructor(props) {
      super(props);
    }

    componentDidMount() {

        basicAccountInfo()
        .then(data=>{
            this.context.setLoggedInEntityRole(data.role);
        })
        .catch(error => {

            let pathSplit = window.location.pathname.split('/');

            if (pathSplit.length === 3 && pathSplit[1].toLowerCase() === 'contact') {
                let contact_id = pathSplit[2];
                
                generic_get_api(BASIC_CONTACT_DETAILS_URL, {contact_id: contact_id})
                .then(data => {
                    this.context.setLoggedInEntityRole(data.role);
                })
                .catch(error => {})
            }
        });
    }

    render() {
        return null;
    }
}

Authentication.contextType = AuthContext;

// this is necessary because props.history is not available when the 
// component is not loaded with Route. Check out router.js. Not sure this is still necessary
export default withRouter(Authentication);
