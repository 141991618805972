import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import toolTip_16 from 'assets/reputation/tooltip_blue_i.svg';
import {Card, Button, OverlayTrigger, Modal, Popover} from 'react-bootstrap';
import { basicAccountInfo } from "api/basic_account_info";
import { generic_get_api } from "api/generic_api";
import { 
    AGENT_CONTACT_FEEDBACK_URL, 
    AGENT_FRIEND_REQUEST_URL, 
    FIRST_RUN_URL,
} from "utils/constants";

class AgentIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_contact_feedback_modal: false,
            contact_vendor_feedback: [],
            pending_invites: [],
            show_contact_us_modal: false,
            show_first_run_card: true,
            about_renolition_expanded: false,
        };

        this.fetchContactFeedbackSummary = this.fetchContactFeedbackSummary.bind(this);
        this.hideContactFeedbackModal = this.hideContactFeedbackModal.bind(this);
        this.fetchPendingInvites = this.fetchPendingInvites.bind(this);
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
        this.fetchFirstRunOptions = this.fetchFirstRunOptions.bind(this);
        this.dismissFirstRunCard = this.dismissFirstRunCard.bind(this);
    }

    setShowContactUsModal(value) {
        this.setState({show_contact_us_modal: value});
    }

    hideContactFeedbackModal() {
        this.setState({show_contact_feedback_modal: false});
    }

    dismissFirstRunCard() {
        localStorage.setItem('show_first_run_card', 'false');

        this.setState({ show_first_run_card: false});
    }

    componentDidMount() {
        document.title = "Welcome to your portal!";

        let show_first_run_card = localStorage.getItem('show_first_run_card');

        if (show_first_run_card && show_first_run_card.toLocaleLowerCase() === 'false'){
            this.setState({show_first_run_card: false});
        } 

        basicAccountInfo()
        .then(
            data => {
                this.setState({ user: data });

                trackEvent(EventNames.PAGE_LOAD, {'data_1': 'agent_index', 'data_2': data.first_name + " " + data.last_name});

                this.fetchContactFeedbackSummary();
                this.fetchPendingInvites();
                this.fetchFirstRunOptions();
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    // duplicated in agent_friends
    fetchPendingInvites() {
        generic_get_api(AGENT_FRIEND_REQUEST_URL, {})
        .then(
            data => {
                if (data){
                    this.setState({
                        pending_invites: data,
                    });
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchContactFeedbackSummary() {
        generic_get_api(AGENT_CONTACT_FEEDBACK_URL, {})
        .then(
            (result) => {
                this.setState( {
                    contact_vendor_feedback: result,
                })
            }
        )
        .catch(
            (error) => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } 
            }
        )
    }   

    fetchFirstRunOptions() {
        generic_get_api(FIRST_RUN_URL, {})
        .then(
            (result) => {
                this.setState( {
                    profile_photo_outstanding: result.profile_photo_outstanding,
                    disclaimer_outstanding: result.disclaimer_outstanding,
                })
            }
        )
        .catch(
            (error) => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } 
            }
        )
    }

    render() {
        return(
            <div class="agentIndexEnvelope">
                <div class="agentIndexPage">
                    
                    { this.state.user &&
                        <>
                            <div class="container">
                                <div class="display-6 mb-5">
                                    { this.state.user.first_name && <span>Hi {this.state.user.first_name}!</span>}
                                </div>    
                            </div>

                            <div class="container learnMoreContainer mt-5">
                                <div class="row justify-content-center">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Renolition is for real estate agents</Card.Title>
                                            <Card.Text>
                                                <div class="container g-0">
                                                    <div class="row gy-3 lh-lg">
                                                        <div class="col">
                                                            {
                                                                !this.state.about_renolition_expanded ?
                                                                <>
                                                                    With Renolition, real estate professionals can organize, share, and discover vendors. <br/>
                                                                    
                                                                    <ul>
                                                                        <li>
                                                                            Input your existing vendors; add tags and notes for easier organization &amp; searching. Or search within our expansive
                                                                            database of vendors...&nbsp;
                                                                            <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: true})}><small>Read more</small></a>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </>
                                                                :
                                                                <>
                                                                    With Renolition, real estate professionals can organize, share, and discover vendors. <br/>
                                                            
                                                                    <ul>
                                                                        <li>
                                                                            Input your existing vendors; add tags and notes for easier organization &amp; searching. Or search within our expansive 
                                                                            database of vendors and detailed data about them to find the right professional that fits your requirements. <br/>
                                                                        </li>
                                                                        <li>
                                                                            Quickly share vendors with your clients, from your phone or desktop. Each time you share vendors, you further reinforce
                                                                            your brand and are more likely to receive recognition for your effort.
                                                                        </li>
                                                                        
                                                                        <li>
                                                                            Renolition is more fun when you build a network with other agents! Invite and connect with other agent, rely on each other's vendors, and help each others' 
                                                                            business grow. You can always <i>hide</i> your must valuable vendors.&nbsp;
                                                                            <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: false})}><small>Show less</small></a>
                                                                        </li>
                                                                    </ul>
                                                                </>
                                                            }                                                    
                                                        </div>
                                                        <div class="row">
                                                        <div class="col text-end">
                                                            
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                            <div class="container mt-5 getStartedContainer">
                                <div class="row gy-4 justify-content-center">
                                    <Card bg="light">
                                        <Card.Body>
                                            <div class="container">
                                                <div class="row"><div class="col fs-4 text-center">
                                                    <Button variant="outline-secondary" disabled><i class="fa-solid fa-1"></i></Button>
                                                </div></div>
                                                <div class="row mt-3"><div class="col fs-4 text-center">Build &amp; manage your list of vendors</div></div>
                                                <div class="row mt-4 mb-3">
                                                    <div class="col text-center">
                                                        <Button variant="primary" onClick={ () => this.props.history.push('/agent/favorite_vendors') }>Get started <i class="fa-solid fa-arrow-right"/></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div class="row gy-4 justify-content-center mt-4">
                                    <Card bg="light">
                                        <Card.Body>
                                            <div class="container">
                                                <div class="row"><div class="col fs-4 text-center">
                                                    <Button variant="outline-secondary" disabled><i class="fa-solid fa-2"></i></Button>
                                                </div></div>
                                                <div class="row mt-3"><div class="col fs-4 text-center">Share vendors with clients &amp; build your brand</div></div>
                                                <div class="row mt-4 mb-3">
                                                    <div class="col text-center">
                                                        <Button variant="primary" onClick={ () => this.props.history.push('/agent/contacts') }>Get started <i class="fa-solid fa-arrow-right"/></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div class="row gy-4 justify-content-center mt-4">
                                    <Card bg="light">
                                        <Card.Body>
                                            <div class="container">
                                                <div class="row"><div class="col fs-4 text-center">
                                                    <Button variant="outline-secondary" disabled><i class="fa-solid fa-3"></i></Button>
                                                </div></div>
                                                <div class="row mt-3"><div class="col fs-4 text-center">Connect with trusted agents</div></div>
                                                <div class="row mt-4 mb-3">
                                                    <div class="col text-center">
                                                        <Button variant="primary" onClick={ () => this.props.history.push('/agent/agent_friends') }>Get started <i class="fa-solid fa-arrow-right"/></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                    {/* <div class="col-12 col-md-4 indexCardWrapper">
                                        <Card  bg="light" className="indexCard">
                                            <Card.Body>
                                                <Card.Title>Contacts &amp; Clients</Card.Title>
                                                <Card.Text className="indexCardText">
                                                    <p>Organize your contacts, share vendors and resources; highlight your brand</p>
                                                </Card.Text>
                                           
                                                <div class="container mt-3">
                                                    <div class="row ">
                                                        <div class="col-auto gx-1">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/agent/contacts') }>Get started <i class="fa-solid fa-arrow-right"/></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div class="col-12 col-md-4 indexCardWrapper">
                                        <Card  bg="light" className="indexCard">
                                            <Card.Body>
                                                <Card.Title>My agent network</Card.Title>
                                                <Card.Text className="indexCardText">
                                                    <p>Connect with other agents &amp; discover even more vendors</p>
                                                </Card.Text>
                                            
                                                <div class="container mt-3">
                                                    <div class="row ">
                                                        <div class="col-auto gx-1">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/agent/agent_friends') }>Get started <i class="fa-solid fa-arrow-right"/></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div> */}
                               
                            </div>
                            { (this.state.profile_photo_outstanding || this.state.disclaimer_outstanding) && this.state.show_first_run_card && 
                                <div class="container toDoList mt-5">
                                    <div class="row justify-content-center">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>
                                                    <div class="row">
                                                        <div class="col-auto">Your To-Do list</div>
                                                        <div class="col text-end"><a role="button" onClick={ this.dismissFirstRunCard }><i class="fa-solid fa-xmark"/></a></div>
                                                    </div>
                                                </Card.Title>
                                                <Card.Text>
                                                    <div class="container g-0">
                                                        { this.state.profile_photo_outstanding &&
                                                            <div class="row gy-3">
                                                                <div class="col">
                                                                <i class="fa-regular fa-square fa-xs"></i>&nbsp;&nbsp;Upload a profile photo&nbsp;&nbsp;<a class="text-decoration-underline" role="button" href="/account##photo">start</a>
                                                                </div>
                                                            </div>
                                                        }
                                                        { this.state.disclaimer_outstanding &&
                                                            <div class="row gy-3">
                                                                <div class="col">
                                                                <i class="fa-regular fa-square fa-xs"></i>&nbsp;&nbsp;Set custom disclaimer&nbsp;&nbsp;<a class="text-decoration-underline" role="button" href="/account##disclaimer">start</a>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            }

                            {/* <div class="container statsContainer mt-5">
                                <div class="row justify-content-center">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Recent updates</Card.Title>
                                            <Card.Text>
                                                <div class="container g-0">
                                                    <div class="row gy-3">
                                                        <div class="col-12 col-md-6">
                                                            <div class="row updatesRow">
                                                                <div class="col-auto">
                                                                    <a role="button" class="text-decoration-underline" 
                                                                            onClick={() => this.props.history.push({pathname: '/agent/agent_friends', state: {show_pending_invites_modal: true}})}>Pending agent network invites
                                                                    </a>
                                                                </div>
                                                                <div class="col-1 gx-0">
                                                                    <OverlayTrigger key="pendingFriendRequests" placement="auto"
                                                                        overlay={ 
                                                                            <Popover id="pendingFriendRequests">
                                                                                <Popover.Header as="h3">Pending invitations</Popover.Header>
                                                                                <Popover.Body>
                                                                                    Agents who have invited you to join their network, and are waiting for your response.
                                                                                </Popover.Body>
                                                                            </Popover> 
                                                                        }>
                                                                            <img src={toolTip_16}class="tooltipImage mb-1"/>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div class="col text-end">{this.state.pending_invites.length}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-md-6">
                                                            <div class="row updatesRow">
                                                                <div class="col-auto">
                                                                    <a role="button" class="text-decoration-underline" onClick={() => this.setState({show_contact_feedback_modal: true})}>Client feedback</a>
                                                                </div>
                                                                <div class="col-1 gx-0">
                                                                    <OverlayTrigger key="contactsFeedback" placement="auto"
                                                                        overlay={ 
                                                                            <Popover id="pendingFriendRequests">
                                                                                <Popover.Header as="h3">Vendor feedback from clients</Popover.Header>
                                                                                <Popover.Body>
                                                                                    Private feedback that your clients have sent you about any of your vendors.
                                                                                </Popover.Body>
                                                                            </Popover>  
                                                                        }>
                                                                            <img src={toolTip_16}class="tooltipImage mb-1"/>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <ContactFeedbackModal 
                                                                    show_contact_feedback_modal={ this.state.show_contact_feedback_modal }
                                                                    hideContactFeedbackModal={ this.hideContactFeedbackModal }
                                                                    contact_vendor_feedback={this.state.contact_vendor_feedback}
                                                                />
                                                                <div class="col col-md-1 text-end">{this.state.contact_vendor_feedback.length}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div> */}
                            

                            <div class="container contactUsContainer mt-5">
                                <div class="row"><div class="col">We are here for you</div></div>
                                <div class="row mt-2"><div class="col"><Button variant="outline-primary" onClick={() => this.setShowContactUsModal(true)}><i class="fa-solid fa-envelope"/>&nbsp;Contact us</Button></div></div>
                                <ContactUs 
                                    displayStatus={ this.state.show_contact_us_modal } 
                                    changeDisplayStatus={ this.setShowContactUsModal }
                                />
                            </div>
                            
                        </>
                    }
                </div>
                
            </div>
        )
    }
}

function ContactFeedbackModal(props) {
    const {hideContactFeedbackModal, show_contact_feedback_modal, contact_vendor_feedback} = props;
   
    if (!show_contact_feedback_modal) { return null; }

    return(
        <Modal
            show={show_contact_feedback_modal}
            onHide={hideContactFeedbackModal}
            size="lg"
            centered
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title>Recently received contact feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    { contact_vendor_feedback && contact_vendor_feedback.length === 0 &&
                        <div class="row">
                            <div class="col fw-bold">No feedback to display</div>
                        </div>

                    }
                    { contact_vendor_feedback && contact_vendor_feedback.length > 0 && contact_vendor_feedback.map(
                        (feedback) => {
                                return <>
                                            <div class="row">
                                                <div class="col-8 col-sm-9 text-start text-primary fw-bold"><small>{feedback.contact_name}</small></div>
                                                <div class="col-4 col-sm-3 text-end"><small>{feedback.created_at}</small></div>
                                            </div>
                                            <div class="row mt-2 mb-3">
                                                <div class="col-12 fw-bold"><small>{feedback.vendor_name}</small></div>
                                                <div class="col-12 mt-1"><small>{feedback.feedback}</small></div>
                                                <div class="col-12"><hr></hr></div>
                                            </div>
                                        </>
                        }
                    )}
                </div>
            </Modal.Body>
            
        </Modal>
    )
    
}

export default AgentIndex;