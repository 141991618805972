import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
// import $ from 'jquery';  // thought may need for bootstrap
// import Popper from 'popper.js';  // thought may need for bootstrap
// import "bootstrap/dist/js/bootstrap.bundle.min.js";  // needed for some bootstrap components
import './index.css';
import MediaQuery from 'react-responsive'
import RenolitionApp from './router';


const Mobile = React.lazy(() => import('styles/main/mobile'));
const Desktop = React.lazy(() => import('styles/main/desktop'));

const ThemeSelector = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<></>}>
          <MediaQuery minWidth={1000}>
            <Desktop />
          </MediaQuery>
          
          <MediaQuery maxWidth={1000}>
            <Mobile />
          </MediaQuery>
      </React.Suspense>
      {children}
    </>
  )
}

ReactDOM.render(
  <ThemeSelector>
    <RenolitionApp />
  </ThemeSelector>,
  document.getElementById('root')
);