import React, { Component } from "react";

import {trackEvent, EventNames} from 'utils/mixpanel';
import { formatPhoneNumber } from "utils/formatters";
import { basicContactDetails } from "api/basic_contact_details";
import {Card, Button, Spinner} from 'react-bootstrap';
import { AGENT_SETTINGS_URL } from "utils/constants";
import { generic_get_api } from "api/generic_api";


class ContactIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching_data: false,
            about_renolition_expanded: false,
        };
        this.fetchContactDetails = this.fetchContactDetails.bind(this);
        this.fetchContactSettings = this.fetchContactSettings.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'contact_index'});
        document.title = "Reputable vendors from your real estate professional";
        this.fetchContactDetails();
        this.fetchContactSettings();
    }

    fetchContactSettings()
    {
        generic_get_api(AGENT_SETTINGS_URL)
        .then(
            data => {
                this.setState({ 
                    disclaimer: data.settings.disclaimer,
                })
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchContactDetails() {

        this.setState({fetching_data: true});

        basicContactDetails()
        .then(data => {
                if (data){
                    this.setState({
                        contact_details: data,
                        fetching_data: false,
                    });
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } else {
                this.setState({fetching_data: false});
            }
        });
    }

    render() {
        const {contact_details, fetching_data} = this.state;

        const hasPhoto = () => {
            if (contact_details.agent_profile_photo_url) {
                return true;
            }
            return false;
        }

        const getPhoneHref = () => { return "tel:"+ contact_details.agent_phone; }

        const getPhoneNumber = () => {
            if (formatPhoneNumber(contact_details.agent_phone).length > 0) { return formatPhoneNumber(contact_details.agent_phone); }
            else return <> &nbsp;</>
        }

        return(
            <div class="clientIndexEnvelope">
                <div class="clientIndexPage">
                    
                    { fetching_data &&
                        <div class="text-center">
                            <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
                        </div>
                    }

                    { !fetching_data && contact_details &&
                        <>
                            { contact_details.agent_first_name && contact_details.agent_last_name && contact_details.active_contact &&
                                <div class="container agentBanner">
                                    <div class="row justify-content-center">
                                        <Card>
                                            <Card.Body>
                                                <div class="container gx-0">
                                                    <div class="row d-flex justify-content-center ">
                                                        {
                                                            hasPhoto() &&
                                                                <div class="col-auto gx-0 text-center photoContainer"><img src={contact_details.agent_profile_photo_url} class="agentPhoto"/></div>
                                                        }
                                                        
                                                        <div class="col">
                                                            <div class="container text-center">
                                                                <div class="row fs-4"><div class="col">{contact_details.agent_first_name} {contact_details.agent_last_name}</div></div>
                                                                <div class="row fw-semibold"><div class="col">{contact_details.agent_company}</div></div>
                                                                <div class="row"><div class="col"><small>CA DRE #: {contact_details.agent_license}</small></div></div>
                                                                <div class="row"><div class="col"><a role="button" class="text-decoration-none" href={"mailto:" + contact_details.agent_email}><small>{contact_details.agent_email}</small></a></div></div>
                                                                <div class="row"><div class="col fw-light"><a className="text-decoration-none" href={getPhoneHref()}><small>{getPhoneNumber()}</small></a></div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            }
                         <div class="container mt-5 contactIndexWrapper">
                                <div class="row">
                                    <div class="col text-center display-6">Hi {contact_details.contact_first_name}! Your agent has shared resources with you</div>
                                </div>
                                <div class="row gy-4 justify-content-center mt-4">
                                    <div class="col-12 col-md-4 indexCardWrapper">
                                        <Card bg="light" className="indexCard">
                                            <Card.Body>
                                                <Card.Title>Suggested vendors</Card.Title>
                                                <Card.Text className="cardHeight">
                                                    <p>View and explore vendors that your agent suggests </p>
                                                </Card.Text>
                                                <div class="container mt-5">
                                                    <div class="row ">
                                                        <div class="col-auto gx-1">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/contact/agent_vendors') }>View vendors</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div class="col-12 col-md-4 indexCardWrapper">
                                        <Card  bg="light" className="indexCard">
                                            <Card.Body>
                                                <Card.Title>Vendor search</Card.Title>
                                                <Card.Text className="cardHeight">
                                                    <p>Search and discover new vendors that fit your job</p>
                                                </Card.Text>
                                           
                                                <div class="container mt-5">
                                                    <div class="row ">
                                                        <div class="col-auto gx-1">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/contact/vendor_discover') }>By category</Button>
                                                        </div>
                                                        <div class="col-auto gx-1 ms-2">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/contact/vendor_search') }>By name</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div class="col-12 col-md-4 indexCardWrapper">
                                        <Card  bg="light" className="indexCard">
                                            <Card.Body>
                                                <Card.Title>Property inspector</Card.Title>
                                                <Card.Text className="cardHeight">
                                                    <p>View any property's permit history.</p>
                                                </Card.Text>
                                            
                                                <div class="container mt-5">
                                                    <div class="row ">
                                                        <div class="col-auto gx-1">
                                                            <Button variant="primary" onClick={ () => this.props.history.push('/property_inspector') }>Inspect property</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <div class="row justify-content-center mt-5">
                                    <Card className="aboutRenolition">
                                        <Card.Body>
                                            <Card.Title>About Renolition</Card.Title>
                                            <Card.Text>
                                                <div class="container g-0">
                                                    <div class="row gy-3 lh-lg">
                                                        <div class="col">
                                                            {
                                                                !this.state.about_renolition_expanded ?
                                                                <>
                                                                    Real estate professionals use <strong>Renolition</strong> to share vendors and other home-related resources with their clients. Now that you have access you can<br/>
                                                                    
                                                                    <ul>
                                                                        <li>
                                                                            Go through vendors that your agent has shared with you, review their work history and reputation, read their verified ...&nbsp;
                                                                            <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: true})}><small>Show more</small></a>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </>
                                                                :
                                                                <>
                                                                    Real estate professionals use <strong>Renolition</strong> to share vendors and other home-related resources with their clients. Now that you have access you can<br/>
                                                            
                                                                    <ul>
                                                                        <li>
                                                                            Browse through vendors that your agent has shared with you, review their work history and reputation, read their verified reviews &amp; more.
                                                                        </li>
                                                                        <li>
                                                                            Renolition offers you all the resources to research and hire your own vendors. Search by name, license number, or category to find skilled 
                                                                            contractors, plumber, engineers, architects, electricians and much more. Use our detailed data about each vendor to decide who fits your job best.
                                                                        </li>
                                                                        <li>
                                                                            <i>Property Inspector</i> allows you to pull the permit history of any property in San Francisco. Investigate any work that has been performed, and 
                                                                            find out who (contractors, engineers, architects) has worked on a property in the past. 
                                                                            &nbsp;
                                                                            <a role="button" class="text-decoration-underline" onClick={() => this.setState({about_renolition_expanded: false})}><small>Show less</small></a>
                                                                        </li>
                                                                    </ul>
                                                                </>
                                                            }                                                    
                                                        </div>
                                                        <div class="row">
                                                        <div class="col text-end">
                                                            
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div class="row mt-5"></div>
                                <div class="row mt-5"></div>
                                <div class="row mt-5"></div>
                                <div class="row mt-5"></div>
                                <div class="row mt-5">
                                    <div class="col fs-6 fw-light text-center">
                                        <span dangerouslySetInnerHTML={{__html: this.state.disclaimer}} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default ContactIndex;