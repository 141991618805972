import four_stars from 'assets/reputation/four_stars.svg';
import three_stars from 'assets/reputation/three_stars.svg';
import two_stars from 'assets/reputation/two_stars.svg';
import one_stars from 'assets/reputation/one_stars.svg';
import five_stars from 'assets/reputation/five_stars.svg';
import zero_stars from 'assets/reputation/zero_stars.svg';

export const formatPhoneNumber = (phoneNumber) => {

    if (!phoneNumber) { return ""; }

    if (phoneNumber.length !== 10) {
        return phoneNumber
    }

    return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, 11);
}

export const getStringForReviews = (numReviews) => {

    if (numReviews === 1) {
        return '1 verified review from homeowner';
    }

    return numReviews + ' verified reviews from homeowners' 
}

export const get_star_icon = (rating) => {
    switch(rating) {
        case 0:
          return zero_stars;
        case 1:
            return one_stars;
        case 2:
            return two_stars;
        case 3:
            return three_stars;
        case 4:
            return four_stars;
        case 5:
            return five_stars;
        default:
            return zero_stars;
      }
}

export const titleCase = (str) => {

    if ((str === null) || (str === ''))
        return '';

    let splits = str.toLowerCase().split(' ');
    let out = [];

    for (let i = 0; i < splits.length; i++) {
        if (splits[i].length > 0) {
            out.push(splits[i].charAt(0).toUpperCase() + splits[i].slice(1));
        }
    }

    return out.join(' ');
}
