import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {trackEvent, EventNames} from 'utils/mixpanel';
import { formatPhoneNumber, titleCase } from "utils/formatters";
import VendorComponent from "./vendor_component";
import { generic_post_api, generic_get_api, generic_put_api } from "api/generic_api";
import {AuthContext} from 'auth_context';
import { EntityRole } from "utils/constants";
import toolTip_16 from 'assets/reputation/tooltip_blue_i.svg';
import { isValidPhoneNumber, isValidEmail, isVendor } from "utils/helpers";
import { FavoriteModals } from "./agent_favorite_vendor_list";
import { VendorModalModes } from "./agent_favorite_vendor_list";

import {Button, Spinner, Modal, ToggleButton, ButtonGroup, OverlayTrigger, ListGroup, Popover, Card} from 'react-bootstrap';

import {
    AUTO_COMPLETE_URL,
    US_STATES,
    VENDOR_CATEGORIES,
    VENDOR_SEARCH_URL,
    VENDOR_ADD_URL,
    AGENT_FAVORITE_VENDOR_URL,
} from 'utils/constants';

class VendorSearch extends Component {
    constructor(props) {
        super(props);

        let search_result = null;

        // to make back button work and search results re-instated
        if (props.location.state && props.location.state.search_result) {
            search_result = props.location.state.search_result;
        }

        this.state = {
            search_result: search_result,
            searching: false,
            search_string: null,
            show_other_vendor_modal: false,
            show_existing_vendor_modal: false,
            selected_vendor: null,
            show_learn_more_modal: false,
        };

        this.submitSearch = this.submitSearch.bind(this);
        this.setModalVisibility = this.setModalVisibility.bind(this);
        this.setSelectedVendor = this.setSelectedVendor.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);
    }

    static contextType = AuthContext;

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    setSelectedVendor(vendor) {
        this.setState({selected_vendor: vendor});
    }

    setModalVisibility(modal, is_visible, modal_mode=null) {
        this.setState({ [modal]: is_visible, modal_mode: modal_mode });
    }

    submitSearch(search_string) {

        let search_term = null;

        // we are having a new search initiated by user
        if (search_string) {

            // store search_string in state in case we need to redo this search automatically
            this.setState({
                searching: true,
                search_string: search_string,
            });

            search_term = search_string;
        }
        // we are re-doing a search after closing the add to favorites dialog to refresh the list
        else if (this.state.search_string){
            this.setState({
                searching: true,
            });
            search_term = this.state.search_string;
        }
        // this case should not be happening
        else {
            return;
        }

        trackEvent(EventNames.SEARCH_START, { data_1: search_term} );

        var request = { 
            search_string: search_term,
        }

        generic_get_api(VENDOR_SEARCH_URL, request)
        .then(
            data => {
                window.scrollTo(0, 0);
                this.setState({ 
                        search_result: data,
                        searching: false,
                });
            }
        ).catch(error => {
            if (error.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
            this.setState({searching: false});
        });

    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_search'})
        document.title = "Search for vendors by name or license number";
    }

    navigateToVendorDetail(vendor_id, role) {
        // to make back button clicks work. Maybe this can be moved into fetch search results
        this.props.history.replace(
            this.props.history.location.pathname, 
            {
                search_result: this.state.search_result,
            }
        );

        let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
        this.props.history.push(details_url);
    }
    
    render() {
        const {search_result, searching, show_other_vendor_modal} = this.state;

        const vendorComponentDisplayMode = () => {
            if (this.context.logged_in_entity_role === EntityRole.AGENT) {
                return 'search';
            }
            else if (this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) {
                return 'contact_vendors_list';
            }
        }

        return(
            <div class="vendorSearchEnvelope">
                <div class="vendorSearchPage">
                    <div class="container vendorSearchHeader">
                        <div class="row"> <div class="display-6">Lookup vendors</div></div>
                        <div class="row mt-4">
                                <div class="col">Search for vendors by name or license number &amp; add vendors to your favorites.&nbsp;
                                    <a class="text-decoration-underline" role="button" onClick={ () => this.setState({ show_learn_more_modal: true})}>Learn more</a>
                                </div>
                        </div>
                        <LearnMoreModal 
                            show_learn_more_modal={this.state.show_learn_more_modal}
                            hideLearnMoreModal={this.hideLearnMoreModal}
                        />
                    </div>
                    <div class="container searchBoxWrapper mt-5">
                        <div class="row mt-6">
                            <div class="col fw-bold text-center">
                                Start with the vendor name or license number
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <VendorSearchBox 
                                    submitSearch={ this.submitSearch }
                                    searching={ searching }
                                />
                            </div>
                        </div>
                    </div>
                    <div class="container searchResultWrapper mt-4">
                        <OtherVendorModal 
                            show_other_vendor_modal={show_other_vendor_modal}
                            setModalVisibility={this.setModalVisibility}
                            modal_mode={this.state.modal_mode}
                            vendor_entity_name={titleCase(this.state.search_string)}
                        />

                        { (search_result && search_result.length === 0) &&
                            <>
                                {this.context.logged_in_entity_role === EntityRole.AGENT ? 
                                    <div class="container text-center" style={{'width': "365px"}}>
                                         <Card  bg="light" >
                                                <Card.Body>
                                                    <Card.Text className="indexCardText">
                                                        <div class="row"><div class="col">Vendor is not in our list yet.</div></div>
                                                    </Card.Text>
                                                    <div class="container mt-3">
                                                        <div class="row justify-content-center">
                                                            <div class="col-auto gx-1">
                                                                <Button variant="primary" onClick={()=>this.setModalVisibility(FavoriteModals.show_other_vendor_modal, true, VendorModalModes.NEW_VENDOR) }><i class="fa-solid fa-plus"></i> Add vendor details</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                    </div>
                                :
                                    <div>We found no results. Try again! </div>
                                }
                            </>
                        }
                        { search_result && search_result.length > 0 &&
                            <div class="container p-0">
                                {this.context.logged_in_entity_role === EntityRole.AGENT &&
                                    <div class="text-center">
                                        Choose a vendor from the list or <a role="button" class="text-decoration-underline text-nowrap" onClick={()=>this.setModalVisibility(FavoriteModals.show_other_vendor_modal, true, VendorModalModes.NEW_VENDOR)}><strong>add a new vendor</strong>.</a> 
                                    </div>
                                }
                                <ListGroup className="mt-4">
                                     <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                        <div class="container header">
                                            <div class="row align-items-center">
                                                <div class="col-4 text-truncate"><small>Name <br></br>Company</small></div>
                                                <div class="col-auto text-truncate reviews"><small>Reviews</small></div>
                                                <div class="col-2 gx-0 medium-container "><small>Category</small></div>
                                                <div class="col-2 gx-0 medium-container"><small>Phone</small></div>
                                                <div class="col-2 gx-0 small-container"><small>Category <br></br> Phone</small></div>
                                            </div>
                                        </div>
                                        </ListGroup.Item>
                                        {
                                            search_result.map((vendor) => { 
                                                    return <VendorComponent 
                                                                vendor={ vendor } 
                                                                display_mode={vendorComponentDisplayMode()}
                                                                setModalVisibility={this.setModalVisibility}
                                                                setSelectedVendor={ this.setSelectedVendor }
                                                                navigateToVendorDetail={ this.navigateToVendorDetail }
                                                            />
                                            })
                                        }
                                </ListGroup>
                                <ExistingVendorModal
                                    setModalVisibility={this.setModalVisibility}
                                    vendor={this.state.selected_vendor}
                                    show_existing_vendor_modal={ this.state.show_existing_vendor_modal}
                                    modal_mode={this.state.modal_mode}
                                    setSelectedVendor={ this.setSelectedVendor }
                                />    
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}


export function ExistingVendorModal(props) {

    const {show_existing_vendor_modal, modal_mode, setModalVisibility, vendor, setSelectedVendor, refreshParent } = props;
    
    const [phone, setPhone] = useState('');
    const [phone_display, setPhoneDisplay] = useState('');
    const [hide_from_friends, setHideFromFriends] = useState(false);
    const [note, setPrivateNote] = useState('');
    const [tags, setTags] = useState([]);
    const [vendor_has_account, setVendorHasAccount] = useState(false);
    const [vendor_data, setVendorData] = useState(null);
    
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [contact_name, setContactName] = useState('');
    const [category, setCategory] = useState('');
    const [other_category, setOtherCategory] = useState(null);
    const [category_2, setCategory_2] = useState(null);
    const [other_category_2, setOtherCategory2] = useState(null);
    const [show_additional_category, setShowAdditionalCategory] = useState(false);

    const [current_tag, setCurrentTag] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const [fetching, setFetching] = useState(false);

    let history = useHistory();

    const addUpdate = () => {
        
        setSubmitting(true);
        
        // in case user forgets to add the tag before adding favorite, and there is a tag in the text box.
        if (current_tag) {
            addTag();
        }

        let effective_category = category;

        if (category === VENDOR_CATEGORIES.OTHER) {
            effective_category = other_category;
        }

        let effective_category_2 = category_2;

        if (category_2 === VENDOR_CATEGORIES.OTHER) {
            effective_category_2 = other_category_2;
        }

        var request = { 
            vendor_id: vendor.ID,
            agent_set_phone: phone,
            role: vendor.role,
            hide_from_friends: hide_from_friends,
            tags: tags,
            note: note,
            agent_set_email: email,
            website: website,
            category: effective_category,
            category_2: effective_category_2,
            agent_set_contact_name: contact_name,
            is_import: false,
        }

        if (modal_mode === VendorModalModes.ADD_TO_FAVORITES) {

            generic_post_api(request, AGENT_FAVORITE_VENDOR_URL)
            .then(
                (result) => {
                    if (result.status === 201) {
                        setSubmitting(false);
                        resetFields();
                        setModalVisibility(FavoriteModals.show_existing_vendor_modal, false);
                        history.push('/agent/favorite_vendors');
                      
                        return;
                    }
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    history.push('/login');
                }
            });
        }
        else if (modal_mode === VendorModalModes.UPDATE_VENDOR) {
            generic_put_api(AGENT_FAVORITE_VENDOR_URL, request)
            .then(
                (result) => {
                    if (result.status === 200) {
                        setSubmitting(false);
                        resetFields();
                        // in this case, we will be in the favorites vendor list
                        refreshParent();
                        setSelectedVendor(null);
                        setModalVisibility(FavoriteModals.show_existing_vendor_modal, false);
                        return;
                    }
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    history.push('/login');
                }
            });
        }
    }

    useEffect(() => { 

        // avoid setting phone to undefined through the network call that fetches the vendor's details
        if (phone != null && phone.length >= 0) {
            let new_phone = phone.replace(/\D/g,'');

            if (isValidPhoneNumber(new_phone)) {
                setPhone(new_phone);
                let formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
                setPhoneDisplay(formatted_number);
            } else if (new_phone.length < 10) {
                setPhone(new_phone);
                setPhoneDisplay(new_phone);
            } else if (new_phone.length > 10) {

                let trimmed_phone = new_phone.substring(0, 10);
                setPhone(trimmed_phone);
                let formatted_number = "(" + trimmed_phone.substring(0,3) + ") " + trimmed_phone.substring(3,6) + "-" + trimmed_phone.substring(6,10);  
                setPhoneDisplay(formatted_number);
            } 
        }
    }, [phone]);

    useEffect(() => { 
        
        if(vendor && (modal_mode === VendorModalModes.UPDATE_VENDOR || modal_mode === VendorModalModes.ADD_TO_FAVORITES) && show_existing_vendor_modal) {
            setFetching(true);
            
            let include_favorite_details = false;

            if (modal_mode === VendorModalModes.UPDATE_VENDOR){
                include_favorite_details = true;
            }

            let request = {
                vendor_id: vendor.ID,
                role: vendor.role,
                include_favorite_details: include_favorite_details,
            }

            generic_get_api(AGENT_FAVORITE_VENDOR_URL, request)
                .then(
                    (result) => {
                        setFetching(false);
                        setContactName(result.agent_set_contact_name);
                        setPhone(result.agent_set_phone);
                        setEmail(result.agent_set_email);
                        setWebsite(result.website);
                        setVendorHasAccount(result.vendor_has_account);
                        setVendorData(result);
                        
                        // do not set these fields in ADD_TO_FAVORITES mode because we will overwrite default values with null 
                        if (modal_mode === VendorModalModes.UPDATE_VENDOR) {
                            setHideFromFriends(result.hidden);
                            setPrivateNote(result.note);
                            setTags(result.tags);

                            let category_match = false;
                            Object.keys(VENDOR_CATEGORIES).map((keyName, i) => {
                                if (VENDOR_CATEGORIES[keyName] === result.category){
                                    setCategory(VENDOR_CATEGORIES[keyName]);
                                    category_match = true;
                                }
                            });

                            if (!category_match) {
                                setCategory(VENDOR_CATEGORIES.OTHER);
                                setOtherCategory(result.category);    
                            }

                            if (result.category_2) {
                                setShowAdditionalCategory(true);

                                let category_match_2 = false;

                                Object.keys(VENDOR_CATEGORIES).map((keyName, i) => {
                                    if (VENDOR_CATEGORIES[keyName] === result.category_2){
                                        setCategory_2(VENDOR_CATEGORIES[keyName]);
                                        category_match_2 = true;
                                    }
                                });

                                if (!category_match_2) {
                                    setCategory_2(VENDOR_CATEGORIES.OTHER);
                                    setOtherCategory2(result.category_2);    
                                }
                            }
                        }
                    }
                )
                .catch(error => {
                    if (error?.name === 'LOGIN_ERROR') {
                        history.push('/login');
                    }
                });
        }

    }, [vendor]);

    const addTag = () => {

        // check for duplicates
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].toLowerCase() === current_tag.toLowerCase()) {
                setCurrentTag('');
                return;
            }
        }

        let temp_tags = tags;
        temp_tags.push(current_tag);
        setTags(temp_tags);
        setCurrentTag('');
    }

    const removeTag = (tag) => {
        let new_tags = [];

        for (let i = 0; i < tags.length; i++) {
            if (tags[i].toLowerCase() !== tag.toLowerCase()) {
                new_tags.push(tags[i]);
            }
        }

        setTags(new_tags);
    }
    
    const canSubmit = () => {

        let categories_ok = false;

        if (category === VENDOR_CATEGORIES.OTHER && other_category) {
            categories_ok = true;
        } 
        else if (category !== VENDOR_CATEGORIES.OTHER && category){
            categories_ok = true;
        }

        let category_2_ok = true;
        if (category_2 === VENDOR_CATEGORIES.OTHER && !other_category_2) {
            category_2_ok = false;
        } 

        let is_valid_phone = true;

        if (phone && !isValidPhoneNumber(phone)){
            is_valid_phone = false;
        }

        if (vendor_data && !vendor_data.phone && !phone) {
            is_valid_phone = false;
        }

        let is_valid_email = true;

        if (email && !isValidEmail(email)) {
            is_valid_email = false;
        }

        return  is_valid_phone && 
                hide_from_friends !== null && 
                is_valid_email && 
                categories_ok &&
                category_2_ok;
    }

    const resetFields = () => {
        setPhone('');
        setHideFromFriends(false);
        setPrivateNote('');
        setTags([]);
        setCurrentTag(null);
        setSubmitting(false);
        setEmail('');
        setCategory(null);
        setOtherCategory(null);
        setCategory_2(null);
        setOtherCategory2(null);
        setWebsite('');
        setContactName('');
        setFetching(false);
        setPhoneDisplay('');
        setShowAdditionalCategory(false);
        setVendorHasAccount(false);
        setVendorData(null);

        // setting the selected vendor to null ensures that if we close the dialog and try to open it with the same vendor again that 
        // that the data is fetched from the BE again.
        setSelectedVendor(null);
    }

    const getHideFromFriendsString = () => {
        if (hide_from_friends === true) {
            return <small>Vendor will be <strong>hidden</strong> from your agent network</small>;
        }
        if (hide_from_friends === false) {
            return <small>Vendor will be <strong>visible</strong> to your agent network</small>;
        }
    }

    const categoryChanged = (value) => {
        setOtherCategory(null);
        setCategory(VENDOR_CATEGORIES[value]);
    }

    const category2Changed = (value) => {
        setOtherCategory2(null);
        setCategory_2(VENDOR_CATEGORIES[value]);
    }

    const buttonText = () => {
        if (modal_mode === VendorModalModes.UPDATE_VENDOR) { return 'Update'; }
        else { return 'Add vendor'; }
    }

    const deleteCategory2 = () => {
        setCategory_2(null);
        setShowAdditionalCategory(false);
        setOtherCategory2(null);
    }

    if (!show_existing_vendor_modal || !vendor){
        return null;
    }

    return (

        <Modal 
            show={show_existing_vendor_modal} 
            onHide={() => { setModalVisibility(FavoriteModals.show_existing_vendor_modal, false); resetFields(); }}
            size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-truncate h5">Add vendor to favorites</Modal.Title>
            </Modal.Header>
            <Modal.Body className="existingVendorModal">
                {fetching ?
                    <div class="row">
                        <div class="col text-center">
                            <Spinner animation="border" role="status" size="lg" />
                        </div>
                    </div>
                    :
                    <div class="container p-0">
                        <div class="row"><div class="col fs-5 text-primary">{vendor.entity_name}</div></div>
                        <div class="row mt-2 gy-2">
                            {
                                vendor_data && vendor_data.phone &&
                                <div class="col-12 col-md-6 mb-2"><i class="fa-solid fa-phone fa-lg me-2" /><small><strong>{formatPhoneNumber(vendor_data.phone)}</strong></small></div>
                            }
                            {
                                vendor_data && vendor_data.email &&
                                <div class="col-12 col-md-6 mb-2"><i class="fa-solid fa-envelope fa-lg me-2"></i><small><strong>{vendor_data.email}</strong></small></div>
                            }
                        </div>
                        <div class="row mt-2 gy-2">
                            <div class="col-12 col-md-6">
                                <input id="vendorPhoneNumber" type="tel" class={`form-control ${vendor_data && !vendor_data.phone && !isValidPhoneNumber(phone) ? " missingField" : ""}`} placeHolder="Phone" value={phone_display} onChange={ (e)=>setPhone(e.target.value) }/>
                            </div>
                            <div class="col-12 col-md-6">
                                <input id="vendorEmail" type="tel" class="form-control" placeHolder="Email" value={email} onChange={ (e)=>setEmail(e.target.value) }/>
                            </div>
                        </div>
                        
                        <div class="row mt-2 gy-2">
                            <div class="col-12 col-md-6">
                                <input type="text" class="form-control" placeHolder="Contact name" value={contact_name} onChange={ (e)=>setContactName(e.target.value) }/>
                            </div>
                            <div class="col-12 col-md-6">
                                <input type="text" class="form-control" placeHolder="Website" value={website} onChange={ (e)=>setWebsite(e.target.value) } disabled={vendor_has_account}/>
                            </div>
                        </div>

                        <div class="row mt-2 gy-2">
                            
                            <div class="col-6 col-md-3 pe-0">
                                <div class="input-group">
                                    <select name="questionCategory" onChange={(e) => categoryChanged(e.target.value)} class={`form-select ${!category ? " missingField" : ""}`}>
                                        <option disabled selected value>Category *</option>
                                        {Object.keys(VENDOR_CATEGORIES).map((keyName, i) => (
                                            <option value={keyName} selected={category === VENDOR_CATEGORIES[keyName]}>{VENDOR_CATEGORIES[keyName]}</option>
                                        ))}
                                    </select>
                                    <OverlayTrigger key="addCategory" placement="auto"
                                        overlay={   <Popover id="addCategory">
                                                        <Popover.Body>
                                                            Add a second category
                                                        </Popover.Body>
                                                    </Popover>}>
                                                    <Button variant="primary"  onClick={() => setShowAdditionalCategory(true)} disabled={show_additional_category || !category}><i class="fa-solid fa-plus" /></Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div class="col-6 col-md-3">
                                {category === VENDOR_CATEGORIES.OTHER &&
                                    <input type="text" class={`form-control ${!other_category ? " missingField" : " "}`} value={other_category} onChange={(e)=>setOtherCategory(e.target.value)}  placeholder="Category"/>
                                }
                            </div>
                            { show_additional_category && 
                                    <>
                                        <div class="col-6 col-lg-3 pe-0">
                                            <div class="input-group">
                                                <select name="questionCategory" onChange={(e) => category2Changed(e.target.value)} class="form-select">
                                                        <option disabled selected value>Category 2</option>
                                                        {Object.keys(VENDOR_CATEGORIES).map((keyName, i) => (
                                                            <option value={keyName} selected={category_2 === VENDOR_CATEGORIES[keyName]}>{VENDOR_CATEGORIES[keyName]}</option>
                                                        ))}
                                                </select>
                                                <OverlayTrigger key="remCategory" placement="auto"
                                                    overlay={   <Popover id="remCategory">
                                                                    <Popover.Body>
                                                                        Remove category
                                                                    </Popover.Body>
                                                                </Popover>}>
                                                                <Button variant="outline-primary"  onClick={ deleteCategory2 }><i class="fa-solid fa-minus"></i></Button>
                                                </OverlayTrigger>
                                                
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-3">
                                            {category_2 === VENDOR_CATEGORIES.OTHER &&
                                                <input type="text" class={`form-control ${!other_category_2 ? " missingField" : " "}`} value={other_category_2} onChange={(e)=>setOtherCategory2(e.target.value)}  placeholder="Category"/>
                                            }
                                        </div>
                                    </>
                                }
                            <div class="col-12 col-md-6">
                                <div class="input-group">
                                    <input id="tags" type="text" class="form-control" placeHolder="Organize vendors with tags" value={current_tag} onChange={ (e)=>setCurrentTag(e.target.value) }/>
                                    <Button variant="primary" disabled={!current_tag} onClick={ addTag } ><i class="fa-solid fa-plus" /></Button>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-12 offset-md-6 col-md-6">
                                { tags && tags.map ( tag => {
                                    return <Button size="sm" variant="light" className="me-1" >{tag}&nbsp;&nbsp;<i class="fa-solid fa-xmark" onClick={() => removeTag(tag)}></i></Button>
                                })}
                            </div>
                        </div>
                    
                        <div class="row mt-2 gy-2">
                            <div class="col">
                                <textarea id="privateNote" class="form-control" rows="5" placeHolder="Add a private note" value={note} onChange={ (e)=>setPrivateNote(e.target.value) }/>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col"><small>Hide from agent network? (required)</small> &nbsp;
                                <OverlayTrigger key="make_visible_details" placement="auto"
                                    overlay={   <Popover id="make_visible_details">
                                                    <Popover.Header as="h3">Hide from agent network?</Popover.Header>
                                                    <Popover.Body>
                                                        When agents in your network browse through your vendors, they can only
                                                        see those vendors that you have explicitly made visible; all other vendors remain hidden from your network.
                                                    </Popover.Body>
                                                </Popover>
                                                }>
                                    <img src={toolTip_16}class="tooltipImage mb-1"/>
                                    {/* <span class="text-decoration-underline fw-light">What is this?</span> */}
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div class="row mt-1 align-items-center">
                            <div class="col-auto">
                                <ButtonGroup>
                                    <ToggleButton
                                            key="share-key-2"
                                            id="anonymous-id-2"
                                            type="radio"
                                            variant="outline-success"
                                            name="share_vendor"
                                            value={false}
                                            checked={false === hide_from_friends}
                                            onChange={() => setHideFromFriends(false)}
                                        > No  
                                    </ToggleButton>
                                    <ToggleButton
                                            key="share-key-1"
                                            id="share-id-1"
                                            type="radio"
                                            variant="outline-success"
                                            name="share_vendor"
                                            value={true}
                                            checked={true === hide_from_friends}
                                            onChange={() => setHideFromFriends(true)}
                                        > Yes    
                                    </ToggleButton>                        
                                </ButtonGroup>
                            </div>
                            <div class="col">{getHideFromFriendsString()}</div>
                        </div>
                        <div class="row mt-5">
                            <div class="col text-center">
                                { submitting ? 
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    :
                                    <Button variant="primary" onClick={ addUpdate } disabled={ !canSubmit() } >
                                        {buttonText()}
                                    </Button>
                                }
                                
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}

export function OtherVendorModal(props) {

    const {vendor, modal_mode, show_other_vendor_modal, setSelectedVendor, setModalVisibility, refreshParent, vendor_entity_name} = props;

    const [vendor_name, setVendorName] = useState('');
    const [contact_name, setContactName] = useState(null);
    
    const [vendor_phone, setVendorPhone] = useState('');
    const [vendor_phone_display, setVendorPhoneDisplay] = useState('');

    const [show_additional_phone, setShowAdditionalPhone] = useState(false);
    const [show_additional_email, setShowAdditionalEmail] = useState(false);
    const [show_additional_category, setShowAdditionalCategory] = useState(false);
    
    const [vendor_secondary_phone, setVendorSecondaryPhone] = useState('');
    const [vendor_secondary_phone_display, setVendorSecondaryPhoneDisplay] = useState('');
    const [vendor_email, setVendorEmail] = useState('');
    const [vendor_secondary_email, setVendorSecondaryEmail] = useState('');

    const [category, setCategory] = useState(null);
    const [other_category, setOtherCategory] = useState(null);
    const [category_2, setCategory_2] = useState(null);
    const [other_category_2, setOtherCategory2] = useState(null);
    const [street_1, setStreet1] = useState(null);
    const [street_2, setStreet2] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [zip_code, setZipCode] = useState(null);
    const [hide_from_friends, setHideFromFriends] = useState(false);
    const [note, setPrivateNote] = useState('');
    const [tags, setTags] = useState([]);
    const [current_tag, setCurrentTag] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [website, setWebsite] = useState('');
    const [fetching, setFetching] = useState(false);
    
    let history = useHistory();

    useEffect(() => { 
        if (!vendor && modal_mode === VendorModalModes.NEW_VENDOR && show_other_vendor_modal) {
            setVendorName(vendor_entity_name);
        }
    }, [modal_mode]);

    useEffect(() => { 
        if (vendor_phone != null && vendor_phone.length >= 0) {
            let new_phone = vendor_phone.replace(/\D/g,'');

            if (isValidPhoneNumber(new_phone)) {
                setVendorPhone(new_phone);
                let formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
                setVendorPhoneDisplay(formatted_number);
            } else if (new_phone.length < 10) {
                setVendorPhone(new_phone);
                setVendorPhoneDisplay(new_phone);
            }else if (new_phone.length > 10) {
                let trimmed_phone = new_phone.substring(0, 10);
                setVendorPhone(trimmed_phone);
                let formatted_number = "(" + trimmed_phone.substring(0,3) + ") " + trimmed_phone.substring(3,6) + "-" + trimmed_phone.substring(6,10);  
                setVendorPhoneDisplay(formatted_number);
            }
        }

    }, [vendor_phone]);

    useEffect(() => { 
        if (vendor_secondary_phone != null && vendor_secondary_phone.length >= 0) {
            let new_phone = vendor_secondary_phone.replace(/\D/g,'');

            if (isValidPhoneNumber(new_phone)) {
                setVendorSecondaryPhone(new_phone);
                let formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
                setVendorSecondaryPhoneDisplay(formatted_number);
            } else if (new_phone.length < 10) {
                setVendorSecondaryPhone(new_phone);
                setVendorSecondaryPhoneDisplay(new_phone);
            }else if (new_phone.length > 10) {
                let trimmed_phone = new_phone.substring(0, 10);
                setVendorSecondaryPhone(trimmed_phone);
                let formatted_number = "(" + trimmed_phone.substring(0,3) + ") " + trimmed_phone.substring(3,6) + "-" + trimmed_phone.substring(6,10);  
                setVendorSecondaryPhoneDisplay(formatted_number);
            }
        }

    }, [vendor_secondary_phone]);

    useEffect(() => { 
        
        if(vendor && show_other_vendor_modal && modal_mode === VendorModalModes.UPDATE_VENDOR) {
            setFetching(true);
            let request = {
                vendor_id: vendor.ID,
                role: vendor.role,
                include_favorite_details: true,
            }
            generic_get_api(AGENT_FAVORITE_VENDOR_URL, request)
                .then(
                    (result) => {
                        setFetching(false);
                        setVendorName(result.entity_name);
                        setContactName(result.contact_name);
                        setVendorPhone(result.phone);
                        setVendorSecondaryPhone(result.secondary_phone);
                        setVendorEmail(result.email);
                        setVendorSecondaryEmail(result.secondary_email);

                        setStreet1(result.street_1);
                        setCity(result.city);
                        setZipCode(result.zip_code);
                        setHideFromFriends(result.hidden);
                        setPrivateNote(result.note);
                        setTags(result.tags);
                        setState(result.state);
                        setWebsite(result.website);
                        
                        if (result.secondary_phone){
                            setShowAdditionalPhone(true);
                        }

                        if (result.secondary_email){
                            setShowAdditionalEmail(true);
                        }

                        let category_match = false;

                        Object.keys(VENDOR_CATEGORIES).map((keyName, i) => {
                            if (VENDOR_CATEGORIES[keyName] === result.category){
                                setCategory(VENDOR_CATEGORIES[keyName]);
                                category_match = true;
                            }
                        });

                        if (!category_match) {
                            setCategory(VENDOR_CATEGORIES.OTHER);
                            setOtherCategory(result.category);    
                        }

                        if (result.category_2) {
                            setShowAdditionalCategory(true);

                            let category_match_2 = false;

                            Object.keys(VENDOR_CATEGORIES).map((keyName, i) => {
                                if (VENDOR_CATEGORIES[keyName] === result.category_2){
                                    setCategory_2(VENDOR_CATEGORIES[keyName]);
                                    category_match_2 = true;
                                }
                            });

                            if (!category_match_2) {
                                setCategory_2(VENDOR_CATEGORIES.OTHER);
                                setOtherCategory2(result.category_2);    
                            }
                        }
                    }
                )
                .catch(error => {
                    if (error?.name === 'LOGIN_ERROR') {
                        history.push('/login');
                    }
                });
        }

    }, [vendor]);

    const clearFields = () => {
        setVendorEmail('');
        setVendorName('');
        setContactName('');
        setVendorPhone('');
        setVendorPhoneDisplay('');
        setVendorSecondaryEmail('');
        setVendorSecondaryPhone('');
        setVendorSecondaryPhoneDisplay('');
        setCategory(null);
        setOtherCategory(null);
        setCategory_2(null);
        setOtherCategory2(null);
        setStreet1(null);
        setStreet2(null);
        setCity(null);
        setState(null);
        setZipCode(null);
        setHideFromFriends(false);
        setSubmitting(false);
        setPrivateNote('');
        setTags([]);
        setCurrentTag(null);
        setWebsite('');
        setFetching(false);
        setShowAdditionalPhone(false);
        setShowAdditionalCategory(false);
        setShowAdditionalEmail(false);

        if (modal_mode === VendorModalModes.UPDATE_VENDOR) {
            // reset the selected vendor in the parent otherwise clicking on the item again will not trigger the above useEffect
            setSelectedVendor(null);
        }
    }

    const addTag = () => {

        // check for duplicates
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].toLowerCase() === current_tag.toLowerCase()) {
                setCurrentTag('');
                return;
            }
        }

        let temp_tags = tags;
        temp_tags.push(current_tag);
        setTags(temp_tags);
        setCurrentTag('');
    }

    const removeTag = (tag) => {
        let new_tags = [];

        for (let i = 0; i < tags.length; i++) {
            if (tags[i].toLowerCase() !== tag.toLowerCase()) {
                new_tags.push(tags[i]);
            }
        }

        setTags(new_tags);
    }

    const categoryChanged = (value) => {
        setOtherCategory(null);
        setCategory(VENDOR_CATEGORIES[value]);
    }

    const category2Changed = (value) => {
        setOtherCategory2(null);
        setCategory_2(VENDOR_CATEGORIES[value]);
    }

    const canSubmit = () => {

        let categories_ok = false;

        if (category === VENDOR_CATEGORIES.OTHER && other_category) {
            categories_ok = true;
        } 
        else if (category !== VENDOR_CATEGORIES.OTHER && category){
            categories_ok = true;
        }

        let category_2_ok = true;
        if (category_2 === VENDOR_CATEGORIES.OTHER && !other_category_2) {
            category_2_ok = false;
        } 

        let is_email_ok = true;
        if (vendor_email && vendor_email.length > 0 && !isValidEmail(vendor_email)){
            is_email_ok = false;
        }

        let is_secondary_email_ok = true;
        if (vendor_secondary_email && vendor_secondary_email.length > 0 && !isValidEmail(vendor_secondary_email)){
            is_secondary_email_ok = false;
        }

        let is_secondary_phone_ok = true;
        if (vendor_secondary_phone && vendor_secondary_phone.length > 0 && !isValidPhoneNumber(vendor_secondary_phone)) {
            is_secondary_phone_ok = false;
        }

        return  vendor_name && 
                isValidPhoneNumber(vendor_phone) && 
                categories_ok && 
                hide_from_friends !== null && 
                is_email_ok && 
                is_secondary_email_ok &&
                is_secondary_phone_ok &&
                category_2_ok;
    }

    if (!props.show_other_vendor_modal) {
        return null;
    }

    const addUpdateNewVendor = () => {

        setSubmitting(true);

        // in case user forgets to add the last tag, and there is a tag in the text box.
        if (current_tag) {
            addTag();
        }
    
        let effective_category = category;

        if (category === VENDOR_CATEGORIES.OTHER) {
            effective_category = other_category;
        }

        let effective_category_2 = category_2;

        if (category_2 === VENDOR_CATEGORIES.OTHER) {
            effective_category_2 = other_category_2;
        }

        var request = {
            business_name: vendor_name,
            contact_name: contact_name,
            phone: vendor_phone,
            secondary_phone: vendor_secondary_phone,
            email: vendor_email,
            secondary_email: vendor_secondary_email,
            category: effective_category,
            category_2: effective_category_2,
            street_1: street_1,
            street_2: street_2,
            state: state,
            zip_code: zip_code,
            city: city,
            hide_from_friends: hide_from_friends,
            tags: tags,
            note: note,
            website: website,
            is_import: false,
        }

        if (modal_mode === VendorModalModes.UPDATE_VENDOR && vendor) {
            request['entity_id'] = vendor.ID;
            request['entity_role'] = vendor.role;

            generic_put_api(VENDOR_ADD_URL, request)
            .then( result => {
                if (result.status === 200) {
                    setSubmitting(false);
                    setModalVisibility(FavoriteModals.show_other_vendor_modal, false);
                    clearFields();
                    refreshParent();
                }
            }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    setModalVisibility(FavoriteModals.show_other_vendor_modal, false);
                    history.push('/login');
                }
            });
        }
        else {
            generic_post_api(request, VENDOR_ADD_URL)
            .then( result => {
                if (result.status == 201) {
                    setSubmitting(false);
                    if (modal_mode === VendorModalModes.NEW_VENDOR) {
                        history.push('/agent/favorite_vendors');
                    }
                    // do this after the previous steps to not overwrite modal_mode
                    setModalVisibility(FavoriteModals.show_other_vendor_modal, false)
                    clearFields();
                }
            }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    setModalVisibility(FavoriteModals.show_other_vendor_modal, false)
                    history.push('/login');
                }
            });
        }
    }

    const getHideFromFriendsString = () => {
        if (hide_from_friends === true) {
            return <small>Vendor will be <strong>hidden</strong> from your agent network</small>;
        }
        if (hide_from_friends === false) {
            return <small>Vendor will be <strong>visible</strong> to your agent network</small>;
        }
    }

    const buttonText = () => {
        if (modal_mode === VendorModalModes.UPDATE_VENDOR) { return 'Update'; }
        else { return 'Add vendor'; }
    }

    const modalTitle = () => {
        if (modal_mode === VendorModalModes.UPDATE_VENDOR) { return 'Update vendor'; }
        else { return 'Add new vendor'; }
    }

    const deleteCategory2 = () => {
        setCategory_2(null);
        setShowAdditionalCategory(false);
        setOtherCategory2(null);
    }

    return(
       
            <Modal 
                show={props.show_other_vendor_modal} 
                onHide={() => {setModalVisibility(FavoriteModals.show_other_vendor_modal, false); clearFields()}}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle()}</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    {fetching ?
                        <div class="row">
                            <div class="col text-center">
                                <Spinner animation="border" role="status" size="lg" />
                            </div>
                        </div>
                    :
                        <div class="container OtherVendorModal">
                            <div class="row mb-2 gy-2">
                                <div class="col-12 col-lg-6"><input type="text" required="true" class={`form-control ${!vendor_name ? " missingField" : ""}`} value={vendor_name} onChange={(e)=>setVendorName(e.target.value)} placeholder="Business name *"/></div>
                                <div class="col-12 col-lg-6"><input type="text" class="form-control" value={contact_name} onChange={(e)=>setContactName(e.target.value)} placeholder="Contact name"/></div>
                            </div>
                            <div class="row gy-2 mb-2">
                                <div class="col-12 col-lg-6">
                                    <div class="input-group">
                                        <input type="tel" class={`form-control ${!isValidPhoneNumber(vendor_phone) ? "missingField" : ""}`} value={vendor_phone_display} onChange={(e)=>setVendorPhone(e.target.value )} placeholder="Primary phone number *"/>
                                        <OverlayTrigger key="addPhone" placement="auto"
                                            overlay={   <Popover id="addPhone">
                                                            <Popover.Body>
                                                                Add a second phone number
                                                            </Popover.Body>
                                                        </Popover>}>
                                                        <Button variant="primary"  onClick={() => setShowAdditionalPhone(true)} disabled={show_additional_phone || !isValidPhoneNumber(vendor_phone)}><i class="fa-solid fa-plus" /></Button>
                                        </OverlayTrigger>
                                        
                                    </div>
                                </div>
                                {
                                    show_additional_phone && 
                                    <div class="col-12 col-lg-6">
                                        <input type="tel" class={`form-control ${vendor_secondary_phone && vendor_secondary_phone.length > 0 && !isValidPhoneNumber(vendor_secondary_phone) ? " missingField" : ""}`} value={vendor_secondary_phone_display} onChange={(e)=>setVendorSecondaryPhone(e.target.value )} placeholder="Phone number #2"/>
                                    </div>
                                }
                                <div class="col-12 col-lg-6">
                                    <div class="input-group">
                                        <input type="email" class={`form-control ${vendor_email && vendor_email.length > 0 && !isValidEmail(vendor_email) ? " missingField" : ""}`} value={vendor_email} onChange={(e)=>setVendorEmail(e.target.value )}  placeholder="Primary email"/>
                                        <OverlayTrigger key="addEmail" placement="auto"
                                            overlay={   <Popover id="addEmail">
                                                            <Popover.Body>
                                                                Add a second email address
                                                            </Popover.Body>
                                                        </Popover>}>
                                                        <Button variant="primary"  onClick={() => setShowAdditionalEmail(true)} disabled={show_additional_email || !isValidEmail(vendor_email)}><i class="fa-solid fa-plus" /></Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                {
                                    show_additional_email  && 
                                    <div class="col-12 col-lg-6">
                                         <input type="email" class={`form-control ${vendor_secondary_email && vendor_secondary_email.length > 0 && !isValidEmail(vendor_secondary_email) ? " missingField" : ""}`} value={vendor_secondary_email} onChange={(e)=>setVendorSecondaryEmail(e.target.value )}  placeholder="Email #2"/>
                                    </div>
                                }
                                
                            </div>
                          
                            <div class="row mb-2 gy-2">
                                <div class="col-6 col-lg-3 pe-0">
                                    <div class="input-group">
                                        <select name="questionCategory" onChange={(e) => categoryChanged(e.target.value)} class={`form-select ${!category ? " missingField" : ""}`}>
                                                <option disabled selected value>Category *</option>
                                                {Object.keys(VENDOR_CATEGORIES).map((keyName, i) => (
                                                    <option value={keyName} selected={category === VENDOR_CATEGORIES[keyName]}>{VENDOR_CATEGORIES[keyName]}</option>
                                                ))}
                                        </select>
                                        <OverlayTrigger key="addCategory" placement="auto"
                                            overlay={   <Popover id="addCategory">
                                                            <Popover.Body>
                                                                Add a second category
                                                            </Popover.Body>
                                                        </Popover>}>
                                                        <Button variant="primary"  onClick={() => setShowAdditionalCategory(true)} disabled={show_additional_category || !category}><i class="fa-solid fa-plus" /></Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-3">
                                    {category === VENDOR_CATEGORIES.OTHER &&
                                        <input type="text" class={`form-control ${!other_category ? " missingField" : " "}`} value={other_category} onChange={(e)=>setOtherCategory(e.target.value)}  placeholder="Category"/>
                                    }
                                </div>
                                { show_additional_category && 
                                    <>
                                        <div class="col-6 col-lg-3 pe-0">
                                            <div class="input-group">
                                                <select name="questionCategory" onChange={(e) => category2Changed(e.target.value)} class="form-select">
                                                        <option disabled selected value>Category 2</option>
                                                        {Object.keys(VENDOR_CATEGORIES).map((keyName, i) => (
                                                            <option value={keyName} selected={category_2 === VENDOR_CATEGORIES[keyName]}>{VENDOR_CATEGORIES[keyName]}</option>
                                                        ))}
                                                </select>
                                                <OverlayTrigger key="remCategory" placement="auto"
                                                    overlay={   <Popover id="remCategory">
                                                                    <Popover.Body>
                                                                        Remove category
                                                                    </Popover.Body>
                                                                </Popover>}>
                                                                <Button variant="outline-primary"  onClick={ deleteCategory2 }><i class="fa-solid fa-minus"></i></Button>
                                                </OverlayTrigger>
                                                
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-3">
                                            {category_2 === VENDOR_CATEGORIES.OTHER &&
                                                <input type="text" class={`form-control ${!other_category_2 ? " missingField" : " "}`} value={other_category_2} onChange={(e)=>setOtherCategory2(e.target.value)}  placeholder="Category"/>
                                            }
                                        </div>
                                    </>
                                }
                                <div class="col-12 col-lg-6"><input type="url" class="form-control" value={website} onChange={(e)=>setWebsite(e.target.value )}  placeholder="Website http://www.example.com"/></div>
                            </div>
                            <div class="row mb-2 gy-2">
                                <div class="col-sm"> <input type="text" class="form-control" value={street_1} onChange={(e)=>setStreet1(e.target.value )}  placeholder="Street address"/></div>
                                <div class="col-12 col-lg-6"><input type="text" class="form-control" value={city} onChange={(e)=>setCity(e.target.value )}  placeholder="City"/></div>
                                
                            </div>
                            <div class="row mb-2 gy-2">
                                <div class="col-6 col-lg-3"> 
                                    <select name="questionCategory" onChange={(e) => setState(e.target.value)} class="form-select">
                                        <option disabled selected value>State</option>
                                            {Object.keys(US_STATES).map((keyName, i) => (
                                                <option value={keyName} selected={state === keyName}>{keyName}</option>
                                            ))}
                                    </select>
                                </div>
                                <div class="col-6 col-lg-3"> <input type="text" class="form-control" value={zip_code} onChange={(e)=>setZipCode(e.target.value )}  placeholder="Zip"/> </div>
                                <div class="col-12 col-lg-6">
                                    <div class="row">
                                        <div class="col">
                                            {/* <label for="tags" class="form-label mb-0"><small>Organize your vendors with optional tags</small></label> */}
                                            <div class="input-group">
                                                <input id="tags" type="text" class="form-control" placeHolder="Organize vendors with tags" value={current_tag} onChange={ (e)=>setCurrentTag(e.target.value) }/>
                                                <Button variant="primary" disabled={!current_tag} onClick={ addTag } ><i class="fa-solid fa-plus" /></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            { tags.map ( tag => {
                                                return <Button size="sm" variant="light" className="me-1" >{tag}&nbsp;&nbsp;<i class="fa-solid fa-xmark" onClick={() => removeTag(tag)}></i></Button>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row mb-2 gy-2">
                                <div class="col-12">
                                    {/* <label for="privateNote" class="form-label mb-0"><small>Keep private notes about vendors</small></label> */}
                                    <textarea id="privateNote" class="form-control" rows="5"  placeHolder="Keep private notes about vendors" value={note} onChange={ (e)=>setPrivateNote(e.target.value) }/>
                                </div>
                            </div>
                            <div class="row mb-3 mt-1 gy-1 align-items-center ">
                                <div class="col-12 col-lg-12"><small>Hide from your agent network? *</small> &nbsp;
                                            <OverlayTrigger key="make_visible_details" placement="auto"
                                                overlay={   <Popover id="make_visible_details">
                                                                <Popover.Header as="h3">Hide from agent network?</Popover.Header>
                                                                <Popover.Body>
                                                                    When agents in your network browse through your vendors, they can only
                                                                    see those vendors that you have explicitly made visible; all other vendors remain hidden from your agent network.
                                                                </Popover.Body>
                                                            </Popover>}>
                                                            <img src={toolTip_16}class="tooltipImage mb-1"/>
                                            </OverlayTrigger></div>
                                <div class="col-auto mt-0">
                                    <ButtonGroup >
                                        <ToggleButton
                                                key="share-key-2"
                                                id="anonymous-id-2"
                                                type="radio"
                                                variant="outline-success"
                                                name="hide_vendor"
                                                value={false}
                                                checked={false === hide_from_friends}
                                                onChange={() => setHideFromFriends(false)}
                                            > No  
                                        </ToggleButton>
                                        <ToggleButton
                                                key="share-key-1"
                                                id="share-id-1"
                                                type="radio"
                                                variant="outline-success"
                                                name="hide_vendor"
                                                value={true}
                                                checked={true === hide_from_friends}
                                                onChange={() => setHideFromFriends(true)}
                                            > Yes    
                                        </ToggleButton>                        
                                    </ButtonGroup>
                                </div>
                                <div class="col">{getHideFromFriendsString()}</div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-12">
                                    * Required fields
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <small>When you add a new vendor, other agents will <strong>not</strong> be able to find this vendor on Renolition unless 1) this vendor is licensed with a state authority;
                                        2) this vendor joins Renolition; 3) you make this vendor visible to your agent network (only your agent
                                        network will find this vendor). </small>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                     { submitting ? 
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting...
                            </Button>
                        :
                        <>
                            <Button variant="secondary" onClick={ () => {setModalVisibility(FavoriteModals.show_other_vendor_modal, false); clearFields()} }> Cancel </Button>
                            <Button  variant="primary"  onClick={ addUpdateNewVendor } disabled={!canSubmit()} > { buttonText() } </Button>
                        </>
                    }               
                       
                </Modal.Footer>
            </Modal>
    )
}

class VendorSearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: null,
            suggestions: [],
            activeSuggestion: 0,
        };

        this.inputChanged = this.inputChanged.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.inputOnKeyDown = this.inputOnKeyDown.bind(this);
        this.suggestionOnClick = this.suggestionOnClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur(e) {
        // https://reactjs.org/docs/events.html#focus-events
        // https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
        if (!e.currentTarget.contains(e.relatedTarget)) {   
            this.setState({
                suggestions: [],
                activeSuggestion: 0
            });
        }
    }

    inputOnKeyDown(e) {
        const { suggestions, activeSuggestion } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                suggestions: [],
                searchString: suggestions[activeSuggestion]
            });
            this.submitSearch(null, suggestions[activeSuggestion]);
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion <= 0) {
                return;
            }   

            this.setState(
                { 
                    activeSuggestion: activeSuggestion - 1,
                    searchString: suggestions[activeSuggestion - 1]
                }
            );
        }
        
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === suggestions.length) {
                return;
            }

            this.setState(
                { 
                    activeSuggestion: activeSuggestion + 1,
                    searchString: suggestions[activeSuggestion + 1]
                }
            );
        }
    }

    suggestionOnClick (e) {
        this.setState({
            activeSuggestion: 0,
            suggestions: [],
            searchString: e.currentTarget.innerText
          });

        this.submitSearch(null, e.currentTarget.innerText);
    }

    inputChanged(e) {

        this.setState({
            suggestions: [],
            searchString: e.target.value
        });

        generic_get_api(AUTO_COMPLETE_URL, {"prefix": e.target.value})
        .then(
            data => {
                this.setState({
                    suggestions: data.result,
                    activeSuggestion: -1
                });
            }
        );
    }

    submitSearch(e, input) {
        var searchString = input != null ? input : this.state.searchString    
        this.props.submitSearch(searchString);
    }

    render() {

        const {suggestions, activeSuggestion } = this.state;
        let suggestionsListComponent;

        suggestionsListComponent = (
            <ul class="suggestions">
                { suggestions && suggestions.map((suggestion, index) => {
                    let className;

                    if (index === activeSuggestion) {
                        className = "suggestion-active";
                    }

                    return (
                        <li key={suggestion} onClick= { this.suggestionOnClick } className={className} tabindex="1">
                            {suggestion}
                        </li>
                    );
                    })}
            </ul>
        );

        return (
                <div class="vendorSearchBox container p-0">
                    <div class="nameField" onBlur={ this.onBlur }>
                        <div class="input-group">
                            <input type="search" 
                                placeHolder="Vendor name or license" 
                                onChange={ this.inputChanged }
                                onKeyDown={ this.inputOnKeyDown }
                                value={ this.state.searchString }
                                class="form-control"
                                name="search" 
                                id="search" 
                            />
                            {
                                this.props.searching ?

                                    <Button variant="warning" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        Searching...
                                    </Button>
                                :
                                    <Button variant="warning" 
                                        onClick={ (e) => { this.setState({activeSuggestion: 0, suggestions: []}) ;this.submitSearch(e, null)} } 
                                        disabled={this.props.searching}>Search
                                    </Button>

                            }
                        </div>
                        {suggestionsListComponent}
                    </div>
                </div>
        );
    }
}


function LearnMoreModal(props) {
    const {show_learn_more_modal, hideLearnMoreModal } = props;

    if (!show_learn_more_modal) { return null; }

    return(
        <Modal
            show={show_learn_more_modal} 
            onHide={ hideLearnMoreModal }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Search &amp; research vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container lh">
                    <div class="row">
                        <div class="col fw-bold">Which vendors are in your database?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Our database includes all vendors that are licensed by the California Contractors State License Board (e.g. contractors, plumbers, electricians etc.) and those licensed
                            by the Department of Consumer Affairs (DCA) (e.g. Architects, Engineers, etc.). 

                            Overtime we will also include businesses registered in San Francisco (e.g. cleaning businesses, moving etc.).
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">How can I search for a vendor?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Search for vendors by name or license number. If you want to discover new vendors by category (e.g. a new plumber) then head to 
                            our <strong>Search by category</strong> section. 
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">What kind of information is available about vendors?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            For licensed vendors (e.g. contractors, engineers, architects), we display license, insurance, bond, and workers compensation insurance (if applicable) information and the 
                            last date that the data was verified. 
                            <br/> In addition, for vendors in construction, we pull their permit history, categorize the permits based on job type, and show on a map where the job was performed.
                            <br/> Renolition also collects verified feedback for completed, permitted jobs. We reach out directly to the homeowner once a job is completed and ask for 
                            feedback for the construction vendor. 
                        </div>
                    </div>
                  
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VendorSearch;