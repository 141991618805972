import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";
import GoogleMapReact from 'google-map-react';

import covered_icon from 'assets/reputation/covered_icon.png';
import not_covered_icon from 'assets/reputation/not_covered_icon.png';
import exempt_icon from 'assets/reputation/exempt.png';

import urlLogo from 'assets/reputation/url_logo.svg';
import mailLogo from 'assets/reputation/mail_logo.svg';
import permit_map_marker from 'assets/reputation/permit_map_marker.svg';
import thumb_up from 'assets/reputation/thumb_up_icon.png';
import thumb_down from 'assets/reputation/thumb_down_icon.png';
import {basicAccountInfo} from 'api/basic_account_info';
import {Button, OverlayTrigger, Modal, Card, Spinner, Carousel, Popover} from 'react-bootstrap';
import { generic_post_api, generic_get_api } from "api/generic_api";
import socket from 'assets/reputation/socket.png';
import pipes from 'assets/reputation/pipes.png';
import brick_wall from 'assets/reputation/brickwall.png';
import { isVendor } from "utils/helpers";

import {
    VENDOR_BASIC_DETAIL_URL,
    VENDOR_PERMIT_INFO,
    VENDOR_PLACES_URL,
    VERIFIED_REVIEWS_URL,
    CLASSIFICATIONS_MAP,
    COMBINED_TAG_MAP,
    COMBINED_TAG_MAP_TOOL_TIP,
    EntityRole, 
    VENDOR_REVIEW_URL,
    AGENT_CONTACT_FEEDBACK_URL,
} from 'utils/constants';

import { 
    formatPhoneNumber,
    getStringForReviews,
    get_star_icon,
 } from 'utils/formatters';

const PermitDisplayOption = {
    TOTAL: 'total',
    ONE_YEAR: 'one_year'
}

const formatPermitTags = (permitTags) => {
    let full_length = permitTags.map(tag => COMBINED_TAG_MAP[tag]).join(', ');

    if (full_length.length > 50){
        return full_length.substring(0,50) + '...';
    }
    
    return full_length;
}

var formatClassifications = (classification_list, short) => {

    var result = [];

    if (classification_list.length == 0) {
        return ["Unknown"];
    }

    var current_string = "";
    
    classification_list.forEach(c => {

        if (c in CLASSIFICATIONS_MAP) { 

            if ((current_string.length + CLASSIFICATIONS_MAP[c].length) > 60) {
                result.push(current_string);
                current_string = CLASSIFICATIONS_MAP[c];
            }
            else if  (current_string.length == 0){
                current_string += CLASSIFICATIONS_MAP[c];
            }
            else {
                current_string += "  |  " + CLASSIFICATIONS_MAP[c];
            }
        }
    }) 
    result.push(current_string);
    if (short) {
        return result.slice(0, 1);
    }
    else {
        return result.slice(0, 3);
    }
}

const get_credentials_icon = (status) => {
    if (status === true) { return covered_icon; }
    return not_covered_icon;
}

const get_workers_comp_icon = (coverage_type, is_covered) => {

    if (coverage_type === 'EXEMPT') {
        return exempt_icon;
    }
    else {
        return get_credentials_icon(is_covered);
    }
}

const getLicenseToolTip = (credentials) => {

    if (!credentials.active_license) {
        return 'While this vendor may have a license number, their license may not be active.'
    }
    else {
        return "Active license with an expiration date of " + credentials.license_expiration_date + "."
    }
}

const getBondedToolTip = (status) => {
    if (status) {
        return 'Active bond';
    }
    else { 
        return 'No active bond'; 
    }
}

const getWkToolTip = (status, type) => {
    if (type === 'EXEMPT') {
        return "This license is exempt from having workers compensation insurance. They certified with the Contractor's State License Board that they have no employees at this time.";
    }
    else if (status) { 
        return 'Active workers compensation insurance'; 
    }
    else {
        return 'No active workers compensation insurance';
    }
}

class VendorDetail extends Component {

    constructor(props){
        super(props);

        let ID = null;
        let entity_role = null;

        
        var urlParams = new URLSearchParams(window.location.search);
        ID = urlParams.get('ID');
        entity_role = urlParams.get('role');
            
        if (!ID || !entity_role) {
            this.props.history.push('/');
        }

        this.state = {
            ID: ID,
            entity_role: entity_role,
            permit_info: null,
            vendor_detail: null,
            reviews: [],
            rating: 0,
            places_info: null,
            agent_user_logged_in: false,
            logged_in_user_role: null,
            is_in_agent_network: false,
            is_shared_with_contact: false,
            additional_vendor_details:{
                tags:[],
                vendor_services: [],
                external_ratings: [],
            },
            add_remove_in_progress: false,
        }
        this.fetchReviews = this.fetchReviews.bind(this);
        this.fetchBasicDetails = this.fetchBasicDetails.bind(this);
        this.fetchPermitInfo = this.fetchPermitInfo.bind(this);
        this.fetchBasicDetails = this.fetchBasicDetails.bind(this);
        this.fetchAgentVendorReviews = this.fetchAgentVendorReviews.bind(this);
    }

    componentDidMount() {
        if (this.state.ID){
            basicAccountInfo()
            .then(data => {
                this.setState({
                    logged_in_user_role: data.role,
                });
                this.fetchVendorDetails(this.state.ID, this.state.entity_role);
            })
            .catch(error => {
                if (error.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } 
            }); 
        } 
    }

    fetchVendorDetails(vendor_id, entity_role){

        this.fetchBasicDetails(vendor_id, entity_role);

        if (this.state.logged_in_user_role === EntityRole.AGENT) {
            this.fetchAgentVendorReviews(vendor_id, entity_role);
        }

        if (entity_role !== EntityRole.SF_BUSINESS && entity_role !== EntityRole.OTHER_BUSINESS) {

            this.fetchPermitInfo(vendor_id, entity_role);
            
            setTimeout(() =>      
                this.fetchReviews(vendor_id, entity_role),
                500
            );

            setTimeout(() =>     
               this.fetchPlaces(vendor_id, entity_role), 
                500
            );
        }   
    }

    fetchPlaces(entity_id, entity_role) {

        let request = {
            "entity_role": entity_role,
            "entity_id": entity_id
        }

        generic_get_api(VENDOR_PLACES_URL, request)
        .then(
            data => {
                this.setState({
                    places_info: data.places,
                });
            }
        )
        .catch(error => {
            if (error.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    fetchBasicDetails(entity_id, entity_role) {
        var params = { 
            entity_id: entity_id,
            entity_role: entity_role,
        }

        generic_get_api(VENDOR_BASIC_DETAIL_URL, params)
        .then(
            data => {
                this.setState({
                    vendor_detail: data.vendor_detail,
                    is_in_agent_network: data.is_in_agent_network,
                    is_shared_with_contact: data.is_shared_with_contact,
                });
                
                if (data.vendor_detail.city) {
                    document.title = data.vendor_detail.entity_name + " - " + data.vendor_detail.city;
                } else {
                    document.title = data.vendor_detail.entity_name;
                }

                
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchAgentVendorReviews(entity_id, entity_role) {
        
        let params = {
            vendor_id: entity_id,
            entity_role: entity_role,
        }

        generic_get_api(VENDOR_REVIEW_URL, params)
        .then(
            data => {
                this.setState({
                    agent_vendor_reviews: data,
                });
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    fetchPermitInfo(entity_id, entity_role) {

        let request = {
            "entity_role": entity_role,
            "entity_id": entity_id,
        }

        generic_get_api(VENDOR_PERMIT_INFO, request)
        .then(
            data => {
                this.setState({
                    permit_info: data
                });
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    fetchReviews(entity_id, entity_role) {

        let request = {
            "entity_role": entity_role,
            "entity_id": entity_id,
        }

        generic_get_api(VERIFIED_REVIEWS_URL, request)
        .then(
            data => {
                this.setState({
                    reviews: data.reviews,
                    rating: data.rating
                });

                // preload images for faster carousel display
                data.reviews.forEach((review) => {
                    review.photos.forEach((photo) => {
                        new Image().src = photo.path;
                    });
                });
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    render () {
        const { vendor_detail, rating, reviews, entity_role, places_info, permit_info } = this.state;

        if (!vendor_detail) { 
            return <div className="vendorDetailEnvelope">
                        <div class="vendorDetailComponent">
                            <div class="container proDetail">
                                <div class="row mb-4">
                                    <div class="col text-center">
                                        <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        }

        return (
            <div className="vendorDetailEnvelope">
                <div class="vendorDetailComponent">
                    <div class="container proDetail">
                        <div class="row mb-4">
                            <div class="col text-left gx-5">
                                <Button size="sm" variant="outline-primary" onClick={() => this.props.history.goBack()}> <i class="fa-solid fa-arrow-left"></i> &nbsp; Search</Button>
                            </div>
                        </div>

                        <div class="row gy-4">
                            <div class="col-12 col-md-6">
                                <VendorBasicDetails
                                    rating={ rating}
                                    reviews={ reviews }
                                    vendor_detail={ vendor_detail }
                                    entity_role={entity_role}
                                    logged_in_user_role={this.state.logged_in_user_role}
                                    ID={this.state.ID}
                                    is_shared_with_contact={ this.state.is_shared_with_contact }
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <CredentialsDetails 
                                    vendor={vendor_detail}
                                    entity_role={entity_role}
                                />
                            </div>
                        </div>
                        <div class="row gy-4 mt-4">
                            <div class="col-12">
                                <AboutUs 
                                    vendor_detail={vendor_detail} 
                                    entity_role={entity_role}
                                />
                            </div>
                        </div>

                        <div class="row gy-4 mt-4">
                            <div class="col-12 col-md-6">
                                <OfferedServices 
                                    vendor_detail={vendor_detail} 
                                    entity_role={entity_role}
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <ServiceCalls
                                    vendor_detail={vendor_detail} 
                                    entity_role={entity_role}
                                />
                            </div>
                        </div>

                        <div class="row gy-4 mt-4">
                            <div class="col-12 col-md-6">
                                <SmallJobs
                                     vendor_detail={vendor_detail}
                                     entity_role={entity_role}
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <BuySellService 
                                    vendor_detail={vendor_detail}
                                    entity_role={entity_role}
                                />
                            </div>
                        </div>
                        
                        
                       
                    </div>

                   { (entity_role === 'ARCHITECT' || entity_role === 'ENGINEER' || entity_role === 'CONTRACTOR') &&
                        <>
                        <PermitHistorySection 
                            permit_info={ permit_info }
                            places_info={ places_info }
                        />
                        <ReviewSection reviews={reviews} />
                        </>
                    }
               
                    <AgentRatings 
                        agent_vendor_reviews={ this.state.agent_vendor_reviews }
                        logged_in_user_role={this.state.logged_in_user_role}
                    />

                </div>
            </div>
        );
    }
}

function ContactActionButtonSection(props) {

    const {logged_in_user_role, ID, is_shared_with_contact, entity_role } = props;
    const [how_client_feedback_model, setShowClientFeedbackModal] = useState(false);

    if (!logged_in_user_role || logged_in_user_role !== EntityRole.AGENT_CONTACT || ! is_shared_with_contact) {
        return null;
    }
    return (
        <div class="container">
            <div class="row">
                <div class="col text-center"> 
                    <Button variant="primary" className="text-nowrap" onClick={()=>setShowClientFeedbackModal(true)}>
                        Send feedback to agent
                    </Button>
                </div>
            </div>
            <ClientFeedbackModal 
                how_client_feedback_model={ how_client_feedback_model }
                setShowClientFeedbackModal={ setShowClientFeedbackModal }
                ID={ ID }
                entity_role={entity_role}           
            />
        </div>
    );
}

function ClientFeedbackModal(props) {
    const {how_client_feedback_model, setShowClientFeedbackModal, ID, entity_role} = props;

    const [feedback, setFeedback] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete]  = useState(false);

    if (!how_client_feedback_model) { return null; }

    const submitFeedback = () => {
        setSubmitting(true);
        
        let request = {
            'feedback': feedback,
            'vendor_id': ID,
            'vendor_entity_role': entity_role,
        }

        generic_post_api(request, AGENT_CONTACT_FEEDBACK_URL)
        .then(
            (result) => {
                if (result.status === 201) {
                    setSubmitting(false);
                    setComplete(true);
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
            setSubmitting(false);
        });
    }

    return (
        <Modal 
            show={how_client_feedback_model} 
            onHide={() => setShowClientFeedbackModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Private feedback form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                        {complete ?
                            <>
                                <div class="row"><div class="col h5 text-center">Your feedback was sent to your agent</div></div>

                                <div class="row mt-5"><div class="col text-center"><Button variant="primary" onClick={ () => setShowClientFeedbackModal(false) }>Continue</Button></div></div>
                            </>
                        :
                            <>
                                <div class="row">
                                    <div class="gx-0">Send feedback about this vendor to your agent. <strong>Your feedback will not be visible to vendors</strong>.</div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col gx-0">
                                        <textarea class="form-control" rows="5" onChange={(e) => setFeedback(e.target.value)}/>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center">
                                        { submitting ? 
                                            <Button variant="primary" disabled={!feedback}>
                                                Submitting... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            </Button>
                                        :
                                            <Button variant="primary" disabled={!feedback} onClick={ submitFeedback }>Submit</Button>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
            </Modal.Body>
        </Modal>
    )
}

function AgentActionButtonSection(props) {

    const {logged_in_user_role, ID, vendor_detail, entity_role } = props;

    let history = useHistory();

    if (!logged_in_user_role || logged_in_user_role !== EntityRole.AGENT) {
        return null;
    }

    const getDisplayName = () => {
        if (vendor_detail.role === EntityRole.OTHER_BUSINESS && vendor_detail.contact_name) {
            return vendor_detail.entity_name + " (" + vendor_detail.contact_name + ")";
        }
        else {
            return vendor_detail.entity_name;
        }
    }

    return (
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <Button variant="primary" 
                        onClick={ () => history.push('/agent/vendor_review', { vendor_id: ID, vendor_role: entity_role, vendor_name: getDisplayName(), vendor_phone: vendor_detail.phone }) } 
                    >Review vendor </Button>
                </div>
            </div>
        </div>
    );
}

function AgentRatings(props) {

    const {agent_vendor_reviews, logged_in_user_role } = props;

    if (logged_in_user_role !== EntityRole.AGENT) {
        return null;
    }

    return( 
        <div class="container agentRatingsEnvelope">
            <div class="row">
                <div class="col fw-bold fs-5">Agent Reviews&nbsp;
                        
                        <OverlayTrigger key="agentReviewsTT" placement="auto"
                            overlay={ 
                                <Popover id="agentReviewsTT">
                                    <Popover.Header as="h3">Agent reviews</Popover.Header>
                                    <Popover.Body>
                                        Agent reviews are from agents for agents. They are not visible to clients or vendors. 
                                    </Popover.Body>
                                </Popover>
                              }>
                                <span class=" fs-6 fw-light text-decoration-underline">What is this?</span>
                        </OverlayTrigger>
                </div>
            </div>
            <div class="row"><div class="col"><hr></hr></div></div>
            {
                agent_vendor_reviews && agent_vendor_reviews.length === 0 && 
                    <div class="row"><div class="col"><i>Vendor has not received any agent reviews</i></div></div>
            }
            {
                agent_vendor_reviews && agent_vendor_reviews.length > 0 && 
                <>
                    <div class="row">
                        { agent_vendor_reviews.map( (agent_review) => {
                            return  <div class="container">
                                        <div class="row">
                                            <div class="col-12 text-left">Agent: <strong>{ agent_review.agent_first_name} { agent_review.agent_last_name}</strong> </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-auto">Recommended: </div>
                                            <div class="col text-start">
                                                {  agent_review.would_recommend ?     
                                                    <img src={thumb_up}/> : 
                                                    <img src={thumb_down}/>
                                                }
                                            </div>
                                        </div>
                                        { (agent_review.quality !== 0 || agent_review.punctual !== 0 ||agent_review.communication !== 0 || agent_review.cleanup !== 0) &&
                                            <div class="row mt-2 gy-2">
                                                <div class="col-6">
                                                    <div class="container">
                                                        <div class="row">Quality of work</div>
                                                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(agent_review.quality)} class="reviewStars"/></div></div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="container">
                                                        <div class="row">Timeliness</div>
                                                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(agent_review.punctual)} class="reviewStars" /></div></div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="container">
                                                        <div class="row">Communication</div>
                                                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(agent_review.communication)} class="reviewStars"/></div></div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="container">
                                                        <div class="row">Job-site Cleanup</div>
                                                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(agent_review.cleanup)} class="reviewStars"/></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {agent_review.project_description &&
                                            <>
                                                <div class="row mt-4">
                                                    <div class="col"><strong>Project description</strong></div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col">{agent_review.project_description}</div>
                                                </div>
                                            </>
                                        }
                                        {agent_review.comment && 
                                            <>
                                                <div class="row mt-4">
                                                    <div class="col"><strong>Review</strong></div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col">{agent_review.comment}</div>
                                                </div>
                                            </>
                                        }
                                        <div class="row mt-4"><hr></hr></div>
                                        
                                    </div>
                                }
                            )
                        }
                    </div>
                </>
            }
        </div>
    )
}

function AboutUs(props) {
    const { vendor_detail, entity_role } = props;
    const [show_photo_popup, setShowPhotoPopup] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [active_photo_index, setActivePhotoIndex] = useState(false);

    if (!vendor_detail || (!vendor_detail.about_us && (vendor_detail.project_photos && vendor_detail.project_photos.length === 0))) {
        return null;
    }

    if (!isVendor(entity_role)) {
        return null;
    }

    const getAboutUsText = () => {

        if (!vendor_detail.about_us) {
            return <span></span>
        }

        if (vendor_detail.about_us.length < 250) {
            return <span>{vendor_detail.about_us}</span>;
        }

        else{

            if(!expanded) {
                return <span>{vendor_detail.about_us.substring(0, 250)}...&nbsp;<a role="button" onClick={() =>setExpanded(true)} class="text-decoration-underline">Show more</a></span>;
            } else {
                return <span>{vendor_detail.about_us}&nbsp;<a role="button" onClick={() =>setExpanded(false)} class="text-decoration-underline">Show less</a></span>;
            }
        }
    }

    return(

        <Card>
            <Card.Body>
                <div class="container">
                    <div class="row"><div class="col fw-bold"><i class="fa-solid fa-circle-info"></i> &nbsp; About the business</div></div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row gy-2">
                    {
                        vendor_detail.profile_photo ?
                        <>
                            <div class="col-12 col-md-auto text-center"><img src={vendor_detail.profile_photo} class="aboutUsPhoto" /></div>
                            <div class="col-12 col-md">{getAboutUsText()}</div>
                        </>
                        :
                        <div class="col ">{getAboutUsText()}</div>
                    }
                    </div>
                    { vendor_detail.project_photos && vendor_detail.project_photos.length > 0 &&
                        <div class="row mt-3 gy-2">
                            <div class="col-12 fw-bold">Recent project photos</div>
                        {
                            vendor_detail.project_photos && vendor_detail.project_photos.map((photo_entry, index) => {
                                return  <div class="col-auto text-center pe-0">
                                            <div class="row mt-1">
                                                <div class="text-center" role="button" onClick={() => {setActivePhotoIndex(index); setShowPhotoPopup(true)}}>
                                                    <img src={ photo_entry.thumb_nail_path } className="vendorProjectPhotoTN"/>
                                                </div>
                                            </div>
                                        </div>
                            })
                        }
                            <Modal
                                show={show_photo_popup} 
                                onHide={() => setShowPhotoPopup(false)}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className="vendorDetailProjectPhotos"
                            >
                                <Modal.Header closeButton >Recent project photos</Modal.Header>
                                <Modal.Body className="pe-0 ps-0">
                                    <Carousel 
                                        slide={false}
                                        indicators={false}
                                        interval={null}
                                        variant="dark"
                                        defaultActiveIndex={ active_photo_index}
                                    >
                                        {vendor_detail.project_photos && vendor_detail.project_photos.map(
                                            (photo_entry) =>{
                                                return   <Carousel.Item>
                                                            <div class="text-center" >
                                                                <img src={photo_entry.photo_path} alt="First slide" class="photoCarousel"/>
                                                            </div>
                                                        </Carousel.Item>
                                            }
                                        )}
                                    </Carousel>
                                </Modal.Body>
                            </Modal>
                        </div>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

function ServiceCalls(props) {
    const { vendor_detail, entity_role } = props;
    
    const [expanded, setExpanded] = useState(false);

    if (!vendor_detail || !vendor_detail.service_calls) {
        return null;
    }

    if (!isVendor(entity_role)) {
        return null;
    }

    const getServiceRow = (service) => {
        if (service){
            return  <div class="row mt-2">
                        <div class="col-12"><i class="fa-solid fa-chevron-right"></i> {service}</div>
                    </div>
        }
    }

    return(
        <Card>
            <Card.Body>
                <div class="container detailContainer">
                    <div class="row"><div class="col fw-bold"><i class="fa-solid fa-bell-concierge"></i> &nbsp; Service calls</div></div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row">
                        <div class="col-auto">Accepts service calls? </div>
                        <div class="col-auto">
                            {vendor_detail.service_calls.accept_service_calls ?
                                <i class="fa-solid fa-check fa-lg"/>
                                :
                                <i class="fa-solid fa-xmark fa-lg"/>
                            }           
                        </div>
                    </div>
                    {
                        vendor_detail.service_calls.accept_service_calls &&
                        <>

                            {
                                !expanded ?
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(true)}><small>Show more</small></a>
                                        </div>
                                    </div>
                                :
                                <>
                                    <div class="row mt-2"><hr></hr></div>

                                    {
                                        vendor_detail.service_calls.min_charge &&
                                        <div class="row mt-2">
                                            <div class="col-12 fw-bold">Minimum charge: ${vendor_detail.service_calls.min_charge}</div>
                                        </div>
                                    }
                                    <div class="row mt-2">
                                        <div class="col-12 fw-bold">Typical services</div>
                                    </div>
                                    {getServiceRow(vendor_detail.service_calls.job_type_1)}
                                    {getServiceRow(vendor_detail.service_calls.job_type_2)}
                                    {getServiceRow(vendor_detail.service_calls.job_type_3)}
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(false)}><small>Show less</small></a>
                                        </div>
                                    </div>
                                </>
                            }

                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

function OfferedServices(props) {
    const { vendor_detail, entity_role } = props;
    
    const [expanded, setExpanded] = useState(false);

    if (!vendor_detail || !vendor_detail.offered_services) {
        return null;
    }

    if (!isVendor(entity_role)) {
        return null;
    }

    const getServiceRow = (service) => {
        if (service){
            return  <div class="row mt-2">
                        <div class="col-12"><i class="fa-solid fa-chevron-right"></i> {service}</div>
                    </div>
        }
    }

    return(
        <Card>
            <Card.Body>
                <div class="container detailContainer">
                    <div class="row"><div class="col fw-bold"><i class="fa-solid fa-screwdriver-wrench"></i> &nbsp; Offered services</div></div>
                    <div class="row mt-2"><hr></hr></div>

                    {vendor_detail.offered_services.length === 0 && 
                        <div class="row mt-2">
                            <div class="col-12"> Vendor has not entered any services</div>
                        </div>
                    }

                    { vendor_detail.offered_services.length === 1 && getServiceRow(vendor_detail.offered_services[0])}

                    {
                        vendor_detail.offered_services.length > 1 &&
                        <>
                            {
                                !expanded ?
                                <>
                                    {getServiceRow(vendor_detail.offered_services[0])}
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(true)}><small>Show more</small></a>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        vendor_detail.offered_services.map(service => {
                                            return getServiceRow(service)
                                        })
                                    }
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(false)}><small>Show less</small></a>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                    

                </div>
            </Card.Body>
        </Card>
    );
}

function SmallJobs(props) {
    const { vendor_detail, entity_role } = props;
    
    const [expanded, setExpanded] = useState(false);

    if (!vendor_detail || !vendor_detail.small_jobs) {
        return null;
    }

    if (!isVendor(entity_role)) {
        return null;
    }

    const getServiceRow = (service) => {
        if (service){
            return  <div class="row mt-2">
                        <div class="col-12"><i class="fa-solid fa-chevron-right"></i> {service}</div>
                    </div>
        }
    }

    return(
        <Card>
            <Card.Body>
                <div class="container detailContainer">
                    <div class="row"><div class="col fw-bold"><i class="fa-regular fa-clock fa-lg"></i> &nbsp; Small projects</div></div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row">
                        <div class="col-auto">Accepts small projects? </div>
                        <div class="col-auto">
                            {vendor_detail.small_jobs.accept_small_jobs ?
                                <i class="fa-solid fa-check fa-lg"/>
                                :
                                <i class="fa-solid fa-xmark fa-lg"/>
                            }           
                        </div>
                    </div>
                   
                    {
                        vendor_detail.small_jobs.accept_small_jobs &&
                        <>
                            
                            {
                                !expanded ?
                                <>
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(true)}><small>Show more</small></a>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div class="row mt-2"><hr></hr></div>
                                    <div class="row mt-2">
                                        <div class="col-12 fw-bold">Typical services</div>
                                    </div>
                                    {getServiceRow(vendor_detail.small_jobs.job_type_1)}
                                    {getServiceRow(vendor_detail.small_jobs.job_type_2)}
                                    {getServiceRow(vendor_detail.small_jobs.job_type_3)}
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(false)}><small>Show less</small></a>
                                        </div>
                                    </div>
                                </>
                            }
                           
                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

function BuySellService(props) {
    const { vendor_detail, entity_role } = props;

    const [expanded, setExpanded] = useState(false);

    if (!vendor_detail || !vendor_detail.buy_sell_service) {
        return null;
    }
    
    if (!isVendor(entity_role)) {
        return null;
    }

    const getServiceRow = (job_type, price) => {

        if (job_type && price) {
            return  <div class="row mt-2">
                        <div class="col-auto"><i class="fa-solid fa-chevron-right"></i> {job_type}</div>
                        <div class="col text-end">${price}</div>
                    </div>
        } else if (job_type) {
            return  <div class="row mt-2">
                        <div class="col-auto"><i class="fa-solid fa-chevron-right"></i> {job_type}</div>
                    </div>
        }
    }

    return(
        <Card>
            <Card.Body>
                <div class="container detailContainer">
                    <div class="row"><div class="col fw-bold"><i class="fa-solid fa-magnifying-glass"></i> &nbsp; Property inspection, survey, evaluation  </div></div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row">
                        <div class="col-auto">Accepts inspections, surveys, ... ? </div>
                        <div class="col-auto">
                            {vendor_detail.buy_sell_service.accept_buy_sell_service ?
                                <i class="fa-solid fa-check fa-lg"/>
                                :
                                <i class="fa-solid fa-xmark fa-lg"/>
                            }      </div>
                    </div>
                    {
                        vendor_detail.buy_sell_service.accept_buy_sell_service &&
                        <>
                            {
                                !expanded ?
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(true)}><small>Show more</small></a>
                                        </div>
                                    </div>
                                :
                                <>
                                    <div class="row mt-2"><hr></hr></div>
                                    <div class="row mt-2">
                                        <div class="col-12 fw-bold">Services</div>
                                    </div>
                                    {getServiceRow(vendor_detail.buy_sell_service.job_type_1, vendor_detail.buy_sell_service.price_1)}
                                    {getServiceRow(vendor_detail.buy_sell_service.job_type_2, vendor_detail.buy_sell_service.price_2)}
                                    {getServiceRow(vendor_detail.buy_sell_service.job_type_3, vendor_detail.buy_sell_service.price_3)}
                                    <div class="row mt-2">
                                        <div class="col text-end">
                                            <a class="text-decoration-underline" role='button' onClick={() => setExpanded(false)}><small>Show less</small></a>
                                        </div>
                                    </div>
                                </>
                            }
                           
                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

function CredentialsDetails(props) {
    const { vendor, entity_role } = props;
    
    if (entity_role !== 'ARCHITECT' && entity_role !== 'ENGINEER' && entity_role !== 'CONTRACTOR') {
        return null;
    }

    const get_licensing_authority = () => {
        if (entity_role === 'CONTRACTOR') {
            return 'CA Contractors License Board';
        }
        else if (entity_role === 'ARCHITECT' || entity_role === 'ENGINEER') {
            return 'CA Department of Consumer Affairs';
        }
    }

    const get_years_licensed_string = () => {
        
        if (!vendor || !vendor.credentials){
            return "N/A";
        }

        if (vendor.credentials.years_licensed === 0) {
            return "< 1";
        }

        return vendor.credentials.years_licensed;
    }

    return(
        <Card>
            <Card.Body>
                <div class="container">
                    <div class="row"><div class="col fw-bold"><i class="fa-solid fa-id-card fa-lg"></i> &nbsp; Licensing information</div></div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row">
                        <div class="col-6">
                            <div class="container">
                                <div class="row">License number</div>
                                <div class="row fw-bold">{ vendor.license_number}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="container">
                                <div class="row">Years licensed</div>
                                <div class="row fw-bold"> {get_years_licensed_string()}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="container">
                                <div class="row">License classifications</div>
                                
                                <div class="row">
                                    <div class="col gx-0 fw-bold">
                                        { formatClassifications(vendor.credentials.classification_list, false).map(
                                            (classification_string) => {
                                                return <div>{classification_string}</div>
                                            }) 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2"><hr></hr></div>
                    
                    <div class="row">
                      
                        { entity_role === EntityRole.CONTRACTOR ?
                            <>
                                <div class='col-4 gx-0'>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col text-center gx-0 text-decoration-underline">
                                                <OverlayTrigger key="activeLicenseTT" placement="auto"
                                                        overlay={ 
                                                            <Popover id="activeLicenseTT">
                                                                <Popover.Header as="h3">License status &amp; expiry</Popover.Header>
                                                                <Popover.Body>
                                                                    {getLicenseToolTip(vendor.credentials, vendor.updated_at)}
                                                                </Popover.Body>
                                                            </Popover>
                                                     }>
                                                                <span>Active license</span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <div class="row mt-2"><div class="col text-center"><img src={get_credentials_icon(vendor.credentials.active_license)} class="badgeIcon"/></div></div>
                                    </div>
                                </div>
                                <div class='col-4 gx-0'>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col text-center gx-0 text-decoration-underline">
                                                <OverlayTrigger key="bondedTT" placement="auto"
                                                        overlay={ 
                                                            <Popover id="bondedTT">
                                                                <Popover.Header as="h3">Bond status</Popover.Header>
                                                                <Popover.Body>
                                                                    {getBondedToolTip(vendor.credentials.bond_status)}
                                                                </Popover.Body>
                                                            </Popover>
                                                        }>
                                                                <span>Bonded</span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                        <div class="row mt-2"><div class="col text-center"><img src={get_credentials_icon(vendor.credentials.bond_status)}  alt="covered" class="badgeIcon"/></div></div>
                                    </div>
                                </div>
                                <div class='col-4 gx-0'>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col text-center gx-0 text-nowrap text-decoration-underline">
                                                <OverlayTrigger key="workersCompTT" placement="auto"
                                                        overlay={ 
                                                            <Popover id="workersCompTT">
                                                                <Popover.Header as="h3">Workers' comp status</Popover.Header>
                                                                <Popover.Body>
                                                                    {getWkToolTip(vendor.credentials.has_active_workers_comp_coverage, vendor.credentials.workers_comp_coverage_type)}
                                                                </Popover.Body>
                                                            </Popover>}>
                                                                <span>Workers' Comp</span>
                                                </OverlayTrigger>
                                            </div> 
                                        </div>
                                        <div class="row mt-2"><div class="col text-center"><img src={get_workers_comp_icon(vendor.credentials.workers_comp_coverage_type, vendor.credentials.has_active_workers_comp_coverage)}  alt="covered" class="badgeIcon"/></div></div>
                                    </div>
                                </div>
                            </>
                            :
                            <div class='col-12 gx-0'>
                                <div class="container">
                                    <div class="row">
                                        <div class="col text-center gx-0 text-decoration-underline">
                                            <OverlayTrigger key="activeLicenseTT" placement="auto"
                                                    overlay={ <Popover id="activeLicenseTT">
                                                                <Popover.Header as="h3">License status &amp; expiry</Popover.Header>
                                                                <Popover.Body>
                                                                    {getLicenseToolTip(vendor.credentials, vendor.updated_at)}
                                                                </Popover.Body>
                                                            </Popover>}>
                                                            <span>Active license</span>
                                            </OverlayTrigger>
                                            
                                        </div>
                                    </div>
                                    <div class="row mt-2"><div class="col text-center"><img src={get_credentials_icon(vendor.credentials.active_license)} class="badgeIcon"/></div></div>
                                </div>
                            </div>
                        }           
                    </div>
                    <div class="row mt-2"><hr></hr></div>
                    <div class="row"><div class="col text-center"><small>Last updated on {vendor.updated_at} with data from {get_licensing_authority()}</small></div></div>
                </div>
            </Card.Body>
        </Card>
    );
}

function VendorBasicDetails(props) {
    const { rating, reviews, vendor_detail, entity_role, logged_in_user_role, ID, is_shared_with_contact} = props;

    const getPhoneHref = (phone_number) => { return "tel:"+ phone_number; }

    return(
            <div class="container">
                
                <div class="row">
                    <div class="col fs-4 fw-bold">{vendor_detail.entity_name}</div>
                </div>
                {vendor_detail.company_name &&
                    <div class="row">
                        <div class="col fw-bold text-secondary">{vendor_detail.company_name}</div>
                    </div>
                }
                <div class="row">
                    <div class="col fw-bold text-success">{vendor_detail.category}</div>
                </div>
                {
                    vendor_detail.role !== EntityRole.OTHER_BUSINESS &&
                    <>
                     <div class="row mt-2">
                        <div class="col-12"><img src={get_star_icon(rating)} class="detailsStarIcon"/></div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12"><a href="#reviews">{ getStringForReviews(reviews.length) }</a></div>
                    </div>
                    </>
                }
                { vendor_detail.contact_name &&
                    <div class="row mt-3">
                        <div class="col-2 d-flex align-items-center"><i class="fa-solid fa-user fa-xl" style={{color: '#6C757D'}}></i></div> 
                        <div class="col-10 gx-0">
                            <div class="text-truncate">{vendor_detail.contact_name}</div>
                        </div>
                    </div>
                }
                { vendor_detail.phone &&
                    <div class="row mt-3">
                        <div class="col-2 d-flex align-items-center"><i class="fa-solid fa-phone" style={{color: '#6C757D'}}></i></div> 
                        <div class="col-10 gx-0">
                            <div class="row">
                                <div class="col"><a className="text-decoration-none" href={getPhoneHref(vendor_detail.phone)}>{formatPhoneNumber(vendor_detail.phone)}</a></div>
                            </div>
                            <div class="row">
                                <div class="col"><a className="text-decoration-none" href={getPhoneHref(vendor_detail.phone_secondary)}>{formatPhoneNumber(vendor_detail.phone_secondary)}</a></div>
                            </div>
                        </div>
                    </div>
                }
                 {(vendor_detail.email || vendor_detail.secondary_email) &&
                    <div class="row mt-3"> 
                         <div class="col-2 d-flex align-items-center"><i class="fa-solid fa-envelope" style={{color: '#6C757D'}}></i></div> 
                        <div class="col-10 gx-0">
                            <div class="row">
                                <div class="col">{vendor_detail.email}</div>
                            </div>
                            <div class="row">
                                <div class="col">{vendor_detail.secondary_email}</div>
                            </div>
                        </div>
                    </div>
                }
                {vendor_detail.website &&
                    <div class="row mt-3"> 
                        <div class="col-2"><img src={urlLogo} class="detailsIcon"/></div> 
                        <div class="col-10 gx-0"><a href={vendor_detail.website} target="_blank">{vendor_detail.website}</a></div>        
                    </div>
                }
                { vendor_detail.address &&
                    <div class="row mt-3">
                        <div class="col-2 d-flex align-items-center"><i class="fa-solid fa-location-dot fa-xl" style={{color: '#6C757D'}}></i></div> 
                        <div class="col-10 gx-0">
                            <div class="text-truncate">{vendor_detail.address}</div>
                            <div  class="text-truncate">{ vendor_detail.city}&nbsp;{ vendor_detail.state}&nbsp;{ vendor_detail.zip_code}</div>
                        </div>
                    </div>
                }
                <div class="row mt-5">
                    <div class="col-12">
                        <AgentActionButtonSection
                            entity_role={entity_role}
                            logged_in_user_role={logged_in_user_role}
                            ID={ID}
                            vendor_detail={vendor_detail}
                        /> 

                        <ContactActionButtonSection
                            entity_role={entity_role}
                            logged_in_user_role={logged_in_user_role}
                            ID={ID}
                            vendor_detail={vendor_detail}
                            is_shared_with_contact={ is_shared_with_contact }
                        /> 
                    </div>
                </div>
                
            </div>);
}

function PlacesComponent(props) {

    return(
        <div class="places" >
            <div class="placesIcon"> <img src={permit_map_marker}/></div>
        </div>

    )
}

const MapMarker = (props) => {

    const [show_permit_details, setShowPermitDetails] = useState(false);

    const infoWindowStyle = {
        position: 'relative',
        top: '-110px',
        left: '-100px',
        width: 240,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    const infoWindowClose = {
        float: 'right',
        fontSize: 11,
    };

    const permit_details = 
    <Modal
        show={show_permit_details} 
        onHide={() => setShowPermitDetails(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton >Permit details</Modal.Header>
        <Modal.Body>
            <div class="container">
                <div class="row">
                    <div class="col fw-light text-success">Permit number</div>
                </div>
                <div class="row">
                    <div class="col">{props.permit_number}</div>
                </div>
                <div class="row mt-2">
                    <div class="col  fw-light text-success">Project categories</div>
                </div>
                <div class="row">
                    <div class="col">{props.tags.map(tag => COMBINED_TAG_MAP[tag]).join(', ')}</div>
                </div>
                <div class="row mt-2">
                    <div class="col  fw-light text-success">Property address</div>
                </div>
                <div class="row">
                    <div class="col">{props.street_address}</div>
                </div>
                <div class="row mt-2">
                    <div class="col  fw-light text-success">Completion date</div>
                </div>
                <div class="row ">
                    <div class="col">{props.completion_date}</div>
                </div>
                <div class="row mt-2">
                    <div class="col fw-light text-success">Permit verbatim</div>
                </div>
                <div class="row">
                    <div class="col ">{props.description}</div>
                </div>

            </div>
        </Modal.Body>

    </Modal>

    return (
        <>
        <div style={infoWindowStyle}>
            <div class="fw-light container gx-0">
                <div class="row" onClick={() => props.setActiveMarker(null)}>
                    <div class="col-10 fw-bold">{props.street_address}</div>
                    <div class="col-2 text-end" onClick={() => props.setActiveMarker(null)}>X</div>
                </div>
                <div class="row mt-1"><div class="col text-success">{formatPermitTags(props.tags)}</div></div>
                <div class="row mt-1"><div class="col">Completed {props.completion_date}</div></div>
                <div class="row mt-1">
                    <div class="col" >Permit # <span role="button" class="text-decoration-underline" onClick={() => setShowPermitDetails(true)}>
                                                    {props.permit_number}
                                                </span>
                </div></div>
               { props.property_value !== '0' &&
                    <div class="row mt-1"><div class="col">Property value ${props.property_value}</div></div>
               }
               { props.permit_count > 1 &&
                    <div class="row mt-2"><div class="col">Plus {props.permit_count - 1} additional permit(s)</div></div>
               }
            </div>
        </div>
        { show_permit_details && permit_details}
        </>
    );
};

function MapComponent(props) {
    const [active_marker, setActiveMarker] = useState(null);

    if (!props.places_info || !props.places_info || props.places_info.length === 0) {
        return (null);
    }
    
    const reSetActiveMarker = (marker) => {
        setActiveMarker(marker);
    }

    const onChildClick = (e, childProps) => {
        setActiveMarker(childProps);
    }

    return (
            <Card>
                <Card.Body>
                    <div class="component ">
                        <div class="row"><div class="col text-center fw-bold">Project locations, last 12 months</div></div>
                        <div class="row mt-2">
                            <div class="col-12 map">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyAyQMpPy1QPIieWCFecN0j8QGQpKTs82vk' }}
                                    defaultCenter={{
                                        lat: 37.7617669,
                                        lng: -122.4473969
                                    }}
                                    defaultZoom={12}
                                    onChildClick={ onChildClick}
                                >
                                    {
                                        props.places_info.map((place) => {
                                            return <PlacesComponent 
                                                        lat={ place.lat }
                                                        lng={ place.lng }
                                                        street_address={ place.streetAddress }
                                                        completionData={ place.completionData }
                                                        tags={ place.tags }
                                                        property_value= {place.property_value}
                                                        permit_count={place.permit_count}
                                                        permit_number={place.permit_number}
                                                        description={place.description}

                                                    />
                                                    
                                        })
                                    }
                                    { active_marker &&
                                        <MapMarker
                                            description={active_marker.description}
                                            permit_number={active_marker.permit_number}
                                            street_address={ active_marker.street_address }
                                            completion_date={ active_marker.completionData }
                                            property_value= {active_marker.property_value}
                                            permit_count={active_marker.permit_count}
                                            tags={ active_marker.tags }
                                            lat={ active_marker.lat }
                                            lng={ active_marker.lng }
                                            setActiveMarker={reSetActiveMarker}
                                        /> 
                                    }
                                   
                                </GoogleMapReact>
                            </div>
                        </div>
                        <div class="row mt-1"><div class="col"><small>*Click on each location for more info</small></div></div>
                    </div>
                 </Card.Body>
             </Card>
    )
}

function ReviewSection(props) {

    const {reviews} = props;

    const [show_verified_review_description, setShowVerifiedReviewDescription] = useState(false);

    return(
        <div class='container reviewSection'>
            <div class="row">
                <div class="col fw-bold fs-5" id="reviews">Verified reviews</div>
            </div>
            <div class="row"><div class="col"><hr></hr></div></div>
            <Card className="bg-light mb-4">
                <Card.Body>
                    <Card.Text>
                        <div class="col">
                            Reviews on Renolition are like no other service. Only homeowners who hired this
                            vendor for a <i>permitted job</i> get access to leave a review. Now you can trust each review.&nbsp;
                            <span class="text-decoration-underline" role="button" onClick={() => setShowVerifiedReviewDescription(true)}><i>Read more</i></span>
                        </div>
                        <VerifiedReviewDescription
                            show_verified_review_description={show_verified_review_description}
                            setShowVerifiedReviewDescription={setShowVerifiedReviewDescription}
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
            {
                reviews.length === 0 ?
                    <div class="row "><div class="col"><i>Vendor has not received any verified reviews</i></div></div>
                :
                <>
                {
                    reviews.map((review) => {
                        return <Review review={review} />
                    })
                }
                </>
            }
           
        </div>
    );
}

function Review(props) {

    const [clickedIndex, setClickedIndex] = useState(null);
    const [show_photo_modal, setShowPhotoModal] = useState(false);
    const {review} = props;

    return(
        <div class="container mt-3 gx-0">
            <div class="row gy-2">
                <div class="col-12 col-md-6">Project dates: <span class="fw-bold">{review.permit_start_date} - {review.permit_end_date}</span></div>
                <div class="col-12 col-md-6">
                    <div class="container">
                        <div class="row">
                            <div class="col gx-0">Recommended by this homeowner</div>
                            <div class="col">{review.would_recommend ?     
                                                        <img src={thumb_up}/> : 
                                                        <img src={thumb_down}/>
                                                    }
                            </div>
                        </div>
                    </div>
                </div>                       
            </div>
            <div class="row mt-2 gy-2">
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Property address</div>
                        <div class="row"><div class="fw-bold gx-0">{review.property_street}, {review.property_city}</div></div>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Work categories</div>
                        <div class="row"><div class="fw-bold gx-0">{formatPermitTags(review.permit_tags, COMBINED_TAG_MAP)}</div></div>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Permit number</div>
                        <div class="row"><div class="fw-bold gx-0">{review.permit_number}</div></div>
                    </div>
                </div>
            </div>
                                                    
            <div class="row mt-2 gy-2">
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Quality of work</div>
                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(review.quality_score)} class="reviewStars"/></div></div>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Timeliness</div>
                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(review.punctual_score)} class="reviewStars" /></div></div>
                    </div>
                </div>
                <div class="col-6 col-md-4">
                    <div class="container">
                        <div class="row">Communication</div>
                        <div class="row"><div class="col text-start gx-0"><img src={get_star_icon(review.communication_score)} class="reviewStars"/></div></div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">{review.comment}</div>
            </div>
            {review.photos && review.photos.length > 0 &&
                <div class="row mt-5">
                    {
                        review.photos.map(
                            (photo, index) => {
                                return <div className="col-4 col-md-2 gx-1" role="button" onClick={ () => {setClickedIndex(index); setShowPhotoModal(true)} }>
                                    <img src={ photo.thumb_nail_path }/>
                                </div>
                            }
                        )
                    }
                    <PhotoPopup 
                        clickedIndex={ clickedIndex } 
                        photos={review.photos}
                        show_photo_modal={show_photo_modal}
                        setShowPhotoModal={setShowPhotoModal}
                    />

                </div>  
            }           

            <div class="row mt-2">
                <div class="col"><hr></hr></div>
            </div>
        </div>
    )
}

function PhotoPopup(props) {
    const { clickedIndex, photos, show_photo_modal, setShowPhotoModal } = props;

    if (!show_photo_modal) { return null; }
    
    return(
        <Modal
            show={show_photo_modal} 
            onHide={() => setShowPhotoModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="reviewPhotoModal"
        >
            <Modal.Header closeButton >Project photos from homeowner</Modal.Header>
            <Modal.Body>
                <Carousel 
                    slide={false}
                    interval={null}
                    variant="dark"
                    defaultActiveIndex={clickedIndex}
                >
                    {photos.map(
                        (photo) =>{
                            return   <Carousel.Item>
                                        <div class="text-center" >
                                            <img src={photo.path} alt="First slide" class="reviewPhoto"/>
                                        </div>
                                    </Carousel.Item>
                        }
                    )}
                </Carousel>
            </Modal.Body>
        </Modal>
    )
}

function PermitHistorySection(props) {
    const {permit_info, places_info} = props;

    const [currentPermitDisplay, setCurrentPermitDisplay] = useState(PermitDisplayOption.ONE_YEAR);
    const [show_project_glossary_dialog, setShowProjectGlossaryDialog] = useState(false);
    const [permits_truncated, setPermitsTruncated] = useState(false);
    const [show_full_permit_view, setShowFullPermitView] = useState(false);

    const getEffectiveMap = () => {
        let max_rows = 10;
        let rows_per_section = 3;

        let building = [];
        let plumbing = [];
        let electrical = [];
        let building_untruncated = [];
        let plumbing_untruncated = [];
        let electrical_untruncated = [];

        if (!permit_info) {

            if (permits_truncated) {
                setPermitsTruncated(false);
            }

            return {
                building: building,
                plumbing: plumbing,
                electrical: electrical,
                building_untruncated: building_untruncated,
                plumbing_untruncated: plumbing_untruncated,
                electrical_untruncated: electrical_untruncated,
            }
        }

        if (currentPermitDisplay === PermitDisplayOption.ONE_YEAR) {
            building = permit_info.building_tag_map_one_year;
            plumbing = permit_info.plumbing_tag_map_one_year;
            electrical = permit_info.electrical_tag_map_one_year;
            building_untruncated = permit_info.building_tag_map_one_year;
            plumbing_untruncated = permit_info.plumbing_tag_map_one_year;
            electrical_untruncated = permit_info.electrical_tag_map_one_year;
        }
        else {
            building = permit_info.building_tag_map_total;
            plumbing = permit_info.plumbing_tag_map_total;
            electrical = permit_info.electrical_tag_map_total;
            building_untruncated = permit_info.building_tag_map_total;
            plumbing_untruncated = permit_info.plumbing_tag_map_total;
            electrical_untruncated = permit_info.electrical_tag_map_total;
        }
        
        let current_rows = building.length + plumbing.length + electrical.length;
        

        if (current_rows > max_rows) {
            if ((building.length === 0 && plumbing.length === 0) || 
                    (building.length === 0 && electrical.length === 0) ||
                    (plumbing.length === 0 && electrical.length === 0)) 
            {
                rows_per_section = 10; 
            }
            else if (building.length === 0 || plumbing.length === 0 || electrical.length === 0) {
                rows_per_section = 5; 
            }


            if (building.length > rows_per_section) {
                building = building.slice(0, rows_per_section)
            }
            if (plumbing.length > rows_per_section) {
                plumbing = plumbing.slice(0, rows_per_section)
            }
            if (electrical.length > rows_per_section) {
                electrical = electrical.slice(0, rows_per_section)
            }
            if (!permits_truncated) {
                setPermitsTruncated(true);
            }
        }
        else {
            if (permits_truncated) {
                setPermitsTruncated(false);
            }
        }

        return {
            building: building,
            plumbing: plumbing,
            electrical: electrical,

            building_untruncated: building_untruncated,
            plumbing_untruncated: plumbing_untruncated,
            electrical_untruncated: electrical_untruncated,
        }
    }

    // does this vendor have any permits to display for the last 12 months or total
    const hasNoPermits = () => {

        if (!permit_info) { return true; };

        return permit_info.electrical_tag_map_total.length === 0 && permit_info.plumbing_tag_map_total.length === 0 && permit_info.building_tag_map_total.length === 0 &&
        permit_info.building_tag_map_one_year.length === 0 && permit_info.plumbing_tag_map_one_year.length === 0 && permit_info.electrical_tag_map_one_year.length === 0;
    }

    return(
        <div class="container PermitHistorySection">
            <div class="row">
                <div class="col fw-bold fs-5">Past permitted projects&nbsp;
                    
                        <OverlayTrigger key="permittedProjectTT" placement="auto"
                            overlay={ <Popover id="permittedProjectTT">
                                        <Popover.Header as="h3">Past project permit history</Popover.Header>
                                        <Popover.Body>
                                            Number of permitted projects that this vendor has completed in each category.
                                            Toggle between last 12 months and the entire license history of the vendor.
                                            Data source: San Francisco Department of Building Inspection. 
                                        </Popover.Body>
                                    </Popover>
                               }>
                                <span class=" fs-6 fw-light text-decoration-underline">What is this?</span>
                        </OverlayTrigger>
                </div>
            </div>
            <div class="row"><div class="col"><hr></hr></div></div>
            {
                hasNoPermits() ? <div class="row"><div class="col"><i>No San Francisco permit history to display</i></div></div> 
                :
                <div class="row gy-4">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">
                                <select class="form-select" onChange={(e) => setCurrentPermitDisplay(e.target.value)}>
                                    <option value={PermitDisplayOption.ONE_YEAR}>Last 12 months</option>
                                    <option value={PermitDisplayOption.TOTAL}>Total</option>
                                </select>
                            </div>

                            {permit_info && permit_info.average_cost && permit_info.average_cost !== '0' &&
                                <div class="row mt-2">
                                    <div class="col-8"> Average job cost</div>
                                    <div class="col-4 fw-bold"> ${ permit_info.average_cost}</div>
                                </div>
                            }

                            {permit_info && permit_info.average_property_price !== '0' && permit_info.highest_property_price !== '0' &&
                                <>
                                    <div class="row mt-2">
                                        <div class="col-8"> Average property value</div>
                                        <div class="col-4 fw-bold"> ${ permit_info.average_property_price}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-8"> Highest property value</div>
                                        <div class="col-4 fw-bold"> ${ permit_info.highest_property_price}</div>
                                    </div>
                                
                                </>
                            }

                            { getEffectiveMap().building.length > 0 &&
                                <>
                                    <div class="row mt-3 mb-2">
                                        <div class="col-auto"><img src={brick_wall} class="permitHeadingIcon"/></div>
                                        <div class="col-auto fw-bold gx-0">Building permits</div>
                                    </div>
                                    {
                                        getEffectiveMap().building.map( 
                                            (entry) => {
                                                return (
                                                    <div class="row gx-0">
                                                        <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                        <div class="col-4">{ entry[1]} </div>
                                                    </div>
                                                );
                                                }
                                            )
                                    }
                                </>
                            }
                            { getEffectiveMap().plumbing.length > 0 &&
                                <>
                                    <div class="row mt-3 mb-2">
                                        <div class="col-auto"><img src={pipes} class="permitHeadingIcon" /></div>
                                        <div class="col-auto fw-bold gx-0">Plumbing permits</div>
                                    </div>
                                    
                                    {
                                        getEffectiveMap().plumbing.map( 
                                            (entry) => {
                                                return (
                                                    <div class="row gx-0">
                                                        <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                        <div class="col-4">{ entry[1]} </div>
                                                    </div>
                                                );
                                                }
                                            )
                                    }
                                </>
                            }
                            { getEffectiveMap().electrical.length > 0 &&
                                <>
                                    <div class="row mt-3 mb-2">
                                        <div class="col-auto"><img src={socket} class="permitHeadingIcon" /></div>
                                        <div class="col-auto fw-bold gx-0">Electrical permits</div>
                                    </div>

                                    {
                                        getEffectiveMap().electrical.map( 
                                            (entry) => {
                                                return (
                                                    <div class="row gx-0">
                                                        <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                        <div class="col-4">{ entry[1]} </div>
                                                    </div>
                                                );
                                                }
                                            )
                                    }
                                </>
                            }

                            { permits_truncated &&
                                <div class="row mt-3">
                                    <div class="col-12"><a class="text-underline" role="button" onClick={() => setShowFullPermitView(true)}>Display all</a></div>
                                </div> 
                                
                            }

                            {
                                (getEffectiveMap().electrical.length > 0 || getEffectiveMap().building.length > 0 || getEffectiveMap().plumbing.length > 0) && 
                                
                                // no need to show project definitions if there are no permits to display
                                <div class="row mt-3">
                                    <div class="col-12 fw-bold">
                                        <span onClick={() => setShowProjectGlossaryDialog(true)} role="button" class=" fs-6 fw-light text-decoration-underline">Open project definitions</span>
                                        <ProjectGlossaryDialog
                                            setShowProjectGlossaryDialog={ setShowProjectGlossaryDialog }
                                            show_project_glossary_dialog={ show_project_glossary_dialog }
                                            getEffectiveMap={ getEffectiveMap }
                                        />
                                    </div>
                                </div>
                            }
                        

                        <Modal 
                            show={show_full_permit_view} 
                            onHide={() => setShowFullPermitView(false)}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className="fullViewPermitsModal"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Full permit view</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="container">
                                    { getEffectiveMap().building_untruncated.length > 0 &&
                                        <>
                                            <div class="row mt-3 mb-2">
                                                <div class="col-auto"><img src={brick_wall} class="permitHeadingIcon"/></div>
                                                <div class="col-auto fw-bold gx-0">Building permits</div>
                                            </div>
                                            {
                                                getEffectiveMap().building_untruncated.map( 
                                                    (entry) => {
                                                        return (
                                                            <div class="row gx-0">
                                                                <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                                <div class="col-4">{ entry[1]} </div>
                                                            </div>
                                                        );
                                                        }
                                                    )
                                            }
                                        </>
                                    }
                                    { getEffectiveMap().plumbing_untruncated.length > 0 &&
                                        <>
                                            <div class="row mt-3 mb-2">
                                                <div class="col-auto"><img src={pipes} class="permitHeadingIcon" /></div>
                                                <div class="col-auto fw-bold gx-0">Plumbing permits</div>
                                            </div>
                                            
                                            {
                                                getEffectiveMap().plumbing_untruncated.map( 
                                                    (entry) => {
                                                        return (
                                                            <div class="row gx-0">
                                                                <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                                <div class="col-4">{ entry[1]} </div>
                                                            </div>
                                                        );
                                                        }
                                                    )
                                            }
                                        </>
                                    }
                                    { getEffectiveMap().electrical_untruncated.length > 0 &&
                                        <>
                                            <div class="row mt-3 mb-2">
                                                <div class="col-auto"><img src={socket} class="permitHeadingIcon" /></div>
                                                <div class="col-auto fw-bold gx-0">Electrical permits</div>
                                            </div>

                                            {
                                                getEffectiveMap().electrical_untruncated.map( 
                                                    (entry) => {
                                                        return (
                                                            <div class="row gx-0">
                                                                <div class="col-8">  {COMBINED_TAG_MAP[entry[0]]} </div>
                                                                <div class="col-4">{ entry[1]} </div>
                                                            </div>
                                                        );
                                                        }
                                                    )
                                            }
                                        </>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>

                        </div>

                    </div>
                    <div class="col-12 col-md-6">
                        <MapComponent 
                            places_info={ places_info }
                        />
                    </div>
                </div>
            }
        </div>
    );
}


export function VerifiedReviewDescription(props) {
    const {show_verified_review_description, setShowVerifiedReviewDescription } = props;
    
    if (!show_verified_review_description){
        return null;
    }

    return (

        <Modal 
            show={show_verified_review_description} 
            onHide={() => setShowVerifiedReviewDescription(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>A New Approach to Reviews</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                       <div class="row">
                            <div class="fw-bold gx-0">Renolition takes reviews seriously </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col  gx-0">
                                <p>You can trust every single review knowing that </p>
                                <p>1) A real, permitted job was completed;</p> 
                                <p>2) The homeowner, who hired for the job, left the review;</p>
                                <p>3) The project description and kind of work are available to you.</p>
                              
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col gx-0">
                                Every review is tied to a <strong>verified job</strong> that was permitted by the city's building department, and performed by a licensed vendor. 
                          </div>
                        </div>
                        
                        <div class="row mt-3">
                            <div class="col gx-0 ">
                                To start a vendor review, Renolition mails a <strong>unique review code</strong> to the homeowner's physical address 
                                once we verify that the job is completed or canceled. Homeowners must use the unique code to start a review.
                            </div>
                        </div>
                       
                    </div>
            </Modal.Body>
        </Modal>
    );
}

function ProjectGlossaryDialog(props) {
    const {show_project_glossary_dialog, setShowProjectGlossaryDialog, getEffectiveMap } = props;
    
    if (!show_project_glossary_dialog){
        return null;
    }

    return (

        <Modal 
            show={show_project_glossary_dialog} 
            onHide={() => setShowProjectGlossaryDialog(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Glossary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                        {getEffectiveMap().building.length > 0 &&
                            <div class="row mb-2"><div class="col-12 text-primary fw-bold">Building permits</div></div>
                        }
                        { getEffectiveMap().building.map(
                            (entry) => {
                                return (
                                    <div class="row mb-3">
                                        <div class="col-4 fw-bold"> {COMBINED_TAG_MAP[entry[0]]} </div> 
                                        <div class="col-8">{ COMBINED_TAG_MAP_TOOL_TIP[entry[0]] } </div>
                                    </div>
                                );
                            })
                        }
                        {getEffectiveMap().plumbing.length > 0 &&
                            <div class="row mb-2"><div class="col-12 text-primary fw-bold">Plumbing permits</div></div>
                        }
                        { getEffectiveMap().plumbing.map(
                            (entry) => {
                                return (
                                    <div class="row mb-3">
                                        <div class="col-4 fw-bold">  {COMBINED_TAG_MAP[entry[0]]} </div> 
                                        <div class="col-8">{ COMBINED_TAG_MAP_TOOL_TIP[entry[0]] } </div>
                                    </div>
                                );
                            })
                        }
                         {getEffectiveMap().electrical.length > 0 &&
                            <div class="row mb-2"><div class="col-12 text-primary fw-bold">Electrical permits</div></div>
                        }
                        { getEffectiveMap().electrical.map(
                            (entry) => {
                                return (
                                    <div class="row mb-3">
                                        <div class="col-4 fw-bold">  {COMBINED_TAG_MAP[entry[0]]} </div> 
                                        <div class="col-8">{ COMBINED_TAG_MAP_TOOL_TIP[entry[0]] } </div>
                                    </div>
                                );
                            })
                        }
                    </div>
            </Modal.Body>
        </Modal>
    );
}

export default VendorDetail;