import React, { Component, useEffect } from "react";

import { trackEvent, EventNames } from 'utils/mixpanel';
import VendorComponent from "./vendor_component";
import { generic_get_api, generic_put_api, generic_post_api, generic_delete_api } from "api/generic_api";
import { Button, Dropdown, ListGroup, Spinner, Modal, Card, Popover, OverlayTrigger, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { isValidEmail } from "utils/helpers";
import { OtherVendorModal } from "./vendor_search";
import { ExistingVendorModal } from "./vendor_search";

import {
    AGENT_FAVORITE_VENDORS_URL,
    AGENT_CONTACTS_URL,
    AGENT_CONTACT_ACCESS_URL,
    AGENT_CONTACT_ACCESS_EMAIL_URL,
    AGENT_FAVORITE_VENDOR_URL,
} from 'utils/constants';
import { useState } from "react";

export const FavoriteModals = {
    show_delete_modal: 'show_delete_modal',
    show_info_modal: 'show_info_modal',
    show_existing_vendor_modal: 'show_existing_vendor_modal',
    show_other_vendor_modal: 'show_other_vendor_modal',
}

export const VendorModalModes = {
    NEW_VENDOR: 'NEW_VENDOR',
    UPDATE_VENDOR: 'UPDATE_VENDOR',
    ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
}

const SortColumn = {
    entity_name: 'entity_name',
    company_name: 'company_name',
    category: 'category',
    visibility: 'visibility',
}

class AgentFavoriteVendorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searching: false,
            vendors: [],
            filtered_vendors: [],
            selected_vendors: {},
            show_share_vendor_modal: false,
            selected_vendor: null,
            show_delete_modal: false,
            show_info_modal: false,
            show_edit_modal: false,
            show_learn_more_modal: false,
            show_other_vendor_modal: false,
            show_existing_vendor_modal: false,
            filter_term: '',
            sort_column: SortColumn.entity_name,
        };

        this.fetchFavoriteVendorList = this.fetchFavoriteVendorList.bind(this);
        this.fetchAgentContacts = this.fetchAgentContacts.bind(this);
        this.toggleVendorSelection = this.toggleVendorSelection.bind(this);
        this.showShareVendorModal = this.showShareVendorModal.bind(this);
        this.isVendorSelected = this.isVendorSelected.bind(this);
        this.canShare = this.canShare.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.areAllVendorsSelected = this.areAllVendorsSelected.bind(this);
        this.setSelectedVendor = this.setSelectedVendor.bind(this);
        this.setModalVisibility = this.setModalVisibility.bind(this);
        this.filterVendorsBoxChanged = this.filterVendorsBoxChanged.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);

        this.refreshParent = this.refreshParent.bind(this);
    }

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    filterVendorsBoxChanged(e) {
        let vendors = this.state.vendors;
        let output = [];

        if (e.target.value === '') {
            output = vendors;
        } else {
            let term = e.target.value.toLowerCase();

            for (let i = 0; i < vendors.length; i++) {
                let vendor = vendors[i];

                if (vendor.entity_name && vendor.entity_name.toLowerCase().search(term) >= 0) {
                    output.push(vendor);
                }
                else if (vendor.company_name && vendor.company_name.toLowerCase().search(term) >= 0) {
                    output.push(vendor);
                }
                else if (vendor.contact_name && vendor.contact_name.toLowerCase().search(term) >= 0) {
                    output.push(vendor);
                }
                else if (vendor.category && vendor.category.toLowerCase().search(term) >= 0) {
                    output.push(vendor);
                }
                else {

                    for (let j = 0; j < vendor.tags.length; j++) {
                        let tag = vendor.tags[j].toLowerCase();
                        if (tag && tag.search(term) >= 0) {
                            output.push(vendor);
                        }
                    }
                }
            }
        }

        this.setState({ filtered_vendors: output, filter_term: e.target.value });
    }

    navigateToVendorDetail(vendor_id, role) {
        let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
        this.props.history.push(details_url);
    }

    setModalVisibility(modal, is_visible, modal_mode=null) {
        this.setState({ [modal]: is_visible, modal_mode: modal_mode });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, { 'data_1': 'favorite_vendor_list' })
        document.title = "My vendors. Share and add to it.";
        this.fetchFavoriteVendorList();
        this.fetchAgentContacts();
    }

    refreshParent() {
        this.fetchFavoriteVendorList();
        this.fetchAgentContacts();
    }

    setSelectedVendor(vendor) {
        this.setState({ selected_vendor: vendor });
    }

    selectAll(checked_value) {
        let temp_checked_vendors = this.state.selected_vendors;

        for (let i = 0; i < this.state.vendors.length; i++) {
            let vendor = this.state.vendors[i];
            temp_checked_vendors[vendor.ID] = checked_value;
        }

        this.setState({ selected_vendors: temp_checked_vendors });
    }

    canShare() {
        for (const [key, value] of Object.entries(this.state.selected_vendors)) {
            if (this.state.selected_vendors[key] === true) {
                return true;
            }
        }
        return false;
    }

    showShareVendorModal(value) {
        this.setState({ show_share_vendor_modal: value });
    }

    toggleVendorSelection(vendor, is_selected) {
        let temp_selected_vendors = this.state.selected_vendors;
        temp_selected_vendors[vendor.ID] = is_selected;

        this.setState({ selected_vendors: temp_selected_vendors });
    }

    areAllVendorsSelected() {

        if (this.state.vendors.length === 0) {
            return false;
        }

        for (let i = 0; i < this.state.vendors.length; i++) {
            let vendor_id = this.state.vendors[i].ID;
            if (!this.state.selected_vendors[vendor_id]) {
                return false;
            }
        }

        return true;
    }

    isVendorSelected(vendor) {
        return this.state.selected_vendors[vendor.ID];
    }

    fetchAgentContacts() {
        let params = { archived_contacts: false }

        generic_get_api(AGENT_CONTACTS_URL, params)
            .then(
                data => {
                    if (data) {
                        this.setState({
                            contacts: data.contacts,
                        });
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    fetchFavoriteVendorList() {

        this.setState({ searching: true });

        var request = {}

        generic_get_api(AGENT_FAVORITE_VENDORS_URL, request)
            .then(
                data => {
                    window.scrollTo(0, 0);

                    this.setState({
                        vendors: data,
                        filtered_vendors: data,
                        filter_term: '',  // clear filter terms each load
                        searching: false,
                    });
                    this.selectAll(false);
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } else {
                    this.setState({ searching: false });
                }
            });
    }

    render() {
        const { filtered_vendors, vendors, filter_term } = this.state;

        const getSortClassName = (column) => {
            if (this.state.sort_column === column) {
                return 'boldAndUnderLine text-dark';
            } else {
                return 'underLine text-dark';
            }
        }

        const sortClick = (column) => {
            
            let current_vendors = vendors;

            if (column === SortColumn.visibility) {
                current_vendors.sort(function(a, b) { 

                    if (a.hidden && !b.hidden) {
                        return -1;
                    }
                    else if (!a.hidden && b.hidden) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
            }
            else {
                current_vendors.sort(function(a, b) {
                    let  a_value = "";
                    let b_value = "";
                    
                    // this is necessary because for the contact name/company column we may display either of the two fields
                    if (column === SortColumn.company_name) {
                        if (a.company_name){
                            a_value = a.company_name;
                        } else if (a.contact_name){
                            a_value = a.contact_name;
                        } 
    
                        if (b.company_name){
                            b_value = b.company_name;
                        } else if (b.contact_name){
                            b_value = b.contact_name;
                        } 
                    } else {
                        a_value = a[column];
                        b_value = b[column];
                    }
                    
                    if (a_value === "") { return 1; }
                    if (b_value === "") { return -1; }

                    if ( a_value < b_value ){
                        return -1;
                    }
                    if ( a_value > b_value ){
                        return 1;
                    }
                      return 0;
                });
            }
            
            this.setState({ sort_column: column, vendors: current_vendors });
        }

        return (
            <div class="favoriteVendorListEnvelope">
                <div class="favoriteVendorListPage">

                    <div class="container favoriteVendorListHeader">
                        <div class="row"> <div class="col display-6">My vendors</div> </div>
                        <div class="row mt-4">
                            <div class="col">Your private list of trusted vendors, organized in one place.&nbsp;
                                <a class="text-decoration-underline" role="button" onClick={() => this.setState({ show_learn_more_modal: true })}>Learn more</a>
                            </div>
                        </div>
                        <LearnMoreModal
                            show_learn_more_modal={this.state.show_learn_more_modal}
                            hideLearnMoreModal={this.hideLearnMoreModal}
                        />
                        
                        {
                            // Only show action buttons and search bar if there are vendors. Otherwise, we are showing the larger action buttons below
                            vendors.length !== 0 && 
                            <>
                                <div class="row mt-2 gx-2 gy-3">
                                    <Card  bg="light" >
                                        <Card.Body className="p-2 pb-3">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col fs-5 text-center">Keep building your vendor list</div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col text-center">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                <i class="fa-solid fa-plus"></i>&nbsp;&nbsp;Add a new vendor
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => this.props.history.push('/agent/vendor_search')}>Lookup vendor by name</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => this.props.history.push('/agent/vendor_discover')}>Find a new vendor</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>


                                <div class="row mt-4 gx-2 gy-3">
                                    {
                                        vendors && vendors.length > 15 &&
                                        <div class="col-12 col-md-6 order-md-1 order-3">
                                            <input
                                                type="search"
                                                class="form-control"
                                                placeHolder="Search my vendors by name, tags or category"
                                                value={filter_term}
                                                onChange={(e) => this.filterVendorsBoxChanged(e)}
                                            />
                                        </div>
                                    }

                                    <div class="col-auto  order-md-3  order-2">
                                        <Button variant="outline-primary" onClick={() => this.showShareVendorModal(true)} disabled={!this.canShare()}>
                                            <i class="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;Share
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        
                    </div>
                </div>

                <div class="container favoritesResultContainer mt-4">
                    { this.state.searching ?
                        <div class="row mt-4">
                            <div class="text-center"><Spinner animation="border" role="status" />Loading ...</div>
                        </div>
                        :
                        <>
                            {   
                                // there are no vendors
                                vendors.length === 0 && filtered_vendors.length === 0 && 
                                <>
                                    <div class="row">
                                        <div class="col fs-4 text-center">
                                            Let's start building your vendor list
                                        </div>
                                    </div>
                                    <div class="row mt-2 gy-4">
                                        <div class="col-12 col-md-6">
                                            <Card  bg="light" >
                                                <Card.Body>
                                                    <Card.Text className="indexCardText">
                                                        <div class="row fs-5"><div class="col">I already know a vendor to add</div></div>
                                                        <div class="row mt-1"><div class="col"><small>Build your list from vendors you already know.</small></div></div>
                                                    </Card.Text>
                                                    <div class="container mt-3">
                                                        <div class="row justify-content-center">
                                                            <div class="col-auto gx-1">
                                                                <Button variant="primary" onClick={ () => this.props.history.push('/agent/vendor_search') }><i class="fa-solid fa-plus"></i> Add a vendor</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <Card  bg="light" >
                                                <Card.Body>
                                                    {/* <Card.Title className="fs-5"></Card.Title> */}
                                                    <Card.Text className="indexCardText">
                                                        <div class="row fs-5"><div class="col">I want to discover new vendors</div></div>
                                                        <div class="row mt-1"><div class="col"><small>Search for new vendors in our vast database.</small></div></div>
                                                    </Card.Text>
                                                    <div class="container mt-3">
                                                        <div class="row justify-content-center">
                                                            <div class="col-auto gx-1">
                                                                <Button variant="primary" onClick={ () => this.props.history.push('/agent/vendor_discover') }><i class="fa-solid fa-magnifying-glass"></i> Discover new vendors</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                // there are vendors but none after applying filters
                                vendors.length !== 0 && filtered_vendors.length === 0 && 
                                    <div class="row mt-5">
                                        <div class="">No vendors match your search criteria.</div>
                                    </div>
                            }
                            {
                                // there are vendors to display
                                filtered_vendors.length !== 0 &&
                                    <>
                                        <div class="row mt-2"><div class="col"><small>Showing 1-{filtered_vendors.length} of {vendors.length} favorites</small></div></div>
                                        <ListGroup className="mt-1">
                                            <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                                <div class="container header">
                                                    <div class="row align-items-center">
                                                        <div class="col-auto gx-3">
                                                            <input
                                                                class="form-check-input vendorComponentCheckbox"
                                                                type="checkbox"
                                                                value=""
                                                                onChange={(e) => this.selectAll(e.currentTarget.checked)}
                                                                checked={this.areAllVendorsSelected()}
                                                            />
                                                        </div>
                                                        <div class="col-4 text-truncate hideMobile"><small>
                                                            <a role="button" className={getSortClassName(SortColumn.entity_name)} onClick={() => sortClick(SortColumn.entity_name)}>Vendor</a>
                                                            <br></br>
                                                            <a role="button" className={getSortClassName(SortColumn.company_name)} onClick={() => sortClick(SortColumn.company_name)}>Contact / Company</a>
                                                            </small>
                                                        </div>
                                                        <div class="col-auto text-truncate reviews hideMobile"><small>Reviews</small></div>
                                                        <div class="col-2 gx-0 medium-container hideMobile"><small>
                                                            <a role="button" className={getSortClassName(SortColumn.category)} onClick={() => sortClick(SortColumn.category)}>Category</a>
                                                            </small></div>
                                                        <div class="col-auto gx-0 medium-container phone hideMobile "><small>Phone</small></div>
                                                        <div class="col-2 gx-0 small-container hideMobile"><small>Category <br></br> Phone</small></div>
                                                        <div class="col hideMobile">
                                                            <div class="row">
                                                                <div class="col gx-0 text-end">&nbsp;</div>
                                                                <div class="col-auto gx-0 text-end">
                                                                    <Button variant="link"><i class="fa-solid fa-sort-down fa-lg" onClick={() => sortClick(SortColumn.visibility)}></i></Button>
                                                                </div>
                                                                <div class="col-auto gx-3 text-end">
                                                                    <Button variant="link" disabled></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>

                                            {
                                                filtered_vendors.map(
                                                    (vendor) => {
                                                        return <VendorComponent
                                                            vendor={vendor}
                                                            display_mode='favorites'
                                                            is_checked={this.isVendorSelected(vendor)}
                                                            toggleVendorSelection={this.toggleVendorSelection}
                                                            setSelectedVendor={this.setSelectedVendor}
                                                            setModalVisibility={this.setModalVisibility}
                                                            navigateToVendorDetail={this.navigateToVendorDetail}
                                                        />
                                                    }
                                                )
                                            }
                                        </ListGroup>
                                    </>
                            }
                        </>
                    }

                    <ShareVendorModal
                        selected_vendors={this.state.selected_vendors}
                        vendors={this.state.vendors}
                        showShareVendorModal={this.showShareVendorModal}
                        show_share_vendor_modal={this.state.show_share_vendor_modal}
                        contacts={this.state.contacts}
                        selectAll={this.selectAll}
                        fetchAgentContacts={this.fetchAgentContacts}
                    />
                    <DeleteFromFavoritesModal
                        vendor={this.state.selected_vendor}
                        show_delete_modal={this.state.show_delete_modal}
                        setModalVisibility={this.setModalVisibility}
                        fetchFavoriteVendorList={this.fetchFavoriteVendorList}
                    />
                    <VendorInfoModal
                        vendor={this.state.selected_vendor}
                        show_info_modal={this.state.show_info_modal}
                        setModalVisibility={this.setModalVisibility}
                        setSelectedVendor={this.setSelectedVendor}
                    />
                    <OtherVendorModal
                        vendor={ this.state.selected_vendor }
                        show_other_vendor_modal={this.state.show_other_vendor_modal}
                        setModalVisibility={this.setModalVisibility}
                        setSelectedVendor={ this.setSelectedVendor }
                        modal_mode={this.state.modal_mode}
                        refreshParent={this.refreshParent}
                    />
                    <ExistingVendorModal
                        vendor={ this.state.selected_vendor }
                        setSelectedVendor={ this.setSelectedVendor }
                        setModalVisibility={this.setModalVisibility}
                        show_existing_vendor_modal={ this.state.show_existing_vendor_modal}
                        refreshParent={this.refreshParent}
                        modal_mode={this.state.modal_mode}
                    />  
                </div>
            </div>
        )
    }
}

function VendorInfoModal(props) {
    const { vendor, show_info_modal, setModalVisibility, setSelectedVendor } = props;

    const [vendor_info, setVendorInfo] = useState(null);

    useEffect(() => {

        // 'vendor' is shared between modals. Must set also 'show_info_modal' otherwise, this api call is execute
        if (vendor && show_info_modal) {
            let request = {
                vendor_id: vendor.ID,
                role: vendor.role,
                include_connection_details: true,
                include_favorite_details: true,
            }
            generic_get_api(AGENT_FAVORITE_VENDOR_URL, request)
                .then(
                    (result) => {
                        setVendorInfo(result);
                    }
                )
                .catch(error => {
                    if (error?.name === 'LOGIN_ERROR') {
                        this.props.history.push('/login');
                    }
                });
        }

    }, [vendor]);

    if (!vendor) { return null; }

    const closeModal = () => {

        setVendorInfo(null);
        setSelectedVendor(null);
        setModalVisibility(FavoriteModals.show_info_modal, false, null);
    }

    const displayTitle = () => {
        if (vendor.entity_name && vendor.company_name) {
            return vendor.entity_name + " (" + vendor.company_name + ")";
        }
        else if (vendor.entity_name && vendor.contact_name) {
            return vendor.entity_name + " (" + vendor.contact_name + ")";
        } else {
            return vendor.entity_name;
        }
    }

    return (
        <Modal
            show={show_info_modal}
            onHide={closeModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className="h5 text-truncate">{displayTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {
                    vendor_info === null ?
                        <div class="row">
                            <div class="col text-center">
                                <Spinner animation="border" role="status" size="lg" />
                            </div>
                        </div>
                        :
                        <div class="container">
                            <div class="row">
                                <div class="col-auto gx-0"><i class="fa-solid fa-tag fa-lg"></i></div>
                                <div class="col fw-bold">Tags</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    {vendor_info.tags.length > 0 ?
                                        vendor_info.tags.map(tag => { return <Button size="sm" className="me-1" variant="light">{tag}</Button> })
                                        :
                                        <>No tags</>
                                    }
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-auto  gx-0"><i class="fa-regular fa-note-sticky fa-lg" /></div>
                                <div class="col fw-bold">Notes</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col notes">
                                    {vendor_info.note ? <>{vendor_info.note}</> : <>No notes</>}
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-auto  gx-0"><i class="fa-solid fa-user-group fa-lg"></i></div>
                                <div class="col fw-bold">Agents in your network who favorited this vendor</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    {
                                        vendor_info.agent_friend_names ? <>{vendor_info.agent_friend_names}</> : <>None</>
                                    }
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-auto gx-0" ><i class="fa-solid fa-globe fa-lg"></i></div>
                                <div class="col">{vendor_info.favorites_count} total agent(s) favorited this vendor</div>
                            </div>
                        </div>
                }
            </Modal.Body>
        </Modal>
    )
}

function DeleteFromFavoritesModal(props) {
    const { vendor, show_delete_modal, setModalVisibility, fetchFavoriteVendorList } = props;
    const [submitting, setSubmitting] = useState(false);

    if (!vendor) { return null; }

    const deleteVendor = () => {

        setSubmitting(true);

        let request = {
            vendor_id: vendor.ID,
            role: vendor.role,
        }

        generic_delete_api(AGENT_FAVORITE_VENDOR_URL, request)
            .then(
                (result) => {
                    if (result.status === 204) {
                        setSubmitting(false);
                        fetchFavoriteVendorList();
                        setModalVisibility(FavoriteModals.show_delete_modal, false, null);
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    return (
        <Modal
            show={show_delete_modal}
            onHide={() => setModalVisibility(FavoriteModals.show_delete_modal, false, null)}
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Remove from favorites</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    <div class="row">
                        <div class="col">Are you sure you want to remove <strong>{vendor.entity_name}</strong> from your favorites?</div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">Note: This vendor will also be removed from any vendor lists that you previously shared.</div>
                    </div>
                    <div class="row mt-4">
                        <div class="col text-center">
                            {submitting ?
                                <Spinner animation="border" role="status" size="lg" />
                                :
                                <Button variant="primary" onClick={(deleteVendor)}>Confirm</Button>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function ShareVendorModal(props) {

    // 'selected_vendors' is a map of vendor_ids to bool of the checked vendors, 'vendors' is an array of all vendors.
    const { selected_vendors, showShareVendorModal, show_share_vendor_modal, contacts, vendors, selectAll, fetchAgentContacts } = props;

    // display mode can be one of 'contact_list_mode' , 'new_contact_mode', 'email' and 'link_mode'
    const [display_mode, setDisplayMode] = useState('contact_list_mode');
    const [shared_link, setSharedLink] = useState('');
    const [link_copied_to_clipboard, setLinkCopiedToClipBoard] = useState(false);

    const [selected_contact_name, setSelectedContactName] = useState('');
    const [selected_contact_email, setSelectedContactEmail] = useState('');
    const [selected_contact_id, setSelectedContactId] = useState(null);

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [filtered_contacts, setFilteredContacts] = useState(contacts);
    const [submitting, setSubmitting] = useState(false);
    const [show_email_section, setShowEmailSection] = useState(false);
    const [email_sent, setEmailSent] = useState(false);

    useEffect(() => {
        setFilteredContacts(contacts);
    }, [contacts]);

    if (!show_share_vendor_modal) { return null; }

    const closeModal = () => {

        setDisplayMode('contact_list_mode');
        setSharedLink(null);
        setSelectedContactName('');
        setSelectedContactEmail('');
        setSelectedContactId(null);
        setLinkCopiedToClipBoard(false);
        setFirstName('');
        setLastName('');
        setEmail('');
        setFilteredContacts(contacts);
        showShareVendorModal(false);
        setSubmitting(false);
        setShowEmailSection(false);
        setEmailSent(false);

        // if a shared link was created (i.e. sharing with some contact was successful) then reset
        // all selected vendors. Otherwise, do not clear out check boxes.
        if (shared_link) {
            selectAll(false);
        }
    }

    const filterContactsBoxChanged = (e) => {
        if (e.target.value === '') {
            setFilteredContacts(contacts);
        } else {
            let term = e.target.value.toLowerCase();
            let output = [];

            for (let i = 0; i < contacts.length; i++) {
                let contact = contacts[i];
                let client_name = contact.first_name + " " + contact.last_name;
                if (contact.first_name && contact.first_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (contact.last_name && contact.last_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (contact.email && contact.email.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (client_name && client_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
            }

            setFilteredContacts(output);
        }
    }

    const getVendorNames = () => {
        let output = "";

        for (let i = 0; i < vendors.length; i++) {
            if (selected_vendors[vendors[i].ID]) {
                output = output + vendors[i].entity_name + " | ";
            }
        }
        if (output.length >= 2) {
            return output.substring(0, output.length - 2);
        } else {
            return output;
        }
    }

    const getVendorCountText = () => {
        let count = 0;

        for (let i = 0; i < vendors.length; i++) {
            if (selected_vendors[vendors[i].ID]) {
                count += 1;
            }
        }

        if (count === 1) {
            return "Sharing 1 vendor";
        }
        else {
            return "Sharing " + count + " vendors";
        }
    }

    const shareLinkCopyClicked = () => {

        try {
            navigator.clipboard.writeText(shared_link);
            setLinkCopiedToClipBoard(true);
            const timeId = setTimeout(() => {
                setLinkCopiedToClipBoard(false);
            }, 3000)
        }
        catch (error) {
            setLinkCopiedToClipBoard(true);

            const timeId = setTimeout(() => {
                setLinkCopiedToClipBoard(false);
            }, 3000)
        }

    }

    const share = (contact) => {
        setSelectedContactName(contact.first_name + " " + contact.last_name);
        setSelectedContactEmail(contact.email);
        setSelectedContactId(contact.id);

        setSubmitting(true);
        setDisplayMode('link_mode');

        let request_selected_vendors = []

        for (let i = 0; i < vendors.length; i++) {
            let vendor = vendors[i];

            if (selected_vendors[vendor.ID]) {
                request_selected_vendors.push({
                    vendor_id: vendor.ID,
                    role: vendor.role,
                })
            }
        }

        let request = {
            contact_id: contact.id,
            shared_vendors: request_selected_vendors,
            append_vendor_to_list: true,
        }

        generic_put_api(AGENT_CONTACT_ACCESS_URL, request)
            .then(
                (result) => {
                    if (result.status === 200) {
                        return result.data.json();
                    }
                }
            )
            .then(
                data => {
                    setSharedLink(data.shared_link);
                    setSubmitting(false);
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    const sendEmail = () => {

        setSubmitting(true);

        let request = {
            email: selected_contact_email,
            contact_id: selected_contact_id,
        }

        generic_post_api(request, AGENT_CONTACT_ACCESS_EMAIL_URL)
            .then(
                (result) => {
                    if (result.status === 201) {
                        setEmailSent(true);
                        setSubmitting(false);
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });

    }

    const createContactAndShare = () => {
        setSubmitting(true);
        setDisplayMode('link_mode');

        setSelectedContactName(first_name + " " + last_name);
        setSelectedContactEmail(email);

        let request_selected_vendors = []

        for (let i = 0; i < vendors.length; i++) {
            let vendor = vendors[i];

            if (selected_vendors[vendor.ID]) {
                request_selected_vendors.push({
                    vendor_id: vendor.ID,
                    role: vendor.role,
                })
            }
        }

        let request = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            shared_vendors: request_selected_vendors,
        }

        generic_post_api(request, AGENT_CONTACT_ACCESS_URL)
            .then(
                (result) => {
                    if (result.status === 201) {
                        return result.data.json();
                    }
                }
            )
            .then(
                data => {
                    setSharedLink(data.shared_link);
                    setSelectedContactId(data.contact_id);
                    setSubmitting(false);
                    fetchAgentContacts();
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    const mobileShare = () => {
        let data = {
            title: "View vendors",
            text: "",
            url: shared_link,
          }
        navigator.share(data)
          .then(() => {
            console.log('true');
          })
          .catch((e) => {})
      }

    const getShareButton = () => {

        if (navigator.share) {
            return <Button variant="light" onClick={ mobileShare } disabled={ !shared_link }><i class="fa-regular fa-share-from-square"/></Button>
        } else {
            return <Button variant="light" onClick={() => setShowEmailSection(true)} disabled={ show_email_section }><i class="fa-regular fa-envelope"></i></Button>
        }
    }

    return (
        <Modal
            show={show_share_vendor_modal}
            onHide={closeModal}
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Share vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container shareVendorDialog p-0">
                    <div class="row">
                        <div class="col text-truncate">
                            <OverlayTrigger key="vendor_names" placement="auto"
                                overlay={
                                    <Popover id="vendor_names">
                                        <Popover.Header as="h3">Vendors</Popover.Header>
                                        <Popover.Body>
                                            {getVendorNames()}
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <a class="text-decoration-underline" role="button">{getVendorCountText()}</a>
                            </OverlayTrigger>
                        </div>
                    </div>

                    {display_mode === 'new_contact_mode' &&
                        <>
                            <div class="row mt-3">
                                <div class="col fw-bold">New contact</div>
                            </div>
                            <div class="row mt-2">
                                <div class="col"><input type="text" class="form-control" value={first_name} placeholder="First name (required)" onChange={(e) => setFirstName(e.target.value)} /></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col"> <input type="text" class="form-control" value={last_name} placeholder="Last name (required)" onChange={(e) => setLastName(e.target.value)} /></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col"><input type="text" class="form-control" value={email} placeholder="Email (optional)" onChange={(e) => setEmail(e.target.value)} /></div>
                            </div>

                            <div class="row mt-4">
                                <div class="col text-center"> <Button variant="primary" disabled={!first_name || !last_name} onClick={createContactAndShare}>Share</Button></div>
                            </div>
                        </>
                    }

                    {display_mode === 'link_mode' &&
                        <>
                            <div class="row mt-3">
                                <div class="col"><small>With this shared link, <strong>{selected_contact_name}</strong> gains
                                    access to your selected vendors. No signup required.</small></div>
                            </div>

                            <div class="row mt-3"><div class="col"><small>Shared link</small></div></div>
                            <div class="row">
                                <div class="col">
                                    <div class="input-group">

                                        {submitting && !shared_link ?
                                            <span><Spinner animation="border" role="status" size="small" />Fetching shared link...</span>
                                            :
                                            <input type="input" class="form-control" value={shared_link} />
                                        }


                                        {link_copied_to_clipboard ?
                                            <Button variant="success" onClick={shareLinkCopyClicked}>Copied</Button>
                                            :
                                            <Button variant="light" onClick={shareLinkCopyClicked}>Copy</Button>
                                        }
                                    </div>
                                </div>
                                <div class="col-auto"> 
                                    {getShareButton()}
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col"><small>This link is unique for this contact. To share with another contact, create a new link.</small></div>
                            </div>

                            {show_email_section &&
                                <>
                                    {!email_sent ?
                                        <>
                                            <div class="row mt-4">
                                                <div class="col"><small>Email shared link to contact</small></div>
                                            </div>
                                            <div class="row mt-1 mb-3">
                                                <div class="col">
                                                    {
                                                        submitting ?
                                                            <Spinner animation="border" role="status" size="sm" />
                                                            :
                                                            <div class="input-group">
                                                                <input type="email" class="form-control" placeholder="Email" value={selected_contact_email} onChange={(e) => setSelectedContactEmail(e.target.value)} />
                                                                <Button variant="primary" onClick={sendEmail} disabled={!isValidEmail(selected_contact_email)}>Send</Button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div class="row mt-4">
                                            <div class="col fw-bold"><small>Email successfully sent</small></div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }


                    {display_mode === 'contact_list_mode' &&
                        <>
                            <div class="row mt-3">
                                <div class="col ">Share with an existing or new contact</div>
                            </div>

                            <div class="row mt-2">
                                <div class="col pe-2"><input type="search" class="form-control" placeholder="Filter clients" onChange={(e) => filterContactsBoxChanged(e)} /></div>
                                <div class="col-auto">
                                    <Button variant="primary" onClick={() => setDisplayMode('new_contact_mode')}>
                                        <i class="fa-solid fa-user-plus" /><span>&nbsp;&nbsp;New</span>
                                    </Button>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="container ">
                                    {filtered_contacts && filtered_contacts.length === 0 &&
                                        <div class="row mt-4 contactsList">
                                            <div class="col text-center fw-bold">No clients. Start by adding a client.</div>
                                        </div>
                                    }
                                    {filtered_contacts && filtered_contacts.length > 0 &&
                                        <ListGroup className="mt-2 contactsList overflow-auto">
                                            <ListGroup.Item className="text-start bg-light ">
                                                <div class="row  align-items-center">
                                                    <div class="col text-left ps-1 fw-bold"><small>Clients</small></div>
                                                </div>
                                            </ListGroup.Item>
                                            {filtered_contacts.map(
                                                contact => {

                                                    return <ListGroup.Item className="ps-2 pb-0 pt-0 ">
                                                        <div class="row align-items-center gx-0">
                                                            <div class="col text-truncate"><small>{contact.first_name} {contact.last_name}</small></div>
                                                            <div class="col-auto text-end">
                                                                <Button variant="link" onClick={() => share(contact)}>
                                                                    {/* <i class="fa-solid fa-arrow-up-from-bracket"/>  */}
                                                                    Share
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>

                                                })
                                            }
                                        </ListGroup>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

function LearnMoreModal(props) {
    const { show_learn_more_modal, hideLearnMoreModal } = props;

    if (!show_learn_more_modal) { return null; }

    return (
        <Modal
            show={show_learn_more_modal}
            onHide={hideLearnMoreModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>My vendors</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div class="container lh">
                    <div class="row">
                        <div class="col fw-bold">Which vendors should be part of <strong>My vendors</strong>?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            You can add any vendors that you wish to your list. These can be vendors you work with frequently, like to refer to others, or simply like to keep track of. 
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">How can I add vendors?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            There are several ways: <br/>
                            1) Search our database by a name or license number (e.g. a contractor, plumber, etc.) and add them to your list of vendors.<br />
                            2) Manually add their info if they are not licensed (e.g. select handy persons, or some cleaning companies). It only takes a minute.<br />
                            3) Use Renolition's vast database of vendors with detailed stats and reviews to discover a new vendor.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">How do I share vendors with my clients?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Select one or more vendors from the list of vendors and click <strong>Share</strong>. Either choose an existing contact or enter a new contact. We will 
                            generate a <i>shared link</i> which you can send to your contact. No sign-up is required, and your contact has immediate access to the vendor(s).
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">Do agent in my network see all my vendors?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            No. You control which vendors are visible to your agent network. When agents in your network browse through your vendors, they can only
                            see those vendors that you have explicitly made visible; all other vendors remain hidden from your network.
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AgentFavoriteVendorList;