/* global google */
import React, { Component, useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

import { PROJECT_INTAKE, PROJECT_INTAKE_PHOTO, US_STATES} from 'utils/constants';
import bay_electric from 'assets/reputation/bay_electric.png';
import panel_1 from 'assets/reputation/panel_1.png';
import panel_2 from 'assets/reputation/panel_2.png';
import panel_3 from 'assets/reputation/panel_3.png';
import panel_close from 'assets/reputation/panel_close.svg';
import panel_far from 'assets/reputation/panel_far.svg';
import meter_example_1 from 'assets/reputation/meter_example_1.png';
import meter_example_2 from 'assets/reputation/meter_example_2.png';
import meter_example_3 from 'assets/reputation/meter_example_3.png';
import garage_1 from 'assets/reputation/garage_1.png';
import garage_2 from 'assets/reputation/garage_2.png';
import garage_3 from 'assets/reputation/garage_3.png';
import { answerFromBool, isValidEmail, isValidPhoneNumber } from "utils/helpers";
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Button, Spinner,  Card, Modal, ButtonGroup, ToggleButton} from 'react-bootstrap';
import { generic_post_api } from "api/generic_api";

const Stages = {
    INTRO: 'intro',
    CONTACT_DETAILS: 'contact_details',
    CHARGER_DETAILS: 'charger_details',
    PANEL_PHOTO_UPLOAD: 'panel_photo_upload',
    METER_UPLOAD: 'meter_upload',
    MESSAGE: 'message',
    OVERVIEW: 'overview',
    CONFIRMATION: 'confirmation',
}

const DeviceTypes = {
    hard_Wired: 'Hard wired',
    nema_14_50: 'Nema 14-50 compatible',
    na: "I am not sure",
}

const noneResponse = (value) => {
    if (value === null || value === undefined || value === "") {
        return 'Not specified';
    }
    return value;
}

class EvQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
           current_stage: Stages.INTRO,
           project: {
                project_id: uuidv4(),
                first_name: null,
                last_name: null,
                email: '',
                phone: '',
                panels: [],
                meter: null,
                garage: null,
                note: null,
                num_chargers: 1,
                vehicle_types: '',
                is_device_ordered: null,
                ordered_charger_type: null,
                address: {
                    street_address: null,
                    unit: null,
                    street_name: null,
                    street_number: null,
                    city: null,
                    state: null,
                    zipCode: null,
                }
            },
        };

        this.transitionStage = this.transitionStage.bind(this);
        this.setProjectAddress = this.setProjectAddress.bind(this);
        this.setProjectField = this.setProjectField.bind(this);
    }

    setProjectField(field, value) {
        let temp_project = this.state.project;
        temp_project[field] = value;
        this.setState({project: temp_project});
    }

    setProjectAddress(address) {
        let tempProject = this.state.project;
        tempProject.address = address;

        this.setState( {currentProject: tempProject} )
    }

    transitionStage(stage) {
        this.setState({current_stage: stage})
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'ev_quote'});
        document.title = "Your EV project request";
    }

    render() {
        return  <div class="quoteFlowPage mt-2 container">
                    <IntroSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                    />
                    <ContactDetailsSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                        project={this.state.project}
                        setProjectAddress={this.setProjectAddress}
                        setProjectField={this.setProjectField}
                    />
                    <ChargerDetailsSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                        project={this.state.project}
                        setProjectField={this.setProjectField}
                    />
                    <PanelPhotoSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                        project={this.state.project}
                        setProjectField={this.setProjectField}
                    />
                    <MeterUploadSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                        project={this.state.project}
                        setProjectField={this.setProjectField}
                    />
                    <MessageSection
                        current_stage={this.state.current_stage}
                        transitionStage={this.transitionStage}
                        project={this.state.project}
                        setProjectField={this.setProjectField}
                    />
                    <OverviewSection
                        current_stage={ this.state.current_stage }
                        transitionStage={ this.transitionStage }
                        project={ this.state.project }
                    />
                    <ConfirmationSection
                        current_stage={ this.state.current_stage }
                    />
                </div>;
    }
}

const OverviewSection = (props) => {
    const {current_stage, transitionStage, project} = props;

    const [photo_preview, setPhotoPreview] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    
    if (current_stage !== Stages.OVERVIEW) {
        return null;
    }

    const submit = () => {
        setSubmitting(true);

        trackEvent(EventNames.API_REQUEST, {data_1: PROJECT_INTAKE, data_2: project});

        generic_post_api(project, PROJECT_INTAKE)
        .then(
            data => {
                setSubmitting(false);
                transitionStage(Stages.CONFIRMATION);
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    return(
        <div class="container text-center">
            <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
            <div class="row mt-4"><div class="col text-center fw-bold fs-4">Project</div></div>
            <div class="row mt-2"><div class="col text-center">Review your information and submit.</div></div>
            
            <div class="row mt-4">
                <div class="col text-center">
                    {
                        submitting ?
                        <Button variant="primary" className="actionButton" disabled>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting
                        </Button>
                        :
                            <Button className="actionButton"  variant="primary" onClick={submit}>Submit&nbsp;<i class="fa-solid fa-circle-check"></i></Button>
                    }
                </div>
            </div>

            <Card className="mt-4">
                <Card.Body>
                    <>
                        <div class="row"><div class="col fw-bold">Client name</div></div>
                        <div class="row"><div class="col">{project.first_name} {project.last_name}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Email</div></div>
                        <div class="row"><div class="col">{project.email}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Phone</div></div>
                        <div class="row"><div class="col">{project.phone}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Project address</div></div>
                        <div class="row"><div class="col">{project.address.street_address}</div></div>
                        {
                            project.address.unit && <div class="row"><div class="col">{project.address.unit}</div></div>
                        }
                        <div class="row">
                            <div class="col">{project.address.city} {project.address.zipCode}</div>
                        </div>
                    </>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    <>
                        <div class="row"><div class="col fw-bold">Number of chargers requested</div></div>
                        <div class="row"><div class="col">{noneResponse(project.num_chargers)}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">EV vehicle models</div></div>
                        <div class="row"><div class="col">{noneResponse(project.vehicle_types)}</div></div>
                        <div class="row mt-2"><div class="col fw-bold">Charging device ordered?</div></div>
                        <div class="row"><div class="col">{noneResponse(answerFromBool( project.is_device_ordered))}</div></div>
                        {
                            project.is_device_ordered &&
                            <>
                                <div class="row mt-2"><div class="col fw-bold">Ordered charger type</div></div>
                                <div class="row"><div class="col">{noneResponse(DeviceTypes[project.ordered_charger_type])}</div></div>
                            </>
                        }
                    </>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    {
                        project.panels.length > 0 ?
                        <>
                            {
                                project.panels.map((panel, index) => {
                                    return  <> 
                                                <div class="row"><div class="col fw-bold">Electrical panel {index+1} photos</div></div>
                                                <div class="row"><div class="col"><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(panel[0])}>{panel[0].file_name}</a></small></div></div>
                                                <div class="row"><div class="col"><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(panel[1])}>{panel[1].file_name}</a></small></div></div>
                                            </>
                                })
                            }
                        </>
                        :
                        <>
                            <div class="row"><div class="col fw-bold">Electrical panel photos</div></div>
                            <div class="row"><div class="col">None submitted</div></div>
                        </>
                    }
                    <div class="row mt-2"><div class="col fw-bold">PG&E meter photo</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.meter ? 
                                    <small>
                                        <a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(project.meter)}>{project.meter.file_name}</a>
                                    </small>
                                :
                                <span>None submitted</span>
                            }
                        </div>
                    </div>

                    <div class="row mt-2"><div class="col fw-bold">Garage photo</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.garage ? 
                                    <small>
                                        <a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(project.garage)}>{project.garage.file_name}</a>
                                    </small>
                                :
                                <span>None submitted</span>
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="mt-2">
                <Card.Body>
                    <div class="row"><div class="col fw-bold">Additional note</div></div>
                    <div class="row">
                        <div class="col">
                            {
                                project.note ? 
                                    <span>{project.note}</span>
                                :
                                    <span>None</span>
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <div class="row mt-5 mb-5">
                <div class="col text-start"><Button className="navigationButton"  disabled={submitting} variant="secondary" onClick={() => transitionStage(Stages.MESSAGE)}> <i class="fa-solid fa-chevron-left"></i>&nbsp; Previous</Button></div>
                <div class="col text-end">
                    {
                        submitting ?
                        <Button variant="primary" className="navigationButton" disabled>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting
                        </Button>
                        :
                            <Button className="navigationButton"  variant="primary" onClick={submit}>Submit&nbsp;<i class="fa-solid fa-circle-check"></i></Button>
                    }
                </div>
            </div>
            <div class="row mb-5"><div class="col"></div></div>
                
            <Modal                        
                show={photo_preview !== null } 
                onHide={ () => setPhotoPreview(null) }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="photoModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-6 fw-bold"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="col text-center">
                        {   photo_preview && 
                            <img src={photo_preview.photo} class="sampleImage" />
                        }
                        
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const MeterUploadSection = (props) => {
    const {current_stage, transitionStage, project, setProjectField} = props;

    const [uploadingFile, setUploadingFile] = useState(false);
    const [show_example_meter_modal, setShowExampleMeterModal] = useState(false);
    const [example_mode, setExampleMode] = useState(false);
    const [photo_preview, setPhotoPreview] = useState(null);

    const meter_file_ref = React.createRef();
    const garage_file_ref = React.createRef();

    const onMeterButtonClick = () => { meter_file_ref.current.click() }
    const onGarageButtonClick = () => { garage_file_ref.current.click() }

    const meterFilePickerChanged = (ev) => {

        if (meter_file_ref.current && meter_file_ref.current.files.length > 0) {
            uploadFile('meter', meter_file_ref);
            ev.target.value = null;
        }
    }

    const garageFilePickerChanged = (ev) => {

        if (garage_file_ref.current && garage_file_ref.current.files.length > 0) {
            uploadFile('garage', garage_file_ref);
            ev.target.value = null;
        }
    }

    const uploadFile = (photo_type, file_ref) => {

        var data = new FormData();
        data.append('file', file_ref.current.files[0]);
        data.append('project_id', project.project_id);

        const requestOptions = {
            method: 'PUT',
            body: data
        };

        setUploadingFile(true);

        fetch(PROJECT_INTAKE_PHOTO, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {                    
                    return result.json();
                }
                else {
                    setUploadingFile(false);
                }
            }
        ).
        then(
            data => {
                let result = {
                    'id': data.id,
                    'file_name': data.file_name,
                    'file_path': data.file_path,
                    'thumb_nail_path': data.thumb_nail_path,
                    'photo': data.photo,
                }
                
                setProjectField(photo_type, result);
                setUploadingFile(false);
            }
        );
    }

    if (current_stage !== Stages.METER_UPLOAD) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-5">
                    <div class="col">     
                            <Card>
                                <Card.Body>
                                    { !project.meter ?
                                        <div class="container">
                                            <div class="row"><div class="col text-center fw-bold">PG&E meter</div></div>
                                            <div class="row mt-3">
                                                <div class="col text-center">
                                                { uploadingFile ? 
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    :
                                                    <Button variant="outline-primary" className="actionButton" onClick={onMeterButtonClick}><i class="fa-solid fa-camera"></i>&nbsp;Add PG&E meter photo</Button>
                                                }                        
                                                <input type="file" id="actual-btn"
                                                    ref={meter_file_ref}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => meterFilePickerChanged(e)}
                                                />
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col text-center"> 
                                                    <a role="button" class="text-decoration-underline" onClick={()=>{setShowExampleMeterModal(true); setExampleMode('meter')}}>Click here for examples</a>
                                                </div>
                                            </div>
                                            <div class="row mt-3"><div class="col text-center">Your PG&E meter may be in your garage or on the outside.</div></div>
                                        </div>
                                    :
                                        <div class="container">
                                            <div class="row"><div class="col text-center  fw-bold">PG&E meter</div></div>
                                            <div class="row mt-3">
                                                <div class="col-12 mt-2">
                                                    <small>
                                                        <a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(project.meter)}>{project.meter.file_name}</a>&nbsp;
                                                        <a role="button" onClick={ () => setProjectField('meter', null)}><i class="fa-regular fa-trash-can fa-lg"></i></a>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                    
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">     
                            <Card>
                                <Card.Body>
                                    { !project.garage ?
                                        <div class="container ">
                                            <div class="row"><div class="col text-center fw-bold">Garage</div></div>
                                            <div class="row mt-3">
                                                <div class="col text-center">
                                                { uploadingFile ? 
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    :
                                                    <Button variant="outline-primary" className="actionButton" onClick={onGarageButtonClick}><i class="fa-solid fa-camera"></i>&nbsp;Add Garage photo</Button>
                                                }                        
                                                <input type="file" id="actual-btn"
                                                    ref={garage_file_ref}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => garageFilePickerChanged(e,)}
                                                />
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col text-center"> 
                                                    <a role="button" class="text-decoration-underline" onClick={()=>{setShowExampleMeterModal(true); setExampleMode('garage')}}>Click here for examples</a>
                                                </div>
                                            </div>
                                            <div class="row mt-3"><div class="col text-center">Capture as much of your garage as possible.</div></div>
                                        </div>
                                    :
                                        <div class="container">
                                             <div class="row "><div class="col text-center fw-bold">Garage</div></div>
                                            <div class="row  mt-3">
                                                <div class="col-12 mt-2">
                                                    <small>
                                                        <a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(project.garage)}>{project.garage.file_name}</a>&nbsp;
                                                        <a role="button" onClick={ () => setProjectField('garage', null)}><i class="fa-regular fa-trash-can fa-lg"></i></a>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                    
                    </div>
                </div>
                
                {
                    project.meter && project.garage ? 
                        <div class="row mt-5">
                            <div class="col text-start"><Button className="navigationButton" variant="secondary" onClick={() => transitionStage(Stages.PANEL_PHOTO_UPLOAD)}> <i class="fa-solid fa-chevron-left"></i>&nbsp; Previous</Button></div>
                            <div class="col text-end"><Button className="navigationButton"  variant="primary" onClick={() => transitionStage(Stages.MESSAGE)}>Add note&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                        </div>
                    :
                        <div class="row mt-5">
                            <div class="col text-start"><Button className="navigationButton"  variant="secondary" onClick={() => transitionStage(Stages.PANEL_PHOTO_UPLOAD)}> <i class="fa-solid fa-chevron-left"></i>&nbsp; Previous</Button></div>
                            <div class="col text-end"><Button className="navigationButton"  variant="secondary" onClick={() => transitionStage(Stages.MESSAGE)}>Skip&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                        </div>
                }
                
                
                <Modal
                    show={show_example_meter_modal} 
                    onHide={ () => {setShowExampleMeterModal(false); setExampleMode(null)} }
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="photoModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fs-6 fw-bold">Example meters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            example_mode === 'meter' &&
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col text-center"><img src={meter_example_1} class="sampleImage"/></div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center"><img src={meter_example_2} class="sampleImage"/></div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center"><img src={meter_example_3} class="sampleImage"/></div>
                                    </div>
                                </div>
                        }
                        {
                            example_mode === 'garage' &&
                                <div class="container p-0">
                                    <div class="row">
                                        <div class="col text-center"><img src={garage_1} class="sampleImage"/></div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center"><img src={garage_2} class="sampleImage"/></div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col text-center"><img src={garage_3} class="sampleImage"/></div>
                                    </div>
                                </div>
                        }
                        
                    </Modal.Body>
                </Modal>

                <Modal                        
                    show={photo_preview !== null } 
                    onHide={ () => setPhotoPreview(null) }
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="photoModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fs-6 fw-bold"></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="col text-center">
                            {   photo_preview && 
                                <img src={photo_preview.photo} class="sampleImage" />
                            }
                            
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
}

const PanelPhotoSection = (props) => {

    const {current_stage, transitionStage, project, setProjectField} = props;

    const [uploadingFile, setUploadingFile] = useState(false);
    const [current_panel_close, setCurrentPanelClose] = useState(null);
    const [current_panel_far, setCurrentPanelFar] = useState(null);
    const [show_close_upload_modal, setShowCloseUploadModal] = useState(false);
    const [show_far_upload_modal, setShowFarUploadModal] = useState(false);
    const [show_new_panel_section, setShowNewPanelSection] = useState(false);
    const [photo_preview, setPhotoPreview] = useState(null);
    const [show_panel_images, setShowPanelImages] = useState(false);
    
    const fileRef = React.createRef();
    const onPhotoBoxClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev, photo_type) => {

        if (fileRef.current && fileRef.current.files.length > 0) {
            uploadFile(photo_type);
            ev.target.value = null;
        }
    }

    const uploadFile = (photo_type) => {

        var data = new FormData();
        data.append('file', fileRef.current.files[0]);
        data.append('project_id', project.project_id);

        const requestOptions = {
            method: 'PUT',
            body: data
        };

        setUploadingFile(true);

        fetch(PROJECT_INTAKE_PHOTO, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {                    
                    return result.json();
                }
                else {
                    setUploadingFile(false);
                }
            }
        ).
        then(
            data => {
                let result = {
                    'id': data.id,
                    'file_name': data.file_name,
                    'file_path': data.file_path,
                    'thumb_nail_path': data.thumb_nail_path,
                    'photo': data.photo,
                }
                
                if (photo_type === 'close') {
                    setCurrentPanelClose(result);
                    setShowCloseUploadModal(false)
                }
                else if (photo_type === 'far') {
                    setCurrentPanelFar(result);
                    setShowFarUploadModal(false);
                }
                setUploadingFile(false);
            }
        );
    }

    const deletePanel = (panel) => {
        let temp_panels = [];

        project.panels.forEach(p => {
            if(p[0].id !== panel[0].id && p[1].id !== panel[1].id){
                temp_panels.push(p);
            }
        })

        setProjectField('panels', temp_panels);
    }

    const addPanelButtonText = () => {
        if (project.panels.length === 0) { return 'Add photos for first panel'; }
        else {
            return 'Add photos for additional panel';
        }
    }

    useEffect (() => {
            if (current_panel_close && current_panel_far) {
                let temp_panels = project.panels;
                temp_panels.push([current_panel_close, current_panel_far]);
                
                setProjectField('panels', temp_panels);

                setCurrentPanelClose(null);
                setCurrentPanelFar(null);
                setShowNewPanelSection(false);
            }

        }, [current_panel_close, current_panel_far]
    )

    if (current_stage !== Stages.PANEL_PHOTO_UPLOAD) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-4"><div class="col text-center fw-bold fs-4">Panels</div></div>
                <div class="row mt-3"><div class="col text-center">Electrical panels hold circuit breakers and are often in the garage or a hallway. You may have multiple panels.</div></div>
                <div class="row mt-2"><div class="col text-center"> <a class="text-decoration-underline" role="button" onClick={() => setShowPanelImages(true)}>Click here to see some examples of panels</a> </div></div>
                {
                    project.panels.map((panel, index) => {
                        return  <>
                                    <div class="row border mt-4 pb-2 pt-2">
                                        <div class="col-12 fw-semi-bold">Electrical panel {index + 1} &nbsp; <a role="button" onClick={() => deletePanel(panel)}><i class="fa-regular fa-trash-can fa-lg"></i></a></div>
                                        <div class="col-12 mt-2"><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(panel[0])}>{panel[0].file_name}</a></small></div>
                                        <div class="col-12"><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(panel[1])}>{panel[1].file_name}</a></small></div>
                                    </div>
                                </>
                    })
                }
                {
                    show_new_panel_section || project.panels.length === 0 ?
                        <Card className="mt-4">
                            <Card.Body>
                                <div class="container">
                                    <div class="row">
                                        <div class="col fw-bold text-center">{addPanelButtonText()}</div>
                                    </div>
                                    <div class="row mt-2"><div class="col text-center">Upload a close up photo <strong>and</strong> another from 10-15 feet away. </div></div>
                                    <div class="row mt-3">
                                        <div class="col text-center">
                                        { current_panel_close ? 
                                            <span ><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(current_panel_close)}>{current_panel_close.file_name}</a></small> 
                                            &nbsp; <a role="button" onClick={() => setCurrentPanelClose(null)}><i class="fa-regular fa-trash-can fa-lg"></i></a></span> 
                                            :
                                            <Button variant="outline-primary"  className="actionButton" onClick={() => setShowCloseUploadModal(true)}><i class="fa-solid fa-camera"></i>&nbsp;Close up photo</Button>
                                        }
                                            
                                        </div>
                                    </div>
                                    <div class="row mt-3 ">
                                        <div class="col text-center">
                                            { current_panel_far ? 
                                                <span ><small><a role="button" class="text-decoration-underline" onClick={() => setPhotoPreview(current_panel_far)}>{current_panel_far.file_name}</a></small> 
                                                &nbsp; <a role="button" onClick={() => setCurrentPanelFar(null)}><i class="fa-regular fa-trash-can fa-lg"></i></a></span> 
                                                :
                                                <Button variant="outline-primary"  className="actionButton" onClick={() => setShowFarUploadModal(true)}><i class="fa-solid fa-camera"></i>&nbsp;Photo from distance</Button>
                                            }
                                        </div>
                                    </div>
                                    { project.panels.length > 0 && 
                                        <div class="row mt-5">
                                            <div class="col text-center"><Button variant="secondary" onClick={() => {setCurrentPanelFar(null); setCurrentPanelClose(null); setShowNewPanelSection(false)}}>Cancel</Button></div>
                                        </div> 
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    :
                        <div class="row mt-4"><div class="col text-center"><Button variant="outline-primary" onClick={() => setShowNewPanelSection(true)}>I have another panel</Button></div></div>
                }
                {
                    project.panels.length === 0 ?
                        <div class="row mt-5">
                            <div class="col text-start"><Button variant="secondary"  className="navigationButton" onClick={() => transitionStage(Stages.CHARGER_DETAILS)} > <i class="fa-solid fa-chevron-left"></i>&nbsp; Previous</Button></div>
                            <div class="col text-end"><Button variant="secondary"  className="navigationButton" onClick={() => transitionStage(Stages.METER_UPLOAD)}>Skip&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                        </div>
                    :
                        <div class="row mt-5">
                            <div class="col text-start"><Button variant="secondary"  className="navigationButton" onClick={() => transitionStage(Stages.CHARGER_DETAILS)}> <i class="fa-solid fa-chevron-left"></i>&nbsp; Previous</Button></div>
                            <div class="col text-end"><Button variant="primary"  className="navigationButton" onClick={() => transitionStage(Stages.METER_UPLOAD)}>Next&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                        </div>
                }

                <Modal
                        show={show_close_upload_modal} 
                        onHide={ () => setShowCloseUploadModal(false) }
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        className="panelUploadModal"
                        centered>
                        
                        <Modal.Header closeButton>
                            <Modal.Title className="fs-6 fw-bold">Panel close up</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="container p-0">
                                <div class="row gy-2">
                                    <div class="col-12 text-center">
                                        <Card className="panelPhotoCard">
                                            <Card.Body>
                                                <div class="container text-center">
                                                    <div class="row"><div class="col">Add a close-up photo with the panel door open such that the entire panel is captured.</div></div>
                                                    <div class="row mt-3"><div class="col"><img src={panel_close} class="panelPhotoExample_1"/></div></div>
                                                    { uploadingFile ? 
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        :
                                                        <div class="row mt-4"><div class="col">
                                                            <Button variant="primary" className="actionButton" onClick={onPhotoBoxClick}>Add photo</Button>
                                                        </div></div>
                                                    }
                                                    
                                                </div>
                                                <input type="file" id="actual-btn"
                                                    ref={fileRef}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => filePickerChanged(e, 'close')}
                                                />
                                            </Card.Body>
                                        </Card>
                                        
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={show_far_upload_modal} 
                        onHide={ () => setShowFarUploadModal(false) }
                        size="md"
                        className="panelUploadModal"
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title className="fs-6 fw-bold">Panel distance photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="container p-0">
                                <div class="row gy-2">
                                    <div class="col-12 text-center">
                                        <Card className="panelPhotoCard">
                                            <Card.Body>
                                                <div class="container text-center">
                                                    <div class="row"><div class="col">Take a photo while standing 10-15 feet away from the panel.</div></div>
                                                    <div class="row mt-3"><div class="col"><img src={panel_far} class="panelPhotoExample_2"/></div></div>
                                                    { uploadingFile ? 
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        :
                                                        <div class="row mt-4"><div class="col">
                                                            <Button variant="primary" className="actionButton" onClick={onPhotoBoxClick}>Add photo</Button>
                                                        </div></div>
                                                    }           
                                                </div>
                                                <input type="file" id="actual-btn"
                                                    ref={fileRef}
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => filePickerChanged(e, 'far')}
                                                />
                                            </Card.Body>
                                        </Card>
                                        
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal                        
                        show={photo_preview !== null } 
                        onHide={ () => setPhotoPreview(null) }
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="photoModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="fs-6 fw-bold"></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="col text-center">
                                {   photo_preview && 
                                    <img src={photo_preview.photo} class="sampleImage" />
                                }
                               
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={show_panel_images} 
                        onHide={ () => setShowPanelImages(false) }
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="photoModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="fs-6 fw-bold">Example panels</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="container p-0">
                                <div class="row">
                                    <div class="col text-center"><img src={panel_1} class="sampleImage"/></div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><img src={panel_2} class="sampleImage"/></div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><img src={panel_3} class="sampleImage"/></div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            </div>
}

const IntroSection = (props) => {

    if (props.current_stage !== Stages.INTRO) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-3"><div class="col text-center text-success"><a href="https://www.bayelectricsf.com/" target="_blank">www.bayelectricsf.com</a></div></div>
                <div class="row mt-1"><div class="col text-center text-primary"><a class="text-decoration-none" href="tel:4156409849">415-640-9849</a></div></div>
                <div class="row mt-1"><div class="col text-center ">Lic#: 810652</div></div>

                <div class="row mt-4"><div class="col text-center">Thank you for contacting Bay Electric, San Francisco's top EV installation contractor with over 25 years of experience.</div></div>

                <div class="row mt-3"><div class="col text-center">Please use this form to send us your contact info, address, and photos of your electrical setup & garage. In most cases, we can give you a quote within 24 hours.</div></div>

                <div class="row mt-3"><div class="col text-center"></div></div>

                <div class="row mt-5"><div class="col text-center"><Button  className="navigationButton" variant="primary" onClick={()=> props.transitionStage(Stages.CONTACT_DETAILS)}>Start&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div></div>
            </div>
}

const MessageSection = (props) => {

    const {current_stage, transitionStage, project, setProjectField} = props;

    if (current_stage !== Stages.MESSAGE) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-4"><div class="col text-center fw-bold fs-4">Additional note</div></div>
                <div class="row mt-2">
                    <div class="col text-center">
                        <textarea
                            class="form-control"
                            rows="6"
                            onChange={(e) => setProjectField('note', e.target.value)}
                            value={project.note}
                        />
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col text-start"><Button  className="navigationButton" variant="primary" onClick={()=> transitionStage(Stages.METER_UPLOAD)}><i class="fa-solid fa-chevron-left"></i>&nbsp;Previous</Button></div>
                    <div class="col text-end"><Button  className="navigationButton" variant="primary" onClick={()=> transitionStage(Stages.OVERVIEW)}>Review&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                </div>
            </div>
}

const ChargerDetailsSection = (props) => {

    const {current_stage, transitionStage, project, setProjectField} = props;

    if (current_stage !== Stages.CHARGER_DETAILS) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-4"><div class="col text-center fw-bold fs-4">Charger details</div></div>
                <div class="row gy-3 mt-2">
                    <div class="col-12 col-md-6">
                        <label for="num_chargers" class="form-label mb-0"><small>Number of chargers to install</small></label>
                        <div class="input-group">
                            <Button variant="primary" onClick={ () => setProjectField('num_chargers', project.num_chargers - 1) } disabled={project.num_chargers === 1}><i class="fa-solid fa-minus"></i></Button>
                            <input type="text" id="num_chargers" class="form-control text-center" value={project.num_chargers}/>
                            <Button variant="primary" onClick={ () => setProjectField('num_chargers', project.num_chargers + 1) } disabled={project.num_chargers >= 10}><i class="fa-solid fa-plus"></i></Button> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <label for="vehicle_types" class="form-label mb-0"><small>Brand & model of your EV vehicle(s)</small></label>
                        <input type="text" id="" class="form-control" value={project.vehicle_types} onChange={(e)=>setProjectField('vehicle_types', e.target.value)}/>
                    </div>
                </div>
                <div class="row gy-3 mt-2">
                    <div class="col-12 col-md-6">
                        <label for="ordered-device-id" class="form-label mb-0"><small>Have you ordered your charging device?</small></label>
                        <div>
                            <ButtonGroup className="d-flex" id="ordered-device-id"> 
                                <ToggleButton
                                        key="ordered-device-key-1"
                                        id="ordered-device-id-1"
                                        type="radio"
                                        variant="outline-primary"
                                        name="ordered-device"
                                        value={false}
                                        checked={false === project.is_device_ordered}
                                        onChange={() => setProjectField('is_device_ordered', false)}
                                    > No  
                                </ToggleButton>
                                <ToggleButton
                                        key="ordered-device-key-2"
                                        id="ordered-device-key-2"
                                        type="radio"
                                        variant="outline-primary"
                                        name="ordered-device"
                                        value={true}
                                        checked={project.is_device_ordered === true}
                                        onChange={() => setProjectField('is_device_ordered', true)}
                                    > Yes    
                                </ToggleButton>
                            </ButtonGroup>
                        </div>
                    </div>
                    {
                        project.is_device_ordered === true && 
                            <div class="col-12 col-md-6">
                                <label for="ordered-device-id" class="form-label mb-0"><small>Which type of device did you order?</small></label>
                                <select class="form-select" name="questionCategory" onChange={(e) => setProjectField("ordered_charger_type", e.target.value)}>
                                    <option disabled selected value>Charger type</option>
                                    <option value='hard_Wired' selected={project.ordered_charger_type === 'hard_Wired'}>Hard-wired charger</option>
                                    <option value='nema_14_50' selected={project.ordered_charger_type === 'nema_14_50'}>Nema 14-50 compatible charger</option>
                                    <option value='na' selected={project.ordered_charger_type === 'na'}>I am not sure</option>
                                </select>
                            </div>
                        }
                    
                </div>

                <div class="row mt-5">
                    <div class="col"></div>
                </div>
                <div class="row mt-5">
                    <div class="col text-start"><Button  className="navigationButton" variant="primary" onClick={()=> transitionStage(Stages.CONTACT_DETAILS)}><i class="fa-solid fa-chevron-left"></i>&nbsp;Previous</Button></div>
                    <div class="col text-end"><Button  className="navigationButton" variant="primary" onClick={()=> transitionStage(Stages.PANEL_PHOTO_UPLOAD)}>Next&nbsp;<i class="fa-solid fa-chevron-right"></i></Button></div>
                </div>
                <div class="row mt-5">
                    <div class="col"></div>
                </div>
            </div>
}

class ContactDetailsSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
            display_phone: '',
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
        this.updatePhone = this.updatePhone.bind(this);
    }

    updatePhone(value) {
        if (value != null && value.length >= 0) {
            let new_phone = value.replace(/\D/g,'');
            let formatted_number = "";

            if (isValidPhoneNumber(new_phone)) {
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
            } else if (new_phone.length < 10) {
                formatted_number = new_phone;
            }
            else if (new_phone.length > 10) {
                new_phone = new_phone.substring(0, 10);
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);
            }
            this.setState({display_phone: formatted_number});
            this.props.setProjectField('phone', new_phone);
        }
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let street_number = '';
        let street_name = '';
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
    
        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                street_number = component.long_name;
            }
            else if (component.types.includes("route")) {
                street_name = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setProjectAddress({
            street_address: street_number + " " + street_name,
            street_name: street_name,
            street_number: street_number,
            unit: "",
            city: city,
            state: state,
            zipCode: zipCode,
        });
    }

    setAddressComponent(component, e) {
        let currentAddress = this.props.project.address;
        currentAddress[component] = e.target.value;
        this.props.setProjectAddress(currentAddress);
    }

    componentDidUpdate(prevProps, prevState) {

        // must do this in componentDidUpdate rather than componentDidMount because when this component mounts initially 
        // it is when the Intro page is shown and the autocomplete text box is not available.
        if (prevProps.current_stage !== this.props.current_stage) {

            const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

            // Create a bounding box with sides ~10km away from the center point
            const defaultBounds = {
                north: center.lat + 0.07,
                south: center.lat - 0.07,
                east: center.lng + 0.07,
                west: center.lng - 0.07,
            };
            this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
                {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name"], bounds: defaultBounds, strictBounds: true });

            this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
        }
    }
    
    render() {
        const {project, transitionStage, current_stage, setProjectField} = this.props;

        const canMoveNext = () => {
            return project.first_name && project.last_name && isValidEmail(project.email) && isValidPhoneNumber(project.phone) && project.address.street_address;
        }

        if (current_stage !== Stages.CONTACT_DETAILS) {
            return null;
        }

        return(  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-4"><div class="col text-center fs-4 fw-bold">Contact details</div></div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6 text-center">
                        <input 
                            class={`form-control ${!project.first_name ? " missingField" : ""}`}
                            type="text" 
                            placeholder="First name" 
                            value={project.first_name} 
                            onChange={(e) => setProjectField('first_name', e.target.value)}
                        />
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <input 
                            class={`form-control ${!project.last_name ? " missingField" : ""}`}
                            type="text" 
                            placeholder="Last name" 
                            value={project.last_name} 
                            onChange={(e) => setProjectField('last_name', e.target.value)}
                        />
                    </div>
                </div>
                <div class="row mt-1 gy-2">
                    <div class="col-12 col-md-6 text-center">
                        <input 
                            class={`form-control ${!project.phone || project.phone.length === 0 || !isValidPhoneNumber(project.phone) ? " missingField" : ""}`}  
                            type="tel" 
                            placeholder="Phone number"
                            value={this.state.display_phone}
                            onChange={(e) => this.updatePhone(e.target.value)}
                        />
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <input 
                            class={`form-control ${!isValidEmail(project.email) ? " missingField" : ""}`}
                            type="email" 
                            placeholder="Email"  
                            value={project.email} 
                            onChange={(e) => setProjectField('email', e.target.value)}
                        />
                    </div>
                </div>
                <div class="row mt-1 gy-2">
                    <div class="col-12 col-md-6 text-center">
                        <input 
                            type="text" 
                            class={`form-control ${!project.address.street_address ? " missingField" : ""}`}
                            placeHolder="Street address" 
                            ref={this.autocompleteInput}  
                            id="autocomplete" 
                            onChange={(e)=>this.setAddressComponent('street_address', e)} 
                            value={project.address.street_address} 
                        />
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <input type="text" class="form-control" placeHolder="Unit/apartment number" onChange={(e)=>this.setAddressComponent('unit', e)} value={project.address.unit}/>
                    </div> 
                </div>
                <div class="row mt-1 gy-2">
                    <div class="col-12 col-md-6 text-center">
                        <input type="text" class="form-control" placeHolder="City" onChange={(e)=>this.setAddressComponent('city', e)} value={project.address.city}/>
                    </div> 
                    <div class="col-6 col-md-3 text-center">
                        <input type="text" class="form-control" placeHolder="Zip" onChange={(e)=>this.setAddressComponent('zipCode', e)} value={project.address.zipCode} />
                    </div> 
                    <div class="col-6 col-md-3 text-center">
                        <select class="form-select" name="questionCategory" onChange={(e) => this.setAddressComponent("state", e)}>
                                <option disabled selected value>State</option>
                                {Object.keys(US_STATES).map((keyName, i) => (
                                    <option value={keyName} selected={project.address.state === keyName}>{keyName}</option>
                                ))}
                            </select>
                    </div> 
                </div>
                <div class="row mt-5">
                    <div class="col text-end">
                        <Button variant="primary"  className="navigationButton" disabled={ !canMoveNext() }onClick={()=> transitionStage(Stages.CHARGER_DETAILS)}>Next&nbsp;<i class="fa-solid fa-chevron-right"></i></Button>
                    </div>
                </div>
            </div>)
    }
}

const ConfirmationSection = (props) => {

    if (props.current_stage !== Stages.CONFIRMATION) {
        return null;
    }

    return  <div class="container">
                <div class="row"><div class="col text-center"><img src={bay_electric} /></div></div>
                <div class="row mt-3"><div class="col text-center text-success"><a href="https://www.bayelectricsf.com/" target="_blank">www.bayelectricsf.com</a></div></div>
                <div class="row mt-1"><div class="col text-center text-primary"><a class="text-decoration-none" href="tel:4156409849">415-640-9849</a></div></div>
                <div class="row mt-1"><div class="col text-center ">Lic#: 810652</div></div>
                <div class="row mt-4"><div class="col text-center">Thank you for submission. We will get back to you within 48 hours.</div></div>
            </div>
}

export default EvQuote;