import React, { Component } from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import {AuthContext} from 'auth_context';
import TermsOfService from 'pages/tos';
import PrivacyPolicy from "pages/privacy_policy";
import AboutUs from 'pages/about_us';
import ScrollToTop from "pages/scroll_to_top";
import Review from 'pages/review';
import Account from 'pages/account';
import Login from 'pages/login';
import PermitStats from "pages/permit_stats";
import AdsLanding from "pages/ads_landing";
import AgentFavoriteVendorList from "pages/agents/agent_favorite_vendor_list";
import AgentContacts from "pages/agents/agent_contacts";
import AgentIndex from "pages/agents/agent_index";
import AgentLanding from "pages/agents/agent_landing";
import VendorDetail from "pages/agents/vendor_detail";
import PasswordReset from "pages/password_reset";
import VendorReview from "pages/agents/vendor_review";
import PropertyInspector from "pages/agents/property_inspector";
import AgentFriends from "pages/agents/agent_friends";
import AgentFriendsSearch from "pages/agents/agent_friends_search";
import VendorSearch from "pages/agents/vendor_search";
import VendorDiscover from "pages/agents/vendor_discover";
import SignUp from "pages/agents/sign_up";
import ContactIndex from "pages/agents/contact_index";
import ContactVendorList from "pages/agents/contact_vendor_list";
import Index from "pages";
import GmailImport from "pages/agents/gmail_import";
import Header from "pages/header";
import Footer from "pages/footer";
import ReviewEmailConfirmation from "pages/review_email_confirmation";
import Authentication from "pages/authentication";
import Guides from "pages/guides";
import ReplaceKitchenAppliances from "pages/guides/replace_kitchen_appliances";
import ReplaceKitchenBacksplash from "pages/guides/replace_kitchen_backsplash";
import ReplaceKitchenCountertop from "pages/guides/replace_kitchen_countertop";
import ContactLogin from "pages/agents/contact_login";
import PermitsAdmin from "pages/permits_admin";
import Video from "pages/video";
import Tutorials from "pages/tutorials";
import SignUpVideo from "pages/sign_up_video";
import VendorPlans from "pages/vendors/vendor_plans";
import VendorIndex from "pages/vendors/vendor_index";
import VendorAccount from "pages/vendors/vendor_account";
import VendorRequests from "pages/vendors/vendor_requests";
import VettedVendors from "pages/agents/vetted_vendors";
import EvQuote from "pages/ev_quote";
import EvProjectView from "pages/ev_project_view";
import ProjectForm from "pages/code_guide/project_form";
import PlanningCodeList from "pages/code_guide/plannning_code_list";
import CodeLanding from "pages/code_guide/code_landing";
import PlanningCodeSearch from "pages/code_guide/planning_code_search";

class RenolitionApp extends Component {

  constructor(props) {
    super(props);

    this.setLoggedInEntityRole = (role) => {
      this.setState( { logged_in_entity_role: role } );
    };

    this.state = {
      logged_in_entity_role: null,
      setLoggedInEntityRole: this.setLoggedInEntityRole,
    }
  }

  render() {
    return (
      <AuthContext.Provider value={ this.state }>
          <Router>
            <Switch>
                <Route path="/v1" render={ props => <QuoteContainer {...props} />} /> 
                <Route render={ props => <DefaultContainer {...props} />} /> 
            </Switch>
          </Router>
      </AuthContext.Provider>
      );
    }
  }

  const QuoteContainer = () => {
      return  <Switch>
                <Route path="/v1/bay-electric" exact render={ props => <EvQuote {...props} />} />
                <Route path="/v1/project_view/:id" exact render={ props => <EvProjectView {...props} />} />
                <Route path="/v1/project_form" exact render={ props => <ProjectForm {...props} />} />
                <Route path="/v1/project_form_plan" exact render={ props => <ProjectForm {...props} />} />
                {/* <Route path="/v1/planning_code" exact render={ props => <PlanningCodeList {...props} />} /> */}
                
              </Switch>
        
        
  }

  const DefaultContainer = () => {
      return <>
                    <Authentication/>
                    <Header/>
                    <ScrollToTop />
                    <div class="mainContent">
                      <Switch>
                          <Route path="/" exact render={ props => <Index {...props} />} /> 

                          <Route path="/code/intro" exact render={ props => <CodeLanding {...props} />} />
                          {/* <Route path="/code/sf_planning" exact render={ props => <PlanningCodeSearch {...props} />} /> */}

                          <Route path="/tos" exact render={ props => <TermsOfService {...props} />} /> 
                          <Route path="/video" exact render={ props => <Video {...props} />} /> 
                          <Route path="/tutorials" exact render={ props => <Tutorials {...props} />} /> 
                          <Route path="/sign_up_video" exact render={ props => <SignUpVideo {...props} />} /> 
                          <Route path="/privacy_policy" exact render={ props => <PrivacyPolicy {...props} />} /> 
                          <Route path="/about_us" exact render={ props => <AboutUs {...props} />} /> 
                          <Route path="/reviews" exact render={ props => <Review {...props} />} /> 
                          <Route path="/review/confirm_email/:id" exact render={ props => <ReviewEmailConfirmation {...props} />} /> 

                          <Route path="/account" exact render={ props => <Account {...props} />} /> 
                          <Route path="/login" exact render={ props => <Login {...props} />} />  
                          <Route path="/password_reset/:id" exact render={ props => <PasswordReset {...props} />} /> 

                          <Route path="/permit_stats" exact render={ props => <PermitStats {...props} />} /> 
                          <Route path="/ads" exact render={ props => <AdsLanding {...props} />} /> 

                          <Route path="/agent/intro" exact render={ props => <AgentLanding {...props} />} />
                          <Route path="/agent/favorite_vendors" exact render={ props => <AgentFavoriteVendorList {...props} />} /> 
                          <Route path="/agent/contacts" exact render={ props => <AgentContacts {...props} />} /> 
          
                          <Route path="/agent/start" exact render={ props => <AgentIndex {...props} />} />
                          <Route path="/vendor_detail" exact render={ props => <VendorDetail {...props} />} />
                          <Route path="/agent" exact render={ props => <AgentIndex {...props} />} />
                          <Route path="/contact/start" exact render={ props => <ContactIndex {...props} />} />
                          <Route path="/contact/agent_vendors" exact render={ props => <ContactVendorList {...props} />} />
                          <Route path="/contact/vendor_discover" exact render={ props => <VendorDiscover {...props} />} />
                          <Route path="/contact/vendor_search" exact render={ props => <VendorSearch {...props} />} />
                          <Route path="/agent/vendor_review" exact render={ props => <VendorReview {...props} />} />
                          <Route path="/agent/agent_friends" exact render={ props => <AgentFriends {...props} />} />
                          <Route path="/agent/agent_search" exact render={ props => <AgentFriendsSearch {...props} />} />
                          <Route path="/agent/vendor_search" exact render={ props => <VendorSearch {...props} />} />
                          <Route path="/agent/vendor_discover" exact render={ props => <VendorDiscover {...props} />} />
                          <Route path="/agent/vetted_vendors" exact render={ props => <VettedVendors {...props} />} />
                          <Route path={["/renolition_agent_invite/:id", "/agent_invite/:id", "/vendor_invite/:id"]} exact render={ props => <SignUp {...props} />} />
                          <Route path="/contact/:id" exact render={ props => <ContactLogin {...props} />} />

                          <Route path="/property_inspector" exact render={ props => <PropertyInspector {...props} />} />
                          <Route path="/gmail_import" exact render={ props => <GmailImport {...props} />} />

                          <Route path="/guides" exact render={ props => <Guides {...props} />} />
                          <Route path="/guides/kitchen_back_splash" exact render={ props => <ReplaceKitchenBacksplash {...props} />} />
                          <Route path="/guides/kitchen_appliances" exact render={ props => <ReplaceKitchenAppliances {...props} />} />
                          <Route path="/guides/kitchen_countertop" exact render={ props => <ReplaceKitchenCountertop {...props} />} />

                          <Route path="/vendor/plans/:id" exact render={ props => <VendorPlans {...props} />} />
                          <Route path="/vendor/start" exact render={ props => <VendorIndex {...props} />} />
                          <Route path="/vendor/account" exact render={ props => <VendorAccount {...props} />} />
                          <Route path="/vendor/vendor_search" exact render={ props => <VendorSearch {...props} />} />
                          <Route path="/vendor/vetted_vendors" exact render={ props => <VettedVendors {...props} />} />
                          <Route path="/vendor/requests" exact render={ props => <VendorRequests {...props} />} />
                          <Route path="/vendor/vendor_discover" exact render={ props => <VendorDiscover {...props} />} />
                          <Route path="/admin/permit" exact render={ props => <PermitsAdmin {...props} />} />
                      </Switch>
                    </div>
                  <Footer/>

                  </>
  }

  export default RenolitionApp;