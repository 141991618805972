import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { isValidEmail } from "utils/helpers";
import {Card, Button, Spinner, ListGroup} from 'react-bootstrap';
import { generic_post_api, generic_get_api } from "api/generic_api";
import { AgentRow } from "./agent_friends";

import {
    AGENT_AUTO_COMPLETE_URL,
    AGENT_SEARCH_URL,
    AGENT_FRIENDS_URL,
} from 'utils/constants';

const SearchStages = {
    START: 'start',
    SEARCHING: 'searching',
    SHOW_SEARCH_RESULT: 'show_search_result',
    SHOW_INVITE_SECTION: 'show_invite_section',
    INVITING: 'inviting', // sending invite to join Renolition
    CONNECTING: 'connecting', // already part of Renolition, connecting
    CONNECTING_COMPLETE: 'connecting_complete',
}

class AgentFriendsSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_result: null,
            search_stage: SearchStages.START,
            search_term: null,
            invite_candidate: null,
        };

        this.toggleAgentDetailDialog = this.toggleAgentDetailDialog.bind(this);
        this.fetchAgents = this.fetchAgents.bind(this);
        this.connect = this.connect.bind(this);
        this.setInviteCandidateEmail = this.setInviteCandidateEmail.bind(this);
    }

    setInviteCandidateEmail(e) {
        let candidate = this.state.invite_candidate;
        candidate.email = e.target.value;
        this.setState({
            invite_candidate: candidate,
        })
    }

    connect(target_agent_id) {
        this.setState({
            search_stage: SearchStages.CONNECTING,
        });
        
        let target_email = null;

        if (this.state.invite_candidate) {
            target_email = this.state.invite_candidate.email;
        }

        var request = { 
            target_agent_id: target_agent_id,
            target_email: target_email,
        }

        generic_post_api(request, AGENT_FRIENDS_URL)
        .then(
            (result) => {
                if (result.status === 201) {
                    this.setState({
                        search_stage: SearchStages.CONNECTING_COMPLETE,
                    });
                    return result.data.json();        
                }
                if (result.status === 206) {
                    this.setState({
                        search_stage: SearchStages.SHOW_INVITE_SECTION,
                    });
                    return result.data.json();       
                }
            }
        ).then(
            data => {
                this.setState({
                    invite_candidate: data,
                });
                window.scrollTo(0, -100);
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'agent_friend_search'})
        document.title = "Add new agent to your network"
    }

    toggleAgentDetailDialog(value) {
        this.setState({ show_agent_dialog: value });
    }

    fetchAgents(search_term) {
        
        if (!search_term) {
            return;
        }

        this.setState({
            search_stage: SearchStages.SEARCHING,
        });

        var request = {
            search_string: search_term
        }

        generic_get_api(AGENT_SEARCH_URL, request)
        .then(
            data => {
                if (data){
                    this.setState({
                        search_result: data,
                        search_stage: SearchStages.SHOW_SEARCH_RESULT,
                    });
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    render() {
        const {search_stage, invite_candidate} = this.state;

        return(
            <div class="agentSearchEnvelope">
                <div class="agentSearchPage">
                    <div class="container agentSearchHeader">
                    <div class="row"> <div class="col display-6">Real estate agent search</div></div>
                    <div class="row mt-4"> <div class="col">Search for agents and add them to your network.</div></div>
                        
                        <AgentSearchBox 
                            submitSearch={ this.fetchAgents }
                            search_stage={ search_stage }
                        />
                            
                        { (search_stage === SearchStages.CONNECTING || search_stage === SearchStages.INVITING) &&
                            <>
                                <div class="text-center mt-4">Sending connection request</div>
                                <div class="invitingSpinner text-center  spinner"> <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></div>
                            </>
                        }

                        { search_stage === SearchStages.SHOW_INVITE_SECTION && invite_candidate &&
                            <div class="container inviteWrapper mt-5">
                                <div class="col_2">
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <p><strong>{invite_candidate.first_name} {invite_candidate.last_name}</strong> has not joined yet.</p>
                                                <p>Invite them to join.</p>
                                            </Card.Text>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col">
                                                        <input type="email" class="form-control" placeHolder="Agent's email" onChange={ (e) => this.setInviteCandidateEmail(e)}/>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col text-center">
                                                        <Button variant="primary" disabled={!isValidEmail(invite_candidate.email)} onClick={ () => this.connect(invite_candidate.id) }>Connect</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        }

                        { ( search_stage === SearchStages.CONNECTING_COMPLETE) &&
                            <>
                                <div class="text-center mt-4">Request successfully sent</div>
                                <div class="text-center mt-2"> 
                                    <Button variant="primary" onClick={()=> this.props.history.push('/agent/agent_friends')}>Continue</Button>
                                </div>
                            </>
                        }

                        { search_stage === SearchStages.SHOW_SEARCH_RESULT &&  
                            <>
                            {
                                this.state.search_result.length == 0 ? 
                                    <div class="mt-4 text-center">We found no results. Try again.</div>
                                :
                                <ListGroup className="mt-4">
                                    <ListGroup.Item className="text-start ps-0 pe-0 bg-light">
                                        <div class="container header">
                                            <div class="row align-items-center friendRow">
                                                <div class="col-4 text-truncate"><small>Name</small></div>
                                                <div class="col-auto dreNumber"><small>DRE #</small></div>
                                                <div class="col-3 text-truncate"><small>Address</small></div>
                                                <div class="col-2 text-truncate medium-container"><small>City</small></div>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                            {
                                                this.state.search_result.map(
                                                    (agent) => {
                                                        return  <AgentRow 
                                                                    agent={ agent }
                                                                    connect={ this.connect }
                                                                    row_mode='friends_search'
                                                                />
                                                    }
                                                )
                                            }
                                </ListGroup>
                            }
                            </>
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}

class AgentSearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: null,
            suggestions: [],
            activeSuggestion: 0,
        };

        this.inputChanged = this.inputChanged.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.inputOnKeyDown = this.inputOnKeyDown.bind(this);
        this.suggestionOnClick = this.suggestionOnClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onBlur(e) {
        // https://reactjs.org/docs/events.html#focus-events
        // https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
        if (!e.currentTarget.contains(e.relatedTarget)) {   
            this.setState({
                suggestions: [],
                activeSuggestion: 0
            });
        }
    }

    inputOnKeyDown(e) {
        const { suggestions, activeSuggestion } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                suggestions: [],
                searchString: suggestions[activeSuggestion]
            });
            this.submitSearch(null, suggestions[activeSuggestion]);
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion <= 0) {
                return;
            }   

            this.setState(
                { 
                    activeSuggestion: activeSuggestion - 1,
                    searchString: suggestions[activeSuggestion - 1]
                }
            );
        }
        
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === suggestions.length) {
                return;
            }

            this.setState(
                { 
                    activeSuggestion: activeSuggestion + 1,
                    searchString: suggestions[activeSuggestion + 1]
                }
            );
        }
    }

    suggestionOnClick (e) {
        this.setState({
            activeSuggestion: 0,
            suggestions: [],
            searchString: e.currentTarget.innerText
          });

        this.submitSearch(null, e.currentTarget.innerText);
    }

    inputChanged(e) {

        this.setState({
            suggestions: [],
            searchString: e.target.value
          });

        generic_get_api(AGENT_AUTO_COMPLETE_URL, {"prefix": e.target.value})
        .then(
            data => {
                this.setState({
                    suggestions: data.result,
                    activeSuggestion: -1
                });
            }
        );

    }

    submitSearch(e, input) {
        var searchString = input != null ? input : this.state.searchString    
        this.props.submitSearch(searchString);
    }

    render() {

        const {suggestions, activeSuggestion } = this.state;
        let suggestionsListComponent;

        suggestionsListComponent = (
            <ul class="suggestions">
                { suggestions && suggestions.map((suggestion, index) => {
                    let className;

                    if (index === activeSuggestion) {
                        className = "suggestion-active";
                    }

                    return (
                        <>
                            <li key={suggestion} onClick= { this.suggestionOnClick } className={className} tabindex="1">
                                {suggestion}
                            </li>
                        </>
                    );
                    })}
            </ul>
        );

        return (
                <div class="container agentSearchBox mt-4">
                    <div class="nameField" onBlur={ this.onBlur }>
                        <div class="input-group">
                            <input type="search" 
                                class="form-control"
                                placeHolder="Agent name or license number" 
                                onChange={ this.inputChanged }
                                onKeyDown={ this.inputOnKeyDown }
                                value={ this.state.searchString }
                            />  
                            {
                                this.props.search_stage === SearchStages.SEARCHING ?
                                    <Button variant="warning" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </Button>
                                :
                                    <Button variant="warning" 
                                        onClick={ (e) => { this.setState({ activeSuggestion: 0,suggestions: []}); this.submitSearch(e, null)} } 
                                        disabled={this.props.search_stage === SearchStages.SEARCHING}>Search
                                    </Button>

                            }
                        </div>
                        {suggestionsListComponent}
                    </div>
                </div>
        );
    }
}

export default AgentFriendsSearch;