import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {COMPANY_NAME} from 'utils/constants';
import {trackEvent, EventNames} from 'utils/mixpanel';


class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
        };
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'about_us'})
        document.title = "About Renolition Inc"
    }

    render() {

        return(
            <div class="aboutUsEvenlope topOfPageMargin">
                <div class="aboutUs">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />
                    <div class="grid" >
                        <div class="content">
                            <div class="title">About {COMPANY_NAME}</div>
                            <div class="body mt-3">
                                <p>
                                    <strong>{COMPANY_NAME}</strong> uses AI to simplify working with local planning and building codes for architects, engineers, designers, and developers. 
                                </p>
                                <p>
                                    The planning code of each city determines what can be built in each part of the city. Most planning code is difficult to understand, changes
                                    frequently and is written in a circular fashion.
                                </p>
                                <p>
                                    Building codes determine how buildings need to be constructed to be safe for its occupants. 
                                    As building codes evolve and become more complex, identifying the right sections that apply to a project becomes more difficult.
                                </p>
                                <p>
                                    Renolition makes identifying relevant planning and building code sections seamless and ensures final construction plans get approved faster.
                                </p>
                                <p>
                                    <strong>Renolition Inc.</strong> is located in San Francisco, California. If you would like to find out more or have any comments,&nbsp;<a onClick={ () => this.setContactUsDisplay(true) }>contact us.</a> 
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        )
    }
}

export default AboutUs;