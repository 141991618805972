import React, { Component, useState } from "react";


import { REVIEW_URL, REVIEW_ATTACHMENT} from 'utils/constants';
import ContactUs from 'pages/contact_us';
import starWhite from 'assets/reputation/star_white.svg';
import starYellow from 'assets/reputation/star_yellow.svg';
import {trackEvent, EventNames} from 'utils/mixpanel';
import dog_200_200 from 'assets/reputation/dog_200_200.jpg';
import review_intro from 'assets/reputation/review_intro.png';
import toolTip_16 from 'assets/reputation/tooltip_blue_i.svg';

import {Button, Spinner,  ToggleButton, ButtonGroup, OverlayTrigger, Tooltip, Card} from 'react-bootstrap';

const API_RESULTS = {
    CODE_SEARCHING: 'code_searching',
    CODE_ERROR: 'code_error',
    CODE_DUPLICATE: 'code_duplicate',
    REVIEW_SUBMITTING: 'review_submitting',
}

const PAGES = {
    INTRO: 'intro',
    CONFIRM_DETAILS: 'confirm_details',
    REWARD_SELECTION: 'reward_selection',
    EMAIL_ENTRY: 'email_entry',
    PAGE_1_QUESTIONS: 'page_1_questions',
    PAGE_2_QUESTIONS: 'page_2_questions',
    PHOTOS: 'photos',
    CONFIRMATION: 'confirmation' 
}

var formatPermitDescription = (permitDescription) => {

    if (permitDescription.length > 145) {
        return permitDescription.substr(0, 145) + '...';
    }
    
    return permitDescription
    
}

const CODE_MAX_LENGTH = 6;

const MIN_COMMENT_CHARACTERS = 125;

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: null,
            page: PAGES.INTRO,
            apiResults: null,
            displayContactUs: false,
            rating: {
                photos: [],
                quality: 0,
                communication: 0,
                punctual: 0,
                estimateComparison: null,
                projectDuration: null,
                totalPaid: null,
                wouldRecommend: null,
                comment: null,
                privateFeedback: null,
                projectDescription: null,
                selectedRewardOfferId: null,
                email: null,
                reasonForHire: {
                    price: null,
                    reviews: null,
                    referral: null,
                    quality: null,
                    other: null,
                    otherText: null,
                },
            }
        };

        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
        this.codeChanged = this.codeChanged.bind(this);
        this.submitCodeSearch = this.submitCodeSearch.bind(this);
        this.moveToNextPage = this.moveToNextPage.bind(this);
        this.starOnClick = this.starOnClick.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.multiSelectClick = this.multiSelectClick.bind(this);
        this.wouldRecommendClick = this.wouldRecommendClick.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.textAreaChanged = this.textAreaChanged.bind(this);
        this.otherTextBoxChanged = this.otherTextBoxChanged.bind(this);
        this.addPhotoToReview = this.addPhotoToReview.bind(this);
        this.removePhoto = this.removePhoto.bind(this);
    }

    removePhoto(index) {
        var currentRating = this.state.rating;
        currentRating.photos.splice(index, 1);

        this.setState({
            rating: currentRating
        })

        trackEvent(EventNames.REVIEW_ATTACHMENT_REMOVE)
    }

    addPhotoToReview(photo) {
        var currentRating = this.state.rating;
        currentRating.photos.push(photo);

        this.setState({
            rating: currentRating
        })
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'review'})
    }

    otherTextBoxChanged(e) {
        var curRating = this.state.rating;
        var curReason = curRating.reasonForHire;
        curReason.otherText = e.target.value;

        this.setState({ rating: curRating })
    }

    submitReview() {
        var request = { 
            rating: this.state.rating,
            professional: this.state.professional,
            permit: this.state.permit,
            code: this.state.code,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(REVIEW_URL);
        
        this.setState({ apiResults: API_RESULTS.REVIEW_SUBMITTING });

        trackEvent(
            EventNames.API_REQUEST, 
            {
                'data_1': this.state.code,
                'data_2': REVIEW_URL,
            });

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setState({ 
                        page: PAGES.CONFIRMATION,
                        apiResults: null,
                        rating: {
                            photos: [],
                            quality: 0,
                            communication: 0,
                            punctual: 0,
                            estimateComparison: null,
                            totalPaid: null,
                            wouldRecommend: null,
                            comment: null,
                            projectDescription: null,
                            email: null,
                            reasonForHire: {
                                price: null,
                                reviews: null,
                                referral: null,
                                quality: null,
                                other: null,
                                otherText: null,
                            },
                        }
                    })

                    window.scrollTo(0, 0);
                }
                else {
                    this.setState(
                        { 
                            page: PAGES.INTRO,
                            apiResults: null,
                            rating: {
                                photos: [],
                                quality: 0,
                                communication: 0,
                                punctual: 0,
                                estimateComparison: null,
                                totalPaid: null,
                                wouldRecommend: null,
                                comment: null,
                                projectDescription: null,
                                email: null,
                                reasonForHire: {
                                    price: null,
                                    reviews: null,
                                    referral: null,
                                    quality: null,
                                    other: null,
                                    otherText: null,
                                },
                            }
                        }
                    )
                }
            }
        );
    }

    textAreaChanged(property, e) {
        var curRating = this.state.rating;
        curRating[property] = e.target.value;
        this.setState( { rating: curRating } );
    }

    wouldRecommendClick(value) {
        var curRating = this.state.rating;
        curRating.wouldRecommend = value;
        this.setState({ rating: curRating });
    }

    multiSelectClick(type) {
        var currentRating = this.state.rating;
        currentRating.reasonForHire[type] = !currentRating.reasonForHire[type];

        if (!currentRating.reasonForHire.other) {
            currentRating.reasonForHire.otherText = null;
        }

        this.setState( { rating: currentRating })
    }

    valueChanged(e, type) {
        var currentRating = this.state.rating;
        currentRating[type] = e.target.value;
        this.setState( { rating: currentRating } );
    }

    starOnClick(category, value) {
        var currentRating = this.state.rating;
        currentRating[category] = value;

        this.setState({
            rating: currentRating
        })
    }

    moveToNextPage(nextPage) {

        trackEvent(EventNames.SECTON_TRANSITION, {
            'data_1': 'next_page',
            'data_2': nextPage,
            'data_3': this.state.rating.email,
        })

        this.setState({ page: nextPage });
        window.scrollTo(0, 0);
    }

    codeChanged(e) {
        const re = /^[0-9A-Za-z]*$/;
        
        let currentCode = e.target.value.toUpperCase()

        if (!currentCode) {
            this.setState({ code: currentCode });
        }

        else if (currentCode && re.test(currentCode) && currentCode.length <= CODE_MAX_LENGTH) {
            this.setState({ code: currentCode });

            if (currentCode.length === CODE_MAX_LENGTH)
            {
                this.submitCodeSearch(currentCode); 
            }
        }
    }

    submitCodeSearch(code) {

        var url = new URL(REVIEW_URL);
        url.searchParams.append("code", code)

        this.setState({ apiResults: API_RESULTS.CODE_SEARCHING });

        trackEvent(EventNames.API_REQUEST, 
            {
                'data_2': REVIEW_URL,
                'data_1': code,
                'data_3': 'GET',
            })

        fetch(url)
        .then(
            (result) => {
                if (result.status == 200) {
                    return result.json();       
                }
                else if (result.status == 400) {
                    this.setState({ apiResults: API_RESULTS.CODE_ERROR });
                }
                else if (result.status == 409) {
                    this.setState({ apiResults: API_RESULTS.CODE_DUPLICATE });
                }
                else {
                    this.setState({ apiResults: null });
                }
            }
        ).then(
            data => {
                if (data) {
                    this.setState({
                        professional: data.professional,
                        permit: data.permit,
                        page: PAGES.CONFIRM_DETAILS,
                        apiResults: null,
                    });
                    window.scrollTo(0, 0);
                }
            }
        );
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }

    render() {
        return(
            <div class="reviewEnvelope">
                <div class="reviewPage">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />
                    { (this.state.page === PAGES.INTRO) && 
                        <div class="container codeSection"> 
                            <div class="row"><div class="col display-6 text-center">A New Approach to Reviews</div></div>
                            <div class="row mt-4"><div class="col text-center"><img src={review_intro}/></div></div>
                            <div class="row mt-5">
                                <div class="col">
                                    <Card>
                                        <Card.Body>
                                            <div class="container">
                                                <div class="row mt-4">
                                                    <div class="col h5 text-center">Enter your unique review code to start </div>
                                                </div>
                                                <div class="row mt-2 d-flex justify-content-center">
                                                    <div class="col text-center inputDiv">
                                                        <input id="codeInput" type="text" placeHolder="Code" class="form-control" onChange={ this.codeChanged } value={ this.state.code } maxLength/>
                                                        <label for="codeInput" class="form-label fw-light">Hint: It's on the card you received</label>
                                                        {
                                                            this.state.apiResults === API_RESULTS.CODE_SEARCHING &&
                                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        }
                                                        {
                                                            this.state.apiResults === API_RESULTS.CODE_ERROR &&
                                                                <div class="text-danger text-center mt-2">We could not find the code. Please try again.</div>
                                                        }

                                                        {
                                                            this.state.apiResults === API_RESULTS.CODE_DUPLICATE &&
                                                                <div class="text-danger text-center mt-2">Review has already been submitted.</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div class="row mt-5">
                                    <div class="col text-center">
                                        Renolition is building a community of trusted construction professionals. Your authentic review helps others to hire with confidence. 
                                        Receive your reward once completed.
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col text-center">
                                        We <i>directly</i> contact homeowners like you that hired for permitted jobs, and ask for a review of their construction 
                                        professional's work.&nbsp;<u>Now every single review can be trusted.</u>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col text-center">
                                        Questions? <a role="button" class="text-decoration-underline" onClick={ () => this.setContactUsDisplay(true) } >Contact us.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    { this.state.page === PAGES.CONFIRM_DETAILS && 
                        <div class="container confirmDetailsSection">
                            <div class="row"><div class="col h3 text-center">Confirm professional &amp; project</div></div>
                            <div class="row mt-3">
                                <div class="col">
                                    <Card>
                                        <Card.Body>
                                            <div class="container">
                                                <div class="row gy-3">
                                                    <div class="col-12 col-md-6">
                                                        <div class="text-success">Construction professional</div>
                                                        <div class=""><strong>{this.state.professional.name}</strong></div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="text-success">Property adddress</div>
                                                        <div >{this.state.permit.address}</div>
                                                        <div >{this.state.permit.city} {this.state.permit.state}</div>
                                                    </div>
                                                </div>

                                                <div class="row gy-3 mt-1">
                                                    <div class="col-12 col-md-6">
                                                        <div class="text-success">License type</div>
                                                        <div >{this.state.professional.discipline }</div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="text-success">
                                                            Approximate construction dates&nbsp;
                                                            <OverlayTrigger
                                                                    key="constructionDateTT"
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id="constructionDateTT">
                                                                            The dates during which the permit was active. The actual construction dates may vary.
                                                                        </Tooltip>
                                                                    }
                                                                    >
                                                                    <img src={toolTip_16} class="toolTipImage mb-1"/>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div>From {this.state.permit.from} to {this.state.permit.to}</div>
                                                    </div>
                                                </div>

                                                <div class="row gy-3 mt-1">
                                                    <div class="col-12">
                                                        <div class="text-success">Permit/work description</div>
                                                        <div class="">{formatPermitDescription(this.state.permit.permit_description)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-12 text-center">
                                    Please submit an authentic &amp; fair review for this pro and their work.
                                    To start the review, confirm that you hired this professional for the above 
                                    property &amp; agree to our <a href="/tos" target="_blank">review guidelines</a>.
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-center">
                                   <Button variant="primary" onClick={() => this.moveToNextPage(PAGES.EMAIL_ENTRY) }>Confirm & Continue</Button>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-12 text-center">
                                    If this information is not accurate, <a class="text-decoration-underline" role="button" onClick={ () => this.setContactUsDisplay(true) } >contact us.</a> 
                                </div>
                            </div>
                        </div>
                    }
                    {/* <RewardsComponent
                        professional={ this.state.professional }
                        moveToNextPage={ this.moveToNextPage }
                        page={ this.state.page }
                        rating={ this.state.rating }
                        setSelectedRewardOffer= { this.setSelectedRewardOffer }
                    /> */}
                    <EmailEntryComponent 
                        rating={ this.state.rating }
                        valueChanged={ this.valueChanged }
                        moveToNextPage={ this.moveToNextPage }
                        page={ this.state.page }
                    />

                    <Unit_1_Component 
                        professional={ this.state.professional }
                        starOnClick={ this.starOnClick }
                        rating={ this.state.rating }
                        valueChanged={ this.valueChanged }
                        moveToNextPage={ this.moveToNextPage }
                        page={ this.state.page }
                    />

                    <Unit_2_Component 
                        professional={ this.state.professional }
                        rating={ this.state.rating }
                        valueChanged={ this.valueChanged }
                        moveToNextPage={ this.moveToNextPage }
                        page={ this.state.page }
                        multiSelectClick={ this.multiSelectClick }
                        otherTextBoxChanged={ this.otherTextBoxChanged }
                        wouldRecommendClick={ this.wouldRecommendClick }
                        textAreaChanged={ this.textAreaChanged }
                    />
                   
                   <PhotoComponent 
                        page={ this.state.page }
                        code={ this.state.code }
                        submitReview= { this.submitReview }
                        addPhotoToReview={ this.addPhotoToReview }
                        photos={ this.state.rating.photos }
                        removePhoto={ this.removePhoto }
                        apiResults={ this.state.apiResults }
                   />

                    <CompleteComponent 
                        page={ this.state.page }
                        professional= { this.state.professional }
                    />
                </div>
                
            </div>
        );
    }
}

function EmailEntryComponent(props) {
    const { page, moveToNextPage, rating, valueChanged } = props;

    if (page !== PAGES.EMAIL_ENTRY) {
        return null;
    }

    return(
        <div class="container emailEntrySection">
            <div class="container">
                <div class="row">
                    <div class="col h3">Claim your reward</div>
                </div>
                <div class="row mt-3">
                    <div class="col">Your reward arrives by email. Enter your email below or leave blank if you prefer no reward.</div>
                </div>
                <div class="row mt-4 d-flex justify-content-center">
                    <div class="col emailField">
                        <input type="text" class="form-control" placeHolder="Your email address (Optional)" onChange={(e) => valueChanged(e, 'email')} value={ rating.email } />
                    </div>
                </div>
                <div class="row m-4">
                    <div class="col text-center"><Button variant="primary" onClick={ () => moveToNextPage(PAGES.PAGE_1_QUESTIONS) }> Next</Button></div>
                </div>
            </div>
        </div>
    )
}

function CompleteComponent(props) {

    const { page } = props;

    if (page !== PAGES.CONFIRMATION) {
        return null;
    }

    return (
        <div class="container completeSection">
            <div class="display-6 text-center">Thank you for your review</div>
            <div class="fs-5 mt-5 text-center">
                If you provided your email address, please check your mailbox for an email from <strong>support@renolition.com</strong>. 
            </div>
            <div class="fs-5 mt-3 text-center">
                Allow 1-2 hours for email delivery. Remember to also check your spam folder.
            </div>
        </div>
    )
}

function PhotoComponent(props) {
    const [showSpinner, setShowSpinner] = useState(false);

    if (props.page != PAGES.PHOTOS) { return null; }

    const fileRef = React.createRef();
    const onPhotoBoxClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev) => {

        if (fileRef.current && fileRef.current.files.length > 0)
        {
            uploadFile();
            ev.target.value = null;
        }
    }

    const uploadFile = () => {

        var data = new FormData();
        data.append('file', fileRef.current.files[0]);
        data.append('review_code', props.code);

        const requestOptions = {
            method: 'PUT',
            body: data
        };

        setShowSpinner(true);

        trackEvent(EventNames.API_REQUEST, {
            'data_1': props.code,
            'data_2': REVIEW_ATTACHMENT,
        });

        fetch(REVIEW_ATTACHMENT, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {                    
                    return result.json();
                }
                else {
                    setShowSpinner(false);
                }
            }
        ).
        then(
            data => {

                const pathParts = data.file_path.split("/");
                
                let photoPath = data.file_path;
                let thumbNailPath = data.thumb_nail_path;
                let fileName = pathParts[pathParts.length - 1];

                const photo = {
                    path: photoPath,
                    location: null,
                    beforeAfter: null,
                    comment: null,
                    fileName: fileName,
                    thumbNailPath: thumbNailPath
                }
        
                props.addPhotoToReview(photo);
                setShowSpinner(false);
            }
        );
    }

    return (
        <div class="container photos">
            <div class="container">
                <div class="row"><div class="col h3 text-center">Max loves home improvement photos</div></div>
                <div class="row mt-4"><div class="col text-center"><img src={dog_200_200} /></div></div>
                <div class="row mt-4"><div class="col text-center fs-5">Share up to six (6) "before &amp; after" photos of your project</div></div>

                {   props.photos.length > 0 &&
                        <div class="row gy-2 mt-3">
                            <div class="col">
                                <Card><Card.Body>  
                                    <div class="container">
                                            <div class="row gy-2">
                                            {
                                                props.photos.map(
                                                    (photo, index) => {
                                                        return <div class="col-12 col-md-6">
                                                                    <div class="container">
                                                                        <div class="row">
                                                                            <div class="col-6">{photo.fileName}</div>
                                                                            <div class="col-6">
                                                                                <a class="text-decoration-underline" role="button" onClick={() => props.removePhoto(index) }>Remove</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                    }
                                                )
                                            }
                                        </div>
                                    </div>
                                </Card.Body></Card>
                            </div>
                        </div>
                        
                }
                {props.photos.length < 6 &&
                    <div class="row mt-3">
                        <div class="col text-center">
                            { showSpinner ?
                                <Button variant="warning" disabled>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    Uploading...
                                </Button>
                                :
                                <Button variant="warning" onClick={ onPhotoBoxClick }>Add a photo</Button>
                            }
                            <input  type="file" id="actual-btn"
                                    ref={fileRef} 
                                    style={{display: 'none'}}
                                    onChange={ (e) => filePickerChanged(e) }
                            />
                        </div>
                    </div>
                }
                <div class="row mt-5">
                    <div class="col text-center">
                        {props.apiResults === API_RESULTS.REVIEW_SUBMITTING ?
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting...
                            </Button>
                            :

                            <>
                                { props.photos.length > 0 ? 
                                    <Button 
                                        variant="primary" 
                                        onClick={ props.submitReview }
                                    >Submit review</Button> 
                                :

                                    <Button 
                                        variant="primary" 
                                        onClick={ props.submitReview }
                                    >Submit review without photos</Button> 
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
       

        </div>
    )
}

function Unit_2_Component(props) {

    if (props.page != PAGES.PAGE_2_QUESTIONS) { return null; }

    const {professional, rating, textAreaChanged} = props;


    const commentOK = () => {
        return rating.comment && rating.comment.trim().length >= MIN_COMMENT_CHARACTERS
    }

    const unit_2_OK = () => {
        var curReason = rating.reasonForHire;

        // if we pass this test, either 'other' is not selected, or it is, together with its text
        if (curReason.other && !curReason.otherText) {
            return false;
        }
        // if 'other' is selected, then its text must also be specified because of the test above.
        let reasonForHireSpecified = curReason.price || curReason.reviews || curReason.referral || curReason.quality || curReason.other;

        return rating.wouldRecommend != null && commentOK() && reasonForHireSpecified && rating.projectDescription;
    }

    const getToolTip = (key,  text) => {
        return <OverlayTrigger
                    key={key}
                    placement="auto"
                    overlay={
                        <Tooltip id={key}>
                            {text}
                        </Tooltip>
                    }
                    >
                    <img src={toolTip_16} class="toolTipImage mb-1"/>
            </OverlayTrigger>
    }

    return (
        <div class="container unit_2">
             <div class="container">
                <div class="row"><div class="col h4">Please rate {professional.name}</div></div>
                <div class="row mt-4 gy-1">
                    <div class="col-12 col-md-6">
                        What convinced you to hire this professional? (Select all that apply)&nbsp;
                        {getToolTip("reasontHireTT", "The factors that affected your decision. <br></br>Project price: The price was the deciding factor.<br></br>Online reviews: Professional had good online reviews (e.g. Yelp)<br></br>Was recommended: You received a referral<br></br>Past work quality: You like their previous work.")}
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="container multiSelectContainer p-0 mt-3">
                            <div class="row gy-1">
                                <div class="col-6"> 
                                    <ToggleButton
                                            key="price-key"
                                            id="price-key"
                                            type="checkbox"
                                            className="text-nowrap multiSelect"
                                            variant="outline-success"
                                            checked={rating.reasonForHire.price === true}
                                            onChange={() => props.multiSelectClick('price')}
                                        > Project price  
                                    </ToggleButton>
                                </div>
                                <div class="col-6">
                                    <ToggleButton
                                        key="reviews-key"
                                        id="reviews-key"
                                        type="checkbox"
                                        className="text-nowrap multiSelect"
                                        variant="outline-success"
                                        checked={rating.reasonForHire.reviews === true}
                                        onChange={() => props.multiSelectClick('reviews')}
                                    > Online ratings   
                                    </ToggleButton>
                                </div>
                                <div class="col-6">
                                    <ToggleButton
                                        key="referral-key"
                                        id="referral-key"
                                        type="checkbox"
                                        className="text-nowrap multiSelect"
                                        variant="outline-success"
                                        checked={rating.reasonForHire.referral === true}
                                        onChange={() => props.multiSelectClick('referral')}
                                    > Was recommended   
                                    </ToggleButton>
                                </div>
                                <div class="col-6">
                                    <ToggleButton
                                        key="quality-key"
                                        id="quality-key"
                                        type="checkbox"
                                        className="text-nowrap multiSelect"
                                        variant="outline-success"
                                        checked={rating.reasonForHire.quality === true}
                                        onChange={() => props.multiSelectClick('quality')}
                                    > Past work quality   
                                    </ToggleButton>
                                </div>
                           
                                <div class="col-6">
                                    <ToggleButton
                                        key="other-key"
                                        id="other-key"
                                        type="checkbox"
                                        className="text-nowrap multiSelect"
                                        variant="outline-success"
                                        checked={rating.reasonForHire.other === true}
                                        onChange={() => props.multiSelectClick('other')}
                                    > Other   
                                    </ToggleButton>
                                </div>
                                <div class="col-6">
                                    {
                                        rating.reasonForHire.other &&  
                                        <input type="text" className="form-control" placeHolder="Please Specify" onChange={ props.otherTextBoxChanged } value={ rating.reasonForHire.otherText }/>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                           
                    </div>
                </div>
                <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6"> Do you recommend this professional's work?&nbsp;
                    {getToolTip("recommendTT", "Would you recommend this construction professional to someone important, like your best friend or your boss?")}
                    </div>
                    <div class="col-12 col-md-6">
                        <ButtonGroup className="mb-2">
                            <ToggleButton
                                    key="recommend-key-1"
                                    id="recommend-id-1"
                                    type="radio"
                                    variant="outline-danger"
                                    name="recommendPro"
                                    value={false}
                                    checked={false === rating.wouldRecommend}
                                    onChange={() => props.wouldRecommendClick(false)}
                                > No  
                            </ToggleButton>
                            <ToggleButton
                                    key="recommend-key-2"
                                    id="recommend-id-2"
                                    type="radio"
                                    variant="outline-success"
                                    name="recommendPro"
                                    value={true}
                                    checked={rating.wouldRecommend === true}
                                    onChange={() => props.wouldRecommendClick(true)}
                                > Yes    
                            </ToggleButton>
                        </ButtonGroup>
                    </div>
                </div>
                <div class="row mt-5 gy-1">
                    <div class="col-12 col-md-6"> Project details (type of work, parts of the house, duration, etc.)</div>
                    <div class="col-12 col-md-6">
                        <textarea class="form-control" rows="5" placeholder="What did the project include, which parts of the house were modified, how long did it take, etc." onChange={(e) => textAreaChanged('projectDescription', e) } value={ rating.projectDescription }/>
                    </div>
                </div>
                <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6"> Your review (quality of work, timeliness, bidding process, change orders, etc.) &nbsp;
                    </div>
                    <div class="col-12 col-md-6">
                        <textarea class="form-control" rows="5" placeholder="Your detailed review of professional and their work (required)" onChange={ (e) => textAreaChanged('comment', e) } value={ rating.comment }/>
                        {(rating.comment && !commentOK()) &&
                            <div class="text-danger">Tell us a little more...</div>
                        }
                    </div>
                </div>
                {/* <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6"> Private feedback for professional's eyes only&nbsp;
                    {getToolTip("privateFeedbackTT", "Share any private feedback with your professional. Let them know what they did great, or how they can improve.")}
                    </div>
                    <div class="col-12 col-md-6">
                        <textarea class="form-control" rows="5" placeholder="Private feedback for your professional. Anything they did great or can improve? " onChange={ (e) => textAreaChanged('privateFeedback', e) } value={ rating.privateFeedback }/>
                    </div>
                </div> */}

                <div class="row mt-5">
                    <div class="col text-center">
                        <Button 
                            variant="primary" 
                            onClick={ () => props.moveToNextPage(PAGES.PHOTOS) }
                            disabled= { !unit_2_OK() }
                        >Next</Button>
                    </div>
                </div>
             </div>

        </div>
    );
}

function Unit_1_Component(props) {

    if (props.page != PAGES.PAGE_1_QUESTIONS) { return null; }

    const {professional, rating } = props;

    let duration = professional.discipline !== 'CONTRACTOR' ? true : rating.projectDuration != null;

    const unit_OK = () => {
        return rating.quality > 0 && rating.communication > 0 && rating.punctual > 0 && duration;
    }

    const getToolTip = (key,  text) => {
        return <OverlayTrigger
                    key={key}
                    placement="auto"
                    overlay={
                        <Tooltip id={key}>
                            {text}
                        </Tooltip>
                    }
                    >
                    <img src={toolTip_16} class="toolTipImage mb-1"/>
            </OverlayTrigger>
    }

    return (
        <div class="container unit_1">
            <div class="container">
                <div class="row"><div class="col h4">Please rate {professional.name}</div></div>
                <div class="row mt-4 gy-1">
                    <div class="col-12 col-md-6">
                        Quality of work&nbsp;{getToolTip("qualityTT", "Was the quality to your expectations?")}
                    </div>
                    <div class="col-12 col-md-6"><StarComponent name="quality" starOnClick={ props.starOnClick } rating={ rating.quality }/></div>
                </div>
                <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6">
                        Communication&nbsp;{getToolTip("communicationTT", "How easy was it to communicate with this professional? Did they respond to your inquieries in a timely manner?")}
                    </div>
                    <div class="col-12 col-md-6"><StarComponent name="communication" starOnClick={ props.starOnClick } rating={ rating.communication }/></div>
                </div>
                <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6">
                        Punctual / On time&nbsp;{getToolTip("punctualTT", "Did the work start on the agreed-upon time frame? Did the professional hit the agreed upon milestones? Was the project completed reasonably on time?")}
                    </div>
                    <div class="col-12 col-md-6"><StarComponent name="punctual" starOnClick={ props.starOnClick } rating={ rating.punctual }/></div>
                </div>
                { professional.discipline.toLowerCase() === 'contractor' &&
                    <div class="row mt-3 gy-1">
                        <div class="col-12 col-md-6">
                            Project duration from start to finis&nbsp;{getToolTip("durationTT", "How long did this project take from the day the professional started work, until finished?")}
                        </div>
                        <div class="col-12 col-md-6">
                            <select class="form-select" name="questionCategory" onChange={(e) => props.valueChanged(e, 'projectDuration') }>
                                <option disabled selected value>(Required)</option>
                                <option value="1">0-1 month</option>
                                <option value="3">2-3 months</option>
                                <option value="6">4-6 months</option>
                                <option value="12">7-12 months</option>
                                <option value="13">12+ months</option>
                            </select>
                        </div>
                    </div>
                }
                <div class="row mt-3 gy-1">
                    <div class="col-12 col-md-6">
                        Total paid&nbsp;{getToolTip("totalPaidTT", "This information will never be publicly displayed. Collecting this data helps us to show helpful average construction costs for similar work.")}
                    </div>
                    <div class="col-12 col-md-6">
                        <input class="form-control" type="text" placeHolder="Dollar amount (Optional)" onChange={ (e) => props.valueChanged(e, 'totalPaid')} value={ rating.totalPaid }/>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <Button 
                            variant="primary" 
                            onClick={() => props.moveToNextPage(PAGES.PAGE_2_QUESTIONS) }
                            disabled= { !unit_OK() }
                        >Next</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function StarComponent (props){

    return(
        <div class="starComponent">
            <div class="star" onClick={() => props.starOnClick(props.name, 1) }>
                { props.rating > 0 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 2) }>
                { props.rating > 1 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 3) }>
                { props.rating > 2 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 4) }>
                { props.rating > 3 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 5) }>
                { props.rating > 4 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
        </div>
    );
}

export default Review;