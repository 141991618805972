import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {XYPlot, XAxis, YAxis, VerticalBarSeries, Hint} from 'react-vis';
import {trackEvent, EventNames} from 'utils/mixpanel';
import '../../node_modules/react-vis/dist/style.css';

import {
    PERMIT_STATS,
} from 'utils/constants';


class PermitStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
            permits_issued: {
                data: [],
                x_label_map: {},
                hint: null,
            },
            project_frequencies: {
                data: [],
                hint: null,
            },
            permit_durations: {
                data: [],
                hint: null,
            },
            construction_costs: {
                data: [],
                hint: null,
            }
        };
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
        this.fetchStats = this.fetchStats.bind(this);
        this._forgetValue = this._forgetValue.bind(this);
        this._rememberValue = this._rememberValue.bind(this);
    }

    setContactUsDisplay(value) {
        this.setState({displayContactUs: value });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'permit_stats'})
        document.title = "San Francisco statistics for home renovation and construction";
        this.fetchStats();
    }

    fetchStats() {
        var url_permit_stats = new URL(PERMIT_STATS);

        fetch(url_permit_stats)
        .then(
            (result) => {
                if (result.status == 200) {
                    return result.json();       
                }
            }
        ).then(
            data => {
                window.scrollTo(0, 0);
                this.setState({
                    permits_issued: data.permits_issued,
                    project_frequencies: data.project_frequencies,
                    permit_durations: data.permit_durations,
                    construction_costs: data.construction_costs,
                });
            }
        );
    }

    _forgetValue = (type) => {
        let graph = this.state[type];
        graph.hint = null;

        this.setState({
            type: graph
        });
      };
    
      _rememberValue = (value, type) => {
        let graph = this.state[type];
        graph.hint = value;

        this.setState({
            type: graph
        });
      };

    render() {
        const { permits_issued, project_frequencies, permit_durations, construction_costs } =  this.state;
       

        return(
            <div class="permitStatsEvenlope topOfPageMargin">
                <div class="permitStats">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />
                    <div class="graphs">
                        <div class="display-6 statsTitle text-center">San Francisco Construction Statistics</div>
                        <div class="mt-4 statsSubTitle text-center">
                            Renolition analyzes and categorizes thousands of construction permits in San Francisco. We use this data to compile current and historical trends 
                            in the construction and home improvement domain. Homeowners, agents, and vendors can use this data to make more informed decisions, and plan their 
                            projects with more insight. All data and graphs are updated periodically as new permits become available. 
                            <br></br>
                            <br></br>
                            <i>Hover over each graph for additional info.</i>
                        </div>

                        <div class="projectDuration stat" >
                            <div class="fs-4  text-center">Construction &amp; renovation duration</div>
                            <div class="description">This graph shows the average duration, in weeks, for different types of projects. 
                                For this, Renolition analyzed over 30K permits for the last 18 months. A project is complete when the permit is closed.</div>
                            <div class="graph">
                                <XYPlot xType="ordinal" width={365} height={380} xDistance={100} 
                                    margin={{
                                        left: 80,
                                        right: 10,
                                        bottom: 100,
                                        top: 20
                                      }} 
                                    className="plot"
                                >
                                    <XAxis 
                                        tickLabelAngle={-45} 
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <YAxis 
                                        tickFormat={v => `${v+" weeks"}`}  
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <VerticalBarSeries 
                                        data={permit_durations.data} style={{fill: "#274E90", stroke: "#ED1D2A"}}
                                        onValueMouseOver={(d) => this._rememberValue (d, 'permit_durations')}
                                        onValueMouseOut={() => this._forgetValue('permit_durations')}
                                    />
                                    {permit_durations.hint ? 
                                        <Hint value={permit_durations.hint}>
                                            <div style={{background: 'black', text: { color: 'white'}}}>
                                                {permit_durations.hint.x}: {permit_durations.hint.y} weeks
                                            </div>         
                                        </Hint> 
                                    : null}
                                </XYPlot>
                            </div>
                        </div>

                        <div class="constructionCosts stat" >
                            <div class="fs-4  text-center">Construction &amp; renovation cost</div>
                            <div class="description">How expensive is construction in San Francisco? The following graph shows the average cost for a variety of project types. 
                                Renolition reaches out to thousands of homeowners who have recently completed a renovation project and queries the cost of the project. 
                                
                                Note that your project's cost can vary depending on a wide range of factors. 
                            </div>
                            <div class="graph">
                                <XYPlot xType="ordinal" width={360} height={360} xDistance={100} 
                                    margin={{
                                        left: 80,
                                        right: 10,
                                        bottom: 90,
                                        top: 20
                                      }} 
                                    className="plot"
                                >
                                    <XAxis 
                                        tickLabelAngle={-45} 
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <YAxis 
                                        tickFormat={v => `${ "$" + v}`}  
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <VerticalBarSeries 
                                        data={construction_costs.data} style={{fill: "#ED1D2A", stroke: "#274E90"}}
                                        onValueMouseOver={(d) => this._rememberValue (d, 'construction_costs')}
                                        onValueMouseOut={() => this._forgetValue('construction_costs')}
                                    />
                                    {construction_costs.hint ? 
                                        <Hint value={construction_costs.hint}>
                                            <div style={{background: 'black', text: { color: 'white'}}}>
                                                {construction_costs.hint.x}: ${construction_costs.hint.y}
                                            </div>         
                                        </Hint> 
                                    : null}
                                </XYPlot>
                            </div>
                        </div>

                        <div class="projectFrequency stat" >
                            <div class="fs-4  text-center">Project type frequency</div>
                            <div class="description">What are the most popular projects, and which are the least common?
                                The graph shows the break down of permitted projects over the last 18 months. </div>
                            <div class="graph">
                                <XYPlot xType="ordinal" width={365} height={380} xDistance={100} 
                                    margin={{
                                        left: 70,
                                        right: 10,
                                        bottom: 100,
                                        top: 20
                                      }} 
                                    className="plot"
                                    // color="#274E90"
                                >
                                    <XAxis 
                                        tickLabelAngle={-45} 
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <YAxis
                                        tickFormat={v => `${v+" %"}`}  
                                        style={{
                                            text: { fontSize: 13}
                                        }}/>
                                    <VerticalBarSeries 
                                        data={project_frequencies.data} 
                                        style={{fill: "#88B6E7", stroke: "#274E90"}}
                                        onValueMouseOver={(d) => this._rememberValue (d, 'project_frequencies')}
                                        onValueMouseOut={() => this._forgetValue('project_frequencies')}
                                    />
                                    {project_frequencies.hint ? 
                                        <Hint value={project_frequencies.hint}>
                                            <div style={{background: 'black', text: { color: 'white'}}}>
                                                {project_frequencies.hint.x}: {project_frequencies.hint.y} %
                                            </div>         
                                        </Hint> 
                                    : null}
                                </XYPlot>
                            </div>
                        </div>

                        <div class="permitsIssued stat" >
                            <div class="fs-4  text-center">Permit applications per month</div>
                            <div class="description">How busy is the construction and home improvement market at the moment? This graph shows the 
                                number of building permits filed with the city of San Francisco, for each of the last 14 months 
                                (administrative permits and revisions are not included in the count). </div>
                            <div class="graph">
                                <XYPlot height={360} width={360} 
                                    margin={{ left: 50, right: 10, bottom: 70, top: 20}} 
                                    className="plot"
                                >
                                   
                                    <VerticalBarSeries 
                                        data={permits_issued.data} 
                                        style={{fill: "#fcca4e", stroke: "#274E90"}}
                                    />

                                    <XAxis  
                                        tickFormat={v => `${permits_issued.x_label_map[v]}`} 
                                        tickLabelAngle={-45}
                                        tickTotal={permits_issued.data.length / 2}
                                    />
                                    <YAxis title="Number of permits filed" tickTotal={6}/>
                                </XYPlot>
                            </div>
                        </div>
                        <div class="disclaimer">Disclaimer: All data is provided without any warranty for accuracy. Your construction needs, duration and costs may greatly vary.</div>
                    </div>
                   
                </div>
                
            </div>
        )
    }
}

export default PermitStats;