import React, { Component, useState, useEffect } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Card, Button, Spinner, Modal, ListGroup, OverlayTrigger, Tooltip, Popover} from 'react-bootstrap';
import { generic_get_api, generic_delete_api, generic_put_api } from "api/generic_api";

import {
    AGENT_FRIENDS_URL,
    AGENT_FRIEND_REQUEST_URL,
} from 'utils/constants';

const LicenseTypeMap = {
    BROKER: 'Broker',
    CORPORATION: 'Corporation',
    OFFICER: 'Officer',
    SALES_PERSON: 'Sales Person',
    UNKNOWN: 'Other',
}

class AgentFriends extends Component {
    constructor(props) {
        super(props);

        let show_pending_invites_modal = false;
        
        // used when navigating to this page to decide whether to show the pending invites modal right away.
        // for now, used from agent_index.js
        if (this.props.location && this.props.location.state && this.props.location.state.show_pending_invites_modal) {
            show_pending_invites_modal = this.props.location.state.show_pending_invites_modal;
        }

        this.state = {
            agent_friends: [],
            filtered_agent_friends: [],
            show_pending_invites_modal: show_pending_invites_modal,
            show_remove_modal: false,
            confirmation_modal_mode: '',  // determines the language/string to use on RemoveConfirmationDialog
            selected_agent: null,  // the agent that has been selected in the listgroup
            show_learn_more_modal: false,
        };

        this.fetchAgentFriends = this.fetchAgentFriends.bind(this);
        this.fetchPendingInvites = this.fetchPendingInvites.bind(this);
        this.togglePendingInvitesModal = this.togglePendingInvitesModal.bind(this);
        this.cancelInvite = this.cancelInvite.bind(this);
        this.unfriend = this.unfriend.bind(this);
        this.initiateCancelInvite = this.initiateCancelInvite.bind(this);
        this.initiateFriendDelete = this.initiateFriendDelete.bind(this);
        this.hideRemoveoDialog = this.hideRemoveoDialog.bind(this);
        this.setSelectedAgent = this.setSelectedAgent.bind(this);
        this.filterFriendsBoxChanged = this.filterFriendsBoxChanged.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'agent_friends'})
        document.title = "My agent network, connect, build, share";
        this.fetchAgentFriends();
        this.fetchPendingInvites();
    }

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    filterFriendsBoxChanged(e) {
        if (e.target.value === '') {
            this.setState({
                filtered_agent_friends: this.state.agent_friends,
                filter_term: e.target.value,
            });
        } else {
            let term = e.target.value.toLowerCase();
            let output = [];

            for (let i=0; i < this.state.agent_friends.length; i++){
                let friend = this.state.agent_friends[i];
                let friend_name = friend.first_name + " " + friend.last_name;
                if (friend.first_name && friend.first_name.toLowerCase().search(term) >= 0) {
                    output.push(friend);
                }
                else if (friend.last_name && friend.last_name.toLowerCase().search(term) >= 0) {
                    output.push(friend);
                }
               
                else if (friend_name && friend_name.toLowerCase().search(term) >= 0) {
                    output.push(friend);
                }
            }

            this.setState({
                filtered_agent_friends: output,
                filter_term: e.target.value,
            });
        }
    }

    setSelectedAgent(friend) {
        this.setState({selected_agent: friend});
    }
    
    togglePendingInvitesModal(value) {
        this.setState({show_pending_invites_modal: value});
    }

    initiateCancelInvite() {
        this.setState({
            confirmation_modal_mode: 'cancel_invite',
            show_remove_modal: true,
        })
    }

    initiateFriendDelete() {
        this.setState({
            confirmation_modal_mode: 'unfriend',
            show_remove_modal: true,
        })
    }

    hideRemoveoDialog() {
        this.setState({
            show_remove_modal: false,
            selected_agent: null,
        })
    }

    fetchAgentFriends() {
        generic_get_api(AGENT_FRIENDS_URL, {})
        .then(
            data => {
                if (data){
                    this.setState({
                        agent_friends: data,
                        filtered_agent_friends: data,
                    });
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    // duplicated in agent_index
    fetchPendingInvites() {
        generic_get_api(AGENT_FRIEND_REQUEST_URL, {})
        .then(
            data => {
                if (data){
                    this.setState({
                        pending_invites: data,
                    });
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    unfriend(agent) {
        var request = { 
            target_agent_id: agent.id
        }

        generic_delete_api(AGENT_FRIENDS_URL, request)
        .then(
            (result) => {
                if (result.status === 204) {
                    this.hideRemoveoDialog();
                    this.fetchAgentFriends(); 
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } else {

            }
        });
    }

     cancelInvite(agent) {
        var request = { 
            target_agent_id: agent.id,
            action: 'cancel',
        }

        generic_put_api(AGENT_FRIENDS_URL, request)
        .then(
            (result) => {
                if (result.status === 200) {
                    this.hideRemoveoDialog();
                    this.fetchAgentFriends(); 
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    render() {
        return(
            <div class="agentFriendsEnvelope">
                <div class="agentFriendsPage">
                    <div class="container agentFriendsHeader">
                        <div class="row"> <div class="col display-6">My agent network</div></div>
                        <div class="row mt-4"> 
                            <div class="col">Connect with trusted agents and share select vendors.&nbsp;
                                <a class="text-decoration-underline" role="button" onClick={ () => this.setState({ show_learn_more_modal: true})}>Learn more</a>
                            </div>
                            <LearnMoreModal 
                                show_learn_more_modal={this.state.show_learn_more_modal}
                                hideLearnMoreModal={this.hideLearnMoreModal}
                            />
                        </div>
                        { this.state.pending_invites && this.state.pending_invites.length > 0 &&
                            <>
                                <div class="row mt-3">
                                    <div class="col">
                                        <Card>
                                            <Card.Body>
                                                <Card.Text>
                                                    <a class="text-decoration-underline" role="button" onClick={() => this.togglePendingInvitesModal(true)}>You have {this.state.pending_invites.length} pending network requests</a>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                {/* After ignoring or accepting an invite, if no other invite exists, this modal will automatically disappear 
                                because of the length condition above. */}
                                <PendingInvitesModal 
                                    invites={this.state.pending_invites}
                                    show_pending_invites_modal={this.state.show_pending_invites_modal}
                                    togglePendingInvitesModal={this.togglePendingInvitesModal}
                                    fetchPendingInvites={this.fetchPendingInvites}
                                    fetchAgentFriends={this.fetchAgentFriends}
                                />
                            </>
                        }
                        <div class="row mt-4 gx-2 gy-3">
                            {/* Disable the search box for now until we see more agents added to the network */}
                            {/* <div class="col-7 col-md-6">
                                <input 
                                    type="search" 
                                    class="form-control" 
                                    placeHolder="Filter by name" 
                                    onChange={ (e) => this.filterFriendsBoxChanged(e) } value={this.state.filter_term}
                                />
                            </div> */}
                            <div class="col-auto">
                                <Button variant="primary" onClick={ () => this.props.history.push('/agent/agent_search') }>
                                    <i class="fa-solid fa-magnifying-glass-plus"></i><span class="desktopButton">&nbsp;&nbsp;Add agent</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div class="container friendsResult">
                        {
                            this.state.filtered_agent_friends.length === 0 ?
                                <div class="row mt-4">
                                    <div class="col">No agent in your network. Expand your network!</div>
                                </div> 
                            :
                                <ListGroup className="mt-4">
                                    <ListGroup.Item className="text-start ps-0 pe-0 bg-light">
                                        <div class="container header">
                                            <div class="row align-items-center friendRow">
                                                <div class="col-4 text-truncate"><small>Name</small></div>
                                                <div class="col-auto dreNumber"><small>DRE #</small></div>
                                                <div class="col-3 text-truncate"><small>Address</small></div>
                                                <div class="col-2 text-truncate medium-container"><small>City</small></div>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                        
                                    {
                                        this.state.filtered_agent_friends.map(
                                            (agent) => {
                                                return  <>
                                                            <AgentRow 
                                                                agent={ agent }
                                                                fetchAgentFriends={ this.fetchAgentFriends }
                                                                row_mode='friends_list'
                                                                initiateFriendDelete={this.initiateFriendDelete}
                                                                initiateCancelInvite={this.initiateCancelInvite}
                                                                setSelectedAgent={ this.setSelectedAgent }
                                                            />
                                                             <RemoveConfirmationDialog 
                                                                agent={this.state.selected_agent}
                                                                show_remove_modal={this.state.show_remove_modal}
                                                                hideRemoveoDialog={ this.hideRemoveoDialog }
                                                                unfriend={ this.unfriend }
                                                                cancelInvite={ this.cancelInvite }
                                                                dialog_mode={ this.state.confirmation_modal_mode }
                                                                setSelectedAgent={ this.setSelectedAgent }
                                                            />
                                                        </>
                                            }
                                        )
                                    }
                                </ListGroup>
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}

function PendingInvitesModal(props) {
    const {invites, show_pending_invites_modal, togglePendingInvitesModal, fetchPendingInvites, fetchAgentFriends } = props;
    const [submitting, setSubmitting] = useState(false);

    const takeActionOnInvite = (action, friend_request_id ) => {
        let request = { action: action, friend_request_id: friend_request_id};

        generic_put_api(AGENT_FRIEND_REQUEST_URL, request)
        .then(
            data => {
                if (data.status === 200){
                    setSubmitting(false);
                    fetchPendingInvites();
                    if (action === 'accept') {
                        fetchAgentFriends();
                    }
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    // If the user has the modal open, and takes action on all the invites, the modal closes automatically because 
    // in render in the parent, we only show the modal if the count of invites > 0. But 'show_pending_invites_modal' would remain 'true'.
    // Corner case: if invite arrives while user is on this page, modal could automatically pop up. Therefore, explicitly set to false
    // when length is 0. 
    useEffect(() => {
        if (invites && invites.length === 0) { 
            togglePendingInvitesModal(false);
        }
        
    }, [invites]);

    if (!show_pending_invites_modal) { return null; }


    return(

        <Modal
            show={show_pending_invites_modal} 
            onHide={() => togglePendingInvitesModal(false)}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Pending invites</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                <div class="row mt-1">
                    <div class="col">Invites that you have received from other agents. Agents will not be notified if you ignore an invite.</div>
                </div>

                { invites && invites.length === 0 &&
                    <div class="row mt-4">
                        <div class="col-12 fw-bold">No pending invites</div>
                    </div>
                }
                {
                    invites && invites.length > 0 && invites.map(invite=> {
                        return  <>
                                    <div class="row mt-4">
                                        <div class="col-12 fw-bold"><strong>{invite.agent_first_name} {invite.agent_last_name}</strong> </div>
                                        <div class="col-12 ">DRE# {invite.agent_license}</div>
                                    </div>
                                    {
                                        submitting ?
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        :
                                        <div class="row mt-2">
                                            <div class="col-6"><Button variant="primary" onClick={() => takeActionOnInvite('accept', invite.invite_id)}>Accept</Button></div>
                                            <div class="col-6"><Button variant="secondary" onClick={() => takeActionOnInvite('ignore', invite.invite_id)}>Ignore</Button></div>
                                            <div class="col"><hr/></div>
                                        </div>
                                    }
                                </>
                    })
                }
                </div>
            </Modal.Body>
        </Modal>
    );
}

export function AgentRow(props) {

    // row_mode determines where this row is being shown, in agent_friends (friends_list) or in agent_search (friends_search)
    const { agent, row_mode, initiateFriendDelete, initiateCancelInvite, connect, setSelectedAgent } = props;

    const desktop_icons = () => {
        // Pending outgoing request 
        if (!agent.are_friends && agent.pending_outgoing_friend_request && row_mode === 'friends_list') {
            return  <>
                        <div class="col-1 text-end text-warning"><small>Pending</small></div>
                        <div class="col gx-0 text-center">
                            <OverlayTrigger key="delete_invite" placement="auto" 
                            overlay={ <Tooltip id="delete_invite">Delete pending invite</Tooltip>}>
                                <Button variant="link icons" className="p-2" onClick={() => {setSelectedAgent(agent); initiateCancelInvite() } }>
                                    <i class="fa-regular fa-trash-can"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </>
        }
        // already in network when showing in network list
        else if (agent.are_friends && row_mode === 'friends_list') {
            return  <>
                        <div class="col-1 text-end">&nbsp;</div>
                        <div class="col gx-0 text-center">
                            <OverlayTrigger key="remove_friend" placement="auto" 
                                overlay={ <Tooltip id="remove_friend">Unfriend</Tooltip>}>
                                <Button variant="link icons" className="p-2" onClick={() => {setSelectedAgent(agent); initiateFriendDelete()} }>
                                    <i class="fa-solid fa-user-minus"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </>
        }
        // already in network when showing in search list
        else if (agent.are_friends && row_mode === 'friends_search') {
            return  <>
                        <div class="col-1 text-end text-success"><small>In network</small></div>
                        <div class="col gx-0 text-center"> &nbsp; </div>
                    </>
        }
        // not in network yet in search list, have not invited this agent, and have not been invited by this agent
        else if (!agent.are_friends && !agent.pending_outgoing_friend_request && !agent.pending_incoming_friend_request) {
            return  <>
                        <div class="col-1 text-end ">&nbsp;</div>
                        <div class="col gx-0 text-center">
                            <Button variant="link icons" className="p-2" onClick={ () => connect(agent.id) }>
                                <small>Add</small>
                            </Button>
                        </div>
                    </>
        }
        // incoming or outgoing friend request in search list.
        else if (row_mode === 'friends_search' && !agent.are_friends && (agent.pending_incoming_friend_request || agent.pending_outgoing_friend_request)) {
            return  <>
                        <div class="col-1 text-end text-warning "><small>Pending</small></div>
                        <div class="col gx-0 text-center">&nbsp;</div>
                    </>
        }
    }

    const mobile_icons = () => {
        // Pending outgoing request 
        if (!agent.are_friends && agent.pending_outgoing_friend_request && row_mode === 'friends_list') {
            return  <>  <div class="row">
                            <div class="col gx-0 text-center">
                                <Button variant="link icons" className="p-2" onClick={ () => {setSelectedAgent(agent); initiateCancelInvite()} }>
                                    <i class="fa-regular fa-trash-can fa-lg"></i>
                                </Button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col gx-0 text-center text-warning"><small><small>Pending</small></small></div>
                        </div>
                    </>
        }
        // already in network when showing in newtork list
        else if (agent.are_friends && row_mode === 'friends_list') {
            return  <>
                        <div class="row">
                            <div class="col gx-0 text-center">
                                <Button variant="link icons" className="p-2" onClick={ () => {setSelectedAgent(agent); initiateFriendDelete()} }>
                                    <i class="fa-solid fa-user-minus fa-lg"></i>
                                </Button>
                            </div>
                        </div>
                    </>
        }
        // already in network when showing in search list
        else if (agent.are_friends && row_mode === 'friends_search') {
            return  <>
                        <div class="row">
                            <div class="col gx-0 text-center text-success">
                                <small><small>In newtork</small></small>
                            </div>
                        </div>
                    </>
        }
        // not in network yet in search list, have not invited this agent, and have not been invited by this agent
        else if (!agent.are_friends && !agent.pending_outgoing_friend_request && !agent.pending_incoming_friend_request) {
            return  <>
                        <div class="row">
                            <div class="col gx-0 text-center">
                                <Button variant="link icons" className="p-2" onClick={ () => connect(agent.id) }>
                                    <small>Add</small>
                                </Button>
                            </div>
                        </div>
                    </>
    
        }
        // incoming or outgoing friend request in search list.
        else if (row_mode === 'friends_search' && !agent.are_friends && (agent.pending_incoming_friend_request || agent.pending_outgoing_friend_request)) {
            return  <>
                        <div class="row">
                            <div class="col gx-0 text-center text-warning">
                                <small><small>Pending</small></small>
                            </div>
                        </div>
                    </>
        }

    }

    return (
        <ListGroup.Item className="text-start p-0 agentRow" >
           
            <div class="container desktop">
                <div class="row align-items-center friendRow">
                    <div class="col-4 fw-bold text-truncate">
                        <OverlayTrigger key="agent_name" placement="auto"
                            overlay={ 
                                <Popover id="vendor_names">
                                    <Popover.Header as="h3">{agent.first_name} {agent.last_name}</Popover.Header>
                                    <Popover.Body>
                                        <>
                                            <div>DRE#: {agent.license_number}</div>
                                            <div>{agent.address_1} {agent.city}</div>
                                            {
                                                agent.license_type && <div class="fw-bold">License type: {LicenseTypeMap[agent.license_type]}</div>
                                            }
                                        </>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                                <a class="text-decoration-underline text-dark" role="button"><small>{agent.first_name} {agent.last_name}</small></a>
                        </OverlayTrigger>
                    </div>
                    <div class="col-auto dreNumber"><small>{agent.license_number}</small></div>
                    {/* medium sized container and larger */}
                    <div class="col-3 text-truncate medium-container"><small>{agent.address_1}</small></div>
                    <div class="col-2 text-truncate medium-container"><small>{agent.city}</small></div>

                    {/* smaller than medium container */}
                    <div class="col-3 small-container">
                        <div class="row"><div class="col text-truncate"><small>{agent.address_1}</small></div></div>
                        <div class="row"><div class="col text-truncate "><small>{agent.city}</small></div></div>
                    </div>
                    
                    {desktop_icons()}

                </div>
            </div>
            <div class="container mobile">
                <div class="row align-items-center">
                    <div class="col-9">
                        <div class="row align-items-center">
                            <div class="col fw-bold text-truncate"><small>{agent.first_name} {agent.last_name}</small></div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col"><small>{agent.license_number}</small></div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col text-truncate"><small>{agent.address_1}</small></div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col text-truncate"><small>{agent.city}</small></div>
                        </div>
                    </div>
                    <div class="col-3">
                        {mobile_icons()}
                    </div>
                </div>
            </div>
        </ListGroup.Item>
    )
}

function RemoveConfirmationDialog(props) {
    const {agent, show_remove_modal, hideRemoveoDialog, unfriend, cancelInvite, dialog_mode, setSelectedAgent } = props;

    const [submitting, setSubmitting] = useState(false);

    if (!show_remove_modal) { return null; }

    const title = () => {
        if (dialog_mode === 'unfriend') {
            return 'Unfriend';
        }
        else if (dialog_mode === 'cancel_invite') {
            return 'Cancel invite';
        }
    }

    const body_text = (first_name, last_name) => {
        if (dialog_mode === 'unfriend') {
            return <span>Are you sure you want to unfriend <strong>{first_name} {last_name}</strong>? You will lose access to each other's vendors.</span>;
        }
        else if (dialog_mode === 'cancel_invite') {
            return <span>Are you sure you want to cancel the pending invite for <strong>{first_name} {last_name}</strong>? </span>;
        }
    }

    const confirmOnClick = () => {
        if (dialog_mode === 'unfriend') {
            unfriend(agent);
            setSubmitting(true);
        }
        else if (dialog_mode === 'cancel_invite') {
            cancelInvite(agent);
            setSubmitting(true);
        }
    }

    return(

        <Modal
            show={show_remove_modal} 
            onHide={() => { hideRemoveoDialog() }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{title()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    {/* <div class="row">
                        <div class="col fs-5">
                            <strong>{agent.first_name} {agent.last_name}</strong>
                        </div>
                    </div> */}
                    <div class="row mt-3">
                        <div class="col">{body_text(agent.first_name, agent.last_name)}</div>
                    </div>
                    <div class="row mt-4">
                        <div class="col"/>
                        <div class="col-6 col-md-4 text-center">
                            {submitting ? 
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            :
                                <Button variant="primary" onClick={ confirmOnClick }>Confirm</Button>
                            }
                        </div>
                        <div class="col"/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function LearnMoreModal(props) {
    const {show_learn_more_modal, hideLearnMoreModal } = props;

    if (!show_learn_more_modal) { return null; }

    return(
        <Modal
            show={show_learn_more_modal} 
            onHide={ hideLearnMoreModal }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>My agent network</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    <div class="row">
                        <div class="col fw-bold">What is my agent network?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            You can invite other real estate professionals to join your network. If you receive an invite, you will see a notification in your
                                dashboard for pending invites. You can accept or ignore an invite. The other agent is not notified if you choose to ignore an invite.
                            
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">Why be part of an agent network?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Agents in the same network can share vendors <strong>if they wish</strong>.
                            This way, you can quickly find new trusted vendors because you know they have been used and trusted by someone in  your network.
                           And you will know who to ask for a reference.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">Can I hide some vendors from my agent network?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Absolutely. Each time you add a new vendor, you can set the vendor's visibility for your network. Your agent network 
                                can only see <strong>visible vendors</strong>. 
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AgentFriends;