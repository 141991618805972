import React, { Component, useState, useRef, useEffect } from "react";
import { ACCOUNT_URL, EntityRole, ACCOUNT_PHOTO_URL, AGENT_SETTINGS_URL } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";
import { CodeSectionsBySection, AdminBulletins, CodeSectionsByRoom } from "utils/code_sections";
import plan from 'assets/temp/plan.png'

const PAGES = {
    PROPERTY_TYPE: 'property_type',
    PROJECT_TYPE: 'project_type',
    GENERAL: 'general',
    WINDOWS: 'windows',
    NEW_SPACES: 'new_spaces',
    BEDROOMS: 'bedrooms',
    BATHROOMS: 'bathrooms',
    RESULT: 'result',
    PLAN_UPLOAD: 'plan_upload',
    PLAN_TAG: 'plan_tag',
    DECK: 'deck',
}

class ProjectForm extends Component {

    constructor(props) {
        super(props);

        let pathSplit = window.location.pathname.split('/');

        this.state = {
            experience: pathSplit[2],
        };
       
    }

    render() {
        return(
            <div class="projectFormEnvelope mb-5">
                <div class="projectFormPage mt-5">
                    {this.state.experience === 'project_form' &&

                        <QuestionsComponent />
                    }
                    {this.state.experience === 'project_form_plan' &&

                        <PlanComponent />
                    }
                </div>
            </div>
        )
    }
}

function PlanComponent(props) {
    const [page, setPage] = useState(props.page);
    const [planUploadSpinner, setPlanUploadSpinner] = useState(false);
    const [show_modal, setShowModal] = useState(false);
    const [show_spinner, setShowSpinner] = useState(false);

    const [drawing, setDrawing] = useState(false);
    const [rectangles, setRectangles] = useState([]);
    const canvasRef = useRef(null);

    const fileRef = React.createRef();

    const moveToResult = () => {
        try {
            setShowSpinner(true);
            const timeId = setTimeout(() => {
                setShowSpinner(false);
                setPage(PAGES.RESULT);
            }, 2000)
        }
        catch (error) {
            const timeId = setTimeout(() => {
                setShowSpinner(false);
                setPage(PAGES.RESULT);
            }, 2000)
        }
       
    }

    const startDrawing = (e) => {
        setDrawing(true);
        const rect = canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setRectangles([...rectangles, { x, y, width: 0, height: 0 }]);
      };
    
    const drawRectangle = (e) => {
        if (!drawing) return;
        const rect = rectangles[rectangles.length - 1];
        const width = e.clientX - rect.x - canvasRef.current.getBoundingClientRect().left;
        const height = e.clientY - rect.y - canvasRef.current.getBoundingClientRect().top;
        setRectangles([...rectangles.slice(0, -1), { ...rect, width, height }]);
    };

    const stopDrawing = () => {
        setDrawing(false);
        setShowModal(true);
    };

    useEffect(() => {
        if (canvasRef.current && page === PAGES.PLAN_TAG) {
            const ctx = canvasRef.current.getContext("2d");
            const image = new Image();
            image.src = plan;
            image.onload = () => {
                ctx.drawImage(image, 0, 0, 700, 460);
            }
        }
    }, [page]);

    useEffect(() => {
        if (canvasRef.current) {
            
            const ctx = canvasRef.current.getContext("2d");
            const image = new Image();
            image.src = plan;
            

            ctx.clearRect(0, 0, 700, 460);

            image.onload = () => {
                ctx.drawImage(image, 0, 0, 700, 460);
                ctx.strokeStyle = "red";
                for (let i = 0; i < rectangles.length; i++) {
                    let rect = rectangles[i];
                    ctx?.strokeRect(rect.x, rect.y, rect.width, rect.height);
                }
            };
        }
    }, [rectangles]);

    const onPhotoBoxClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev) => {

        if (fileRef.current && fileRef.current.files.length > 0) {
            try {
                setPlanUploadSpinner(true);
                const timeId = setTimeout(() => {
                    setPlanUploadSpinner(false);
                    setPage(PAGES.PLAN_TAG);
                }, 2000)
            }
            catch (error) {
                const timeId = setTimeout(() => {
                    setPlanUploadSpinner(false);
                    setPage(PAGES.PLAN_TAG);
                }, 2000)
            }
        }
    }

    return (
            <>
                       
                {
                    page === PAGES.PLAN_UPLOAD && 
                
                    <div class="container">
                        <div class="row">
                            <div class="col fs-4">3. Upload your floor plan (DXF format)</div>
                        </div>
                        <div class="row mt-4">

                            <div class="col-12">

                                <input type="file" id="actual-btn"
                                    ref={fileRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => filePickerChanged(e)}
                                />
                                

                            </div>
                        </div>
                        <div class="row mt-4">

                            <div class="col text-center">
                                {
                                    planUploadSpinner ?
                                        <Button variant="outline-primary">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                        </Button>
                                        :
                                        <Button variant="primary" size="sm" onClick={onPhotoBoxClick}><i class="fa-solid fa-plus"/> Select file</Button>
                                        
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    page === PAGES.PLAN_TAG && 
                
                    <div class="container">
                        <div class="row">
                            <div class="col fs-4">4. Draw rectangles around current and proposed states</div>
                        </div>
                        <div class="row mt-4">

                            <div class="col-12">

            
                                <div>
                                    <canvas
                                        ref={canvasRef}
                                        width="700"
                                        height="460"
                                        style={{ border: "1px solid black" }}
                                        onMouseDown={startDrawing}
                                        onMouseMove={drawRectangle}
                                        onMouseUp={stopDrawing}
                                    />
                                </div>
                                
                            </div>
                        </div>
                        <div class="row mt-4">

                            <div class="col text-center">
                            {
                                    show_spinner ? 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>  
                                    :
                                        <Button variant="primary" onClick={ moveToResult }>Get relevant code <i class="fa-solid fa-arrow-right"></i></Button>
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    page === PAGES.RESULT && 
                        <ResultSection/>
                }

                <Modal
                    show={show_modal}
                    onHide={setShowModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                <Modal.Header closeButton>
                    <Modal.Title className="h5 text-truncate">Label current &amp; proposed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row">
                                <div class="col text-center"><Button variant="primary" onClick={()=> setShowModal(false)}>Current</Button></div>
                                <div class="col text-center"><Button variant="primary"  onClick={()=> setShowModal(false)}>Proposed</Button></div>
                            </div>                  
                    </Modal.Body>
                </Modal>
            </>
    )
}

function QuestionsComponent(props) {
    const [page, setPage] = useState(PAGES.PROPERTY_TYPE);
    const [show_spinner, setShowSpinner] = useState(false);

    const moveToResult = () => {
        try {
            setShowSpinner(true);
            const timeId = setTimeout(() => {
                setShowSpinner(false);
                setPage(PAGES.RESULT);
            }, 2000)
        }
        catch (error) {
            const timeId = setTimeout(() => {
                setShowSpinner(false);
                setPage(PAGES.RESULT);
            }, 2000)
        }
       
    }

    return (
        <div class="container questionsContainer">
            <div class="row"><div class="col display-6">Residential building code guide</div></div>

            <div class="row mt-5 gx-2 gy-3">
                <Card  bg="light" >
                    <Card.Body>
                        {
                            page === PAGES.PROPERTY_TYPE && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">1. Building type</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Single family residence
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                2-unit building
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                            <label class="form-check-label" for="flexRadioDefault3">
                                                Apartment building
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.PROJECT_TYPE)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.PROJECT_TYPE && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">2. Project type</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="projectType" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                Horizontal addition
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="projectType" id="projectType2"/>
                                            <label class="form-check-label" for="projectType2">
                                                Vertical addition
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="projectType" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Remodel of existing habitable space
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="projectType" id="projectType4"/>
                                            <label class="form-check-label" for="projectType4">
                                                Replace existing windows
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.GENERAL)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.GENERAL && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">3. General</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="general" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                House has fire sprinkler system
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="general" id="projectType2"/>
                                            <label class="form-check-label" for="projectType2">
                                                Part of the new ceiling is sloped
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="general" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Project will modify the entry door (egress exit)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.WINDOWS)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.WINDOWS && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">4. Replacement windows</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="windows" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                One or more of the replacement windows will be in existing bedrooms
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.NEW_SPACES)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.NEW_SPACES && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">5. Which new spaces/structures are you adding?</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                Bedrooms
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType2"/>
                                            <label class="form-check-label" for="projectType2">
                                                Living rooms / Family rooms
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Kitchens
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Bathrooms
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Interior stairs
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Exterior stairs
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Hallways
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Roof decks
                                            </label>
                                        </div>
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="new_space" id="projectType3"/>
                                            <label class="form-check-label" for="projectType3">
                                                Garages
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.BEDROOMS)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.BEDROOMS && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">6. For newly added bedrooms (select all that apply)</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="bedroom" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                One or more bedroom windows open into a well
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.BATHROOMS)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.BATHROOMS && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">6. For newly added bathrooms (select all that apply)</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" name="bedrooms" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                One or more bathrooms contain bathtub, shower or tub/shower combination
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.DECK)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page === PAGES.DECK && 
                        
                            <div class="container">
                                <div class="row">
                                    <div class="col fs-4">7. Roof decks</div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="bedroom" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                Roof deck has more than 250 square feet in area
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="form-check mt-2">
                                            <input class="form-check-input" type="checkbox" name="bedroom" id="projectType1"/>
                                            <label class="form-check-label" for="projectType1">
                                                Roof deck is accessed via spiral stairs
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col text-center"><Button variant="primary" onClick={() => setPage(PAGES.PLAN_UPLOAD)}>Next <i class="fa-solid fa-arrow-right"></i></Button></div>
                                </div>
                            </div>
                        }
                        {
                            page == PAGES.PLAN_UPLOAD &&
                            <PlanComponent page={page} />
                        }
                        {
                            page === PAGES.RESULT && 
                                <ResultSection/>
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
    )

}

function ResultSection(props) {

    const [sections, setSections] = useState({})
    const [rooms, setRooms] = useState({})
    const [hidden, setHidden] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [warning, setWarning] = useState(['1011_10']);
    const [current_view, setCurrentView] = useState('to_verify');

    const setSectionVisibility = (section_id, value) => {

        setSections(prevState => ({
            ...prevState,
              [section_id]: value
            }));
    }

    const setRoomVisibility = (room_id, value) => {

        setRooms(prevState => ({
            ...prevState,
              [room_id]: value
            }));
    }

    const addHidden = (section_id) => {
        
        setHidden(prevState => [
            ...prevState,
            section_id,
            ]);
    }

    const addCompleted = (section_id) => {

        setCompleted(prevState => [
            ...prevState,
            section_id,
            ]);
    }

    const getCurrentView = (sections) => {
        
        let result = [];
        
        if (current_view === 'verified') {
            for (let i = 0; i < sections.length; i++) {

                let section = sections[i];
                if (completed.includes(section.id )) {
                    result.push(section);
                }
            }
        }
        else if (current_view === 'hidden') {
            for (let i = 0; i < sections.length; i++) {

                let section = sections[i];
                if (hidden.includes(section.id )) {
                    result.push(section);
                }
            }
        }
        else if (current_view === 'to_verify') {
            for (let i = 0; i < sections.length; i++) {

                let section = sections[i];
                if (!completed.includes(section.id ) && !hidden.includes(section.id )) {
                    result.push(section);
                }
            }
        }
        else if (current_view === 'warning') {
            for (let i = 0; i < sections.length; i++) {

                let section = sections[i];
                if (warning.includes(section.id )) {
                    result.push(section);
                }
            }
        }

        return result;
    }

    const showRoom = (room) => {

        for (let i = 0; i < room.sections.length; i++) {

            if (getCurrentView(room.sections[i].subsections).length > 0){
                return true;
            }
        }
        return false;
    }

    const getTotalToVerify = () => {

        let count = 0;

        for (let i = 0; i < CodeSectionsByRoom.rooms.length; i++) {
            let room = CodeSectionsByRoom.rooms[i];

            for (let j = 0; j < room.sections.length; j++) {
                
                let section = room.sections[j];

                for (let k = 0; k < section.subsections.length; k ++) {

                    let subsection = section.subsections[k];
                    if (!completed.includes(subsection.id) && !hidden.includes(subsection.id)) {
                        count ++;
                    }
                }
            }
           
        }
        return count;
    }

    return(
        <div class="container resultSection">
            <div class="row mt-2"> <div class="col fs-5 text-secondary text-center">Project code requirements</div></div>
           
            <div class="row mt-2 border rounded p-2 mt-4">
                <div class="col-3 text-center fw-bold text-warning" role="button" onClick={() => setCurrentView('to_verify')}>To verify ({getTotalToVerify()})</div>
                <div class="col-3 text-center  fw-bold text-danger" role="button" onClick={() => setCurrentView('warning')}>Warnings ({warning.length})</div>
                <div class="col-3 text-center  fw-bold text-success" role="button" onClick={() => setCurrentView('verified')}>Verified ({completed.length})</div>
                <div class="col-3 text-center  fw-bold text-secondary" role="button" onClick={() => setCurrentView('hidden')}>Hidden ({hidden.length})</div>
            </div>
           
            <div class="row mt-5"> 
                <div class="col d-flex justify-content-start">
                    <span className="pt-2">Organize by&nbsp;</span>
                    <select class="form-select sortSelect" onChange={ true }>
                        <option >Building sections</option>
                        <option >Code categories</option>
                    </select>
                </div>  
            </div>
            <div class="row mt-2">
                <div class="col"><hr></hr></div>
            </div>
            {
                CodeSectionsByRoom.rooms.map(
                    (room) => {
                        return  <div class="container section mt-4">
                                    {
                                        showRoom(room) && 
                                        <div class="row sectionTitle"><div class="col fw-semibold">{room.title}</div></div>
                                    }
                                    {
                                        room.sections.map(
                                            (section) => {
                                                return  <div class="container section mt-2">
                                                            { getCurrentView(section.subsections).length > 0 && 
                                                                <div class="row sectionTitle"> 
                                                                    <div class="col" role="button" onClick={() => setSectionVisibility(section.id, !sections[section.id])}>
                                                                        {
                                                                            sections[section.id] ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-right"></i>
                                                                        }
                                                                        &nbsp;{section.number}: {section.title}
                                                                    </div>
                                                                </div>
                                                            }
                                                            { sections[section.id] && 
                                                                getCurrentView(section.subsections).map(
                                                                    (subsection) => {
                                                                        return  <div class="container border rounded mt-3 ms-2 pt-1 pb-1">
                                                                                    <div class="row"> 
                                                                                        <div class="col-12 fst-italic" role="button" onClick={() => setSectionVisibility(subsection.id, !sections[subsection.id])}>
                                                                                            {
                                                                                                sections[subsection.id] ? <i class="fa-solid fa-minus" /> : <i class="fa-solid fa-plus" />
                                                                                            }
                                                                                            &nbsp;{subsection.number}&nbsp;{subsection.title}
                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        sections[subsection.id] &&
                                                                                        <>
                                                                                            <div class="row mt-1 ms-2"><div class="col-12">{subsection.text}</div></div>
                                                                                            {
                                                                                                subsection.url && 
                                                                                                <div class="row  mt-1 ms-2"><div class="col-12"><a role="button" href={subsection.url} target="_blank">Read full bulletin</a></div></div>
                                                                                            }
                                                                                            {
                                                                                                !completed.includes(subsection.id) && !hidden.includes(subsection.id) &&
                                                                                                <>
                                                                                                    <div class="row  p-0 mt-1 ps-3 pe-3"><div class="col p-0"><hr></hr></div></div>
                                                                                                    <div class="row mt-2 mb-1">
                                                                                                        <div class="col-6 fw-semibold text-center"><a role="button" onClick={() => addCompleted(subsection.id)}><i class="fa-regular fa-square-check fa-lg"></i> Mark as verified</a> </div>
                                                                                                        <div class="col-6 fw-semibold text-center"><a role="button" onClick={() => addHidden(subsection.id)}><i class="fa-regular fa-eye-slash"></i> Hide in future projects</a></div>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                    }
                                                                )
                                                            }
                                                            
                                            
                                                        </div>
                                }
                                )
            }
                 </div>
                 
                })
            }

            {/* <div class="row mt-4"> <div class="col fs-5 text-secondary">San Francisco DBi bulletins</div></div>
            {
                    AdminBulletins.sections.map(
                        (section) => {
                                return  <div class="container border rounded mt-3 pt-1 pb-1">
                                            <div class="row"> 
                                                <div class="col-12 fst-italic" role="button" onClick={() => setSectionVisibility(section.id, !sections[section.id])}>
                                                    {
                                                        sections[section.id] ? <i class="fa-solid fa-minus" /> : <i class="fa-solid fa-plus" />
                                                    }
                                                    &nbsp;{section.number} {section.title}
                                                </div>
                            
                                                {
                                                    sections[section.id] &&
                                                    <>
                                                        <div class="row mt-1 ms-2"><div class="col-12">{section.text}</div></div>
                                                        <div class="row  mt-1 ms-2"><div class="col-12"><a role="button" href={section.url} target="_blank">Read full bulletin</a></div></div>
                                                        <div class="row  p-0 mt-1 ps-3 pe-3"><div class="col p-0"><hr></hr></div></div>
                                                        <div class="row mt-2 mb-1">
                                                            <div class="col-6 fw-semibold text-center"><a role="button" onClick={() => addCompleted(section.id, section.number, section.title, section.text)}><i class="fa-regular fa-square-check fa-lg"></i> Verified</a> </div>
                                                            <div class="col-6 fw-semibold text-center"><a role="button" onClick={() => addToDo(section.id, section.number, section.title, section.text)}><i class="fa-regular fa-rectangle-list fa-lg"></i> To-do</a></div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                        }
                    )
            } */}
            
            {/* <Modal
                show={show_to_do_modal}
                onHide={setShowToDoModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
            <Modal.Header closeButton>
                <Modal.Title className="h5 text-truncate">Code to-do list</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            to_do.map(
                                (subsection) => {
                                    return  <div class="container mt-3 border rounded pt-1 pb-1 todoModal">
                                                <div class="row"><div class="col fw-bold">{subsection.number} {subsection.title}</div></div>
                                                <div class="row"><div class="col ms-2">{subsection.text}</div></div>
                                                <div class="row  p-0 mt-2 ps-3 pe-3"><div class="col p-0"><hr></hr></div></div>
                                                <div class="row mt-2 mb-1">
                                                    <div class="col-12 fw-semibold text-center"><a role="button" onClick={() => moveToCompleted(subsection.id, subsection.number, subsection.title, subsection.text)}><i class="fa-regular fa-square-check fa-lg"></i> Verified</a> </div>
                                                </div>
                                                
                                            </div>
                                })
                        }
                    </Modal.Body>
                </Modal> */}
            


        </div>
    )
}

export default ProjectForm