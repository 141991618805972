import React, { Component } from "react";
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Card, Button, OverlayTrigger, Modal, Popover} from 'react-bootstrap';
import license_example from 'assets/reputation/license_example.png';
import permit_example from 'assets/reputation/permit_example.png';
import { VerifiedReviewDescription } from "pages/agents/vendor_detail";
import vendor_story_1 from 'assets/reputation/vendor_story_1.svg';
import vendor_story_2 from 'assets/reputation/vendor_story_2.svg';
import vendor_story_3 from 'assets/reputation/vendor_story_3.svg';


class VendorPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_example_modal: false,
            example_modal_image: null,
            show_verified_review_description: false,
            invite_id: null,
        };
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
        this.setShowVerifiedReviewDescription = this.setShowVerifiedReviewDescription.bind(this);
    }

    setShowVerifiedReviewDescription(value) {
        this.setState({show_verified_review_description: value});
    }

    setShowContactUsModal(value) {
        this.setState({show_contact_us_modal: value});
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_plans'});
        document.title = "Reach 1000s of real estate agents";

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length === 4) {
            this.setState({invite_id: pathSplit[3]});
        }
    }

    render() {

        return(
            <div class="vendorPlansEnvelope">
                <div class="vendorPlansPage">
                    <div class="container">
                        <div class="row">
                            <div class="col display-6 text-center">Grow your business with Renolition</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col text-center fs-4">Get jobs from real estate agents and their clients</div></div>
                        <div class="row mt-3">
                            
                        </div>
                        {/* <div class="row mt-3">
                            <div class="col">
                                <img src={vendor_story_2} />
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                <img src={vendor_story_3} />
                            </div>
                        </div> */}
                    </div>
                    <div class="row mt-5 mb-4"><div class="col text-center fw-bold fs-5">HOW IT WORKS</div></div>
                    <div class="p-5 vendorStory story1">
                        <div class="container mt-5">
                            <div class="row gy-4 justify-content-center">
                                <div class="col-12 col-md-6 text-center storyPhoto">
                                    <img src={vendor_story_1} />
                                </div>
                                <div class="col-12 col-md-6 storyDescription d-flex align-items-center">
                                    <Card className="storyCard">
                                        <Card.Body>
                                            <div class="container text-center">
                                                <div class="row"><div class="col"><i class="fa-solid fa-1 fa-xl"></i></div></div>
                                                <div class="row mt-3"><div class="col h4 text-secondary"><strong>Renolition</strong> selects top construction professionals</div></div>
                                                <div class="row mt-2"><div class="col"><strong>Small number</strong> of licensed, insured, and experienced professionals are invited to join.</div></div>
                                                <div class="row mt-2"><div class="col">License, insurance, work history, customer service are regularly verified.</div></div>
                                            </div>
                                        </Card.Body>
                                </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-5 vendorStory story2">
                        <div class="container mt-5">
                            <div class="row gy-4 justify-content-center">
                                <div class="col-12 col-md-6 text-center storyPhoto">
                                    <img src={vendor_story_2} />
                                </div>
                                <div class="col-12 col-md-6 storyDescription d-flex align-items-center">
                                    <Card className="storyCard">
                                        <Card.Body>
                                            <div class="container text-center">
                                                <div class="row"><div class="col"><i class="fa-solid fa-2 fa-xl"></i></div></div>
                                                <div class="row mt-3"><div class="col h4 text-secondary">Real estate agents find top professionals</div></div>
                                                <div class="row mt-2"><div class="col">Real estate agents are constantly looking for professionals for their clients.</div></div>
                                                <div class="row mt-2"><div class="col">Agents find reliable and vetted construction professionals on <strong>Renolition</strong>.</div></div>
                                            </div>
                                        </Card.Body>
                                </Card>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-5 vendorStory story3">
                        <div class="container mt-5">
                            <div class="row gy-4 justify-content-center">
                                <div class="col-12 col-md-6 text-center storyPhoto">
                                    <img src={vendor_story_3} />
                                </div>
                                <div class="col-12 col-md-6 storyDescription d-flex align-items-center">
                                    <Card className="storyCard">
                                        <Card.Body>
                                            <div class="container text-center">
                                                <div class="row"><div class="col"><i class="fa-solid fa-3 fa-xl"></i></div></div>
                                                <div class="row mt-3"><div class="col h4 text-secondary">Agents refer pros to their clients</div></div>
                                                <div class="row mt-2"><div class="col">Agents send the professionals that they found on <strong>Renolition</strong> to their clients.</div></div>
                                                <div class="row mt-2"><div class="col">Agents and professionals build <strong>long-lasting referral business relationship</strong>.</div></div>
                                            </div>
                                        </Card.Body>
                                </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="container mt-5">
                        <div class="row gy-4 justify-content-center">
                            <div class="col-12 col-md-4 indexCardWrapper">
                                <Card bg="light" className="indexCard">
                                    <Card.Body>
                                        <Card.Text className="indexCardText">
                                            <div class="row mt-1"><div class="col text-center"><i class="fa-solid fa-retweet fa-2xl"></i></div></div>
                                            <div class="row mt-4"><div class="col text-center fw-bold">Reliable business</div></div>
                                            <div class="row mt-3"><div class="col text-center">Our contractors can get jobs from 1000s of real estate professionals and their clients.</div></div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4 indexCardWrapper">
                                <Card  bg="light" className="indexCard">
                                    <Card.Body>
                                        <Card.Text className="indexCardText">
                                            <div class="row"><div class="col text-center"><i class="fa-regular fa-clock fa-2xl"></i></div></div>
                                            <div class="row mt-4"><div class="col text-center fw-bold">Long-term, repeat business</div></div>
                                            <div class="row mt-3"><div class="col text-center">Build long-term business relationships with agents and get repeat business.</div></div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4 indexCardWrapper">
                                <Card  bg="light" className="indexCard">
                                    <Card.Body>
                                        <Card.Title></Card.Title>
                                        <Card.Text className="indexCardText">
                                            <div class="row"><div class="col text-center"><i class="fa-solid fa-piggy-bank fa-2xl"></i></div></div>
                                            <div class="row mt-3"><div class="col text-center fw-bold">Reduce your cost</div></div>
                                            <div class="row mt-3"><div class="col text-center">Stop paying thousands for ads or unreliable leads.</div></div>
                                        </Card.Text>
                                    
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>

    

                    <div class="container planDetails mt-5">
                        
                        <div class="row justify-content-center mt-3">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <div class="container g-0">
                                        <div class="row"><div class="col text-center fs-4">Membership details</div></div>
                                            <div class="row mt-4">
                                                <div class="col-6">&nbsp;</div>
                                                <div class="col-6 text-primary text-center">Limited time </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-6 text-center fs-4 text-secondary text-decoration-line-through">
                                                    <div>$50 </div>
                                                    <div class="fs-6" ><small>per month</small></div>
                                                </div>
                                                <div class="col-6 text-center">
                                                    <div class="fs-4">$5</div>
                                                    <div class="text-secondary"><small>per month. Billed annually for the first year.</small></div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col fw-bold text-center">
                                                    Fully refundable first year fees if you are not satisfied.
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col text-center">
                                                    <Button 
                                                        className="joinButton" 
                                                        variant="primary" 
                                                        onClick={() => this.props.history.push('/vendor_invite/' + this.state.invite_id) }>Join now</Button></div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col"><hr></hr></div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> Reach 1000s of real estate agents and their clients</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> Automatic verification of license, insurance, workers comp, if applicable.
                                                (<a class="text-decoration-underline" role="button" onClick={() => this.setState({show_example_modal:true, example_modal_image: license_example })}>See example</a>)
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> Analysis and display of permit history, if applicable.
                                                (<a class="text-decoration-underline" role="button" onClick={() => this.setState({show_example_modal:true, example_modal_image: permit_example })}>See example</a>)
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> Collection of verified reviews 
                                                (<a class="text-decoration-underline" role="button" onClick={() => this.setShowVerifiedReviewDescription(true)}>more info</a>)
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> Online scheduling tools that save time</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col"> One simple membership plan that includes all services</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-auto pe-0"><i class="fa-solid fa-check"></i></div>
                                                <div class="col">You can cancel any time</div>
                                            </div>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <Modal 
                                show={this.state.show_example_modal} 
                                onHide={() => this.setState({show_example_modal: false, example_modal_image: null})}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body className="ps-0 pe-0">
                                <div class="container">
                                    <div class="row">
                                        <div class="col text-center">
                                            <img src={this.state.example_modal_image} />
                                        </div>
                                    </div>    
                                </div>
                            </Modal.Body>
                        </Modal>
                        <VerifiedReviewDescription
                            show_verified_review_description={this.state.show_verified_review_description}
                            setShowVerifiedReviewDescription={this.setShowVerifiedReviewDescription}
                        />
                    </div>

                    <div class="container contactUsContainer mt-5">
                        <div class="row"><div class="col text-center">We are here for you</div></div>
                        <div class="row mt-2"><div class="col text-center"><Button variant="outline-primary" onClick={() => this.setShowContactUsModal(true)}><i class="fa-solid fa-envelope"/>&nbsp;Contact us</Button></div></div>
                        <ContactUs 
                            displayStatus={ this.state.show_contact_us_modal } 
                            changeDisplayStatus={ this.setShowContactUsModal }
                        />
                    </div>
                            
                </div>
                
            </div>
        )
    }
}

export default VendorPlans;