import React, { Component, useState, useRef, useEffect } from "react";
import { PLANNING_CODE_SECTIONS } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";
import { CodeSectionsBySection, AdminBulletins, CodeSectionsByRoom } from "utils/code_sections";
import plan from 'assets/temp/plan.png'


class PlanningCodeList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code_sections: [],
        };
       
    }

    componentDidMount() {

        generic_get_api(PLANNING_CODE_SECTIONS)
        .then(
            data => {
                this.setState({ 
                    code_sections: data,
                })
            }
        ).catch(error => {
            
        });
    }

    render() {
        const {code_sections} = this.state;

        // const convert = (input) => {
        //     return dangerouslySetInnerHTML={{__html: input}};
        // }
        return(
            <div class="planningCodeListEnvelope mt-5">
                {code_sections.map(section => {
                    return <div class="sectionContainer">
                            <div class="sectionName">SEC {section.section_id} {section.section_name}</div>
                            <span dangerouslySetInnerHTML={{__html: section.html}} />
                            <div class="references container">
                                <div class="header">Referenced sections</div>
                                {
                                    section.references.map(reference => {
                                        return <li class="reference">{reference.formatted_output}</li>
                                    })
                                }
                            </div>
                        </div>
                })}
            </div>
        )
    }
}

export default PlanningCodeList;