import React, { Component  } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { basicAccountInfo } from "api/basic_account_info";
import { isVendor } from "utils/helpers";
import {
    EntityRole,
} from 'utils/constants';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'index'})
    
        basicAccountInfo()
        .then(data=>{
            if (data.role === EntityRole.AGENT) {
                this.props.history.push('/agent/start');
            }
            else if (data.role === EntityRole.AGENT_CONTACT) {
                this.props.history.push('/contact/start');
            }
            else if (isVendor(data.role)) {
                this.props.history.push('/vendor/start');
            }
            else {
                // this.props.history.push('/agent/intro');
                this.props.history.push('/code/intro');
            }
        })
        .catch(error => {
            this.props.history.push('/code/intro');
        }); 
    }

    render() {
        return(<div></div>)
    }
}

export default Index;
