import React, { Component, Fragment } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import right_arrow from  'assets/reputation/next.png';


class ReplaceKitchenAppliances extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
        };
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'guide_replace_kitchen_appliances'})
    }

    render() {
        return(
            <div class="guideDetailEnvelope">
                <div class="guideDetail">
                    
                    <div class="container guideContent mt-5" >
                        <div class="display-6">Project: Replacing Kitchen Appliances</div>

                        <div class="mt-3">
                            <div>This guide highlights the main considerations and action items when replacing the kitchen appliances, while leaving
                            the rest of the kitchen as is. This is often useful when the cabinets/counters are in a good shape, but the appliances
                            are dated/old.</div>
                            <div>&nbsp;</div>
                            <div>First, the guide describes considerations applicable to all appliances and then each appliance is described in
                            detail.</div>

                            <div class=" mt-3 h4">Table of Contents</div>

                            <div class="mt-2 h5">Applicable to all Appliances</div>
                            <div class="fw-light"><a href="#manual"><img class="arrow_image"src={right_arrow}/>Installation manuals</a></div>
                            <div class="fw-light"><a href="#path"><img class="arrow_image"src={right_arrow}/>Clear path</a></div>
                            <div class="fw-light"><a href="#delivery"><img class="arrow_image"src={right_arrow}/>Delivery</a></div>
                            <div class="fw-light"><a href="#old_appliances"><img class="arrow_image"src={right_arrow}/>Removal of old appliances</a></div>
                            <div class="fw-light"><a href="#colors"><img class="arrow_image"src={right_arrow}/>Appliance colors</a></div>
                            <div class="fw-light"><a href="#discounts"><img class="arrow_image"src={right_arrow}/>Discounts</a></div>

                            <div class="mt-2 h5">Appliance Specific</div>
                            <div class="fw-light"><a href="#stove"><img class="arrow_image"src={right_arrow}/>Stoves/Ranges</a></div>
                            <div class="fw-light"><a href="#fridge"><img class="arrow_image"src={right_arrow}/>Fridges</a></div>
                            <div class="fw-light"><a href="#dishwasher"><img class="arrow_image"src={right_arrow}/>Dishwashers</a></div>
                            <div class="fw-light"><a href="#garbage"><img class="arrow_image"src={right_arrow}/>Garbage disposals</a></div>
                            <div class="fw-light"><a href="#microwave"><img class="arrow_image"src={right_arrow}/>Over-the-range microwaves</a></div>
                            

                            <div class="section"><a id="manual"/>
                                <div class="h5 mt-3">Installation manuals</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> The manufacturer's installation manual outlines the conditions that
                                                must exist for the installation of your new appliance. 
                                        </div>
                                
                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                                <li>
                                                    <div>Find and download the manual for the appliances you'd like to purchase.</div>
                                                </li>
                                                <li>
                                                    <div>Often, the manual can be obtained from the appliance dealer's website (e.g., HomeDepot or Lowe's).</div>
                                                </li>
                                                <li>
                                                    <div>If that is not possible, the manufacturer's website has the manual.</div>
                                                </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                                
                            </div>

                            <div class="section"><a id="path"/>
                                <div class="h5 mt-3">Clear path</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> There needs to be a clear path from the street (where the truck will
                                            unload the appliance) to the spot in the house where the new appliance is being installed.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                                <li><div>The appliance dealer's website (e.g., HomeDepot) or the installation manual outline the overall dimensions
                                                    and clearance requirements of the appliance.</div>
                                                </li>
                                                <li>
                                                    <div>Walk through the path that the appliance would take and ensure it would fit through that space.</div>
                                                </li>
                                                <li>
                                                    <div>This is most critical for larger appliances like fridges, dishwashers and ranges/stoves.</div>
                                                </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="section"><a id="delivery"/>
                                <div class="h5 mt-3">Delivery</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> Delivery companies have different terms regarding where to drop off
                                        the item, and possible defects.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Confirm with the delivery company where they will drop off your new appliance. Some possible options they
                                            offer can be curb side, inside the home, at installation site etc. Ensure what they are offering fits your
                                            needs.</div>
                                        </li>
                                        <li>
                                            <div>Most appliances are not returnable (unlike many other items that have 30 days return policies). The
                                            exception is if the item is damaged upon arrival.</div>
                                        </li>
                                        <li>
                                            <div>Before delivery, determine the seller's policy regarding delivery damages. There is often a window (e.g.,
                                            24-48 hours) to report problems.</div>
                                        </li>
                                        <li>
                                            <div>On the delivery day, take a few minutes to inspect each item, take photos of the boxes, items etc for your
                                            records.</div>
                                        </li>
                                        <li>
                                            <div>If there are any dents, damages etc. contact the company right away.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            
                            <div class="section"><a id="old_appliances"/>
                                <div class="h5 mt-3">Removal of old appliances</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> Plan the removal of your old appliances.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Most appliance dealers (e.g., HomeDepot) also offer removal of the old ones. The cost is often $25+
                                            per appliance.</div>
                                        </li>
                                        <li>
                                            <div>Confirm with the appliance dealer that they will also remove your old appliance.</div>
                                        </li>
                                        <li>
                                            <div>If your appliance is in good order, you may also consider selling it on one of the online platforms.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>

                            <div class="section"><a id="colors"/>
                                <div class="h5 mt-3">Appliance colors</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Appliance colors may differ.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>If you are purchasing all your appliances from the same brand/manufacturer, then most probably, they will
                                                match (e.g., all stainless steel appliances will have the same shade)</div>
                                            </li>
                                            <li>
                                                <div>Otherwise, you may be able to find a showroom close by that has your new appliance, or similar one on
                                                display.</div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="section"><a id="discounts"/>
                                <div class="h5 mt-3">Discounts</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> For certain appliances, especially those that can save energy, government-level discounts could exist.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Refer to the website of the appliance dealer's website description for your new appliance. It often lists
                                            discounts that are applicable to your location.</div>
                                        </li>
                                        <li>
                                            <div>Alternatively, do an online search for federal, state, or city/county level discounts for your appliance.
                                            </div>
                                        </li>
                                        <li>
                                            <div>Make sure to read the fine print since eligibility is very specific to date of purchase, location, model
                                            number, etc.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                                
                            <div class="h4 mt-3">Appliance Specific</div>
                            <div class="h5 fw-bold"><a id="stove"/>Stoves/Ranges</div>
                            
                            <div class="section">
                                <div class="h5 mt-1">Type of range</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Your existing range is powered by gas or electricity. Your new range
                                            must be of the same type (see below in FAQ if you'd like to change the type)
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Determine the type of your existing range (A gas range produces flames on the cook top).</div>
                                            </li>
                                            <li>
                                                <div>Ensure your new appliance is of the same type. This information is listed on the appliance dealer's
                                                website, as well as in its manual.</div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="section">
                                <div class="h5 mt-3">Dimensions</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Your new range must fit into the same space as your old appliance, and
                                            its surface should be approximately the at the same level as the existing countertop. Standard widths are 24,
                                            30, and 36 inches.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Measure the width of your existing range or space.</div>
                                            </li>
                                            <li>
                                                <div>Measure the height from floor to the top of the countertop.</div>
                                            </li>
                                            <li>
                                                <div>The width of your new range should be prominently listed on the product's web page, or installation manual.
                                                </div>
                                            </li>
                                            <li>
                                                <div>The height of your new range is either listed on the product's webpage, or installation manual.</div>
                                            </li>
                                            <li>
                                                <div>If both dimensions match, you're good! Note that the range height is usually a few inches higher than the
                                                countertop.</div>
                                            </li>
                                            <li>
                                                <div>It is not recommended to install a stove that smaller than your current width (e.g., install a new 24" wide
                                                stove into a space for a 30" wide stove)</div>
                                            </li>
                                            <li>
                                                <div>If your cabinets are higher than the stove, you must install a heat resistant footing under the stove to
                                                increase its height. The installation manual will provide more details for this scenario.</div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Connections</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Your old connections must be in good order for your new appliance
                                            installation.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <div class="subsubsection">For a gas range, check for:
                                                <ol>
                                                    <li>
                                                    <div>A regular 3-pronged 110 V outlet within 3-5 feet of the new unit.</div>
                                                    </li>
                                                    <li>
                                                    <div>A gas line connection right behind the range.</div>
                                                    </li>
                                                    <li>
                                                    <div>A gas shut off valve for the gas close by.</div>
                                                    </li>
                                                    <li>
                                                    <div>Gas connection should be functioning and non-eroding.</div>
                                                    </li>
                                                    <li>
                                                    <div>If the gas connection looks old, or eroding, hire a <a href="/">licensed plumber</a> to fix it. Older connections can
                                                        also have leaks. This would be a small job and it may require a few calls to hire a professional.</div>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div class="subsubsection">For a gas range, check for:
                                                <ol>
                                                    <li>
                                                        <div>A 220-volt power connection within 3-5 feet of the range.</div>
                                                    </li>
                                                    <li>
                                                        <div>If you are replacing an existing electric range, most probably your connection is in good order.</div>
                                                    </li>
                                                </ol>
                                            </div>  
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Installation</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Your new appliance must be installed. Range installations are quite
                                            simple, and many appliance dealers also offer installation as part of the delivery. However, they expect
                                            functioning connections, and will not do any repairs/modifications to existing connections.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Determine whether appliance dealer is offering installation. Installations can range between $30-$50, a
                                                little more for gas stoves.</div>
                                            </li>
                                            <li>
                                                <div>If all connections are in order, your local handy person can also perform the installation.</div>
                                            </li>
                                            <li>
                                                <div><a href="/">Licensed plumbers</a> (for gas ranges) and <a href="/">licensed electricians</a> (for electric ranges) can also perform the installation, but it
                                                would be more expensive given their higher hourly rate.</div>
                                            </li>
                                            <li>
                                                <div>If you are performing a complete kitchen remodel, the appliance installation may already be part of the
                                                service/estimate provided by your <a href="/">licensed electrician</a>/<a href="/">plumber</a> . Check with them first.</div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="section">
                                <div class="h5 mt-3">FAQ</div>
                                    <Fragment>
                                        <div class="subsection">
                                            Can I install a gas stove be installed instead of an electric stove, or vice-versa?
                                        </div>

                                        <div class="subsection">
                                            <ul>
                                            
                                                <li>
                                                    <div>A gas stove needs a gas line, and an electric stove needs a 220V outlet (The usual outlet found in homes is
                                                    110V). If the necessary connection is already present, then switching the type of the stove is easy.</div>
                                                </li>
                                                <li>
                                                    <div>The cost of adding a new gas line depends on 1) the existence of gas in the building and 2) the distance
                                                    from the closest existing gas line in the building to the new location. This work should be performed by a 
                                                    <a href="/">licensed plumber</a>, and walls need to be patched/painted after the plumbing work by a general contractor or
                                                    handy person. In rare cases, the <a href="/">plumber</a>  may perform the patching.</div>
                                                </li>
                                                <li>
                                                    <div>The cost of adding a 220V outlet depends on the distance of the electrical panel to the new stove location.
                                                    This work should be performed by a <a href="/">licensed electrician</a>. The walls need to be patched/painted after the
                                                    electrical work by a general contractor or handy person. In rare cases, the electrician may perform the patching.
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>Pro-tip: Only perform this upgrade if it is necessary. The cost of the additional
                                                    electrical/plumbing line will likely be in several $1000s.</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="h5 fw-bold"><a id="fridge"/>Fridges</div>
                            <div class="section">
                                <div class="h5 mt-3">Type of fridge</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> There are two common types of fridges: those that have ice/water dispensers and those without.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Determine which type of fridge you have first. </div>
                                            </li>
                                            <li>
                                                <div>If your old fridge has ice/water functionality then you can choose a new fridge with the same functionality, or one without it.</div>
                                            </li>
                                            <li>
                                                <div>If your old fridge does not have ice/water functionality and you would like to have a new fridge that does have such functionality, you will need to ensure that the right water connections are available. See below in the <i>connections</i> section. </div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3" >Dimensions</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> You must ensure that your new fridge fits into the existing space. 
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Measure the opening in which your fridge is to fit. Make sure to measure the width, depth and height of the space.</div>
                                            </li>
                                            <li>
                                                <div>Refer to your new fridge's installation manual to ensure it will fit into the current space.</div>
                                            </li>
                                            <li>
                                                <div>In addition to height and width considerations, fridges need a certain space at the back for proper air flow. Again, check the manual.</div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Connections</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> All fridges need a regular 110V power outlet for operation. Those units with ice/water dispensers, also need 
                                            a water connection.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Ensure that an easily accessible power outlet is right behind the fridge. Most fridge wires are 3'-5' long.</div>
                                            </li>
                                            <li>
                                                <div>If you are installing a unit with water/ice dispensers, a water connection within 5 feet of the unit is required. The installation manual will outline where the connection must be. </div>
                                            </li>
                                            <li>
                                                <div>If you don't have a water connection close by and would like to buy a fridge with ice/water dispensers, then you must install a water connection first. It is best to contact a <a href="/">licensed plumber</a>  to do this work. </div>
                                            </li>
                                            <li>
                                                <div>The cost will depend on the location of the closest water line. If your fridge is close to your sink, that's the best/cheapest solution. A new waterline can be forked from the existing sink's waterline. </div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Installation</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Fridge installations are quite simple. If the unit has no water/ice functionality, all you need to do is to plug it in. Otherwise, the water system needs to get hooked up.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>For units with no water functionality, the delivery company will often slide the unit into place for you and plug it in. Check the <i>delivery</i> section above for details.</div>
                                            </li>
                                            <li>
                                                <div>For units with water functionality, the water system must be hooked up to the water line. Most delivery services may include this service for a fee ($30+)</div>
                                            </li>
                                            <li>
                                                <div>If you are handy, you can do this part yourself as well, or hire a handy person. While this part is easy, it is important to be done with diligence to avoid water leaks.</div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="h5 fw-bold"><a id="dishwasher"/>Dishwashers</div>
                            <div class="section">
                                <div class="h5 mt-3" >Dimensions</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> The main consideration for the size of a dishwasher is its width. Most standard dishwashers are 24" wide, with a smaller selection of 18" wide units. Nonetheless, if your kitchen is old, you need to ensure all dimensions fit.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Measure the width and height of your existing dishwasher or the opening. Then measure the depth of your counter. That is from the front edge of the countertop to the back wall.</div>
                                            </li>
                                            <li>
                                                <div>Refer to the installation manual for the new dishwasher and ensure that your existing measurements fit the new unit.</div>
                                            </li>
                                            <li>
                                                <div>Pro tip: If your old dishwasher is a popular brand and installed within the last 5-10 years, then your dimensions should be ok.</div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Connections</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Dishwashers have the most connections from all kitchen appliances. That is because they must be connected to the power, water, and sewer. 
                                            Having proper/functioning connections is important for the operation of this appliance, but also to avoid feature problems like leaks.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Ensure there is a <i>dedicated</i> power outlet for this unit. This is often under the kitchen cabinets or (less likely) behind the unit. Today's electrical code may require that dishwashers are hooked to a dedicated circuit. 
                                                That is, the line must go directly to the panel and cannot have other appliances connected to it. If you have a doubt, check your electrical panel to see whether there is a switch labeled Dishwasher or similar. 
                                                If in doubt, you can hire a <a href="/">licensed electrician</a> for an hour to check this for you.</div>
                                            </li>
                                            <li>
                                                <div>Dishwashers receive their hot water from the sink's hot water. If you have had an old dishwasher, then there should already be a water 
                                                    connection under your sink. Check that water connection and ensure the valve is in good shape and is not eroding. If the valve has problems, a <a href="/">licensed plumber</a>  is the right professional to fix this issue.</div>
                                            </li>
                                            <li>
                                                <div>Some cities/states also require an overflow device installed on the counter, often next to the water faucet. To install the overflow device, an extra hole is required. 
                                                    If there is no hole and an overflow device is required, a local handy person, or counter top specialist can drill that hole. Make sure they are experienced to avoid damage to your countertop.</div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Installation</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Dishwasher installations are most involved because they include a variety of connections and they must be secured to the cabinet/counter. 
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Many appliance dealers (e.g., HomeDepot, Lowe's) offer installation as part of the delivery. Installation can be $150+ and these companies expect functioning and proper connections.</div>
                                            </li>
                                            <li>
                                                <div>If you are handy, you can do this installation yourself, or hire a local handy person.</div>
                                            </li>
                                            <li>
                                                <div><a href="/">Licensed plumbers</a> can perform this installation as well, but will have a higher hourly charge than the other options. If you are performing a complete kitchen remodel, the appliance installation 
                                                    may already be part of the service/estimate provided by your plumber. Check with them first.</div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="fw-bold h5"><a id="garbage"/>Garbage disposals</div>
                            <div class="section">
                                <div class="h5 mt-3">Type of garbage disposal</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description: </span>The main difference between various garbage disposal units is 1) their power and 2) their noise level. 
                                        Units with a higher power (horsepower) will have fewer jams and will pulverize the food better.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Check your current disposal's power rating. It is either written on top of it, or you can look it up online given its model.</div>
                                        </li>
                                        <li>
                                            <div>Use that as a reference point for your new unit. </div>
                                        </li>
                                        <li>
                                            <div>For noise, units get more expensive the quieter they are. Choose a new unit that fits you financially. The noise rating of the new unit is listed on the dealer's website and the unit's manual.</div>
                                        </li>
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Connections</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> These appliances require a regular power outlet, and sit between the sink and the sewer connection, under the cabinet.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>Check under your sink to ensure there is a free power outlet for your disposal system. 
                                                    Note that your dishwasher also requires an outlet, and outlets should not be shared between the two units. If there is no outlet available, you need a <a href="/">licensed electrician</a> to install a new outlet.
                                                </div>
                                            </li>
                                            <li>
                                                <div>If you have an existing disposal system, then a new one can just be swapped in. However, if your sewer connects directly to your sink, then the sewer pipe under the sink needs to get adjusted first to fit the new disposal unit.
                                                    A <a href="/">licensed plumber</a>  is best for this work to avoid any leaks and future problems.  </div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Installation</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description: </span>Garbage disposal units can be cumbersome to install given the tight space under the kitchen sink. Most appliance dealers <i>do not</i> offer installation for this type of appliance.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>A local handy person and <a href="/">licensed plumbers</a>  are best for this installation. If you are handy, you can perform the installation yourself.
                                                </div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="h5 fw-bold"><a id="microwave"/>Over-the-range microwaves</div>
                            <div class="section">
                                <div class="h5 mt-3">Type of microwave</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> Over-the-range microwave ovens differ primarily in their method of venting, and size of vent ducts required. 
                                        Almost all allow you to circulate the air: that is, the air sucked in but then pumped back into the kitchen after going through a filter. A preferable system is one that vents the air to the outside. 
                                        Many microwaves support both systems. Whether you can vent to the outside will depend on the existence of a ducting.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Check your current over-the-range microwave. Most that vent to the outside have a small kitchen cabinet on top of the unit and the ducting is visible inside the cabinet.
                                                If there is ducting, measure the diameter of the duct (if round), or dimensions (if rectangular). Refer to the new unit's manual to ensure your current duct system fits the new unit.
                                            </div>
                                        </li>
                                        <li>
                                            <div>If you don't have an existing duct, adding some ducting that vents to the outside is usually prohibitively/invasive. Make sure to purchase a unit that allows for air circulation.
                                                If you still would like to vent to the outside, contact a general contractor or HVAC contractor to find the best path for the duct. </div>
                                        </li>
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Dimensions</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> Over-the-range microwaves must fit in the space above the range, be the same width as the range, and have the minimum vertical distance from the top of the range.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                            <li>
                                                <div>
                                                    Ensure your new microwave's width is the same as your range's. Refer to installation manuals if necessary.
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    Measure the width of the area where the microwave is to be installed. Ensure that it will fit the new microwave.
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    The surface of most ranges is about 1-2 inches above the kitchen counter. Measure from the surface of the range to the bottom of where the new microwave is to be installed. 
                                                    Refer to the microwave's installation manual and ensure that distance is adequate. 
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            <div class="section">
                                <div class="h5 mt-3">Connections</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Other than the ducting described above, microwaves only require a <i>dedicated</i> power outlet close by, usually in the cabinet above them.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>
                                                    Find the power outlet that used for your existing unit. It should be functional and not used for any other device. In certain jurisdictions, over-the-range microwaves may require their
                                                    dedicated circuit. Check your electrical panel for a switch with labeling for the hood/microwave. If in doubt, check with a local <a href="/">licensed electrician</a>.
                                                </div>
                                            </li>
                                            
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>

                            <div class="section">
                                <div class="h5 mt-3">Installation</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Installing an over-the-range microwave can be difficult because 1) the ducts need to be connected and sealed, and 2) the unit needs to be secured to the wall (and cabinets).
                                            Unfortunately, most appliance dealers do not offer installation as part of the delivery. 
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                            <li>
                                                <div>
                                                    If you are doing a full kitchen remodel, installing this unit may already be part of your estimate from your general contractor. Check with them.
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    A local handy person, and a general contractor can be used for an individual unit installation. Be prepared to spend $150+ for the installation depending on your location.
                                                </div>
                                            </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="mt-5">
                                <small>Disclaimer: The information on this page is offered without any warranty or claim of accuracy. For any construction or home improvement work, 
                                you should always check with your local building department regarding codes and regulations and consult with licensed professionals when in doubt.</small>
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
                
            </div>
        )
    }
}

export default ReplaceKitchenAppliances;