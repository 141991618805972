import { formatPhoneNumber, get_star_icon } from "utils/formatters";
import { ListGroup, Dropdown, Button, OverlayTrigger, Popover} from 'react-bootstrap';
import network from 'assets/reputation/network.svg';
import friends from 'assets/reputation/friends.svg';
import { FavoriteModals, VendorModalModes } from "./agent_favorite_vendor_list";
import { DiscoverModals } from "./vendor_discover";
import { EntityRole } from "utils/constants";


function VendorComponent(props) {

    // display_mode can be one of favorites, search, friends, contacts
    const { vendor, display_mode, is_checked, toggleVendorSelection, setSelectedVendor, setModalVisibility, navigateToVendorDetail} = props;

    const getContactFirmName = (vendor) => {
        
        if (vendor.role === EntityRole.OTHER_BUSINESS && vendor.contact_name) {
            return <><small>{vendor.contact_name}</small></>;
        }
        else if (vendor.role === EntityRole.CONTRACTOR && vendor.contact_name) {
            return <><small>{vendor.contact_name}</small></>;
        }
        else if (vendor.role === EntityRole.ENGINEER || vendor.role === EntityRole.ARCHITECT) {

            if (vendor.company_name) {
                return <><small>{vendor.company_name}</small></>; 
            } else if(vendor.contact_name) {
                return <><small>{vendor.contact_name}</small></>; 
            }
        }
    }

    const getPhoneHref = () => { 
        if (vendor.phone) {
            return "tel:"+ vendor.phone; 
        } 
        else if (vendor.phone_secondary) {
            return "tel:"+ vendor.phone_secondary; 
        }
    }

    const getPhoneNumber = () => {
        if (formatPhoneNumber(vendor.phone).length > 0) { return formatPhoneNumber(vendor.phone); }
        else if (formatPhoneNumber(vendor.phone_secondary).length > 0) { return formatPhoneNumber(vendor.phone_secondary); }
        else return <> &nbsp;</>
    }

    const visibilityTooltip = () => {
        if (vendor.hidden) { return 'This vendor is hidden from your agent network when they browse your vendors.'}
        else {
            return 'This vendor is  visible to your agent network when they browse your vendors.'
        }
    }

    const visibilityTooltipHeader = () => {
        if (vendor.hidden) { return 'Hidden'}
        else {
            return 'Visible'
        }
    }

    const getIconsDesktop = () => {
        if (display_mode === 'favorites') {
            return <div class="row">
                        <div class="col gx-0 text-end">
                            {
                                vendor.note && vendor.note.length > 0 ?
                                    <OverlayTrigger key="private_note" placement="auto"
                                        overlay={ 
                                        <Popover id="popover-basic">
                                            <Popover.Header as="h3">Private note</Popover.Header>
                                            <Popover.Body>
                                                {vendor.note}
                                            </Popover.Body>
                                        </Popover>
                                        }>
                                        <Button variant="link" className="p-2  noCursorPointer">
                                            <i class="fa-regular fa-note-sticky"></i>
                                        </Button>
                                    </OverlayTrigger>
                                :
                                <>&nbsp;</>
                            }
                        </div>
                        <div class="col-auto gx-0 text-end">
                            <OverlayTrigger key="visibility" placement="auto"
                                overlay={ 
                                <Popover id="popover-basic">
                                    <Popover.Header as="h3">{visibilityTooltipHeader()}</Popover.Header>
                                    <Popover.Body>
                                        {visibilityTooltip()}
                                    </Popover.Body>
                                  </Popover>
                                }>
                                 <Button variant="link" className="p-2  noCursorPointer">
                                    { vendor.hidden ? <i class="fa-regular fa-eye-slash" style={{color: '#DC3545'}}/>: <i class="fa-regular fa-eye"/>}
                                </Button>
                            </OverlayTrigger>
                            
                        </div>
                        <div class="col-auto gx-0 text-end">
                            <Dropdown size="sm" >
                                <Dropdown.Toggle variant="link">
                                    <i class="fa-solid fa-ellipsis "></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_info_modal, true)} }>More info</Dropdown.Item>
                                    {
                                        vendor.role === EntityRole.OTHER_BUSINESS ?
                                            <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_other_vendor_modal, true, VendorModalModes.UPDATE_VENDOR)} }>Edit</Dropdown.Item>
                                        :
                                            <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.UPDATE_VENDOR)} }>Edit</Dropdown.Item>
                                    }                                 
                                    <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_delete_modal, true)} }>Remove from favorites</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
        }
        else if (display_mode === 'search') {
                return <div class="row align-items-center">
                            <div class="col-md-8 text-end text-primary">
                                <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                                    <Popover id="favorites_count">
                                        <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                        <Popover.Body>
                                            Number of agents who have included this vendor in their favorites.
                                        </Popover.Body>
                                    </Popover>
                                }>
                                        <Button variant="link icons" className="ps-2 pe-1 noCursorPointer">
                                            <img src={network} class="icons"/> 
                                        </Button>
                                </OverlayTrigger>{vendor.favorites_count}
                            </div>
                            <div class="col-md-auto text-end">
                                { vendor.is_favorite ?
                                     <OverlayTrigger key="already-favorite" placement="auto" overlay={ 
                                        <Popover id="already-favorite">
                                            <Popover.Header as="h3">My vendors</Popover.Header>
                                            <Popover.Body>
                                                This vendor is already in your list of vendors.
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                            <Button variant="link icons" size="sm"><i class="fa-solid fa-check fa-lg" style={{color: '#135736'}}></i></Button>
                                    </OverlayTrigger>
                                    
                                :
                                    <Button variant="link icons" size="sm" onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.ADD_TO_FAVORITES)} }>Add</Button>
                                }
                            </div>
                        </div>
        }
        else if (display_mode === 'friends') {
            return <div class="row">
                        <div class="col-md-4 text-center text-primary">
                            <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                                <Popover id="favorites_count">
                                    <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                    <Popover.Body>
                                        Number of agents who have included this vendor in their favorites.
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Button variant="link icons" className="ps-2 pe-1 noCursorPointer">
                                    <img src={network} class="icons"/> 
                                </Button>
                            </OverlayTrigger>{vendor.favorites_count}
                        </div>
                        <div class="col-md-4 text-center text-primary">
                            <Button variant="link icons" onClick={ () => {setSelectedVendor(vendor); setModalVisibility(DiscoverModals.show_friends_modal, true)} }>
                                <img src={friends} class="icons"/> 
                            </Button>
                        </div>
                        <div class="col-md-auto text-center">
                            { vendor.is_favorite ?
                                    <OverlayTrigger key="already-favorite" placement="auto" overlay={ 
                                    <Popover id="already-favorite">
                                        <Popover.Header as="h3">My vendors</Popover.Header>
                                        <Popover.Body>
                                            This vendor is already in your list of vendors.
                                        </Popover.Body>
                                    </Popover>
                                }>
                                        <Button variant="link icons" size="sm"><i class="fa-solid fa-check fa-lg" style={{color: '#135736'}}></i></Button>
                                </OverlayTrigger>
                                
                            :
                                <Button variant="link icons" size="sm" onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.ADD_TO_FAVORITES)} }>Add</Button>
                            }
                        </div>
                    </div>
            }
        else if (display_mode === 'contact_vendors_list') {
            return <div class="row">
                        <div class="col text-center text-primary">
                            <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                            <Popover id="favorites_count">
                                <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                <Popover.Body>
                                    Number of agents who have included this vendor in their favorites.
                                </Popover.Body>
                            </Popover>
                            }>
                                <Button variant="link icons" className="ps-2 pe-1 noCursorPointer">
                                    <img src={network} class="icons"/> 
                                </Button>
                            </OverlayTrigger>{vendor.favorites_count}
                        </div>
                    </div>
            }
    }

    const getIconsMobile = () => {
        if (display_mode === 'favorites') {
            return  <div class="col gx-0">
                        <div class="row">
                            <div class="col">
                            {
                                vendor.note && vendor.note.length > 0 ?
                                    <OverlayTrigger key="private_note" placement="auto"
                                        overlay={ 
                                        <Popover id="popover-basic">
                                            <Popover.Header as="h3">Private note</Popover.Header>
                                            <Popover.Body>
                                                {vendor.note}
                                            </Popover.Body>
                                        </Popover>
                                        }>
                                        <Button variant="link" className="p-2  noCursorPointer">
                                            <i class="fa-regular fa-note-sticky"></i>
                                        </Button>
                                    </OverlayTrigger>
                                :
                                <>&nbsp;</>
                            }
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <OverlayTrigger key="visibility" placement="auto"
                                    overlay={ 
                                    <Popover id="popover-basic">
                                        <Popover.Header as="h3">{visibilityTooltipHeader()}</Popover.Header>
                                        <Popover.Body>
                                            {visibilityTooltip()}
                                        </Popover.Body>
                                    </Popover>}>
                                    <Button variant="link" className="p-2  noCursorPointer">
                                        { vendor.hidden ? <i class="fa-regular fa-eye-slash" />: <i class="fa-regular fa-eye" />}
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <Dropdown size="sm" >
                                    <Dropdown.Toggle variant="link">
                                        <i class="fa-solid fa-ellipsis"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_info_modal, true)}  }>More info</Dropdown.Item>
                                        {
                                            vendor.role === EntityRole.OTHER_BUSINESS ?
                                                <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_other_vendor_modal, true, VendorModalModes.UPDATE_VENDOR)} }>Edit</Dropdown.Item>
                                            :
                                                <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.UPDATE_VENDOR)} }>Edit</Dropdown.Item>
                                        }  
                                        <Dropdown.Item onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_delete_modal, true)} }>Remove from favorites</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
        }
        else if (display_mode === 'search') {
            return  <div class="col">
                        <div class="row mt-2">
                            <div class="col text-primary">
                                <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                                     <Popover id="favorites_count">
                                        <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                        <Popover.Body>
                                            Number of agents who have included this vendor in their favorites.
                                        </Popover.Body>
                                    </Popover>
                                }>
                                    <Button variant="link icons" className="p-0 ms-3  noCursorPointer">
                                        <img src={network} class="icons me-1"/> 
                                    </Button>
                                </OverlayTrigger>{vendor.favorites_count}
                            </div>

                        </div>
                        <div class="row  mt-3">
                            <div class="col ps-3 text-center">
                                { vendor.is_favorite ?
                                     <OverlayTrigger key="already-favorite" placement="auto" overlay={ 
                                        <Popover id="already-favorite">
                                            <Popover.Header as="h3">My vendors</Popover.Header>
                                            <Popover.Body>
                                                This vendor is already in your list of vendors.
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                            <Button variant="link icons" size="sm"><i class="fa-solid fa-check fa-lg" style={{color: '#135736'}}></i></Button>
                                    </OverlayTrigger>
                                    
                                :
                                    <Button variant="link icons" size="md" 
                                        onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.ADD_TO_FAVORITES)} }>Add</Button>
                                }
                            </div>
                        </div>
                    </div>
        }
        else if (display_mode === 'friends') {
            return  <div class="col-2">
                        {/* right now, the UI is too crowded with 3 icons */}
                        {/* <div class="row mt-1">
                            <div class="col text-primary gx-0 justify-content-center d-flex">
                                <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                                    <Popover id="favorites_count">
                                        <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                        <Popover.Body>
                                            Number of agents who have included this vendor in their favorites.
                                        </Popover.Body>
                                    </Popover>
                                    }>
                                    <Button variant="link icons" className="p-0 pe-1 ps-3  noCursorPointer">
                                        <img src={network} class="icons"/> 
                                    </Button>
                                </OverlayTrigger>{vendor.favorites_count}
                            </div>
                        </div> */}
                        <div class="row mt-1 mb-2">
                            <div class="col text-primary gx-0 justify-content-center d-flex">
                                <Button variant="link icons" className="p-0 pe-1 ps-2" 
                                    onClick={ () => {setSelectedVendor(vendor); setModalVisibility(DiscoverModals.show_friends_modal, true)} }>
                                    <img src={friends} class="icons"/> 
                                </Button>
                            </div>
                        </div>
                        <div class="row  mt-3">
                            <div class="col text-center justify-content-center d-flex">
                                { vendor.is_favorite ?
                                     <OverlayTrigger key="already-favorite" placement="auto" overlay={ 
                                        <Popover id="already-favorite">
                                            <Popover.Header as="h3">My vendors</Popover.Header>
                                            <Popover.Body>
                                                This vendor is already in your list of vendors.
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                            <Button variant="link icons" size="sm"><i class="fa-solid fa-check fa-lg" style={{color: '#135736'}}></i></Button>
                                    </OverlayTrigger>
                                    
                                :
                                    <Button variant="link icons" size="md" 
                                        onClick={ () => {setSelectedVendor(vendor); setModalVisibility(FavoriteModals.show_existing_vendor_modal, true, VendorModalModes.ADD_TO_FAVORITES)} }>Add</Button>
                                }
                            </div>
                        </div>
                    </div>
        }
        else if (display_mode === 'contact_vendors_list') {
            return  <div class="col-2">
                        <div class="row">
                            <div class="col ps-1 justify-content-center d-flex text-primary">
                                <OverlayTrigger key="favorites_count" placement="auto" overlay={ 
                                    <Popover id="favorites_count">
                                        <Popover.Header as="h3">Popularity of this vendor</Popover.Header>
                                        <Popover.Body>
                                            Number of agents who have included this vendor in their favorites.
                                        </Popover.Body>
                                    </Popover>
                                    }>
                                    <Button variant="link icons" className="p-0 pe-1 ps-3  noCursorPointer">
                                        <img src={network} class="icons"/> 
                                    </Button>
                                </OverlayTrigger>{vendor.favorites_count}
                            </div>
                        </div>
                    </div>
        }
    }

    return (
        <ListGroup.Item className="text-start p-0 vendorComponent" >
            {/* Desktop */}
            <div class="container desktop ">
                <div class="row align-items-center vendorRow">
                    {display_mode === 'favorites' && 
                        <div class="col-auto gx-3">
                            <input 
                                class="form-check-input" 
                                type="checkbox"  
                                value="" 
                                checked={is_checked} 
                                onChange={ (e) => toggleVendorSelection(vendor, e.currentTarget.checked) }
                            />
                        </div>
                    }
                    <div class="col-4" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}>
                        <div class="row">
                            <div class="col fw-semibold text-truncate" ><small>{vendor.entity_name}</small></div>
                        </div>
                        <div class="row">
                            <div class="col text-truncate text-secondary" >{getContactFirmName(vendor)}</div>
                        </div>
                    </div>
                    <div class="col-auto" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}><img src={get_star_icon(vendor.verified_review_rating)} class="detailsStarIcon"/></div>

                    {/* medium sized container and larger */}
                    <div class="col-2 gx-2 fw-light text-truncate medium-container text-success" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}><small>{vendor.category}</small></div>
                    <div class="col-auto gx-2 fw-light medium-container phone"><small><a className="text-decoration-none" href={getPhoneHref()}>{getPhoneNumber()}</a></small></div>

                    {/* smaller than medium container */}
                    <div class="col-2 gx-0 small-container">
                        <div class="row"><div class="col fw-light text-truncate text-success" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}><small>{vendor.category}</small></div></div>
                        <div class="row"><div class="col fw-light text-truncate "><small><a className="text-decoration-none" href={getPhoneHref()}>{getPhoneNumber()}</a></small></div></div>
                    </div>
                    <div class="col">{getIconsDesktop()}</div>
                </div>
            </div>

             {/* Mobile */}
             <div class="container mobile ">
                <div class="row vendorRow">
                {   display_mode === 'favorites' && 
                    <div class="col-auto gx-3 pe-0">
                        <input 
                            class="form-check-input vendorComponentCheckbox" 
                            type="checkbox"  
                            value="" 
                            checked={is_checked} 
                            onChange={ (e) => toggleVendorSelection(vendor, e.currentTarget.checked) }
                        />
                    </div>
                }
                    <div class="col-9">
                        <div class="row">
                            <div class="col fw-semibold text-truncate" role="button"  onClick={() =>navigateToVendorDetail(vendor.ID, vendor.role)}><small>{vendor.entity_name}</small></div>
                        </div>
                        <div class="row">
                            <div class="col text-truncate text-success" role="button"  onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}><small>{vendor.category}</small></div>
                        </div>
                        <div class="row">
                            <div class="col" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}><img src={get_star_icon(vendor.verified_review_rating)} class="detailsStarIcon"/></div>
                        </div>
                        <div class="row">
                            <div class="col fw-light text-secondary" role="button" onClick={() => navigateToVendorDetail(vendor.ID, vendor.role)}>{getContactFirmName(vendor)}</div>
                        </div>
                        <div class="row">
                            <div class="col fw-light"><small><a className="text-decoration-none" href={getPhoneHref()}>{getPhoneNumber()}</a></small></div>
                           
                        </div>
                    </div>
                    {getIconsMobile()}
                    
                </div>
            </div>
        </ListGroup.Item>
    )
}

export default VendorComponent;