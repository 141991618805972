import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import VendorComponent from "./vendor_component";
import { generic_get_api } from "api/generic_api";
import {Button, Spinner, ListGroup, ToggleButton, Pagination, Modal} from 'react-bootstrap';
import {AuthContext} from 'auth_context';
import { EntityRole } from "utils/constants";
import { ExistingVendorModal } from "./vendor_search";
import { isVendor } from "utils/helpers";

import {
    VENDORS_URL,
    TRUSTED_VENDORS_URL,
} from 'utils/constants';

const RESULTS_PER_PAGE = 15;

export const DiscoverModals = {
    show_friends_modal: 'show_friends_modal',
}

const NetworkFilter = {
    TRUSTED: 'Renolition trusted vendors',
    ALL: 'Ranked list of all SF vendors',
    // ANY_NETWORK: 'Favorite vendors of any agent',
    MY_FRIENDS_NETWORK: "Vendors in my agent network",
}

const SortOrder = {
    BEST_MATCH: 'best_match',
    YEARS_LICENSED_HIGH_LOW: 'years_licensed_high_low',
    YEARS_LICENSED_LOW_HIGH: 'years_licensed_low_high',
    NUM_JOBS: 'num_jobs',
    NUM_NETWORK_HIGH_LOW: 'num_network_high_low',
    NUM_NETWORK_LOW_HIGH: 'num_network_low_high',
}

const VENDOR_SUB_CATEGORIES = {
    b_kitchen: 'Kitchen remodel',
    b_bathroom: 'Bathroom remodel',
    b_foundation: "Foundations",
    concrete: "Concrete/Masonry",
    b_shoring: "Shoring",
    b_adu: "ADUs/New Units",
    b_deck: "Decks/Patios",
    // b_fence_and_landscaping: "Fences/Landscaping",
    // stairs: "Stairs",
    // addition: "Liveable Space",
    
    // structural: "Framing/Steel",
    // door_and_window: "Doors/Windows",
    // dryrot: "Dryrot/Pest",
    // facade: "Facade/Siding",
    
    // laundry: "Laundries",
    // roof: "Roofing",
    // fire: "Fire Protection",
    // hvac: "HVAC",
    
    // demolition: "Demolition",
    
    // remodel: "Other Remodel",
    // living_space: "Living Apace",
}

const VENDOR_CATEGORIES = {
    GENERAL_BUILDING: 'General Building',
    ENGINEER: 'Engineer',
    ARCHITECT: 'Architect',
    ROOFING: 'Roofing',
    WINDOWS: 'Windows',
    PLUMBING: 'Plumbing',
    ELECTRICAL: 'Electrical',
    EV_CHARGING: 'EV Charging',
    HVAC: 'HVAC',
    FIRE: 'Fire Protection',
    LANDSCAPING: 'Landscaping',
    SOLAR: 'Solar',
    PAINTING: 'Painting',
}

const SubCategoryFilterWithAll = Object.assign({ALL: 'All'}, VENDOR_SUB_CATEGORIES);

class VendorDiscover extends Component {
    constructor(props) {
        super(props);

        let search_result = null;
        let network_filter = null;
        let category_filter = null;
        let sub_category_filter = null;
        let has_verified_reviews = false;
        let has_active_workers_comp_coverage = false;
        let has_active_license = false;
        let has_no_active_permits = false;
        let sort_order =  SortOrder.BEST_MATCH;
        let start = 0;
        let total_result = 0;

        // to make back button work and search results re-instated
        if (props.location.state){
            if (props.location.state.search_result) {
                search_result = props.location.state.search_result;
            }
            if (props.location.state.network_filter) {
                network_filter = props.location.state.network_filter;
            }
            if (props.location.state.category_filter) {
                category_filter = props.location.state.category_filter;
            }
            if (props.location.state.sub_category_filter) {
                sub_category_filter = props.location.state.sub_category_filter;
            }
            if (props.location.state.has_verified_reviews) {
                has_verified_reviews = props.location.state.has_verified_reviews;
            }
            if (props.location.state.has_active_workers_comp_coverage) {
                has_active_workers_comp_coverage = props.location.state.has_active_workers_comp_coverage;
            }
            if (props.location.state.has_active_license) {
                has_active_license = props.location.state.has_active_license;
            }
            if (props.location.state.has_no_active_permits) {
                has_no_active_permits = props.location.state.has_no_active_permits;
            }
            if (props.location.state.sort_order) {
                sort_order = props.location.state.sort_order;
            }
            if (props.location.state.start) {
                start = props.location.state.start;
            }
            if (props.location.state.total_result) {
                total_result = props.location.state.total_result;
            }
        } 

        this.state = {
            search_result: search_result,
            searching: false,
            network_filter: network_filter,
            category_filter: category_filter, 
            sub_category_filter: sub_category_filter,
            has_verified_reviews: has_verified_reviews,
            has_active_workers_comp_coverage: has_active_workers_comp_coverage,
            has_active_license: has_active_license,
            has_no_active_permits: has_no_active_permits,
            sort_order: sort_order,
            start: start,
            checked_entity_role: false,
            show_verified_review_description: false,
            show_existing_vendor_modal: false,
            selected_vendor: null,
            show_learn_more_modal: false,
            show_friends_modal: false,
            total_result: total_result,
        };

        this.fetchVendors = this.fetchVendors.bind(this);
        this.setShowAddToFavorites = this.setShowAddToFavorites.bind(this);
        this.setSelectedVendor = this.setSelectedVendor.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
        this.onSuccessVendorAddToFavorites = this.onSuccessVendorAddToFavorites.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);
        this.setShowFriendsModal = this.setShowFriendsModal.bind(this);
        this.setModalVisibility = this.setModalVisibility.bind(this);
    }

    static contextType = AuthContext;

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_discover'})
        document.title = "Discover new vendors to add to your network";

        if( this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) {
            this.setState({ 
                network_filter: NetworkFilter.ALL,
            });
        }
    }

    setModalVisibility(modal, is_visible, modal_mode=null) {
        this.setState({[modal]: is_visible, modal_mode: modal_mode});
    }

    setShowFriendsModal(value) {
        this.setState({ show_friends_modal: value });
    }

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    // we redo the search here after a successful addition of vendor to favorites list to freshen up the list
    onSuccessVendorAddToFavorites() {
        this.setState({
            show_existing_vendor_modal: false,
        })
        this.fetchVendors();
    }

    // checking for logged_in_entity_role must happen in componentDidMount and componentDidUpdate. During refresh
    // refresh the value for logged_in_entity_role may not be available yet to componentDidMount. And during page
    // navigation componentDidUpdate is not called on first call.
    componentDidUpdate(prevProps, prevState) {

        if (!prevState.checked_entity_role) {

            if( this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) {
                this.setState({ 
                    network_filter: NetworkFilter.ALL,
                    checked_entity_role: true,
                });
            }
            else {
                this.setState({ 
                    checked_entity_role: true,
                });
            }
        }
    }

    navigateToVendorDetail(vendor_id, role) {
        // to make back button clicks work
        this.props.history.replace(
            this.props.history.location.pathname, 
            {
                search_result: this.state.search_result,
                network_filter: this.state.network_filter,
                category_filter: this.state.category_filter,
                sub_category_filter: this.state.sub_category_filter,
                has_verified_reviews: this.state.has_verified_reviews,
                has_active_workers_comp_coverage: this.state.has_active_workers_comp_coverage,
                has_active_license: this.state.has_active_license,
                has_no_active_permits: this.state.has_no_active_permits,
                sort_order: this.state.sort_order,
                start: this.state.start,
                total_result: this.state.total_result,
            }
        );

        let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
        this.props.history.push(details_url);
    }

    setSelectedVendor(vendor) {
        this.setState({selected_vendor: vendor});
    }

    setShowAddToFavorites(value) {
        this.setState({show_existing_vendor_modal: value});
    }

    fetchVendors() {

        this.setState({ searching: true });
        window.scrollTo(0, 0); // scroll up here in case one of the pagination controls are clicked


        if (this.state.network_filter === NetworkFilter.TRUSTED) {
            generic_get_api(TRUSTED_VENDORS_URL, {})
            .then(
                data => {
                    window.scrollTo(0, 0);
                    this.setState({
                        search_result: data.result,
                        total_result: data.total,
                        // network_filter: filter,
                        searching: false,
                    });
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } else {
                    this.setState({ searching: false });
                }
            });

            trackEvent(EventNames.API_REQUEST, {data_1: TRUSTED_VENDORS_URL });
        }
        else{
            // must do this here since we need the key rather than the value that is displayed to match the backend
            // e.g. {kitchen: 'Kitchen remodel'}, we need 'kitchen' but are storing 'Kitchen remodel'
            let sub_category = Object.keys(SubCategoryFilterWithAll).find(key => SubCategoryFilterWithAll[key] === this.state.sub_category_filter);
            
            // same as sub_category, but with network type
            let network_filter = Object.keys(NetworkFilter).find(key => NetworkFilter[key] === this.state.network_filter);

            let url_params = {
                result_type: network_filter, 
                category: this.state.category_filter, 
                sub_category: sub_category,
                has_verified_reviews: this.state.has_verified_reviews,
                has_active_workers_comp_coverage: this.state.has_active_workers_comp_coverage,
                has_active_license: this.state.has_active_license,
                has_no_active_permits: this.state.has_no_active_permits,
                sort_order: this.state.sort_order,
                start: this.state.start,
            }

            generic_get_api(VENDORS_URL, url_params)
            .then(
                data => {
                    window.scrollTo(0, 0);
                    this.setState({
                        search_result: data.result,
                        total_result: data.total,
                        // network_filter: filter,
                        searching: false,
                    });
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } else {
                    this.setState({ searching: false });
                }
            });

            trackEvent(EventNames.API_REQUEST, {data_1: VENDORS_URL, data_2: url_params});
        }
    }

    render() {
        const {search_result, searching, network_filter, category_filter, sub_category_filter, sort_order, total_result, start,
            has_active_license, has_active_workers_comp_coverage, has_verified_reviews, has_no_active_permits} = this.state;
        
        const effectiveCategories = () => {
            
            if (network_filter === NetworkFilter.ALL) {
                return VENDOR_CATEGORIES;
            }

            return Object.assign({ALL: 'All'}, VENDOR_CATEGORIES);
        }

        const canSubmitSearch = () => {

            let network_filter_set = network_filter !== null;
            let trusted_selected = network_filter ===  NetworkFilter.TRUSTED;

            // if general building is selected, then a sub-category must be set.
            let sub_category_set = category_filter && (category_filter !== VENDOR_CATEGORIES.GENERAL_BUILDING || (sub_category_filter));

            return network_filter_set && (trusted_selected || sub_category_set);
        }

        const paginationPages = () => {
            if (!total_result) {
                return [0];
            }

            let num_pages = Math.ceil(total_result / RESULTS_PER_PAGE);

            if (num_pages <= 8) {
                let output = [];
                let i = 1;
                for (; i <= num_pages; i++) {
                    output.push(i);
                }
                return output;
            }

            // we will need ellipses in the pagination
            else {
                let current_page = Math.floor(start / RESULTS_PER_PAGE) + 1;
                
                if (current_page === 4) {
                    return [1, 2, 3, current_page, "...", num_pages - 2, num_pages - 1, num_pages]
                }
                else if (current_page === num_pages - 3) {
                    return [1, 2, 3, "...", current_page, num_pages - 2, num_pages - 1, num_pages]
                }
                else if (current_page > 4 && current_page <= num_pages - 4) {
                    return [1, 2, 3, "...", current_page, "...", num_pages - 1, num_pages]
                }
                else {
                    return [1, 2, 3, "...", num_pages - 2, num_pages - 1, num_pages]
                }
            }
        }

        const pageClick = (page) => {

            let new_start = (page - 1) * RESULTS_PER_PAGE;

            if (start !== new_start) {
                this.setState({start: new_start}, this.fetchVendors);
            }
        }

        const nextPageClick = () => {
            if (start + search_result.length < total_result) {
                let new_start = start + search_result.length;
                this.setState({start: new_start}, this.fetchVendors);
            }
        }

        const previousClick = () => {
            if (start !== 0 && start >= RESULTS_PER_PAGE) {
                let new_start = start - RESULTS_PER_PAGE;
                this.setState({start: new_start}, this.fetchVendors);
            }
        }

        const filterChanged = (filter_type, value) => {

            this.setState({
                [filter_type]: value,
                start: 0,
            }, this.fetchVendors);
        }

        const resetSearchResults = () => {
            this.setState({
                search_result: null,
                searching: false,
                has_verified_reviews: false,
                has_active_workers_comp_coverage: false,
                has_active_license: false,
                has_no_active_permits: false,
                sort_order: SortOrder.BEST_MATCH,
                start: 0,
            })
        }

        const getVendorComponentDisplayMode = () => {
            if (this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) {
                return 'contact_vendors_list';
            } 
            else if (this.context.logged_in_entity_role === EntityRole.AGENT) {
                if (this.state.network_filter === NetworkFilter.MY_FRIENDS_NETWORK) {
                    return "friends";
                }
                else { 
                    return "search"; 
                }
            }

        }

        return(


            <div class="vendorDiscoverEnvelope">
                <div class="vendorDiscoverPage">
                    <div class="container vendorDiscoverHeader">
                        <div class="row"> <div class="col display-6">Discover vendors by category</div></div>

                        <div class="row mt-4">
                            <div class="col">Discover vendors in San Francisco by using our unique search tools &amp; detailed data about each vendor.&nbsp;
                                <a class="text-decoration-underline" role="button" onClick={ () => this.setState({ show_learn_more_modal: true})}>Learn more</a>
                            </div>
                        </div>
                        <LearnMoreModal 
                            show_learn_more_modal={this.state.show_learn_more_modal}
                            hideLearnMoreModal={this.hideLearnMoreModal}
                            logged_in_entity_role= {this.context.logged_in_entity_role}
                        />
                    </div>
                    <div class="container vendorDiscoverCriteria">
                        <div class="row mt-4">
                            <div class="col-12 gx-2">
                                { (this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT || isVendor(this.context.logged_in_entity_role)) ?
                                    <select name="questionCategory" class="form-select">
                                        <option disabled selected value>All vendors</option>
                                    </select>
                                    :
                                    <select name="questionCategory" class="form-select" 
                                            onChange={ (e) => this.setState({ network_filter: e.target.value, category_filter: null, sub_category_filter: null}, resetSearchResults) } disabled={searching}>
                                        <option disabled selected value>Where to search</option>
                                            {Object.keys(NetworkFilter).map((keyName, i) => (
                                                <option value={NetworkFilter[keyName]} selected={network_filter === NetworkFilter[keyName]}> {NetworkFilter[keyName]} </option>
                                            ))}
                                    </select>

                                }
                            </div>
                        </div>
                        {
                            network_filter !== NetworkFilter.TRUSTED &&
                            <div class="row mt-2">
                                <div class="col-12 gx-2">
                                    <select name="questionCategory" class="form-select" onChange={(e) => this.setState({ category_filter: e.target.value, sub_category_filter: null }, resetSearchResults )} disabled={searching || network_filter === null}>
                                        <option disabled selected={category_filter === null} value>Category</option>
                                        {Object.keys(effectiveCategories()).map((keyName, i) => (
                                            <option value={effectiveCategories()[keyName]} selected={category_filter === effectiveCategories()[keyName]}>{effectiveCategories()[keyName]}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        
                        { category_filter === effectiveCategories().GENERAL_BUILDING &&
                            <div class="row mt-2"> 
                                <div class="col-12 gx-2">
                                    <select name="questionCategory" class="form-select" onChange={(e) => this.setState({ sub_category_filter: e.target.value}, resetSearchResults )} disabled={searching}>
                                        <option disabled selected={sub_category_filter === null} value>Sub category</option>
                                        {Object.keys(SubCategoryFilterWithAll).map((keyName, i) => (
                                            <option value={SubCategoryFilterWithAll[keyName]} selected={sub_category_filter === SubCategoryFilterWithAll[keyName]}>{SubCategoryFilterWithAll[keyName]}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        <div class="row mt-2"> 
                            <div class="col-12 d-grid gx-2 text-nowrap">
                                {searching ? 
                                    <Button variant="warning" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        Searching...
                                    </Button> 
                                :   
                                    <Button 
                                        disabled={!canSubmitSearch()}
                                        variant="warning"
                                        onClick={ this.fetchVendors }
                                    > 
                                        Search Vendors</Button>
                                }
                            </div>
                        </div> 
                    </div>
                    <div class="container vendorDiscoverResults">       
                        { search_result !== null && network_filter === NetworkFilter.TRUSTED &&
                            <div class="col mt-5 text-center mb-4">Trusted vendors have attested to provide high customer service for <strong>real estate agents and their clients</strong>. This list keeps growing
                            as new vendors join the trusted circle.
                            </div>
                        }
                        { search_result !== null && network_filter !== NetworkFilter.TRUSTED &&
                            <>
                                <div class="overflow-auto text-nowrap mt-4">
                                    <span class="text-primary me-3"><i class="fa-solid fa-filter fa-lg me-1"></i>Filters</span>
                                    <ToggleButton
                                        size="sm"
                                        className="me-2"
                                        id="reviews-toggle-check"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={has_verified_reviews}
                                        value="reviews-toggle-check"
                                        onChange={ (e) => filterChanged("has_verified_reviews", e.currentTarget.checked) }
                                    >
                                        Verified reviews
                                    </ToggleButton>
                                    {/* workers comp is not relevant to Engineers and Architects */}
                                    { category_filter !== effectiveCategories().ARCHITECT && category_filter !== effectiveCategories().ENGINEER && category_filter !== effectiveCategories().ALL &&
                                       
                                        <ToggleButton
                                            size="sm"
                                            className="me-2"
                                            id="workers-toggle-check"
                                            type="checkbox"
                                            variant="outline-primary"
                                            checked={has_active_workers_comp_coverage}
                                            value="workers-toggle-check"
                                            onChange={(e) => filterChanged("has_active_workers_comp_coverage", e.currentTarget.checked )}
                                        >
                                            Workers comp
                                        </ToggleButton>
                                    }
                                     <ToggleButton
                                        size="sm"
                                        className="me-2"
                                        id="license-toggle-check"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={has_active_license}
                                        value="license-toggle-check"
                                        onChange={(e) => filterChanged("has_active_license", e.currentTarget.checked )}
                                    >
                                        With active license
                                    </ToggleButton>
                                </div>
                            </>
                        }

                        { search_result && search_result.length == 0 &&
                            <div class="row mt-4"><div class="col">We found no results for your criteria</div></div>
                        }

                        { search_result && search_result.length > 0 &&
                            <>
                                <div class="row mt-3">
                                    <div class="col-auto d-flex align-items-center">
                                        <small>Showing {start + 1}-{start + search_result.length} of { total_result }</small>
                                    </div>
                                    { 
                                        network_filter !== NetworkFilter.TRUSTED &&
                                            <div class="col d-flex justify-content-end text-end">
                                                <select class="form-select sortSelect" onChange={(e)=> this.setState({sort_order: e.target.value }, this.fetchVendors )}>
                                                    <option disabled selected value>Sort By</option>
                                                    <option value={SortOrder.BEST_MATCH} selected={sort_order === SortOrder.BEST_MATCH}>Best match</option>
                                                    <option value={SortOrder.YEARS_LICENSED_HIGH_LOW} selected={sort_order === SortOrder.YEARS_LICENSED_HIGH_LOW}>Years licensed, high to low</option>
                                                    <option value={SortOrder.YEARS_LICENSED_LOW_HIGH} selected={sort_order === SortOrder.YEARS_LICENSED_LOW_HIGH}>Years licensed, low to high</option>
                                                    <option value={SortOrder.NUM_JOBS} selected={sort_order === SortOrder.NUM_JOBS}>Permits completed, last 12 months</option>
                                                    <option value={SortOrder.NUM_NETWORK_LOW_HIGH} selected={sort_order === SortOrder.NUM_NETWORK_LOW_HIGH}>Favorited by agents, low to high</option>
                                                    <option value={SortOrder.NUM_NETWORK_HIGH_LOW} selected={sort_order === SortOrder.NUM_NETWORK_HIGH_LOW}>Favorited by agents, high to low</option>
                                                </select>
                                            </div>
                                    }
                                </div>

                                <div class="row mt-2">
                                 
                                    <ListGroup>
                                        <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                            <div class="container header">
                                                <div class="row align-items-center">
                                                    <div class="col-4 text-truncate"><small>Name <br></br>Company</small></div>
                                                    <div class="col-auto text-truncate reviews"><small>Reviews</small></div>
                                                    <div class="col-2 gx-0 medium-container "><small>Category</small></div>
                                                    <div class="col-auto gx-0 medium-container phone"><small>Phone</small></div>
                                                    <div class="col-2 gx-0 small-container"><small>Category <br></br> Phone</small></div>
                                                </div>
                                            </div>
                                            </ListGroup.Item>
                                            {     
                                                search_result.map(
                                                    (vendor) => {
                                                        return <VendorComponent 
                                                                    vendor={ vendor } 
                                                                    display_mode={getVendorComponentDisplayMode()}
                                                                    setShowAddToFavorites={this.setShowAddToFavorites}
                                                                    setSelectedVendor={ this.setSelectedVendor }
                                                                    navigateToVendorDetail={ this.navigateToVendorDetail }
                                                                    setModalVisibility={this.setModalVisibility}
                                                                />
                                                    })
                                            }
                                    </ListGroup>
                                    <ExistingVendorModal
                                        setModalVisibility={this.setModalVisibility}
                                        vendor={this.state.selected_vendor}
                                        show_existing_vendor_modal={ this.state.show_existing_vendor_modal}
                                        onSuccessVendorAddToFavorites= {this.onSuccessVendorAddToFavorites}
                                        modal_mode={this.state.modal_mode}
                                        setSelectedVendor={ this.setSelectedVendor }
                                    />    
                                    <FriendsModal 
                                        vendor={this.state.selected_vendor}
                                        setShowFriendsModal={this.setShowFriendsModal}
                                        show_friends_modal= {this.state.show_friends_modal}
                                    />
                                </div>
                                <div class="row mt-4">
                                    <div class="col d-flex justify-content-center">
                                        <Pagination>
                                            <Pagination.Prev onClick={ previousClick }  disabled={start === 0}/>
                                                {
                                                    paginationPages().map(
                                                        (page) => {
                                                            if (page === '...') {
                                                                return <Pagination.Ellipsis />
                                                            } else {
                                                                return <Pagination.Item 
                                                                                key={page}
                                                                                onClick={()=> pageClick(page)}
                                                                                active={Math.floor(start / RESULTS_PER_PAGE) + 1 === page}>
                                                                            {page}         
                                                                        </Pagination.Item>
                                                            }
                                                           
                                                                
                                                        }
                                                    )
                                                }
                                            <Pagination.Next onClick={ nextPageClick }  disabled={start + search_result.length >= total_result}/>
                                        </Pagination>
                                    </div>
                                </div>
                            </>
                            
                        }
                                
                            
                    </div>
                </div>
            </div>
        )
    }
}

function FriendsModal(props) {
    const {show_friends_modal, setShowFriendsModal, vendor } = props;

    if (!show_friends_modal) { return null; }

    return(
        <Modal
            show={show_friends_modal} 
            onHide={ () => setShowFriendsModal(false) }
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="fs-6 fw-bold">Agents in your network who favorited vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container p-0">
                    <div class="row">
                        <div class="col text-secondary">Vendor name</div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">{vendor.name}</div>
                    </div>
                    <div class="row">
                        <div class="col text-secondary">Agent</div>
                    </div>
                    {
                        vendor.friends.map(friend => {
                            return <div class="row mt-2">
                                <div class="col"> <i class="fa-regular fa-user fa-sm pe-2"></i>{friend}</div>
                            </div>
                        })
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

function LearnMoreModal(props) {
    const {show_learn_more_modal, hideLearnMoreModal, logged_in_entity_role } = props;

    if (!show_learn_more_modal) { return null; }

    return(
        <Modal
            show={show_learn_more_modal} 
            onHide={ hideLearnMoreModal }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Discover new vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container lh">
                    <div class="row">
                        <div class="col fw-bold">How can I discover new vendors?</div>
                    </div>
                    <div class="row lh-lg">
                        <div class="col"><small>
                            Our database includes all licensed vendors (contractors, electricians, plumbers, architects, engineers etc.) who work in San Francisco. Select the kind of 
                            vendor you are looking for, set various powerful filters, and discover vendors that are best suited.</small>
                        </div>
                    </div>
                    { logged_in_entity_role === EntityRole.AGENT && 
                        <>
                            <div class="row mt-3">
                                <div class="col fw-bold">How can I see venders that agents in my network use?</div>
                            </div>
                            <div class="row lh-lg">
                                <div class="col"> <small>
                                    You can increase your confidence by selecting vendors that other agents use. Choose from two options: <br/>
                                    1) <i>All vendors</i>: Search among all vendors that work in San Francisco.<br/>
                                    2) <i>Vendors of my network</i>: Search among vendors that agents in  your network use and who have been made visible to you.
                                     You can also see which agent uses which vendor.<br/></small>
                                </div>
                            </div>
                        </>
                    }
                    <div class="row mt-3">
                        <div class="col fw-bold">How can I filter the results further?</div>
                    </div>
                    <div class="row lh-lg">
                        <div class="col"><small>
                            1) <i>Verified reviews</i>: This filter includes  vendors who have received at least one verified review.<br/>
                            2) <i>Workers comp</i>: This filter includes vendors who have active workers compensation insurance (if applicable).<br/>
                            3) <i>With active license</i>: This filter includes vendors who have an active license.<br/></small>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">How can I sort the results?</div>
                    </div>
                    <div class="row lh-lg">
                        <div class="col"><small>
                            1) <i>Best match</i>: Use Renolition's internal algorithm to view the best vendors based on reviews, years licensed, work history and more.<br/>
                            2) <i>Years licensed</i>: Sort the results based on how many years the vendors have been licensed.<br/>
                            3) <i>Favorited by agents</i>: Sort the results based on the number of agents who have favorited vendors.<br/>
                            4) <i>Permits completed</i>: Sort the results based on the number of permitted jobs that vendors have completed in the last 12 months.<br/></small>
                        </div>
                    </div>
                  
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VendorDiscover;