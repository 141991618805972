import React, { Component } from "react";
import { Spinner } from 'react-bootstrap';
import {trackEvent, EventNames} from 'utils/mixpanel';
import { REVIEW_URL} from 'utils/constants';


class ReviewEmailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: true,
        };
        this.confirmEmail = this.confirmEmail.bind(this);
    }
    
    componentDidMount() {

        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'ReviewEmailConfirmation'});

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length == 4) {
            
            let code = pathSplit[3];
            this.confirmEmail(code);
        }
    }

    confirmEmail(code) {
        var request = { 
            code: code,
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(REVIEW_URL);

        trackEvent(REVIEW_URL);

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status === 204) {
                    this.setState({ submitting: false } );
                }
            }
        );
    }

    render() {

        return(
            <div class="container mt-5 text-center reviewEmailConfirmationPage">
                { this.state.submitting ?
                    <div class="mt-5"><Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" /></div>
                :
                    <div class="mt-5 fs-5">Thank you for confirming your email address. You should receive your reward in 3-5 business days.</div>
                }
            </div>
        )
    }
}

export default ReviewEmailConfirmation;