import React, { Component, useState, useEffect  } from "react";
import TimePicker from "rc-time-picker";
import moment from 'moment';
import { ACCOUNT_URL, EntityRole, ACCOUNT_PHOTO_URL, AGENT_SETTINGS_URL, VENDOR_PROFILE_DETAIL, VENDOR_PROJECT_PHOTOS } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Spinner, ButtonGroup, ToggleButton, Carousel, Modal } from 'react-bootstrap';
import user_profile_place_holder from 'assets/reputation/user_profile_place_holder.png';
import { generic_put_api_file, generic_get_api, generic_delete_api, generic_put_api } from "api/generic_api";
import { formatPhoneNumber } from "utils/formatters";
import { isValidPhoneNumber, isValidEmail } from "utils/helpers";

class VendorAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
            
            // account update related
            contact_details_update_mode: false,
            temp_contact_details_data: {},
            contact_details_update_error: false,
            updating_contact_details: false,

            new_helpful_hints: '',
            saving_helpful_hints: false,
            helpful_hints_saved: false,
            updating_notification_settings: false,
        };
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
        this.fetchAccount = this.fetchAccount.bind(this);

        this.updateNotificationSettings = this.updateNotificationSettings.bind(this);
        this.fetchVendorDetails = this.fetchVendorDetails.bind(this);
        this.saveHelpfulHints = this.saveHelpfulHints.bind(this);

        this.startContactDetailsUpdate = this.startContactDetailsUpdate.bind(this);
        this.updateAccountDetailsField = this.updateAccountDetailsField.bind(this);
        this.updatePhoneDisplay = this.updatePhoneDisplay.bind(this);
        this.canSaveContactDetails = this.canSaveContactDetails.bind(this);
        this.saveContactDetails = this.saveContactDetails.bind(this);

        // this.startAcceptSmallJobsUpdate = this.startAcceptSmallJobsUpdate.bind(this);
        // this.updateSmallJobsField = this.updateSmallJobsField.bind(this);
    }

    startContactDetailsUpdate() {
        this.setState({
            contact_details_update_mode: true,
            contact_details_update_error: false,
            updating_contact_details: false,
            temp_contact_details_data: {
                first_name: this.state.account.first_name,
                last_name: this.state.account.last_name,
                phone: this.state.account.phone,
                email: this.state.account.email,
                company: this.state.account.company,
            }
        }, this.updatePhoneDisplay);
    }

    canSaveContactDetails() {
        let temp_contact_details_data = this.state.temp_contact_details_data;
        
        let phone_ok = true;
        if (temp_contact_details_data.phone && !isValidPhoneNumber(temp_contact_details_data.phone)) {
            phone_ok = false;
        }

        return temp_contact_details_data.first_name && 
            temp_contact_details_data.last_name && 
            isValidEmail(temp_contact_details_data.email) &&
            phone_ok;
    }

    updateAccountDetailsField(field_name, value) {
        let new_temp_account_data = { ...this.state.temp_contact_details_data } ;
        new_temp_account_data[field_name] = value;

        this.setState({ temp_contact_details_data: new_temp_account_data}, this.updatePhoneDisplay);
    } 

    setContactUsDisplay(value) {
        this.setState({ displayContactUs: value });
    }

    fetchAccount() {
        generic_get_api(ACCOUNT_URL, {})
            .then(
                data => {
                    this.setState(
                        { 
                            account: data,
                        })
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    fetchVendorDetails() {
        generic_get_api(VENDOR_PROFILE_DETAIL)
            .then(
                data => {
                    this.setState({
                        vendor_details: data, // this may be obsolete
                        business_details: data.business_details,
                        small_jobs: data.small_jobs,
                        buy_sell_service: data.buy_sell_service,
                        service_calls: data.service_calls,
                        scheduling_service: data.scheduling_service,
                        offered_services: data.offered_services,
                        project_photos: data.project_photos,
                    })
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    saveHelpfulHints() {

        this.setState({ saving_helpful_hints: true, helpful_hints_saved: false });

        generic_put_api(AGENT_SETTINGS_URL, { helpful_hints: this.state.new_helpful_hints })
            .then(
                data => {
                    this.setState({ saving_helpful_hints: false, helpful_hints_saved: true });
                    this.fetchContactSettings();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    updateNotificationSettings(type, value) {

        let notification_settings = this.state.account.notification_settings;
        notification_settings[type] = value;

        this.setState({ updating_notification_settings: true });

        generic_put_api(ACCOUNT_URL, { notification_settings: notification_settings, update_type: 'notification_settings' })
            .then(
                data => {
                    this.fetchAccount();
                    this.setState({ updating_notification_settings: false });
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    saveContactDetails() {

        this.setState({ 
            updating_contact_details: true,
            contact_details_update_error: false,
        });

        generic_put_api(ACCOUNT_URL, { contact_details: this.state.temp_contact_details_data, update_type: 'contact_details' })
            .then(
                (result) => {
                    if(result.status === 204) {
                        this.fetchAccount();
                        this.setState({ updating_contact_details: false, contact_details_update_mode: false });
                    }
                    else if (result.status === 400) {
                        this.setState({ updating_contact_details: false, contact_details_update_error: true });
                    }
                }
                
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, { 'data_1': 'account' });
        document.title = "Account - Manage your current and prospective clients";
        this.fetchAccount();
        this.fetchVendorDetails();
    }

    updatePhoneDisplay() {
        if (this.state.temp_contact_details_data.phone != null && this.state.temp_contact_details_data.phone.length >= 0) {
            let new_phone = this.state.temp_contact_details_data.phone.replace(/\D/g,'');
            let formatted_number = "";

            if (isValidPhoneNumber(new_phone)) {
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
            } else if (new_phone.length < 10) {
                formatted_number = new_phone;
            }
            else if (new_phone.length > 10) {
                new_phone = new_phone.substring(0, 10);
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);
            }

            let new_temp_account_data = { ...this.state.temp_contact_details_data } ;
            new_temp_account_data['phone'] = new_phone;
            new_temp_account_data['phone_display'] = formatted_number;
            this.setState({ temp_contact_details_data: new_temp_account_data });
        }
    }

    render() {
        const { account, temp_contact_details_data, contact_details_update_mode } = this.state;


        if (!account) {
            return null;
        }

        return (
            <div class="vendorAccountPageEnvelope">
                <div class="vendorAccountPage">

                    <div class="container accountSection">
                        <div class="row"><div class="col display-6">Account</div></div>

                        <div class="row mt-4">
                            <div class="col text-center">We are here if you need help <Button variant="outline-primary" onClick={() => this.setContactUsDisplay(true)}>Contact us</Button>
                                <ContactUs
                                    displayStatus={this.state.displayContactUs}
                                    changeDisplayStatus={this.setContactUsDisplay}
                                />
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col">
                                <Card>
                                    <Card.Title className="ps-3 pt-3 fw-bold">Contact details</Card.Title>
                                    <Card.Body>
                                        <div class="container">
                                            <div class="row gy-3">
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>First name</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!temp_contact_details_data.first_name ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.first_name} 
                                                                        onChange={(e) => this.updateAccountDetailsField('first_name', e.target.value)}/>

                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.first_name}</div></div>
                                                        }
                                                            
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Last name</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!temp_contact_details_data.last_name ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.last_name} 
                                                                        onChange={(e) => this.updateAccountDetailsField('last_name', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.last_name}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Phone number</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${temp_contact_details_data.phone && temp_contact_details_data.phone.length > 0 && !isValidPhoneNumber(temp_contact_details_data.phone) ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.phone_display} 
                                                                        onChange={(e) => this.updateAccountDetailsField('phone', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{formatPhoneNumber(account.phone)}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="container p-0">
                                                        <div class="row"><div class="col text-nowrap fw-bold"><small>Email</small></div></div>
                                                        {
                                                            contact_details_update_mode ?
                                                                <div class="row"><div class="col text-nowrap ">
                                                                    <input 
                                                                        type="text" 
                                                                        class={`form-control ${!isValidEmail(temp_contact_details_data.email) ? " missingField" : ""}`} 
                                                                        value={temp_contact_details_data.email} 
                                                                        onChange={(e) => this.updateAccountDetailsField('email', e.target.value)}/>
                                                                </div></div>
                                                            :
                                                                <div class="row"><div class="col text-nowrap ">{account.email}</div></div>
                                                        }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 text-start">
                                                    { !this.state.contact_details_update_mode ?
                                                        <Button variant="primary" onClick={ this.startContactDetailsUpdate }>Edit</Button>
                                                    :
                                                    <>
                                                        <Button 
                                                            variant="secondary" 
                                                            className="me-3" 
                                                            disabled={this.state.updating_contact_details} 
                                                            onClick={() => this.setState({contact_details_update_mode: false, contact_details_update_error: false})}>
                                                                Cancel</Button>
                                                        {
                                                            this.state.updating_contact_details ?
                                                                <Button variant="primary" disabled>
                                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    Saving ...
                                                                </Button>
                                                            :
                                                                <Button variant="primary"  disabled={!this.canSaveContactDetails()} onClick={this.saveContactDetails}>Save</Button>
                                                        }
                                                       
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            {this.state.contact_details_update_error && 
                                                <div class="row mt-2">
                                                    <div class="col-12 text-start text-danger">
                                                        There was an error updating your contact details.
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <BusinessDetailsComponent 
                            business_details={this.state.business_details}
                            fetchVendorDetails={this.fetchVendorDetails}
                        />
                         <OfferedServices 
                            offered_services={this.state.offered_services}
                            fetchVendorDetails={this.fetchVendorDetails}
                        />
                        <ServiceCallsComponent 
                            service_calls={this.state.service_calls}
                            fetchVendorDetails={this.fetchVendorDetails}
                        />
                        <SmallJobsComponent 
                            small_jobs={this.state.small_jobs}
                            fetchVendorDetails={this.fetchVendorDetails}
                        />
                        
                        <BuySellServiceComponent 
                            buy_sell_service={this.state.buy_sell_service}
                            fetchVendorDetails={this.fetchVendorDetails}
                        />

                        <SchedulingComponent 
                            scheduling_service={this.state.scheduling_service}
                            fetchVendorDetails={this.fetchVendorDetails}
                            phone={account.phone}
                            email={account.email}
                        />
                       
                        <div class="row mt-5" id="#photo">
                            <div class="col">
                                <PhotoComponent
                                    account={account}
                                    fetchAccount={this.fetchAccount}
                                />
                            </div>
                        </div>

                        <ProjectPhotosComponent 
                            fetchVendorDetails={ this.fetchVendorDetails }
                            project_photos={ this.state.project_photos }
                        />

                        <LicensingComponent licensing_info={account.licensing_info} />
                       
                    </div>
                </div>

            </div>
        );
    }
}

function LicensingComponent(props) {

    const {licensing_info} = props;
    if (!props.licensing_info) {
        return null;
    }

    return (
        <div class="row mt-5">
            <div class="col">
                <Card>
                    <Card.Title className="ps-3 pt-3 fw-bold">Information from licensing authority</Card.Title>
                    <Card.Body>
                        <div class="container">
                            <div class="row gy-3">
                                <div class="col-12 col-md-6">
                                    <div class="container gx-0">
                                        <div class="row"><div class="col text-nowrap fw-bold">Registered name</div></div>
                                        <div class="row"><div class="col text-nowrap ">{licensing_info.name}</div></div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="container gx-0">
                                        <div class="row"><div class="col text-nowrap fw-bold">License number</div></div>
                                        <div class="row"><div class="col text-nowrap ">{licensing_info.license_number}</div></div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="container gx-0">
                                        <div class="row"><div class="col text-nowrap fw-bold">License expiry Date</div></div>
                                        <div class="row"><div class="col text-nowrap ">{licensing_info.license_expiry_date}</div></div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="container gx-0">
                                        <div class="row"><div class="col text-nowrap fw-bold">Address</div></div>
                                        <div class="row"><div class="col text-nowrap ">{licensing_info.address},&nbsp;{licensing_info.city}&nbsp;{licensing_info.zip_code}</div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

function ProjectPhotosComponent(props) {
    
    const {fetchVendorDetails, project_photos} = props;

    const [show_spinner, setShowSpinner] = useState(false);
    const [show_photo_popup, setShowPhotoPopup] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [active_photo_index, setActivePhotoIndex] = useState(false);

    const fileRef = React.createRef();

    const onAddButtonClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev) => {

        if (fileRef.current && fileRef.current.files.length > 0) {
            uploadFile();
            ev.target.value = null;
        }
    }

    const uploadFile = () => {
        setShowSpinner(true);

        var data = new FormData();
        data.append('file', fileRef.current.files[0]);

        generic_put_api_file(VENDOR_PROJECT_PHOTOS, data)
            .then(
                (result) => {
                    if (result.status === 201) {
                        setShowSpinner(false);
                        fetchVendorDetails();
                    }
                    else {
                        setShowSpinner(false);
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
                // if upload anything other than images
                setShowSpinner(false);
            });
    }

    const deletePhoto = (photo_id) => {

        // avoid uploading and deleting together
        if (show_spinner) {
            return;
        }

        setDeleting(true);
        let request = { 'photo_id': photo_id }

        generic_delete_api(VENDOR_PROJECT_PHOTOS, request)
        .then(
            (result) => {
                if (result.status === 204) {
                    setDeleting(false);
                    fetchVendorDetails();
                }
                else {
                    setDeleting(false);
                }
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    return(
        <div class="container p-0 projectPhotos">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Past project Photos</Card.Title>
                        <Card.Body>
                            <div class="container">
                                <div class="row gy-3">
                                    <div class="col">
                                        Add up to <strong>10</strong> project photos. Photos will appear on your details page.
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <input type="file" id="actual-btn"
                                            ref={fileRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => filePickerChanged(e)}
                                        />
                                        {show_spinner ?
                                            <Button variant="outline-primary">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />&nbsp;
                                                Uploading ...
                                            </Button>
                                        :
                                            <Button variant="primary" onClick={onAddButtonClick} disabled={(project_photos && project_photos.length >= 10) || deleting}><i class="fa-solid fa-plus"></i> Add photo</Button>
                                        }
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    {
                                        project_photos && project_photos.map((photo_entry, index) => {
                                            return  <div class="col-auto text-center border">
                                                        <div class="row">
                                                            <div class="text-center">
                                                                {
                                                                    deleting ?
                                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                    :
                                                                        <a class="text-decoration-underline" role="button" onClick={()=> deletePhoto(photo_entry.photo_id)}><small>Delete</small></a>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="row mt-1">
                                                            <div class="text-center" role="button" onClick={() => {setActivePhotoIndex(index); setShowPhotoPopup(true)}}>
                                                                <img src={ photo_entry.thumb_nail_path } className="projectPhotoTN"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                        })
                                    }
                                </div>
                                <Modal
                                    show={show_photo_popup} 
                                    onHide={() => setShowPhotoPopup(false)}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className="vendorProjectPhotos"
                                >
                                    <Modal.Header closeButton ></Modal.Header>
                                    <Modal.Body className="pe-0 ps-0">
                                        <Carousel 
                                            slide={false}
                                            indicators={false}
                                            interval={null}
                                            variant="dark"
                                            defaultActiveIndex={ active_photo_index}
                                        >
                                            {project_photos && project_photos.map(
                                                (photo_entry) =>{
                                                    return   <Carousel.Item>
                                                                <div class="text-center" >
                                                                    <img src={photo_entry.photo_path} alt="First slide" class="vendorProjectPhotoCarousel"/>
                                                                </div>
                                                            </Carousel.Item>
                                                }
                                            )}
                                        </Carousel>
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}


function PhotoComponent(props) {
    const { account, fetchAccount } = props;
    const [showSpinner, setShowSpinner] = useState(false);

    const fileRef = React.createRef();
    const onPhotoBoxClick = () => { fileRef.current.click() }

    const filePickerChanged = (ev) => {

        if (fileRef.current && fileRef.current.files.length > 0) {
            uploadFile();
            ev.target.value = null;
        }
    }

    const getPhoto = () => {
        if (account.profile_photo) {
            return account.profile_photo;
        }
        return user_profile_place_holder;
    }

    const uploadFile = () => {
        setShowSpinner(true);

        var data = new FormData();
        data.append('file', fileRef.current.files[0]);

        generic_put_api_file(ACCOUNT_PHOTO_URL, data)
            .then(
                (result) => {
                    if (result.status === 201) {
                        setShowSpinner(false);
                        fetchAccount();
                    }
                    else {
                        setShowSpinner(false);
                    }
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    const deleteFile = () => {
        setShowSpinner(true);

        generic_delete_api(ACCOUNT_PHOTO_URL, {})
            .then(
                (result) => {
                    setShowSpinner(false);
                    fetchAccount();
                }
            )
            .catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            });
    }

    return (
        <>
            <Card>
                <Card.Title className="ps-3 pt-3 fw-bold">Profile photo</Card.Title>
                <Card.Body>
                    <div class="container">
                        <div class="row gy-3">
                            <div class="col">
                                We recommend a square photo, <strong>300 pixel x 300 pixel</strong>.
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6">
                                <img src={getPhoto()} class="userProfilePhoto" />
                                <input type="file" id="actual-btn"
                                    ref={fileRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => filePickerChanged(e)}
                                />
                            </div>
                            <div class="col-6">
                                {
                                    showSpinner ?
                                        <Button variant="outline-primary">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="visually-hidden">Loading...</span>
                                        </Button>

                                        :
                                        <>
                                            {
                                                account.profile_photo ?
                                                    <Button variant="secondary" size="sm" onClick={deleteFile}>Remove</Button>
                                                    :
                                                    <Button variant="primary" size="sm" onClick={onPhotoBoxClick}>Upload</Button>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

function SchedulingComponent(props) {
    const { scheduling_service, fetchVendorDetails, phone, email } = props;

    const [edit_mode, setEditMode] = useState(false);
    const [temp_accept, setTempAccept] = useState(null);
    const [temp_phone, setTempPhone] = useState(null);
    const [phone_display, setPhoneDisplay] = useState(null);
    const [temp_email, setTempEmail] = useState(null);

    const [temp_mon_available, setTempMonAvailable] = useState(null);
    const [temp_tue_available, setTempTueAvailable] = useState(null);
    const [temp_wed_available, setTempWedAvailable] = useState(null);
    const [temp_thr_available, setTempThrAvailable] = useState(null);
    const [temp_fri_available, setTempFriAvailable] = useState(null);
    const [temp_sat_available, setTempSatAvailable] = useState(null);
    const [temp_sun_available, setTempSunAvailable] = useState(null);

    const [temp_mon_start, setTempMonStart] = useState(null);
    const [temp_mon_end, setTempMonEnd] = useState(null);
    const [temp_tue_start, setTempTueStart] = useState(null);
    const [temp_tue_end, setTempTueEnd] = useState(null);
    const [temp_wed_start, setTempWedStart] = useState(null);
    const [temp_wed_end, setTempWedEnd] = useState(null);
    const [temp_thr_start, setTempThrStart] = useState(null);
    const [temp_thr_end, setTempThrEnd] = useState(null);
    const [temp_fri_start, setTempFriStart] = useState(null);
    const [temp_fri_end, setTempFriEnd] = useState(null);
    const [temp_sat_start, setTempSatStart] = useState(null);
    const [temp_sat_end, setTempSatEnd] = useState(null);
    const [temp_sun_start, setTempSunStart] = useState(null);
    const [temp_sun_end, setTempSunEnd] = useState(null);
    const [updating, setUpdating] = useState(false);
    
    useEffect(()=> {
        if (temp_phone != null && temp_phone.length >= 0) {
            let new_phone = temp_phone.replace(/\D/g,'');
            let formatted_number = "";

            if (isValidPhoneNumber(new_phone)) {
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
            } else if (new_phone.length < 10) {
                formatted_number = new_phone;
            }
            else if (new_phone.length > 10) {
                new_phone = new_phone.substring(0, 10);
                formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);
            }

            setTempPhone(new_phone);
            setPhoneDisplay(formatted_number);
        }
    }, [temp_phone])


    if (!scheduling_service) {
        return null;
    }

    const startEditMode = () => {
        setEditMode(true);

        if (scheduling_service.phone) {
            setTempPhone(scheduling_service.phone);
        }
        else {
            setTempPhone(phone);
        }
        if (scheduling_service.email) {
            setTempEmail(scheduling_service.email);
        }
        else {
            setTempEmail(email);
        }

        setTempAccept(scheduling_service.accept_scheduling_service);
        
        setTempMonAvailable(scheduling_service.mon_available);
        setTempMonStart(scheduling_service.mon_start);
        setTempMonEnd(scheduling_service.mon_end);

        setTempTueAvailable(scheduling_service.tue_available);
        setTempTueStart(scheduling_service.tue_start);
        setTempTueEnd(scheduling_service.tue_end);

        setTempWedAvailable(scheduling_service.wed_available);
        setTempWedStart(scheduling_service.wed_start);
        setTempWedEnd(scheduling_service.wed_end);

        setTempThrAvailable(scheduling_service.thr_available);
        setTempThrStart(scheduling_service.thr_start);
        setTempThrEnd(scheduling_service.thr_end);

        setTempFriAvailable(scheduling_service.fri_available);
        setTempFriStart(scheduling_service.fri_start);
        setTempFriEnd(scheduling_service.fri_end);

        setTempSatAvailable(scheduling_service.sat_available);
        setTempSatStart(scheduling_service.sat_start);
        setTempSatEnd(scheduling_service.sat_end);

        setTempSunAvailable(scheduling_service.sun_available);
        setTempSunStart(scheduling_service.sun_start);
        setTempSunEnd(scheduling_service.sun_end);
    }

    const saveSchedulingService = () => {

        setUpdating(true);
        let request = {
            accept_scheduling_service: temp_accept,
            email: temp_email,
            phone: temp_phone,

            mon_start: temp_mon_start,
            mon_end: temp_mon_end,
            mon_available: temp_mon_available,

            tue_start: temp_tue_start,
            tue_end: temp_tue_end,
            tue_available: temp_tue_available,

            wed_start: temp_wed_start,
            wed_end: temp_wed_end,
            wed_available: temp_wed_available,

            thr_start: temp_thr_start,
            thr_end: temp_thr_end,
            thr_available: temp_thr_available,

            fri_start: temp_fri_start,
            fri_end: temp_fri_end,
            fri_available: temp_fri_available,

            sat_start: temp_sat_start,
            sat_end: temp_sat_end,
            sat_available: temp_sat_available,

            sun_start: temp_sun_start,
            sun_end: temp_sun_end,
            sun_available: temp_sun_available,
        }

        generic_put_api(VENDOR_PROFILE_DETAIL, { scheduling_service: request, update_type: 'scheduling_service' })
            .then(
                data => {
                    setUpdating(false);
                    setEditMode(false);
                    fetchVendorDetails();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    
                }
            });
    }

    const getDayTimeRow = (day, from_string, to_string, fromSetter, toSetter, available_value, availableSetter) => {
        return  <div class="row mt-3 align-items-center">
                    <div class="col-2">{day}</div>
                    <div class="col-2 text-center">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            checked={available_value}
                            value=""
                            id="available"
                            onChange={(e) => availableSetter(e.currentTarget.checked)}
                        />
                    </div>
                    {
                        available_value && 
                        <>
                            <div class="col-3">
                                <TimePicker
                                    className="schedulingTimePicker"
                                    placeholder="Earliest start"
                                    use12Hours
                                    minuteStep={30}
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    onChange={e => fromSetter(e.format('LT'))}
                                    value={moment(from_string, 'HH:mm a')}
                                />
                            </div>
                            <div class="col-4">
                                <TimePicker
                                    className="schedulingTimePicker"
                                    placeholder="Latest start"
                                    use12Hours
                                    minuteStep={30}
                                    showSecond={false}
                                    focusOnOpen={true}
                                    format="hh:mm A"
                                    value={moment(to_string, 'HH:mm a')}
                                    onChange={e => toSetter(e.format('LT'))}
                                />
                            </div>
                        </>
                    }
                    
                </div>
    }

    const formatDayRow = (day, available, from, to) => {
        return <div class="row gy-2 align-items-center">                        
            <div class="col-2">{day}</div>
            { available ?
                <>
                <div class="col-2">Yes</div>
                <div class="col-auto">{from}</div>
                <div class="col-auto">to</div>
                <div class="col-4">{to}</div>
                </>
                :
                <div class="col-2">No</div>
            }
        </div>
    }

    return(
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Easy scheduling</Card.Title>
                        <Card.Body>
                            <div class="container">
                                <div class="row gy-3">
                                    <div class="col-12">
                                        Receive appointment requests from real estate agents on your phone and email. You can accept or decline requests.
                                    </div>
                                </div>
                                <div class="row gy-2 mt-2 align-items-center">
                                    <div class="col-auto">Do you accept requests for scheduling an appointment?</div>
                                    <div class="col">
                                        {
                                            edit_mode ? 
                                                <ButtonGroup >
                                                    <ToggleButton
                                                            key="scheduling-key-2"
                                                            id="scheduling-id-2"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="hide_vendor"
                                                            value={false}
                                                            checked={false === temp_accept}
                                                            onChange={() => setTempAccept(false)}
                                                        > No  
                                                    </ToggleButton>
                                                    <ToggleButton
                                                            key="scheduling-key-1"
                                                            id="scheduling-id-1"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="hide_vendor"
                                                            value={true}
                                                            checked={true === temp_accept}
                                                            onChange={() => setTempAccept(true)}
                                                        > Yes    
                                                    </ToggleButton>                        
                                                </ButtonGroup>
                                            :
                                            <>
                                                {
                                                    scheduling_service.accept_scheduling_service ? <span class="fw-bold">Yes</span> : <span class="fw-bold">No</span>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    edit_mode && temp_accept && 
                                    <>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-12">Mobile phone number to receive requests</div>
                                            <div class="col-12 col-md-6"> 
                                                <input type="text" class="form-control" value={phone_display} onChange={(e) => setTempPhone(e.target.value)}/> 
                                            </div>
                                        </div>

                                        <div class="row mt-3 align-items-center">
                                            <div class="col-12">Email address to receive requests</div>
                                            <div class="col-12 col-md-6"> 
                                                <input type="text" class="form-control" value={temp_email} onChange={(e) => setTempEmail(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12 fw-bold">
                                                Enter availability for appointments for each day of the week
                                            </div>
                                        </div>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-2 fw-bold">Day</div>
                                            <div class="col-2 text-center fw-bold">
                                                Available?
                                            </div>
                                            <div class="col-3 fw-bold">
                                                Start time
                                            </div>
                                            <div class="col-4 fw-bold">
                                                End time
                                            </div>
                                        </div>
                                               
                                        {getDayTimeRow('Mon', temp_mon_start, temp_mon_end, setTempMonStart, setTempMonEnd, temp_mon_available, setTempMonAvailable)}
                                        {getDayTimeRow('Tue', temp_tue_start, temp_tue_end, setTempTueStart, setTempTueEnd, temp_tue_available, setTempTueAvailable)}
                                        {getDayTimeRow('Wed', temp_wed_start, temp_wed_end, setTempWedStart, setTempWedEnd, temp_wed_available, setTempWedAvailable)}
                                        {getDayTimeRow('Thr', temp_thr_start, temp_thr_end, setTempThrStart, setTempThrEnd, temp_thr_available, setTempThrAvailable)}
                                        {getDayTimeRow('Fri', temp_fri_start, temp_fri_end, setTempFriStart, setTempFriEnd, temp_fri_available, setTempFriAvailable)}
                                        {getDayTimeRow('Sat', temp_sat_start, temp_sat_end, setTempSatStart, setTempSatEnd, temp_sat_available, setTempSatAvailable)}
                                        {getDayTimeRow('Sun', temp_sun_start, temp_sun_end, setTempSunStart, setTempSunEnd, temp_sun_available, setTempSunAvailable)}
                                    </>
                                }
                                {
                                    ! edit_mode && scheduling_service.accept_scheduling_service && 
                                    <>
                                        <div class="row mt-3"></div>
                                        {formatDayRow('Mon', scheduling_service.mon_available, scheduling_service.mon_start, scheduling_service.mon_end)}
                                        {formatDayRow('Tue', scheduling_service.tue_available, scheduling_service.tue_start, scheduling_service.tue_end)}
                                        {formatDayRow('Wed', scheduling_service.wed_available, scheduling_service.wed_start, scheduling_service.wed_end)}
                                        {formatDayRow('Thr', scheduling_service.thr_available, scheduling_service.thr_start, scheduling_service.thr_end)}
                                        {formatDayRow('Fri', scheduling_service.fri_available, scheduling_service.fri_start, scheduling_service.fri_end)}
                                        {formatDayRow('Sat', scheduling_service.sat_available, scheduling_service.sat_start, scheduling_service.sat_end)}
                                        {formatDayRow('Sun', scheduling_service.sun_available, scheduling_service.sun_start, scheduling_service.sun_end)}
                                    </>
                                }
                                
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEditMode }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveSchedulingService}>
                                                            Save
                                                        </Button><span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function BuySellServiceComponent(props) {
    const { buy_sell_service, fetchVendorDetails } = props;

    const [edit_mode, setEditMode] = useState(false);
    const [temp_accept, setTempAccept] = useState(null);
    const [temp_job_type_1, setTempJobType1] = useState(null);
    const [temp_job_type_2, setTempJobType2] = useState(null);
    const [temp_job_type_3, setTempJobType3] = useState(null);
    const [temp_price_1, setTempPrice1] = useState(null);
    const [temp_price_2, setTempPrice2] = useState(null);
    const [temp_price_3, setTempPrice3] = useState(null);
    const [updating, setUpdating] = useState(false);
    

    const startEditMode = () => {
        setEditMode(true);

        setTempAccept(buy_sell_service.accept_buy_sell_service);
        setTempJobType1(buy_sell_service.job_type_1);
        setTempJobType2(buy_sell_service.job_type_2);
        setTempJobType3(buy_sell_service.job_type_3);
        setTempPrice1(buy_sell_service.price_1);
        setTempPrice2(buy_sell_service.price_2);
        setTempPrice3(buy_sell_service.price_3);
    }

    const saveBuySellService = () => {

        setUpdating(true);
        let request = {
            accept_buy_sell_service: temp_accept,
            job_type_1: temp_job_type_1,
            job_type_2: temp_job_type_2,
            job_type_3: temp_job_type_3,
            price_1: temp_price_1,
            price_2: temp_price_2,
            price_3: temp_price_3,
        }

        generic_put_api(VENDOR_PROFILE_DETAIL, { buy_sell_service: request, update_type: 'buy_sell_service' })
            .then(
                data => {
                    setUpdating(false);
                    setEditMode(false);
                    fetchVendorDetails();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    
                }
            });
    }

    const formatJobPrice = (job_type, price) => {
        if (job_type && price) {
            return  <div class="row mt-3">
                        <div class="col-auto text-truncate">{job_type}</div>
                        <div class="col">${price}</div>
                    </div>
        } else if (job_type){
            return  <div class="row mt-3">
                        <div class="col-12 text-truncate">{job_type}</div>
                    </div>
        }
        else return null;
        
    }

    if (!buy_sell_service) {
        return null;
    }

    return(
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Inspections & services for property transactions</Card.Title>
                        <Card.Body>
                            <div class="container">
                                <div class="row gy-3">
                                    <div class="col-12">
                                        Property owners may require certain inspections. In addition, buyers and sellers may need 
                                        evaluations or estimates for a property transaction.
                                    </div>
                                </div>
                                <div class="row gy-2 mt-2 align-items-center">
                                    
                                    <div class="col-auto">Do you accept such jobs?</div>
                                    
                                    <div class="col">
                                        {
                                            edit_mode ? 
                                                <ButtonGroup >
                                                    <ToggleButton
                                                            key="buySellService-key-2"
                                                            id="buySellService-id-2"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="buySellService"
                                                            value={false}
                                                            checked={false === temp_accept}
                                                            onChange={() => setTempAccept(false)}
                                                        > No  
                                                    </ToggleButton>
                                                    <ToggleButton
                                                            key="buySellService-key-1"
                                                            id="buySellService-id-1"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="buySellService"
                                                            value={true}
                                                            checked={true === temp_accept}
                                                            onChange={() => setTempAccept(true)}
                                                        > Yes    
                                                    </ToggleButton>                        
                                                </ButtonGroup>
                                            :
                                            <>
                                                {
                                                    buy_sell_service.accept_buy_sell_service ? <span class="fw-bold">Yes</span> :  <span class="fw-bold">No</span>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    edit_mode && temp_accept && 
                                    <>
                                        <div class="row mt-3">
                                            <div class="col-12 ">
                                                List up to 3 typical jobs and cost
                                            </div>
                                        </div>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-9 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_1} onChange={(e) => setTempJobType1(e.target.value)}/> 
                                            </div>
                                            <div class="col-auto pe-0">$</div>
                                            <div class="col ps-1">
                                                <input type="text" class="form-control" maxLength="10" value={temp_price_1} onChange={(e) => setTempPrice1(e.target.value)} placeholder="Price"/> 
                                            </div>
                                        </div>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-9">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_2} onChange={(e) => setTempJobType2(e.target.value)}/> 
                                            </div>
                                            <div class="col-auto pe-0">$</div>
                                            <div class="col ps-1">
                                                <input type="text" class="form-control" maxLength="10" value={temp_price_2} onChange={(e) => setTempPrice2(e.target.value)} placeholder="Price"/> 
                                            </div>
                                        </div>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-9">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_3} onChange={(e) => setTempJobType3(e.target.value)}/> 
                                            </div>
                                            <div class="col-auto pe-0">$</div>
                                            <div class="col ps-1">
                                                <input type="text" class="form-control" maxLength="10" value={temp_price_3} onChange={(e) => setTempPrice3(e.target.value)} placeholder="Price"/> 
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    ! edit_mode && buy_sell_service.accept_buy_sell_service && 
                                    <>
                                        {formatJobPrice(buy_sell_service.job_type_1, buy_sell_service.price_1)}
                                        {formatJobPrice(buy_sell_service.job_type_2, buy_sell_service.price_2)}
                                        {formatJobPrice(buy_sell_service.job_type_3, buy_sell_service.price_3)}
                                       
                                    </>
                                }
                                
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEditMode }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveBuySellService}>
                                                            Save
                                                        </Button>
                                                        <span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function BusinessDetailsComponent(props) {
    const { business_details, fetchVendorDetails } = props;
    const [edit_mode, setEditMode] = useState(false);
    const [new_business_details, setNewBusinessDetails] = useState({});
    const [phone_display, setPhoneDisplay] = useState('');
    const [updating, setUpdating] = useState(false);

    useEffect(() => { 
        
        let phone = new_business_details.phone;

        // avoid setting phone to undefined through the network call that fetches the vendor's details
        if (phone != null && phone.length >= 0) {
            let new_phone = phone.replace(/\D/g,'');

            if (isValidPhoneNumber(new_phone)) {
                setField('phone', new_phone);
                let formatted_number = "(" + new_phone.substring(0,3) + ") " + new_phone.substring(3,6) + "-" + new_phone.substring(6,10);  
                setPhoneDisplay(formatted_number);
            } else if (new_phone.length < 10) {
                setField('phone', new_phone);
                setPhoneDisplay(new_phone);
            } else if (new_phone.length > 10) {

                let trimmed_phone = new_phone.substring(0, 10);
                setField('phone', new_phone);
                let formatted_number = "(" + trimmed_phone.substring(0,3) + ") " + trimmed_phone.substring(3,6) + "-" + trimmed_phone.substring(6,10);  
                setPhoneDisplay(formatted_number);
            } 
        }
    }, [new_business_details.phone]);

    const setField = (field, value) => {
        let temp_business_details = { ...new_business_details } ;
        temp_business_details[field] = value;
        setNewBusinessDetails(temp_business_details);
    }

    const startEdit = () => {
        setNewBusinessDetails(business_details);
        setEditMode(true);
    }

    
    const saveAboutUs = () => {
        setUpdating(true);

        generic_put_api(VENDOR_PROFILE_DETAIL, { business_details: new_business_details, update_type: 'business_details' })
            .then(
                data => {
                    setUpdating(false);
                    fetchVendorDetails();
                    setEditMode(false);
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {;
                }
            });
    }
    
    if (!business_details) {
        return null;
    }

    return (
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Business details</Card.Title>
                        <Card.Body>
                            <div class="container">
                                <div class="row gy-3">
                                    <div class="col-12 col-md-6">
                                        <div class="container p-0">
                                            <div class="row">
                                                <div class="col text-nowrap fw-bold"><small>Business name</small></div>
                                            </div>
                                            {
                                                edit_mode ?
                                                    <div class="row"><div class="col text-nowrap ">
                                                        <input 
                                                            type="text" 
                                                            class={`form-control ${!new_business_details.business_name ? " missingField" : ""}`} 
                                                            value={new_business_details.business_name} 
                                                            onChange={(e) => setField('business_name', e.target.value)}/>
                                                    </div></div>
                                                :
                                                    <div class="row"><div class="col text-nowrap ">{business_details.business_name}</div></div>
                                            }
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="container p-0">
                                            <div class="row">
                                                <div class="col text-nowrap fw-bold"><small>Business phone</small>
                                                </div>
                                            </div>
                                            {
                                                edit_mode ?
                                                    <div class="row"><div class="col text-nowrap ">
                                                        <input 
                                                            type="text" 
                                                            class={`form-control ${!new_business_details.phone || !isValidPhoneNumber(new_business_details.phone) ? " missingField" : ""}`} 
                                                            value={phone_display} 
                                                            onChange={(e) => setField('phone', e.target.value)}/>
                                                    </div></div>
                                                :
                                                    <div class="row"><div class="col text-nowrap ">{formatPhoneNumber(business_details.phone)}</div></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row gy-3 mt-1">
                                    <div class="col-12 col-md-6">
                                        <div class="container p-0">
                                            <div class="row">
                                                <div class="col text-nowrap fw-bold"><small>Business email</small></div>
                                            </div>
                                            {
                                                edit_mode ?
                                                    <div class="row"><div class="col text-nowrap ">
                                                        <input 
                                                            type="text" 
                                                            class={`form-control ${!isValidEmail(new_business_details.email)? " missingField" : ""}`} 
                                                            value={new_business_details.email} 
                                                            onChange={(e) => setField('email', e.target.value)}/>
                                                    </div></div>
                                                :
                                                    <div class="row"><div class="col text-nowrap ">{business_details.email}</div></div>
                                            }
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="container p-0">
                                            <div class="row">
                                                <div class="col text-nowrap fw-bold"><small>Business website</small></div>
                                            </div>
                                            {
                                                edit_mode ?
                                                    <div class="row"><div class="col text-nowrap ">
                                                        <input 
                                                            type="text" 
                                                            class={`form-control ${!new_business_details.website ? " missingField" : ""}`} 
                                                            value={new_business_details.website} 
                                                            onChange={(e) => setField('website', e.target.value)}/>
                                                    </div></div>
                                                :
                                                    <div class="row"><div class="col text-nowrap ">{business_details.website}</div></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row gy-3 mt-1">
                                    <div class="col-12 ">
                                        <div class="container p-0">
                                            <div class="row">
                                                <div class="col text-nowrap fw-bold"><small>About the business</small>
                                                </div>
                                            </div>
                                            {
                                                edit_mode ?
                                                    <div class="row">
                                                        <div class="col text-nowrap ">
                                                            <textarea
                                                                class="form-control"
                                                                rows="6"
                                                                onChange={(e) => setField('about_us', e.target.value)}
                                                                value={new_business_details.about_us}
                                                                placeholder="Enter details about your company such as years in business, size of your company, main job types, cities you work in, etc. "/>
                                                        </div>
                                                    </div>
                                             :
                                                    <div class="row"><div class="col">{business_details.about_us}</div></div>
                                            }
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEdit }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveAboutUs}>
                                                            Save
                                                        </Button>
                                                        <span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function ServiceCallsComponent(props) {
    const { service_calls, fetchVendorDetails } = props;

    const [edit_mode, setEditMode] = useState(false);
    const [temp_accept, setTempAccept] = useState(null);
    const [temp_min_charge, setTempMinCharge] = useState(null);
    const [temp_job_type_1, setTempJobType1] = useState(null);
    const [temp_job_type_2, setTempJobType2] = useState(null);
    const [temp_job_type_3, setTempJobType3] = useState(null);
    const [updating, setUpdating] = useState(false);
    

    const startEditMode = () => {
        setEditMode(true);

        setTempAccept(service_calls.accept_service_calls);
        setTempJobType1(service_calls.job_type_1);
        setTempJobType2(service_calls.job_type_2);
        setTempJobType3(service_calls.job_type_3);
        setTempMinCharge(service_calls.min_charge);
    }

    const saveServiceCalls = () => {

        setUpdating(true);
        let request = {
            accept_service_calls: temp_accept,
            job_type_1: temp_job_type_1,
            job_type_2: temp_job_type_2,
            job_type_3: temp_job_type_3,
            min_charge: temp_min_charge,
        }

        generic_put_api(VENDOR_PROFILE_DETAIL, { service_calls: request, update_type: 'service_calls' })
            .then(
                data => {
                    setUpdating(false);
                    setEditMode(false);
                    fetchVendorDetails();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    
                }
            });
    }

    if (!service_calls) {
        return null;
    }

    return(
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Service calls</Card.Title>
                        <Card.Body>
                            <div class="container">
                                <div class="row gy-3">
                                    <div class="col-12">
                                        Service calls often require trouble-shooting of the problem and may take a few hours or more to resolve.
                                    </div>
                                </div>
                                <div class="row gy-2 mt-2 align-items-center">
                                    
                                    <div class="col-auto fw-bold">Do you accept service calls?</div>
                                    
                                    <div class="col">
                                        {
                                            edit_mode ? 
                                                <ButtonGroup >
                                                    <ToggleButton
                                                            key="service_calls-key-2"
                                                            id="service_calls-id-2"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="service_calls"
                                                            value={false}
                                                            checked={false === temp_accept}
                                                            onChange={() => setTempAccept(false)}
                                                        > No  
                                                    </ToggleButton>
                                                    <ToggleButton
                                                            key="service_calls-key-1"
                                                            id="service_calls-id-1"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="service_calls"
                                                            value={true}
                                                            checked={true === temp_accept}
                                                            onChange={() => setTempAccept(true)}
                                                        > Yes    
                                                    </ToggleButton>                        
                                                </ButtonGroup>
                                            :
                                            <>
                                                {
                                                    service_calls.accept_service_calls ? <span class="fw-bold">Yes</span> :  <span class="fw-bold">No</span>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    edit_mode && temp_accept && 
                                    <>
                                        <div class="row mt-3 align-items-center">
                                            <div class="col-auto fw-bold">
                                                Minimum charge
                                            </div>
                                            <div class="col-auto pe-0">$</div>
                                            <div class="col-2 ps-1">
                                                <input type="text" class="form-control" maxLength="6" value={temp_min_charge} onChange={(e) => setTempMinCharge(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-12  fw-bold">
                                                List up to 3 typical service calls
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_1} onChange={(e) => setTempJobType1(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_2} onChange={(e) => setTempJobType2(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_3} onChange={(e) => setTempJobType3(e.target.value)}/> 
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    ! edit_mode && service_calls.accept_service_calls && 
                                    <>
                                        {
                                            service_calls.min_charge && 
                                            <div class="row mt-3">
                                               <div class="col-12 fw-bold">Minimum charge: ${service_calls.min_charge}</div>
                                            </div>
                                        }

                                        <div class="row mt-3">
                                            <div class="col-12 ">{service_calls.job_type_1}</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">{service_calls.job_type_2}</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">{service_calls.job_type_3}</div>
                                        </div>
                                    </>
                                }
                                
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEditMode }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveServiceCalls}>
                                                            Save
                                                        </Button>  
                                                        <span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )

}

function OfferedServices(props) {
    const { offered_services, fetchVendorDetails } = props;

    const [edit_mode, setEditMode] = useState(false);
    const [temp_job_type_1, setTempJobType1] = useState(null);
    const [temp_job_type_2, setTempJobType2] = useState(null);
    const [temp_job_type_3, setTempJobType3] = useState(null);
    const [temp_job_type_4, setTempJobType4] = useState(null);
    const [temp_job_type_5, setTempJobType5] = useState(null);
    const [temp_job_type_6, setTempJobType6] = useState(null);
    const [updating, setUpdating] = useState(false);
    

    const startEditMode = () => {
        setEditMode(true);
        if (offered_services.length >= 1){
            setTempJobType1(offered_services[0]);
        }
        if (offered_services.length >= 2){
            setTempJobType1(offered_services[1]);
        }
        if (offered_services.length >= 3){
            setTempJobType1(offered_services[2]);
        }
        if (offered_services.length >= 4){
            setTempJobType1(offered_services[3]);
        }
        if (offered_services.length >= 5){
            setTempJobType1(offered_services[4]);
        }
        if (offered_services.length >= 6){
            setTempJobType1(offered_services[5]);
        }
    }

    const saveOfferedServices = () => {

        setUpdating(true);

        let services = []

        if (temp_job_type_1 && temp_job_type_1.length > 0) {
            services.push(temp_job_type_1);
        }
        if (temp_job_type_2 && temp_job_type_2.length > 0) {
            services.push(temp_job_type_2);
        }
        if (temp_job_type_3 && temp_job_type_3.length > 0) {
            services.push(temp_job_type_3);
        }
        if (temp_job_type_4 && temp_job_type_4.length > 0) {
            services.push(temp_job_type_4);
        }
        if (temp_job_type_5 && temp_job_type_5.length > 0) {
            services.push(temp_job_type_5);
        }
        if (temp_job_type_6 && temp_job_type_6.length > 0) {
            services.push(temp_job_type_6);
        }

        let request = {
            services: services,
        }

        generic_put_api(VENDOR_PROFILE_DETAIL, { offered_services: request, update_type: 'offered_services' })
            .then(
                data => {
                    setUpdating(false);
                    setEditMode(false);
                    fetchVendorDetails();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    
                }
            });
    }

    const getServiceRow = (service) => {

        if (service){
            return  <div class="col-12 col-md-6">{service}</div>
        }
        else {
            return null;
        }
    }

    if (!offered_services) {
        return null;
    }

    return(
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Offered services</Card.Title>
                        <Card.Body>
                            <div class="container">
                                { edit_mode && 
                                <>
                                    <div class="row mt-2">
                                        <div class="col-12  fw-bold">
                                            List up to 6 typical projects and services. Enter service calls and small jobs in the sections below.
                                        </div>
                                    </div>
                                    <div class="row mt-1 gy-3">
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_1} onChange={(e) => setTempJobType1(e.target.value)}/> 
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_2} onChange={(e) => setTempJobType2(e.target.value)}/> 
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_3} onChange={(e) => setTempJobType3(e.target.value)}/> 
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_4} onChange={(e) => setTempJobType4(e.target.value)}/> 
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_5} onChange={(e) => setTempJobType5(e.target.value)}/> 
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <input type="text" class="form-control" maxLength="40" value={temp_job_type_6} onChange={(e) => setTempJobType6(e.target.value)}/> 
                                        </div>
                                    </div>
                                </>
                                }
                                {
                                    ! edit_mode && 
                                   
                                    <div class="row gy-3">
                                        {offered_services.map(service => {
                                            return getServiceRow(service)
                                        })}
                                        {/* {getServiceRow(offered_services.job_type_1)}
                                        {getServiceRow(offered_services.job_type_2)}
                                        {getServiceRow(offered_services.job_type_3)}
                                        {getServiceRow(offered_services.job_type_4)}
                                        {getServiceRow(offered_services.job_type_5)}
                                        {getServiceRow(offered_services.job_type_6)} */}
                                    </div>  
                                   
                                }
                                
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEditMode }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveOfferedServices}>
                                                            Save
                                                        </Button>
                                                        <span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function SmallJobsComponent(props) {
    const { small_jobs, fetchVendorDetails } = props;

    const [edit_mode, setEditMode] = useState(false);
    const [temp_accept, setTempAccept] = useState(null);
    const [temp_job_type_1, setTempJobType1] = useState(null);
    const [temp_job_type_2, setTempJobType2] = useState(null);
    const [temp_job_type_3, setTempJobType3] = useState(null);
    const [updating, setUpdating] = useState(false);
    

    const startEditMode = () => {
        setEditMode(true);

        setTempAccept(small_jobs.accept_small_jobs);
        setTempJobType1(small_jobs.job_type_1);
        setTempJobType2(small_jobs.job_type_2);
        setTempJobType3(small_jobs.job_type_3);
    }

    const saveSmallJobs = () => {

        setUpdating(true);
        let request = {
            accept_small_jobs: temp_accept,
            job_type_1: temp_job_type_1,
            job_type_2: temp_job_type_2,
            job_type_3: temp_job_type_3,
        }

        generic_put_api(VENDOR_PROFILE_DETAIL, { small_jobs: request, update_type: 'small_jobs' })
            .then(
                data => {
                    setUpdating(false);
                    setEditMode(false);
                    fetchVendorDetails();
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    
                }
            });
    }

    if (!small_jobs) {
        return null;
    }

    return(
        <div class="container p-0">
            <div class="row mt-5">
                <div class="col">
                    <Card>
                        <Card.Title className="ps-3 pt-3 fw-bold">Small projects</Card.Title>
                        <Card.Body>
                            <div class="container">
                                {/* <div class="row gy-3">
                                    <div class="col-12">
                                        Typically, small jobs take 1 or 2 days to complete.
                                    </div>
                                </div> */}
                                <div class="row gy-2 mt-2 align-items-center">
                                    
                                    <div class="col-auto fw-bold">Do you accept small projects?</div>
                                    
                                    <div class="col">
                                        {
                                            edit_mode ? 
                                                <ButtonGroup >
                                                    <ToggleButton
                                                            key="smallJobs-key-2"
                                                            id="smallJobs-id-2"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="smallJobs"
                                                            value={false}
                                                            checked={false === temp_accept}
                                                            onChange={() => setTempAccept(false)}
                                                        > No  
                                                    </ToggleButton>
                                                    <ToggleButton
                                                            key="smallJobs-key-1"
                                                            id="smallJobs-id-1"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="smallJobs"
                                                            value={true}
                                                            checked={true === temp_accept}
                                                            onChange={() => setTempAccept(true)}
                                                        > Yes    
                                                    </ToggleButton>                        
                                                </ButtonGroup>
                                            :
                                            <>
                                                {
                                                    small_jobs.accept_small_jobs ? <span class="fw-bold">Yes</span> :  <span class="fw-bold">No</span>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    edit_mode && temp_accept && 
                                    <>
                                        <div class="row mt-4">
                                            <div class="col-12  fw-bold">
                                                List up to 3 typical small project types
                                            </div>
                                        </div>
                                        <div class="row mt-1">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_1} onChange={(e) => setTempJobType1(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_2} onChange={(e) => setTempJobType2(e.target.value)}/> 
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">
                                                <input type="text" class="form-control" maxLength="40" value={temp_job_type_3} onChange={(e) => setTempJobType3(e.target.value)}/> 
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    ! edit_mode && small_jobs.accept_small_jobs && 
                                    <>
                                        <div class="row mt-3">
                                            <div class="col-12 ">{small_jobs.job_type_1}</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">{small_jobs.job_type_2}</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12 ">{small_jobs.job_type_3}</div>
                                        </div>
                                    </>
                                }
                                
                                <div class="row mt-4">
                                    { !edit_mode ?
                                        <div class="col-12 text-start">
                                            <Button variant="primary" onClick={ startEditMode }>Edit</Button>
                                        </div>
                                        :
                                        <>
                                            <div class="col-auto">
                                                <Button variant="secondary" onClick={() => setEditMode(false)}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div class="col-auto">
                                                {updating ?
                                                    <Button variant="primary" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        Saving ...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button variant="primary"
                                                            onClick={saveSmallJobs}>
                                                            Save
                                                        </Button>
                                                        <span class="text-danger"> <small>&nbsp;&nbsp;&nbsp;Don't forget to save your changes</small></span>
                                                    </>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default VendorAccount;