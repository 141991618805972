import React, { Component } from "react";

import {trackEvent, EventNames} from 'utils/mixpanel';
import spinnerGif from 'assets/reputation/spinner_150.gif';
import { formatPhoneNumber } from "utils/formatters";
import { basicContactDetails } from "api/basic_contact_details";
import VendorComponent from "./vendor_component";
import {Card, ListGroup} from 'react-bootstrap';
import { generic_get_api } from "api/generic_api";
import {AuthContext} from 'auth_context';

import {
    EntityRole,
    AGENT_CONTACT_VENDORS_URL,
    CONTACT_AGENT_TEST_VIEW_URL,
    AGENT_SETTINGS_URL,
} from 'utils/constants';

class ContactVendorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search_result: null,
            searching: false,
            is_agent_view: false,
        };

        this.fetchClientVendors = this.fetchClientVendors.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
        this.fetchAgentDisclaimer = this.fetchAgentDisclaimer.bind(this);
    }

    navigateToVendorDetail(vendor_id, role) {
        let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
        this.props.history.push(details_url);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'client_vendors'})
        document.title = "Your agent's suggested vendors";

        this.setState({searching: true});
       

        // this is the case when agent is trying out the shared link
        if (this.props.location.state && this.props.location.state.role && this.props.location.state.contact_id && this.props.location.state.role === EntityRole.AGENT) {
            let contact_id = this.props.location.state.contact_id;

            generic_get_api(CONTACT_AGENT_TEST_VIEW_URL, {contact_id: contact_id})
            .then(data => {
                this.setState({
                    contact_details: data.contact_details,
                    search_result: data.vendors,
                    searching: false,
                    disclaimer: data.disclaimer,
                    is_agent_view: true,
                });
            }).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.setState({searching: false});
                }
            });
        }
        else {  // this is the case when contact uses the link. Token was already stored in contact_login
            basicContactDetails()
            .then(
                data => {
                    if (data){
                        this.setState({
                            contact_details: data,
                            searching: false,
                        });
                        this.fetchClientVendors();
                        this.fetchAgentDisclaimer();
                    }
                }
            ).catch(error => {
                if (error?.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                } else {
                    this.setState({searching: false});
                }
            });
        }
    }

    fetchAgentDisclaimer()
    {
        generic_get_api(AGENT_SETTINGS_URL)
        .then(
            data => {
                this.setState({ 
                    disclaimer: data.settings.disclaimer,
                })
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchClientVendors() {

        generic_get_api(AGENT_CONTACT_VENDORS_URL, {})
        .then(
            data => {
                window.scrollTo(0, 0);
                this.setState({
                    search_result: data,
                });
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/contact/start');
            } else {
            }
        });
    }

    render() {
        const {search_result, searching, contact_details, is_agent_view} = this.state;

        const hasPhoto = () =>{
            if (contact_details.agent_profile_photo_url) {
                return true;
            }
            return false;
        }

        const getPhoneHref = () => { return "tel:"+ contact_details.agent_phone; }

        const getPhoneNumber = () => {
            if (formatPhoneNumber(contact_details.agent_phone).length > 0) { return formatPhoneNumber(contact_details.agent_phone); }
            else return <> &nbsp;</>
        }

        return(
            <div class="contactVendorListEnvelope">
                <div class="contactVendorsPage">
                
                    { searching &&
                        <div class="spinner"><input type="image" src={spinnerGif} /></div>
                    }

                    {contact_details &&
                        <div class="container agentBanner">
                            {
                                is_agent_view &&
                                        <div class="row justify-content-center mb-4">
                                            <Card>
                                                <Card.Body className="p-1">
                                                    <div class="container gx-0">
                                                        <div class="row d-flex justify-content-center">
                                                            <div class="col fs-5 text-secondary text-center">
                                                                Agent preview                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                            }
                            <div class="row justify-content-center">
                                <Card>
                                    <Card.Body>
                                        <div class="container gx-0">
                                            <div class="row d-flex justify-content-center ">
                                                {hasPhoto() && 
                                                    <div class="col-auto gx-0 text-center photoContainer"><img src={contact_details.agent_profile_photo_url} class="agentPhoto"/></div>
                                                }
                                                <div class="col">
                                                    <div class="container text-center">
                                                        <div class="row fs-5"><div class="col">{contact_details.agent_first_name} {contact_details.agent_last_name}</div></div>
                                                        <div class="row fw-semibold"><div class="col">{contact_details.agent_company}</div></div>
                                                        <div class="row"><div class="col"><small>CA DRE #: {contact_details.agent_license}</small></div></div>
                                                        <div class="row"><div class="col"><a role="button" class="text-decoration-none" href={"mailto:" + contact_details.agent_email}><small>{contact_details.agent_email}</small></a></div></div>
                                                        <div class="row"><div class="col fw-light"><a className="text-decoration-none" href={getPhoneHref()}><small>{getPhoneNumber()}</small></a></div></div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    }
                    <div class="container vendorLisWrapper mt-5">
                    { search_result && search_result.length > 0 &&
                        <>
                            <div class="row mb-2">
                                <div class="col fs-5">Vendor suggestions from your agent</div>
                            </div>
                            <ListGroup className="mt-1">
                                <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                    <div class="container header">
                                        <div class="row align-items-center">
                                            <div class="col-4 text-truncate hideMobile"><small>Vendor name <br></br>Company</small></div>
                                            <div class="col-auto text-truncate reviews hideMobile"><small>Reviews</small></div>
                                            <div class="col-2 gx-0 medium-container hideMobile"><small>Category</small></div>
                                            <div class="col-auto gx-0 medium-container phone hideMobile "><small>Phone</small></div>
                                            <div class="col-2 gx-0 small-container hideMobile"><small>Category <br></br> Phone</small></div>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                {
                                    search_result.map(
                                        (vendor) => {
                                            return <VendorComponent 
                                                        vendor={ vendor }
                                                        display_mode='contact_vendors_list'
                                                        navigateToVendorDetail={this.navigateToVendorDetail}
                                                    />
                                        })
                                }
                            </ListGroup>
                            
                            <div class="row mt-5"></div>
                            <div class="row mt-5"></div>
                            <div class="row mt-5">
                                <div class="col fs-6 fw-light text-center">
                                    <span dangerouslySetInnerHTML={{__html: this.state.disclaimer}} />
                                </div>
                            </div>
                        </>
                    }
                    </div>
                </div>
                
            </div>
        )
    }
}

ContactVendorList.contextType = AuthContext;
export default ContactVendorList;