import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Card, Button, OverlayTrigger, Modal, Popover} from 'react-bootstrap';


class VendorRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_requests'});
        document.title = "Your requests";
    }

    render() {

        return(
            <div class="vendorRequestsEnvelope">
                <div class="vendorRequestsPage">
                    <div class="container">
                        <div class="row">
                            <div class="col display-6">Requests</div>
                        </div>
                        <div class="row mt-3">
                            <div class="col ">You have 0 pending requests </div>
                        </div>
                    </div>

                </div>
                
            </div>
        )
    }
}

export default VendorRequests;