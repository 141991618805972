import mixpanel from 'mixpanel-browser';

mixpanel.init('9b4e1a4d239b1ea885f92efcaf3cc353');

export var trackEvent = (eventName, options) => {

    try {
        mixpanel.track(eventName, options);
      } catch (error) {
        console.error(error);
      }
}

export const EventNames = {
  SEARCH_START: 'search_start',
  SEARCH_END: 'search_end',
  HEADER_HOME_CLICK: 'header_home_click',
  PAGE_LOAD: 'page_load',
  PROFESSIONAL_DETAIL_CLICK: 'professional_detail_click',
  PROFESSIONAL_FIND: 'professional_find',
  SUBSCRIPTION_SEARCH: 'subscription_search',
  SUBSCRIPTION_CLICK: 'subscription_click',
  WAIT_LIST_SEND: 'wait_list_send',
  REVIEW_SUBMIT_CODE: 'review_submit_code',
  REVIEW_SUBMIT_REVIEW: 'review_submit_review',
  REVIEW_NEXT_PAGE: 'review_next_page',
  REVIEW_ATTACHMENT_UPLOAD: 'review_attachment_upload',
  REVIEW_ATTACHMENT_REMOVE: 'review_attachment_remove',
  REVIEW_OFFER_CLICKED: 'review_offer_clicked',
  REVIEW_ADMIN_UPLOAD: 'review_admin_upload',
  REVIEW_REFERRAL_SUBMIT: 'review_referral_submit',
  REVIEW_PRO_EMAIL: 'review_pro_email',
  CONTACT_US_SUBMIT: 'contact_us_submit',
  SEARCH_TAG: 'search_tag',
  INDEX_STAGE: 'index_stage',
  LOOKUP_PRO_CLICK: 'lookup_pro',
  NEW_ACCOUNT: 'new_account',
  ACCOUNT_FETCH: 'account_fetch',
  LOGIN: 'login',
  NEW_ACCOUNT_SEARCH: 'new_account_search',
  LOGOUT: 'logout',
  SURVEY_SUBMIT: 'survey_submit',
  SURVEY_CANCEL: 'survey_cancel',
  SUBMIT_PROJECT: 'submit_project',
  SUBMIT_QUOTE: 'submit_quote',
  ATTACH_PROJECT_FILE: 'attach_project_file',
  SOURCE_TRACKER: 'source_tracker',
  UNSUBSCRIBE: 'unsubscribe',
  INFO_REQUEST_URL: 'INFO_REQUEST_URL',
  ADS_SUBMIT_CODE: 'ads_submit_code',
  OFFERS_SHARE: 'offers_share',
  TOKEN_ERROR: 'token_error',
  API_REQUEST: 'api_request',
  SECTON_TRANSITION: 'section_transition',
}