import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';


class TermsOfService extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'tos'})
    }

    render() {

        return(
            <div class="TOSEnvelope topOfPageMargin">
                <div class="TOS">
                    
                    <div class="tosGrid" >
                        <div class="tosText">           
                            <h2>Terms of Service</h2>
                            <p>
                                Welcome to Renolition! We take a data-centered approach to helping you find and hire qualified and 
                                reputable construction professionals. These Terms of Use govern your use our website, software and 
                                online platform owned by Renolition Inc. d/b/a Renolition (“Renolition,” “Company,” “We,” “Our”), 
                                which also includes all related widgets, tools, data, software, and other services provided by us (the “Services”).
                            </p>
                            <p>
                                This document, together with our Privacy Policy and any other terms specifically referred to therein, constitute
                                a legally binding agreement (the “Agreement”) between you and the Company in relation to your use of our Services. 
                                If you do not agree with these Terms, do not use Renolition.
                            </p>
                            <h3>
                                Acceptance of Terms of Use
                            </h3>
                            <p>
                                Please read these Terms of Use, and our Privacy Policy, very carefully. If you use Renolition without creating an account, 
                                you agree to accept these Terms of Use and our Privacy Policy.  If you create an account on Renolition, please click or 
                                press “Accept” if you agree to be legally bound by all the terms and conditions herein. Your acceptance of these Terms 
                                of Use creates a legally binding contract between you and the Company. If you do not agree with any aspect of these Terms 
                                of Use, then do not click or press “Accept,” in which case you may not use the Services. By accepting the Terms of Use and 
                                creating an account, you represent and warrant that the information you include on the Website is accurate and that you have 
                                the capacity to enter into and abide by these terms and conditions.  
                            </p>
                            <h3>
                                Changes to Terms of Use
                            </h3>
                            <p>
                                We reserve the right to change, alter, replace or otherwise modify (collectively “Changes”) these Terms of Use at any time. 
                                The date of last modification is stated in the footer of these Terms of Use. 
                            </p>
                            <p>
                                When we make any updates to these Terms of Use, we will highlight this fact on the website or online platform. In addition, 
                                if you register an account and these Terms of Use are subsequently changed in any material respect (for example, for security, 
                                legal, or regulatory reasons), we will notify you in advance by sending an email to the email address that you have provided to us. 
                                You will have no obligation to continue using the Services following any such notification, but if you do not terminate your account 
                                as described in the Termination section below, your continued use of the Services will constitute your acceptance of the revised Terms of Use.
                            </p>
                            
                            <h3>Your Renolition Account</h3>
                            <p>
                                If you choose to create an account, are solely responsible for maintaining the confidentiality and security of your login and account 
                                information, and you will remain responsible for all activity emanating from your account, whether or not such activity was authorized by you.
                                If you are a professional and you create an account, you agree that a user may use a project quote flow to contact you via email.
                            </p>
                            <p>
                                We reserve the right to disallow, cancel, remove, or reassign certain usernames and permalinks in appropriate circumstances, as determined by 
                                us in our sole discretion, and may, with or without prior notice, suspend, terminate, and delete your account if activities occur on that 
                                account which, in our sole discretion, would or might constitute a violation of these Terms of Use or an infringement or violation of the 
                                rights of any third party, or of any applicable laws or regulations. You may terminate your account at any time through our Services.
                            </p>
                            <h3>
                                Review Collection Overview
                            </h3>
                            <p>
                                Renolition collects feedback from homeowners of recent projects about any professionals who in any form were involved in the project, 
                                including but not limited to licensed contractors, engineers, architects, and designers. The contact information of these professionals 
                                is collected  through public records. These reviews, including some public information about the project (e.g., permit number, project 
                                dates, partial project address), will be available on our Services.
                            </p>
                            
                            <h3>Homeowner Review Guidelines</h3>
                            <p>
                                Homeowners may be notified by mail, email or other form of communication to leave feedback.  Homeowners agree that by leaving feedback, 
                                they or their household was the entity hiring the professional for the specified project. We may provide homeowners an incentive to 
                                provide a review. If a homeowner was promised an incentive to provide a review, they will receive the incentive in 7-21 business days 
                                once the review is complete. The incentive will be delivered via mail, email or through your Renolition account. No incentive will be 
                                sent if a review is determined, at Renolition's sole discretion, to be fraudulent, inauthentic, a copy of a previous review or otherwise 
                                inappropriate.  
                            </p>
                            <p>
                                Once a homeowner leaves a review, the professional may identify the reviewer and contact them about the review, however 
                                Renolition does not post the name or contact information of homeowner reviewers on its public website. We may release 
                                the ability for users to purchase more information about each review. In some cases, we may allow professionals to post 
                                a public response to a homeowner review.  
                            </p>
                            
                            <h3>Business Review Guidelines</h3>
                            <p>As a business professional, you represent and warrant that you will not, and will not authorize or induce any other party, to:</p>
                            <ul>
                                <li>
                                    offer incentives of any kind, such as discounts, freebies, refunds, gift cards, contest entries, offers, or deals in 
                                    exchange for the posting of reviews of your business, or to prevent or remove reviews, and you understand and acknowledge 
                                    that Renolition may publicly notify consumers about such incentives and other attempts to obtain, prevent, or remove reviews;
                                </li>
                                <li>
                                    write reviews or vote on Content for your business or your business's competitors;
                                </li>
                                <li>
                                    pay or induce anyone to post, refrain from posting, or remove reviews, or otherwise attempt to circumvent Renolition's software or 
                                    fraud detection systems;
                                </li>
                                <li>
                                    attempt to generate automated, fraudulent, or otherwise invalid ad impressions, inquiries, conversions, ad clicks, or other actions;
                                </li>
                                <li>
                                    misrepresent your identity or affiliation to anyone in connection with Renolition.
                                </li>
                            </ul>
                            <p>You may encourage homeowners to provide reviews, as long as no incentives are provided.</p>

                            <h3>Your Use of the Services</h3>
                            <p>
                                Subject to your strict compliance with these Terms of Use, Renolition grants you a limited, personal, non-exclusive, revocable, 
                                non-assignable, and non-transferable right and license to use the Services in order to generate text, graphics, software, 
                                photographs, videos, data, and other materials (collectively “Content”), view Content, share and download Content using the 
                                features of the Services where the appropriate functionality has been enabled.
                            </p>
                            <p>The above licenses are conditional upon your strict compliance with these Terms of Use including, without limitation, the following:</p>
                            <p>
                                (i) You must not copy, rip or capture, or attempt to copy, rip or capture, any Content from the Services or any part of the Services, 
                                other than by means of download or sharing in circumstances where we have elected to permit downloads and sharing of the relevant Content. 
                            </p>
                            <p>
                                (ii) You must not employ scraping or similar techniques to aggregate, repurpose, republish or otherwise make use of any Content.
                            </p>
                            <p>
                                (iii) You must not alter or remove, attempt to alter or remove any trademark, copyright or other proprietary or legal notices 
                                contained in, or appearing on, the Services or any Content appearing on the Services (other than your Content).
                            </p>
                            <p>
                              (iv) You must not, and must not permit any third party to, copy or adapt the object code of the Website or any of the Services, 
                                or reverse engineer, reverse assemble, decompile, modify or attempt to discover any source or object code of any part of the Services, 
                                or circumvent or attempt to circumvent or copy any copy protection mechanism or access any rights management information pertaining 
                                to Content other than your Content.
                            </p>
                            <p>
                                (v) You must not use the Services to upload, post, store, transmit, display, copy, distribute, promote, make available or 
                                otherwise communicate to the public:
                            </p>
                            <ul>
                                <li>
                                any Content that is offensive, abusive, libelous, defamatory, obscene, racist, ethnically or culturally offensive, indecent, 
                                that promotes violence, terrorism, or illegal acts, incites hatred on grounds of race, gender, religion or sexual orientation, 
                                or is otherwise objectionable in the Company's reasonable discretion;
                                </li>
                                <li>
                                any information, Content, or other material that violates, plagiarizes, misappropriates, or infringes the rights of 
                                third parties including, without limitation, copyright, trademark rights, rights of privacy or publicity, confidential 
                                information or any other right; or
                                </li>
                                <li>
                                    any Content that violates, breaches, or is contrary to any law, rule, regulation, court order, or is otherwise is 
                                    illegal or unlawful in the Company's reasonable opinion;
                                </li>
                                <li>
                                    any material of any kind that contains any virus, Trojan horse, spyware, adware, malware, bot, time bomb, worm, or other 
                                    harmful or malicious component, which actually or potentially could overburden, impair or disrupt the Services or servers 
                                    or networks forming part of, or connected to, the Services, or which actually or potentially could restrict or inhibit 
                                    any other user's use and enjoyment of the Services; or
                                </li>
                                <li>
                                    any unsolicited or unauthorized advertising, promotional messages, spam or any other form of solicitation.
                                </li>
                            </ul>
                            <p>
                                (vi) You must not commit or engage in, or encourage, induce, solicit or promote, any conduct that would constitute a criminal offence, 
                                give rise to civil liability or otherwise violate any law or regulation.
                            </p>
                            <p>
                                (vii) You must not deliberately impersonate any person or entity or otherwise misrepresents your affiliation with a person or entity, 
                                for example, by registering an account in the name of another person or company or sending messages or making comments using the name of another person.
                            </p>
                            <p>
                                You agree to comply with the above conditions and acknowledge and agree that Renolition has the right, in its sole discretion,
                                to terminate your account or take such other action as we see fit if you breach any of the above conditions or any of the 
                                other terms of these Terms of Use. This may include taking court action and/or reporting offending users to the relevant authorities.
                            </p>
                            
                            <h3>Intellectual Property Rights</h3>
                            <p>
                            The Services and its entire contents, features, and functionality (including but not limited to all information, software, text, 
                            displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Renolition, its licensors, 
                            or other providers of such material and are protected by United States and international copyright, trademark, patent, trade 
                            secret, and other intellectual property or proprietary rights laws.
                            </p>
                            <p>
                            All related names, logos, product and service names, designs, and slogans are trademarks of the Renolition or its affiliates or 
                            licensors. You must not use such marks without the prior written permission of Renolition. All other names, logos, product and 
                            service names, designs, and slogans on this website are the trademarks of their respective owners.
                            </p>

                            <h3>Copyright Infringement and the Digital Millennium Copyright Act (DMCA) Safe Harbor</h3>
                            <p>
                                We take the intellectual property rights of others seriously and require that users of Renolition do the same. The Digital Millennium 
                                Copyright Act (DMCA) established a process for addressing claims of copyright infringement that we have implemented for our services. 
                                If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that a third party is 
                                infringing that material on or through Renolition, please send a notice to our copyright agent that includes all of the items 
                                below and we will expeditiously take appropriate action:
                            </p>
                            <ol>
                                <li>A description of the copyrighted work that you claim is being infringed;</li>
                                <li>A description of the material you claim is infringing and that you want removed or access to which you want disabled and the URL or other location of that material;</li>
                                <li>Your address, telephone number, and email address;</li>
                                <li>The following statement: “I have a good faith belief that the use of the copyrighted material I am complaining of is 
                                    not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)”;</li>
                                <li>The following statement: “The information in this notice is accurate and, under penalty of perjury, I am the owner, 
                                    or authorized to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly infringed”; and</li>
                                <li>An electronic or physical signature of the owner of the copyright or a person authorized to act on the owner's behalf.</li>
                            </ol>
                            <p>
                                Our designated copyright agent to receive such claims can be reach at support@renolition.com. We may, in appropriate circumstances, disable 
                                or terminate the accounts of users who may be repeat infringers. This process does not limit our ability to pursue any other remedies 
                                we may have to address suspected infringement.
                            </p>
                            
                            <h3>Data Protection, Privacy, and Cookies</h3>
                            <p>
                                All personal data that you provide to us in connection with your use of the Services is collected, stored, used, and disclosed 
                                by the Company in accordance with our Privacy Policy, which is in compliance with the California Consumer Privacy Act (CCPA). 
                                In addition, in common with most websites, we use cookies, location data, and other user information to help us understand 
                                how people are using the Services, so that we can continue to improve the service we offer. The Privacy Policy, as may be 
                                updated by the Company from time to time in accordance with its terms, is hereby incorporated into these Terms of Use, 
                                and you hereby agree to the collection, use and disclose practices set forth therein.
                            </p>

                            <h3>Third Party Websites and Services</h3>
                            <p>
                                The Services may provide you with access to and/or integration with third party websites, databases, networks, servers, information, software, 
                                programs, systems, directories, applications, products or services (hereinafter “External Services”).  In particular, Renolition collects 
                                various data from the following public records of governmental agencies:
                            </p>
                            <ol>
                                <li>Contractor Data: https://www.cslb.ca.gov/onlineservices/dataportal/</li>
                                <li>Engineer/Architect data: https://www.dca.ca.gov/consumers/public_info/index.shtml</li>
                                <li>Permit data: https://data.sfgov.org/Housing-and-Buildings/Building-Permits/</li>
                                <li>Owner information: https://recorder.sfgov.org/</li>
                            </ol>
                            <p>
                            The Company does not have or maintain any control over External Services and is not and cannot be responsible for their content, 
                            operation or use. By linking or otherwise providing access to any External Services, the Company does not give any representation, 
                            warranty, or endorsement, express or implied, with respect to the legality, accuracy, quality or authenticity of content, 
                            information or services provided by such External Services.
                            </p>
                            <p>
                            External Services may have their own terms of use and/or privacy policy, and may have different practices and requirements to those 
                            operated by the Company with respect to the Services. You are solely responsible for reviewing any terms of use, privacy policy, 
                            or other terms governing your use of these External Services, which you use at your own risk. You are advised to make reasonable 
                            inquiries and investigations before entering into any transaction, financial or otherwise, and whether online or offline, with 
                            any third party related to any External Services.
                            <p>
                            </p>
                            You are solely responsible for taking the precautions necessary to protect yourself from fraud when using External Services, and 
                            to protect your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content and material that 
                            may be included on or may emanate from any External Services.
                            <p>
                            </p>
                            The Company disclaims any and all responsibility or liability for any harm resulting from your use of External Services, and you 
                            hereby irrevocably waive any claim against the Company with respect to the content or operation of any External Services.
                            </p>
                            
                            <h3>No Warranty</h3>
                            <p>
                                All construction information on Renolition is an aggregation of public data. Renolition does not guarantee the accuracy of the information.  
                                These guides are for informational purposes only. Professional profile pages on Renolition are automatically create from public databases once 
                                they are posted on such public sites. Renolition does not warrant the accuracy or completeness of public data, licensing, insurance, worker's 
                                compensation, permits or any other information provided on the Services. The categorization of permits may not be completely accurate, and some 
                                permits may be miscategorized or omitted from the analysis.  The reviews represent our best efforts to collect feedback from homeowners who have 
                                worked with a particular professional, but they may be skewed or provide an incomplete depiction of the professional's work product. We provide no 
                                warranty about professionals or their quality of work should you hire them.
                            </p>

                            <h3>Disclaimer</h3>
                            <p>
                            THE SERVICES, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, ONLINE PLATFORM AND ALL CONTENT AND SERVICES ACCESSED THROUGH OR VIA 
                            THE WEBSITE, ONLINE PLATFORM OR OTHERWISE, ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS.” 
                            </p>
                            <p>
                            YOU AGREE AND ACKNOWLEDGE THAT YOU ASSUME FULL, EXCLUSIVE, AND SOLE RESPONSIBILITY FOR THE USE OF AND RELIANCE ON THE 
                            SERVICES, AND YOU FURTHER AGREE AND ACKNOWLEDGE THAT YOUR USE OF OR RELIANCE ON THE SERVICES IS MADE ENTIRELY AT YOUR 
                            OWN RISK. YOU FURTHER ACKNOWLEDGE THAT IT IS YOUR RESPONSIBILITY TO COMPLY WITH ALL APPLICABLE LAWS WHILE USING THE SERVICE.
                            WHILE THE COMPANY USES REASONABLE ENDEAVOURS TO CORRECT ANY ERRORS OR OMISSIONS IN THE SERVICES AS SOON AS PRACTICABLE 
                            ONCE THEY HAVE BEEN BROUGHT TO THE COMPANY’S ATTENTION, THE COMPANY MAKES NO PROMISES, GUARANTEES, REPRESENTATIONS, OR 
                            WARRANTIES OF ANY KIND WHATSOEVER (EXPRESS OR IMPLIED) REGARDING THE SERVICES, OR ANY PART OR PARTS THEREOF, ANY CONTENT, 
                            OR ANY LINKED SERVICES OR OTHER EXTERNAL SERVICES. THE COMPANY DOES NOT WARRANT THAT YOUR USE OF THE SERVICES WILL BE ACCURATE 
                            OR RELIABLE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR ANY PART 
                            OR PARTS THEREOF, THE CONTENT, OR THE SERVERS ON WHICH THE SERVICES OPERATES ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL 
                            COMPONENTS. THE COMPANY DOES NOT WARRANT THAT ANY TRANSMISSION OF CONTENT UPLOADED TO THE SERVICES WILL BE SECURE OR THAT 
                            ANY ELEMENTS OF THE SERVICES DESIGNED TO PREVENT UNAUTHORISED ACCESS, SHARING OR DOWNLOAD OF CONTENT WILL BE EFFECTIVE IN 
                            ANY AND ALL CASES, AND DOES NOT WARRANT THAT YOUR USE OF THE SERVICES IS LAWFUL IN ANY PARTICULAR JURISDICTION.
                            </p>
                            <p>
                            THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, 
                            OFFICERS AND SHAREHOLDERS, SPECIFICALLY DISCLAIM ALL OF THE FOREGOING WARRANTIES AND ANY OTHER WARRANTIES NOT EXPRESSLY SET 
                            OUT HEREIN TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING WITHOUT LIMITATION ANY EXPRESS OR IMPLIED WARRANTIES REGARDING 
                            NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                            </p>
                            <p>
                            WHERE THE LAW OF ANY JURISDICTION LIMITS OR PROHIBITS THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES AS SET OUT ABOVE, 
                            THE ABOVE DISCLAIMERS SHALL NOT APPLY TO THE EXTENT THAT THE LAW OF SUCH JURISDICTION APPLIES TO THIS AGREEMENT.
                            </p>
                            
                            <h3>Limitation of Liability</h3>
                            <p>
                            THE COMPANY'S AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, 
                            OFFICERS AND SHAREHOLDERS, LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE 
                            COMPANY BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, 
                            EXEMPLARY, OR PUNITIVE DAMAGES, LOST PROFITS, LOST DATA, LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY COST TO PROCURE SUBSTITUTE 
                            GOODS OR SERVICES, OR ANY INTANGIBLE LOSS, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH 
                            YOUR USE OF THE WEBSITE, OR ANY OTHER SERVICES PROVIDED TO YOU BY THE COMPANY.
                            </p>
                            <p>
                            This limitation shall apply regardless of whether the damages arise out of breach of contract, tort, any form of error, or breakdown 
                            in the function of the service, or any other legal theory or form of action.
                            </p>
                            <p>
                            ALTHOUGH NOT AN EXHAUSTIVE LIST AND WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, 
                            AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, SHALL HAVE NO LIABILITY FOR:
                            </p>

                            <p>1.	ANY LOSS OR DAMAGE ARISING FROM:</p>

                            <p>(A) YOUR RELIANCE ON THE CONTENT OF THE SERVICES, INCLUDING WITHOUT LIMITATION, CONTENT ORIGINATING FROM THIRD PARTIES, OR FROM ANY COMMUNICATION WITH THE SERVICES;:</p>
                            <p>(B) YOUR INABILITY TO ACCESS OR USE THE SERVICES OR ANY PART OR PARTS THEREOF, INCLUDING DELETION OR CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND 
                            OTHER DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICE, OR YOUR ABILITY TO ACCESS ANY CONTENT OR ANY EXTERNAL SERVICES VIA THE SERVICES;:</p>
                            <p>(C) ANY CHANGES THAT THE COMPANY MAY MAKE TO THE SERVICES OR ANY PART THEREOF, OR ANY TEMPORARY OR PERMANENT SUSPENSION OR CESSATION OF ACCESS TO THE SERVICES 
                            OR ANY CONTENT IN OR FROM ANY OR ALL TERRITORIES;:</p>
                            <p>(D) ANY ACTION TAKEN AGAINST YOU BY THIRD PARTY RIGHTS HOLDERS WITH RESPECT TO ANY ALLEGED INFRINGEMENT OF SUCH THIRD PARTY'S RIGHTS RELATING TO YOUR CONTENT 
                            OR YOUR USE OF THE SERVICES, OR ANY ACTION TAKEN AS PART OF AN INVESTIGATION BY THE COMPANY OR ANY RELEVANT LAW ENFORCEMENT AUTHORITY REGARDING YOUR USE OF THE SERVICES;:</p>
                            <p>(E) ANY ERRORS OR OMISSIONS IN THE SERVICES'S TECHNICAL OPERATION, OR FROM ANY INACCURACY OR DEFECT IN ANY CONTENT OR ANY INFORMATION RELATING TO CONTENT;:</p>
                            <p>(F) YOUR FAILURE TO PROVIDE THE COMPANY WITH ACCURATE OR COMPLETE INFORMATION, OR YOUR FAILURE TO KEEP YOUR ACCOUNT LOGIN INFORMATION SUITABLY CONFIDENTIAL;:</p>
                            <p>(G) ANY LOSS OR DAMAGE TO ANY COMPUTER HARDWARE OR SOFTWARE, ANY LOSS OF DATA, OR ANY LOSS OR DAMAGE FROM ANY SECURITY BREACH; :</p>
                            <p>(H) ANY LOSS OF PROFITS, INCLUDING THOSE CAUSED BY YOUR RELIANCE ON THE SERVICES, OR ANY LOSS YOU SUFFER WHETHER OR NOT IT IS FORESEEABLE.</p>
                            <p>
                            ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES MUST BE NOTIFIED TO THE COMPANY AS SOON AS POSSIBLE.
                            APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS 
                            MAY NOT APPLY TO YOU. IN SUCH CASES, YOU ACKNOWLEDGE AND AGREE THAT SUCH LIMITATIONS AND EXCLUSIONS REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK 
                            BETWEEN YOU AND THE COMPANY AND ARE FUNDAMENTAL ELEMENTS OF THE BARGAIN BETWEEN YOU AND THE COMPANY, AND THAT THE COMPANY'S LIABILITY WILL BE LIMITED 
                            ENTIRELY, TO THE MAXIMUM EXTENT PERMITTED BY LAW. 
                            </p>
                            
                            <h3>Indemnification</h3>
                            <p>
                            You hereby agree to indemnify, defend and hold harmless the Company, its successors, assigns, affiliates, agents, directors, officers, 
                            employees, and shareholders from and against any and all claims, obligations, damages, losses, expenses, and costs, including 
                            reasonable attorneys' fees, resulting from:
                            </p>
                            <p>(i) any violation by you of these Terms of Use; or </p>
                            <p>(ii) any activity related to your account, be it by you or by any other person accessing your account with or without your consent.</p>

                            <h3>Complete Agreement</h3>
                            <p>
                            These Terms constitute the entire agreement between you and Renolition with respect to the use of the Renolition 
                            Site and Content. Your use of the Renolition Services is also subject to the Renolition Privacy Policy. 
                            If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity 
                            of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and 
                            effect. No waiver of any of these Terms shall be deemed a further or continuing waiver of such term or condition 
                            or any other term or condition.
                            </p>

                            <h3>Contact us</h3>
                            <p>
                            For questions or comments about the Terms, please contact us at support@renolition.com. 
                            </p>

                            <p>Last Update: August 12, 2023</p>


                        </div>
                    </div>
                </div>
                
            </div>
        ) 
    }
}

export default TermsOfService;