import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { generic_post_api } from "api/generic_api";
import {
    GOOGLE_AUTH_URL,
} from 'utils/constants';


class GmailImport extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'gmail_import'})

        let urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get('code');

        if (code) {
            generic_post_api({code: code}, GOOGLE_AUTH_URL)
            .then(
                (data) => {
                    setTimeout(() =>     
                    this.props.history.push('/agent/contacts'), 
                    5000
                )
                }
            )
        } 
        else {
            setTimeout(() =>     
                this.props.history.push('/agent/contacts'), 
                5000
            )
        }
    }

    render() {

        return(
            <div class="TOSEnvelope">
                <div class="TOS">
                    
                    <div class="tosGrid" >
                        <div class="tosText">       
                            <center>
                                <p class="fw-bold">Loading your clients in the background...</p>
                                <p></p>
                                <p>Clients will appear in your clients page as they load.</p>
                                <p class="mt-2">You will be re-directed in 5 seconds.</p>
                            </center>
                        </div>
                    </div>
                </div>
                
            </div>
        ) 
    }
}

export default GmailImport;