import React, { Component, Fragment } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import right_arrow from  'assets/reputation/next.png';


class ReplaceKitchenCountertop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
        };
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'guide_replace_kitchen_backsplash'})
    }

    render() {
        return(
            <div class="guideDetailEnvelope">
                <div class="guideDetail">
                    <div class="container guideContent mt-5" >
                        <div class="display-6">Project: Replacing the Kitchen Countertop</div>

                        <div class="mt-3">
                            <div>Replacing the kitchen countertop has a big effect on the look and feel of your kitchen. Besides the cabinets, the countertop 
                                has the largest surface in the kitchen and so attracts the most focus and attention. Different countertops go in and out of fashion 
                                every few years, so replacing the countertop can give a quick modern look to the kitchen.  

                                <p>
                                This guide highlights the main considerations and action items when 
                                replacing the kitchen countertop, while leaving the 
                                rest of the kitchen as is. <a onClick={ () => this.setContactUsDisplay(true) }>Contact us</a> with additional questions or comments.
                                </p>
                            </div>
                        <div>&nbsp;</div>
                            
                            <div class=" mt-3 h4">Table of Contents</div>

                            <div class="fw-ligh"><a href="#material"><img class="arrow_image"src={right_arrow}/>Selecting the material</a></div>
                            <div class="fw-ligh"><a href="#measurement"><img class="arrow_image"src={right_arrow}/>Measurements / islands</a></div>
                            <div class="fw-ligh"><a href="#demo"><img class="arrow_image"src={right_arrow}/>Current material of surface / demolition</a></div>
                            <div class="fw-ligh"><a href="#professional"><img class="arrow_image"src={right_arrow}/>Finding contractors/professionals</a></div>
                            <div class="fw-ligh"><a href="#backsplash"><img class="arrow_image"src={right_arrow}/>Backsplash considerations</a></div>
                            
                            <div class="section"><a id="material"/>
                                <div class="h5 mt-3">Selecting the material</div>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> First, you must decide the material for your new countertop. Today, there is a wide 
                                            range of materials available, each with different looks, price points, and advantages/disadvantages. Popular materials are natural 
                                            stones (granite, marble), manufactured (glass stone, quartz, artificial stone), laminate, and wood/butcher block. 
                                        </div>
                                
                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>

                                            <li>
                                                <div>Decide on the material you’d like for your new countertop. If searching online, make sure the website is neutral and it is not the biased 
                                                    opinion of some manufacturer.</div>
                                            </li>
                                            <li>
                                                <div><a href="https://www.consumerreports.org/cro/magazine/2015/07/pros-cons-costs-10-countertop-materials/index.htm" target="_blank">The Consumer Reports</a> is 
                                                a good source of comparisons</div>
                                            </li>
                                            <li>
                                                <div>Keep in mind that natural stones are the most expensive, but not all are as durable and stain resistant as some of the manufactured stones.</div>
                                            </li>
                                            <li>
                                                <div>Visit a variety of local countertop dealers to see displayed countertops.</div>
                                            </li>
                                            <li>
                                                <div>Pro tip: Home Depot, Lowes, and IKEA offer countertops at various price points.</div>
                                            </li>
                                            </ol>
                                        </div>
                            </div>

                            <div class="section"><a id="measurement"/>
                                <div class="h5 mt-3">Measurements / islands</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> Accurate measurements of your space will help you to get more accurate cost estimates from countertop dealers and installers. 
                                            In addition to measurements, the number/type of islands, number of cuts for sinks, and holes faucets will affect the price. 
                                            <p>Most countertops come in standard size pieces that then are cut to specification. Mostly, the pieces are about 26” wide 
                                                (from the wall to the edge of the counter) and 110” - 130” long. When you purchase pieces, you must purchase the entire piece, 
                                                even if you use only 30” of it. Pieces that are made for islands are often 36” and wider. 
                                            </p>
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                                <li><div>Start with your countertop that is against the wall (that is, non-island countertop) and measure the length of all the areas that 
                                                    require a new countertop. This will be the length of your “standard width” countertop.</div>
                                                </li>
                                                <li>
                                                    <div>If your kitchen has the shape of an L or U, and the widths of the various sections differ, record those separately.</div>
                                                </li>
                                                <li>
                                                    <div>If your kitchen has an island, measure both the length and width of the island — record this as your island measurements.</div>
                                                </li>
                                                <li>
                                                    <div>Record the length and width of your kitchen sink(s).</div>
                                                </li>
                                                <li>
                                                    <div>Decide what kind of faucet you would like with your new counter and record the number of faucet holes required. Keep in mind that 
                                                        you may require an additional hole for the dishwasher over flow.</div>
                                                </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="section"><a id="demo"/>
                                <div class="h5 mt-3">Current material of surface / demolition</div>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> CBefore installing a new countertop, the old countertop must be removed and the surface prepared for the new 
                                        installation. For most new materials, the layer of plywood is installed on top of the kitchen cabinets, and the new countertop is adhered to the plywood 
                                        layer. Often, while removing the old countertop the underlying plywood layer may get destroyed and needs to get replaced or partially repaired.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Countertop removal and surface preparation is a time consuming and labor intensive task and is best left to professionals. In addition, countertops are heavy and their disposal requires finding construction dumps.</div>
                                        </li>
                                        <li>
                                            <div>Finding a professional for this step is <a href="#professional">discussed below.</a></div>
                                        </li>
                                        <li>
                                            <div>Ensure to discuss with them the current countertop material, disposal costs, and installation of a new plywood layer if necessary.</div>
                                        </li>
                                        <li>
                                            <div>Pro tip: In pays in making sure the professional is insured in case any other part of your kitchen/house is damaged during the demolition and removal step. </div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                            </div>
                            
                            <div class="section"><a id="professional"/>
                                <div class="h5 mt-3">Finding contractors/professionals</div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> In general, the replacement of kitchen countertops has three steps. First the current surface must be removed, 
                                        and <a href="#demo">prepared for the new countertop</a>. Second, the new stone must be cut to measurement and any holes for sinks and faucets are drilled or cut out. 
                                        Finally, the countertop is installed. Many countertop dealers provide steps 2 and 3, but they require that the old countertop is removed and the surface prepared. 
                                        Others, may only sell the countertop and provide no other services.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Once you have selected your new countertop, inquire from the store what services they provide.</div>
                                        </li>
                                        <li>
                                            <div>If the store does not provide all the necessary services (demolition, cutting and/or installation), then you will need to 
                                                hire a <a href="/">general contractor</a> (GC) specializing in countertop installations to perform any remaining steps.</div>
                                        </li>
                                        <li>
                                            <div>When you contact a GC, make sure to mention the details about the current surface, measurements, and new countertop that are mentioned on this page, 
                                                to get the most detailed estimate.</div>
                                        </li>
                                        <li>
                                            <div>Also, if the countertop dealer only sells the countertop without any other services, make sure to discuss with your GC that they need to pick up 
                                                the new surface from the dealer (countertops are very heavy).</div>
                                        </li>
                                        <li>
                                            <div>Pro-top: Since this is a small job and harder to find professionals for small jobs, it may be best to focus on smaller, one-person GCs.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            
                            <div class="section"><a id="backsplash"/>
                                <div class="h5 mt-3">Backsplash considerations</div>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> If your kitchen has a tile or similar backsplash it will not be possible to install a new countertop 
                                        without modifying/replacing the existing backsplash. This is because the backsplash is installed <i>after</i> the countertop and so sits on top of the countertop. 
                                        Removing the countertop will damage the backsplash.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                            <li>
                                                <div>It is often best to replace the backsplash with the countertop. If you are planning that, refer to our <a href="/guides/guide_replace_kitchen_backsplash">guide 
                                                    for backsplash replacement</a>. 
                                                    As mentioned, the backsplash is installed after the countertop</div>
                                            </li>
                                            <li>
                                                <div>If you do not want to replace your backsplash, consult your countertop installation professional and determine whether it is possible. In some cases, 
                                                    it is possible to remove the layer right above the countertop and reinstall that layer once the countertop is in place.
                                                </div>
                                            </li>
                                            
                                        </ol>
                                    </div>
                            </div>
                            <div class="mt-5">
                                <small>
                                Disclaimer: The information on this page is offered without any warranty or claim of accuracy. For any construction or home improvement work, 
                                you should always check with your local building department regarding codes and regulations and consult with licensed professionals when in doubt.
                                </small>
                            </div>
                        </div>
                    </div>

                    
                </div>
                
            </div>
        )
    }
}

export default ReplaceKitchenCountertop;