import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Setting to 0,0 does not work. Probably because of the fixed position header. Setting to -10 seems to scroll all the way up.
    window.scrollTo(0, -100);
  }, [pathname]);

  return null;
}