import React, { Component } from "react";
import { PASSWORD_RESET } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import {Button, Spinner} from 'react-bootstrap';
import { isValidPassword } from "utils/helpers";


const Stages = {
    INIT: 'init',
    ERROR: 'error',
    CONFIRMATION: 'confirmation',
}



var fieldsMatch = (field1, field2) => {
    if (!field1 || !field2) { return true; }

    return field1 === field2;
} 

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: Stages.INIT,
            submitting: false,
        };
        this.formChanged = this.formChanged.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.setContactUsDisplay = this.setContactUsDisplay.bind(this);
    }

    setContactUsDisplay(value) {
        this.setState({ displayContactUs: value });
    }

    handlePasswordReset() {
        
        if (!this.state.password || !this.state.password_reset_entry_id)
        {
            return;
        }

        var request = { 
            password: this.state.password,
            password_reset_entry_id: this.state.password_reset_entry_id,
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(PASSWORD_RESET);
        
        this.setState({ 
                submitting: true,
            }
        )

        trackEvent(PASSWORD_RESET, {'PUT': true })

        fetch(url, requestOptions)
        .then(
            (result) => {
                window.scrollTo(0, -100);

                if (result.status === 201) {
                    this.setState({
                        stage: Stages.CONFIRMATION,
                        submitting: false,
                    });
                }
                else if (result.status === 401) {
                    this.setState({ 
                        stage: Stages.ERROR,
                        submitting: true,
                     });
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'password_reset'});
        document.title = "Reset your Renolition account password";

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length === 3 && pathSplit[1] === 'password_reset') {
            this.setState({password_reset_entry_id: pathSplit[2]});
        }
    }

    canSubmit() {
        return this.state.password && 
            this.state.passwordConfirm && 
            isValidPassword(this.state.password) &&
            fieldsMatch(this.state.password, this.state.passwordConfirm);
    }

    formChanged(e, target) {
        this.setState({ [target]: e.target.value })
    }

    render() {
        return(
            <div class="resetPasswordEnvelope">
                <div class="resetPassword">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />
                     { this.state.stage === Stages.INIT &&
                        <div class="container passwordResetContainer">
                            <div class="row"><div class="col fs-5 fw-bold text-center"> Enter your new password</div></div>
                            <div class="row mt-4"><div class="col"> 
                                <input 
                                    class="form-control"
                                    type="password" 
                                    placeHolder="Password" 
                                    onChange={ (e) => this.formChanged(e, 'password') }
                                    value= { this.state.password }
                                />
                            </div></div>

                            <div class="row mt-1 ps-2">
                                <div class="col"> 
                                    <small>Must contain at least eight characters, including at least 1 upper case letter, 1 lower case letter, 1 number and a special character from !@#$%^&*_</small>
                                </div>
                            </div>
                           
                            <div class="row mt-3"><div class="col"> 
                                <input 
                                    class="form-control"
                                    type="password" 
                                    placeHolder="Confirm password" 
                                    value= { this.state.passwordConfirm }
                                    onChange={ (e) => this.formChanged(e, 'passwordConfirm') }
                                    disabled={ !isValidPassword(this.state.password)}
                                />
                            </div></div>

                            { !fieldsMatch(this.state.password, this.state.passwordConfirm) && 
                                <div class="row mt-1 ps-2">
                                    <div class="col text-danger"> 
                                        <small>Passwords do not match</small>
                                    </div>
                                </div>
                            }
                            
                            <div class="row mt-4">
                                <div class="col text-center"> 
                                { this.state.submitting ? 
                                        <Button variant="primary" disabled>
                                            Submitting... <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                        </Button>
                                    : 
                                        <Button variant="primary" 
                                            onClick={() => this.handlePasswordReset()}  
                                            disabled={ !this.canSubmit() }>Reset your password</Button>
                                }
                            </div></div>
                        
                            <div class="row mt-4"><div class="col text-center text-secondary">
                                Questions?&nbsp; 
                                <a role="button" class="text-decoration-underline" onClick={ () => this.setContactUsDisplay(true) } >
                                    Contact us
                                </a> 
                            </div> </div>
                                
                        </div>
                    }
                    { this.state.stage === Stages.CONFIRMATION &&
                        <div class="container passwordResetContainer">
                            <div class="row"><div class="col fs-5 fw-bold text-center">
                                Your password was successfully reset.
                            </div></div>
                            <div class="row mt-4"><div class="col text-center">
                                <a class="text-decoration-underline" role="button" href='/login'>Login to your account</a>
                            </div></div>
                        </div>
                    }

                    { this.state.stage === Stages.ERROR &&
                        <div class="container passwordResetContainer">
                            <div class="row"><div class="col fs-5 text-center text-danger">
                                Your link is no longer valid. 
                                </div></div>
                            <div class="row mt-4"><div class="col text-center">
                                <a href='/login'>Request a new link</a>
                            </div></div>
                        </div>
                    }
                </div>
                
            </div>
        );
    }
}

export default PasswordReset;