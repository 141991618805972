import React, { Component, Fragment } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import right_arrow from  'assets/reputation/next.png';


class ReplaceKitchenBacksplash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayContactUs: false,
        };
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'guide_replace_kitchen_backsplash'})
    }

    render() {
        return(
            <div class="guideDetailEnvelope">
                <div class="guideDetail">
                    <div class="container guideContent mt-5" >
                        <div class="display-6">Project: Replacing the Kitchen Backsplash with New Tiles</div>

                        <div class="mt-3">
                            <div>Replacing your kitchen backsplash has the biggest effect on the look of your kitchen given the cost and effort of the project. Compared
                                to other projects like changing the countertop, or replacing cabinets, retiling the backsplash area provides the most change for the least cost. 
                                This guide highlights the main considerations and action items when 
                                replacing the kitchen backsplash with new tiles, while leaving the rest of the kitchen as is.  
                            <div>
                        </div>
                            
                        <div class=" mt-3 h4">Table of Contents</div>

                        <div class="fw-light"><a href="#measurements"><img class="arrow_image"src={right_arrow}/>Measurements</a></div>
                        <div class="fw-light"><a href="#tileSelection"><img class="arrow_image"src={right_arrow}/>Tile selection</a></div>
                        <div class="fw-light"><a href="#upgrades"><img class="arrow_image"src={right_arrow}/>Additional upgrades</a></div>
                        <div class="fw-light"><a href="#removal"><img class="arrow_image"src={right_arrow}/>Removing the old backsplash</a></div>
                        <div class="fw-light"><a href="#contractors"><img class="arrow_image"src={right_arrow}/>Finding contractors/professionals</a></div>
                        <div class="fw-light"><a href="#installation"><img class="arrow_image"src={right_arrow}/>Installation/finishing</a></div>
                        
                        <div><a id="measurements"/>
                            <div class="h5 mt-3">Measurements</div>
                                <div class="subsection">
                                    <span class="emphasis">Description:</span> Exact measurements of the area to tile is important 
                                    for 1) selecting tiles 2) receiving estimates from contractors. 
                                </div>
                        
                                <div class="subsection"><span class="emphasis">What to do:</span> 
                                    <ol>

                                    <li>
                                        <div>It is best to draw each wall/section that will be tiled on paper and add measurements to the drawing.</div>
                                    </li>
                                    <li>
                                        <div>Add any irregularities that affect the tiled area, like windows, under-cabinet hoods, fridges etc.</div>
                                    </li>
                                    <li>
                                        <div>Add vertical measurements by measuring from top of countertop to the edge of where tiling should end. This can be the 
                                            bottom of your wall cabinets, a window, or hood, or it can be a self-selected height. </div>
                                    </li>
                                    <li>
                                        <div>Add horizontal measurements by measuring the horizontal space where tile will be installed for each wall/section. 
                                            Pay attention to corners where tiles will overlap and account for the thickness of the tile (you can use a 1/4 inch tile 
                                            thickness as an average until you select your tiles). Also, you may already have old tile on your wall. Make sure *not* to 
                                            count the old tile’s thickness.</div>
                                    </li>
                                    <li>
                                        <div>Preferably, make measurements with a 1/4 inch accuracy</div>
                                    </li>
                                    <li>
                                        <div>For best results, take multiple measurements in different spots and use the average. Walls/cabinets/counters are often not 
                                            100% squared. Note any big differences (more than 1 inch) and share with contractor.</div>
                                    </li>
                                    <li>
                                        <div>Use the individual measurements to calculate the total area, in square feet, that requires tiling.</div>
                                    </li>
                                    <li>
                                        <div>Hint: If you took all your measurements in inches, divide area by 144 to obtain the area in square feet.</div>
                                    </li> 
                                    </ol>
                                </div>
                            </div>

                            <div class="section"><a id="tileSelection"/>
                                <div class="h5 mt-3">Tile selection</div>
                                    <Fragment>
                                        <div class="subsection">
                                            <span class="emphasis">Description:</span> The type and size of of new tile can affect 1) the cost and 2) how it fits into the space. Certain tiles are 
                                            more expensive to cut and install. Also, given the space, some tiles will look better installed, and others will not.
                                        </div>

                                        <div class="subsection"><span class="emphasis">What to do:</span> 
                                            <ol>
                                                <li><div>When you shop for tiles, use your measurements from above to ensure the tile fits into the space. Mosaic tiles are 
                                                    usually attached to a plastic backing and the grout size is fixed. 
                                                    For individual tiles (e.g. subway tiles), you need to add the grout space to your calculation. </div>
                                                </li>
                                                <li>
                                                    <div>For example, if your tiling space is 20 inches high, and you choose 3 inch high tiles with a grout size of 1/4”, then you will be 
                                                        left with a 3/4” space at the top (6 x 3” tiles plus 5 x 1/4” grout space = 19 1/4”).</div>
                                                </li>
                                                <li>
                                                    <div>Specialists at tile stores, or designers are helpful when deciding how certain tile fits into the space.</div>
                                                </li>
                                                <li>
                                                    <div>In addition, discuss your tile choices with your contractor to receive an accurate quote. Often, glass tiles are 
                                                        more expensive to cut and install.</div>
                                                </li>
                                            </ol>
                                        </div>
                                    </Fragment>
                            </div>
                            
                            <div class="section"><a id="upgrades"/>
                                <div class="h5 mt-3">Additional upgrades</div>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> Changing the backsplash is a good opportunity to make additional changes that 
                                        are in the wall behind the backsplash.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Determine whether you’d like to do any of the following:</div>
                                        </li>
                                        <li>
                                            <div>If you have very old power outlets, or you would like additional outlets, this would be the idea time to 
                                                upgrade the electrical inside the wall.</div>
                                        </li>
                                        <li>
                                            <div>Installing under cabinet lighting is another great enhancement that can be done when changing the backsplash tiles. 
                                                The wiring can be run inside the walls for a cleaner look.</div>
                                        </li>
                                        <li>
                                            <div>Another possible addition is to run a new water line for a fridge with water/ice dispensers.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                            </div>
                            
                            <div class="section"><a id="removal"/>
                                <div class="h5 mt-3">Removing the old backsplash </div>
                                <Fragment>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> - If your kitchen has an old backsplash, it needs to get removed first.  You need to account for this work 
                                        and ensure someone will do this work.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                        <li>
                                            <div>Removing an old backsplash is not a very involved task. It can easily be done by a handy person, the tiling contractor or yourself.</div>
                                        </li>
                                        <li>
                                            <div>Pick whoever can do it for the cheapest, and will ensure that the wall behind it will not get damaged in the process. This will save additional work/cost.</div>
                                        </li>
                                        <li>
                                            <div>Pro tip: tiling contractors are usually the best option for this removal job because the are most familiar with the process.</div>
                                        </li>
                                        
                                        </ol>
                                    </div>
                                </Fragment>
                            </div>
                            
                            <div class="section"><a id="contractors"/>
                                <div class="h5 mt-3">Finding contractors/professionals</div>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> The type of professional that you have to hire depends on the current condition and the 
                                        kind of additional upgrades (as described above).
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                            <li>
                                                <div>In general, the following professionals can do the different parts of the kitchen backsplash project.</div>
                                            </li>
                                            <li>
                                                <div>A designer can help you to select tiles, and make sure it fits your space. Designers often charge $100+ per hour for 
                                                    their work which can include selecting the tile, and drawing out its installation pattern for more complex tiles. Tile 
                                                    stores/dealers will often offer a lite version of what a designer can do for free. 
                                                </div>
                                            </li>
                                            <li>
                                                <div>Tiling contractor will tile the space. They expect the area to be clean, and the drywall in fixed/good order. If the 
                                                    space has old tile already, tiling contractors will also remove the old tile. In general, they will not perform any 
                                                    patching or repairs to drywall.
                                                </div>
                                            </li>
                                            <li>
                                                <div>Electricians will install new outlets, upgrade old ones, and install under cabinet lighting.</div>
                                            </li>
                                            <li>
                                                <div>Plumbers will install the additional water line inside the wall.</div>
                                            </li>
                                            <li>
                                                <div>- General contractors or a handy person will patch and repair the drywall if it is damaged, or got damaged in the process 
                                                    of any demolition or additional work. That work is usually small and you may be able to do it yourself if you are 
                                                    handy.
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                            </div>

                            <div class="section"><a id="installation"/>
                                <div class="h5 mt-3">Installation/finishing</div>
                                    <div class="subsection">
                                        <span class="emphasis">Description:</span> To have the best results from your backsplash project, there are some additional items to consider, 
                                        such as the grout and power outlet colors.
                                    </div>

                                    <div class="subsection"><span class="emphasis">What to do:</span> 
                                        <ol>
                                            <li>
                                                <div>The grout color and spacing will have a big effect on the final result. Grouts come in large number of colors 
                                                    and shades, and you can choose one that fits your tiles and taste best. If in doubt, you can ask your tiling 
                                                    contractor to make some sample batches for you so you can see how it looks in your space. </div>
                                            </li>
                                            <li>
                                                <div>Sealing of grout will be very important in the kitchen because there is a lot of splashing, dirt and oil that 
                                                    can splash against the tiles. Ensure that your contractor will apply a sealing agent to the grout. This typically 
                                                    happens several days after the grout has dried. 
                                                </div>
                                            </li>
                                            <li>
                                                <div>If your tile comes down all the way to your countertop, then it is important to apply a layer of silicone between 
                                                    the tile and the countertop. This ensure water does not seep between the two and damage the wall behind it. Ensure 
                                                    your contractor applies this layer of silicone. The most common colors are white or transparent.
                                                </div>
                                            </li>
                                            <li>
                                                <div>- It is possible that your new tile color will not work well with your existing electrical outlet colors. Standard 
                                                    outlet colors that can be bought in most hardware stores are white, black and gray. If you want another color, 
                                                    search online or visit a local electrical supply store.</div>
                                            </li>
                                        </ol>
                                    </div>
                            </div>
                            
                            <div class="mt-5">
                                <small>Disclaimer: The information on this page is offered without any warranty or claim of accuracy. For any construction or home improvement work, 
                                you should always check with your local building department regarding codes and regulations and consult with licensed professionals when in doubt.</small>
                            </div>
                        </div>
                    </div>  </div>
                </div>
            </div>
        )
    }
}

export default ReplaceKitchenBacksplash;