import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { formatPhoneNumber} from "utils/formatters";
import { isValidEmail, isValidPhoneNumber  } from "utils/helpers";
import { basicAccountInfo } from "api/basic_account_info";
import { generic_get_api } from "api/generic_api";
import {Button, Spinner, Modal, Pagination, Dropdown, OverlayTrigger, Popover, Tooltip, ListGroup} from 'react-bootstrap';
import { generic_post_api, generic_put_api, generic_delete_api } from "api/generic_api";
import google_g from 'assets/reputation/google_g.png';

import {
    AGENT_CONTACTS_URL,
    EntityRole,
    GOOGLE_AUTH_URL,
    AGENT_FAVORITE_VENDORS_URL,
    AGENT_CONTACT_ACCESS_URL,
    AGENT_CONTACT_ACCESS_EMAIL_URL,
} from 'utils/constants';

const RESULTS_PER_PAGE = 20;

const ContactModalModes = {
    NEW: 'new',
    UPDATE: 'update',
}

class AgentContacts extends Component {

    constructor(props) {

        super(props);
        this.state = {
            basic_account_info: {},
            contacts: [], // all the contacts from API
            filtered_contacts: [], // any contacts from 'contacts' after filter has been applied
            current_display: [], // what the user currently sees which may be a subset of 'filtered_contacts' because of pagination
            show_contact_modal: false,
            fetching_contacts: false,
            fetching_contacts_complete: false,
            is_archived_view: false,
            filter_term: '',
            start: 0,
            vendors: [],
            selected_contact: null,
            show_access_modal: false,
            show_learn_more_modal: false,
        };

        this.setShowContactModal = this.setShowContactModal.bind(this);
        this.fetchAgentContacts = this.fetchAgentContacts.bind(this);
        this.filterContactsBoxChanged = this.filterContactsBoxChanged.bind(this);
        this.fetchVendorNetwork = this.fetchVendorNetwork.bind(this);
        this.googleImport = this.googleImport.bind(this);
        this.setSelectedContact = this.setSelectedContact.bind(this);
        this.setShowAccessModal = this.setShowAccessModal.bind(this);
        this.hideLearnMoreModal = this.hideLearnMoreModal.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'agent_contacts'});
        document.title = "All your real estate clients in one place. Refer reputable professionals.";

        basicAccountInfo()
        .then(data=>{
            this.setState({
                basic_account_info: data,
            });
            if (data.role !== EntityRole.AGENT) {
                this.props.history.push('/');
            }
            this.fetchAgentContacts();
            
            // fetch vendor network once since all contacts will be off the same list of vendors
            this.fetchVendorNetwork();
        })
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    hideLearnMoreModal() {
        this.setState({ show_learn_more_modal: false });
    }

    setShowAccessModal(value) {
        this.setState({ show_access_modal: value});
    }

    setSelectedContact(contact) {
        this.setState({selected_contact: contact});
    }

    fetchVendorNetwork() {
        generic_get_api(AGENT_FAVORITE_VENDORS_URL, {})
        .then(
            data => {
                this.setState({vendors: data })
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    fetchAgentContacts(fetch_archived_contacts=false) {
        this.setState({
            fetching_contacts: true,
            fetching_contacts_complete: false,
            filter_term: '',
         });

        let params = { archived_contacts: fetch_archived_contacts }

        generic_get_api(AGENT_CONTACTS_URL, params)
        .then(
            data => {
                if (data){
                    this.setState({
                        contacts: data.contacts,
                        filtered_contacts: data.contacts,
                        current_display: data.contacts.slice(0, RESULTS_PER_PAGE),
                        result_count: data.count,
                        
                        start: 0,
                        fetching_contacts: false,
                        fetching_contacts_complete: true,
                        is_archived_view: fetch_archived_contacts,
                    });
                }
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            } 
        });
    }

    filterContactsBoxChanged(e) {
        if (e.target.value === '') {
            this.setState({
                start: 0,
                filtered_contacts: this.state.contacts,
                current_display: this.state.contacts.slice(0, RESULTS_PER_PAGE),
                filter_term: e.target.value,
            });
        } else {
            let term = e.target.value.toLowerCase();
            let output = [];

            for (let i=0; i < this.state.contacts.length; i++){
                let contact = this.state.contacts[i];
                let client_name = contact.first_name + " " + contact.last_name;
                if (contact.first_name && contact.first_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (contact.last_name && contact.last_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (contact.email && contact.email.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
                else if (client_name && client_name.toLowerCase().search(term) >= 0) {
                    output.push(contact);
                }
            }

            this.setState({
                filtered_contacts: output,
                start:0,
                current_display: output.slice(0, RESULTS_PER_PAGE),
                filter_term: e.target.value,
            });
        }
    }

    setShowContactModal(value) {
        this.setState({ show_contact_modal: value });
    }

    googleImport() {
        generic_get_api(GOOGLE_AUTH_URL)
        .then(
            (result) =>
            {
                window.location.href = result.authorization_url;
            }
        )
    }

    render() {

        const {start, filtered_contacts, current_display, filter_term, vendors, contacts} = this.state;

        const paginationPages = () => {
            if (!filtered_contacts.length) {
                return [0];
            }
            else{
                let output = [];
                let i = 1;
                for (; i < filtered_contacts.length / RESULTS_PER_PAGE; i++) {
                    output.push(i);
                }
                output.push(i);

                return output;
            }
        }

        const pageClick = (page) => {

            let new_start = (page - 1) * RESULTS_PER_PAGE;

            if (start !== new_start) {
                this.setState({
                    start: new_start,
                    current_display: filtered_contacts.slice(new_start, new_start + RESULTS_PER_PAGE),
                });
            }
        }

        const nextPageClick = () => {
            if (start + RESULTS_PER_PAGE < filtered_contacts.length) {
                let new_start = start + RESULTS_PER_PAGE;
                this.setState({
                    start: new_start,
                    current_display: filtered_contacts.slice(new_start, new_start + RESULTS_PER_PAGE),
                });
            }
        }

        const previousClick = () => {
            if (start !== 0 && start >= RESULTS_PER_PAGE) {
                let new_start = start - RESULTS_PER_PAGE;
                this.setState({
                    start: new_start,
                    current_display: filtered_contacts.slice(new_start, new_start + RESULTS_PER_PAGE),
                });
            }
        }

        return(
            <div class="agentClientsEnvelope">
                <div class="agentClientsPage">
                    
                    <div class="container agentContactsHeader">
                        <div class="row"> <div class="col display-6">My clients</div></div>
                        <div class="row mt-4"> 
                            <div class="col">Add or import your clients. Share your vendors with your clients.&nbsp;
                                <a class="text-decoration-underline" role="button" onClick={ () => this.setState({ show_learn_more_modal: true})}>Learn more</a>
                            </div>
                        </div>
                        <LearnMoreModal 
                            show_learn_more_modal={this.state.show_learn_more_modal}
                            hideLearnMoreModal={this.hideLearnMoreModal}
                        />
                        <div class="row mt-4 gx-2 gy-3">
                            {
                                contacts && contacts.length >= 20 && 

                                <div class="col-7 col-md-6">
                                    <input 
                                        type="search" 
                                        class="form-control" 
                                        placeHolder="Search clients" 
                                        onChange={ (e) => this.filterContactsBoxChanged(e) } value={filter_term}
                                    />
                                </div>
                            }
                            
                            <div class="col-auto">
                                <Button variant="primary" onClick={ () => this.setShowContactModal(true) }>
                                    <i class="fa-solid fa-user-plus"/><span class="desktopButton">&nbsp;&nbsp;Add contact</span>
                                </Button>
                            </div>
                                <ContactForm
                                    show_contact_modal={ this.state.show_contact_modal }
                                    setShowContactModal={ this.setShowContactModal }
                                    contact={ { contact_property: {} } } // For loading an empty form
                                    contactModalMode={ContactModalModes.NEW}
                                    fetchAgentContacts={ this.fetchAgentContacts }
                                />
                            <div class="col-auto ">
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                        <i class="fa-solid fa-file-import"/><span class="desktopButton">&nbsp;&nbsp;Import</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={ this.googleImport }><span><img class="googleImport" src={google_g}/>&nbsp;&nbsp;Import from Google</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="container contactsResultContainer mt-4">
                        {
                            !this.state.fetching_contacts_complete && this.state.fetching_contacts &&
                                <div class="row"> 
                                    <div class="col text-center"> 
                                        <Spinner animation="border" role="status">
                                        </Spinner> Loading ...
                                    </div>
                                </div>
                        }
                            
                        {
                            this.state.fetching_contacts_complete && this.state.contacts.length === 0 && 
                                <div class="row"><div class="col text-center">No clients to display. Start by adding or importing clients.</div> </div>
                        }

                        {
                             this.state.fetching_contacts_complete && !this.state.fetching_contacts &&
                                <div class="row">
                                    {
                                        this.state.filtered_contacts.length > 0 && 
                                            <div class="col pe-0"><small>Showing {start + 1}-{start + current_display.length} of { filtered_contacts.length }</small></div>
                                    }
                                    
                                    {
                                        this.state.is_archived_view ?
                                            <div class="col text-end text-decoration-underline" role="button" onClick={() => {this.fetchAgentContacts(false)} }><small>Hide archived</small></div>
                                        :
                                            <div class="col text-end text-decoration-underline" role="button" onClick={() => { this.fetchAgentContacts(true)} }><small>Show archived</small></div>
                                    }
                                    
                                </div>
                        }
                        {
                            this.state.fetching_contacts_complete && this.state.filtered_contacts.length > 0 && 
                            <>
                                
                                <ListGroup className="mt-2">
                                    <ListGroup.Item className="text-start ps-0 pe-0 bg-light ">
                                        <div class="container header">
                                            <div class="row align-items-center">
                                                {/* medium sized container and larger */}
                                                <div class="col-4 text-truncate medium-container"><small>Name</small></div>
                                                <div class="col-4 gx-0  text-truncate medium-container"><small>Email</small></div>
                                                <div class="col-2 gx-0 medium-container"><small>Phone</small></div>

                                                {/* smaller than medium container */}
                                                <div class="col-5 text-truncate small-container"><small>Name</small></div>
                                                <div class="col-4 text-truncate small-container"><small>Email <br/> Phone</small></div>
                                            </div>
                                        </div>
                                        </ListGroup.Item>
                                    {
                                        current_display.map(
                                            (contact) => {
                                                return   <ContactSection 
                                                            contact={ contact }
                                                            fetchAgentContacts={ this.fetchAgentContacts }
                                                            vendors={vendors}
                                                            setSelectedContact={ this.setSelectedContact }
                                                            setShowAccessModal={ this.setShowAccessModal }
                                                        /> 
                                            }
                                        )
                                    }
                                 </ListGroup>

                                <GrantAccessDialog
                                    show_grant_access_dialog={ this.state.show_access_modal }
                                    toggleGrantAccessDialog={ this.setShowAccessModal }
                                    contact={ this.state.selected_contact }
                                    vendors={ vendors }
                                    fetchAgentContacts={ this.fetchAgentContacts }
                                    setSelectedContact={ this.setSelectedContact }
                                />

                                {/* </div> */}
                                <div class="row mt-4">
                                    <div class="col d-flex justify-content-center">
                                        <Pagination>
                                            <Pagination.Prev onClick={ previousClick }  disabled={start === 0}/>
                                                {
                                                    paginationPages().map(
                                                        (page) => {
                                                            return   <Pagination.Item 
                                                                        key={page}
                                                                        onClick={()=> pageClick(page)}
                                                                        active={Math.floor(start / RESULTS_PER_PAGE) + 1 === page}>
                                                                            
                                                                            {page}</Pagination.Item>
                                                                
                                                        }
                                                    )
                                                }
                                            <Pagination.Next onClick={ nextPageClick }  disabled={start + RESULTS_PER_PAGE >= filtered_contacts.length}/>
                                        </Pagination>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}

function ContactForm(props) {
    const { show_contact_modal, setShowContactModal, contact, contactModalMode, fetchAgentContacts } = props;

    const [first_name, setFirstName] = useState(contact.first_name);
    const [last_name, setLastName] = useState(contact.last_name);
    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);
    const [submitting, setSubmitting] = useState(false);
    const [currentDialogMode, setCurrentDialogMode] = useState(contactModalMode);

    let history = useHistory();

    if (!show_contact_modal) {
        return null;
    }

    // Must set to original values
    const closeModal = () => {
        setFirstName(contact.first_name);
        setLastName(contact.last_name);
        setEmail(contact.email);
        setPhone(contact.phone);
        setCurrentDialogMode(contactModalMode);
        setShowContactModal(false);
        setSubmitting(false);
    }

    const setPhoneNumber = (value) => {
        if (!value) {
            setPhone(value);
        }

        if( /^\d+$/.test(value) && value.length <= 10) {
            setPhone(value);
        }
    }

    const requiredFieldsOk = () => {

        let is_phone_ok = true;

        if (phone && phone.length > 0 && !isValidPhoneNumber(phone)) {
            is_phone_ok = false;
        }

        let is_email_ok = true;
        if (email && email.length > 0 && !isValidEmail(email)){
            is_email_ok = false;
        }

        return first_name && last_name && is_email_ok && is_phone_ok;
    }

    const getTitleText = () => {
        if (currentDialogMode === ContactModalModes.NEW) { return 'Add new contact' }
        else if (currentDialogMode === ContactModalModes.UPDATE) { return 'Update contact information' }
    }

    const createNewContact = () => {
        setSubmitting(true);
        
        var request = { 
            contact: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
            }
        }

        generic_post_api(request, AGENT_CONTACTS_URL)
        .then(
            (result) => {
                if (result.status == 201) {
                    closeModal();
                    fetchAgentContacts();
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                history.push('/login');
            } else {

            }
        });
    }

    const updateContact = () => {
        setSubmitting(true);

        var request = { 
            contact: {
                id: contact.id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
            },
            action: 'update',
        }


        generic_put_api(AGENT_CONTACTS_URL, request)
        .then(
            (result) => {
                if (result.status === 204) {
                    closeModal();
                    fetchAgentContacts();
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                history.push('/login');
            } else {

            }
        });
    }

    const getButton = () => {
        if (currentDialogMode === ContactModalModes.NEW) {
            return <Button  variant="primary"  onClick={ createNewContact } disabled={!requiredFieldsOk()} > Save </Button>
        }
        else if (currentDialogMode === ContactModalModes.UPDATE) {
            return <Button  variant="primary"  onClick={ updateContact } disabled={!requiredFieldsOk()} > Save </Button>
        }
    }

    return(
        <Modal 
            show={show_contact_modal} 
            onHide={ closeModal }
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{ getTitleText() }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    <div class="row gy-3 mb-3">
                        <div class="col-12 col-md-6">
                            <input type="text" class="form-control" value={first_name} onChange={(e)=>setFirstName(e.target.value )} placeholder="First name (required)" />
                        </div>
                        <div class="col-12 col-md-6">
                            <input type="text" class="form-control" value={last_name} onChange={(e)=>setLastName(e.target.value )} placeholder="Last name (required)" />
                        </div>
                    </div>
                    <div class="row gy-3 mb-4">
                        <div class="col-12 col-md-6">
                            <input type="email" class="form-control" value={email} onChange={(e)=>setEmail(e.target.value )} placeholder="Email" />
                        </div>
                        <div class="col-12 col-md-6">
                            <input type="text" class="form-control" value={phone} onChange={(e)=>setPhoneNumber(e.target.value )}  placeholder="Phone number"/>
                        </div>
                    </div>
                    <div class="row">
                    { submitting ? 
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />   
                    :
                        <div class="col text-center">
                            {getButton()}
                        </div>
                    }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function GrantAccessDialog(props) {

    const { show_grant_access_dialog, toggleGrantAccessDialog, contact, vendors, fetchAgentContacts, setSelectedContact } = props;
    const DisplayMode = { VENDOR_LIST_MODE: 'vendor_list_mode', LINK_MODE: 'link_mode' }

    const [selected_vendors, setSelectedVendors] = useState({});
    const [all_vendors_selected, setAllVendorsSelected] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [shared_link, setSharedLink] = useState('');
    const [display_mode, setDisplayMode]  = useState(DisplayMode.VENDOR_LIST_MODE);
    const [link_copied_to_clipboard, setLinkCopiedToClipBoard] = useState(false);
    const [show_email_section, setShowEmailSection] = useState(false);
    const [email_sent, setEmailSent] = useState(false);
    const [selected_contact_email, setSelectedContactEmail] = useState('');


    const resetFields = () => {
        setSelectedVendors({});
        setAllVendorsSelected(true);
        setSubmitting(false);
        setSelectedContact(null);
        setSharedLink('');
        setDisplayMode(DisplayMode.VENDOR_LIST_MODE);
        setLinkCopiedToClipBoard(false);
        setShowEmailSection(false);
        setEmailSent(false);
        setSelectedContactEmail('');

        fetchAgentContacts();
    }

    const toggleVendorSelection = (vendor_id) => {
        let current_value = selected_vendors[vendor_id];

        setSelectedVendors(prevState => ({
            ...prevState,
            [vendor_id]: !current_value
        }));
    }

    const countSelectedVendors = () => {
        let count = 0;

        for (let i=0; i < vendors.length; i++) {
            let vendor_id = vendors[i].ID;

            if (selected_vendors[vendor_id])
            {
                count++;
            }
        }

        return count;
    }

    // function that selects / unselects all vendors
    const toggleAllSelected = () => {

        let currently_all_selected = all_vendors_selected;

        for (let i=0; i < vendors.length; i++) {
            let vendor_id = vendors[i].ID;

            setSelectedVendors(prevState => ({
                ...prevState,
                [vendor_id]: !currently_all_selected
            }));
        }
    }

    const setAccess = () => {
        
        let request_selected_vendors = []

        Object.keys(selected_vendors).map(
            (vendor_id) => {
                if (selected_vendors[vendor_id]) {
                    for (let i=0; i < vendors.length; i++) {
                        if (vendors[i].ID === vendor_id) {
                            request_selected_vendors.push({
                                vendor_id: vendor_id,
                                role: vendors[i].role,
                            })
                        }
                    }
                }
            }
        )
        
        let request = {
            contact_id: contact.id,
            shared_vendors:request_selected_vendors,
        }

        setSubmitting(true);
        setDisplayMode('link_mode');
        setSelectedContactEmail(contact.email);

        generic_put_api(AGENT_CONTACT_ACCESS_URL, request)
        .then(
            (result) => {
                if (result.status === 200) {
                    return result.data.json();
                }
            }
        )
        .then(
            data => {
                setSharedLink(data.shared_link);
                setSubmitting(false);
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    const sendEmail = () => {

        setSubmitting(true);

        let request = {
            email: selected_contact_email,
            contact_id: contact.id,
        }

        generic_post_api(request, AGENT_CONTACT_ACCESS_EMAIL_URL)
        .then(
            (result) => {
                if (result.status === 201) {
                    setEmailSent(true);
                    setSubmitting(false);
                }
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
       
    }

    // Sets the "select/unselect all checkbox"
    useEffect(() => {
        if (vendors === null || vendors.length === 0) { 
            setAllVendorsSelected(false);
        }
        else {
            let all_set = true;

            for (let i=0; i < vendors.length; i++) {
                if (!selected_vendors[vendors[i].ID]) {
                    all_set = false;
    
                }
            }
            setAllVendorsSelected(all_set);
        }
        
    }, [selected_vendors]);

    const initialize_modal = () => {
        if (!vendors || !contact) { return }

        for (let i=0; i < contact.shared_vendors.length; i++) {
            let vendor_id = contact.shared_vendors[i].vendor_id;

            // this notation is necessary because states updates are asynchronous. Thus, we may overwrite 
            // in one iteration the previous action before it is executed.
            setSelectedVendors(prevState => ({
                ...prevState,
                [vendor_id]: true
            }));
        } 
    }
    
    const shareLinkCopyClicked = () => {

        try {
            navigator.clipboard.writeText(shared_link);
            setLinkCopiedToClipBoard(true);
            const timeId = setTimeout(() => {
                setLinkCopiedToClipBoard(false);
            }, 3000)
        }
        catch (error) {
            setLinkCopiedToClipBoard(true);

            const timeId = setTimeout(() => {
                setLinkCopiedToClipBoard(false);
            }, 3000)
        }
       
    }

    const getVendorNames = () => {
        let  output = "";

        for (let i = 0; i < vendors.length; i++) {
            if (selected_vendors[vendors[i].ID]) {
                output = output + vendors[i].name + " | ";
            }
        }
        if (output.length >= 2) {
            return output.substring(0, output.length - 2);
        } else {
            return output;
        }
    }

    const getVendorCountText = () => {
        let count = countSelectedVendors();

        if (count === 1) {
            return "Sharing 1 vendor";
        }
        else {
            return "Sharing " + count + " vendors";
        }
    }

    // Initialize the selected vendors when modal loads
    useEffect(() => {
        initialize_modal();
    }, [vendors, contact]);


    const getButtonText = () => {
        
        if (contact.shared_vendors.length > 0 && countSelectedVendors() === 0) {
            return 'Remove access';
        }
        else if (contact.shared_vendors.length > 0 && countSelectedVendors() > 0) {
            return 'Update vendors';
        }
        else {
            return 'Share vendors';
        }
    }

    const shareButtonDisabled = () => {
        if (contact.shared_vendors.length === 0 && countSelectedVendors() === 0) {
            return true;
        }
        return false;
    }

    const mobileShare = () => {
        let data = {
            title: "View vendors",
            text: "",
            url: shared_link,
          }
        navigator.share(data)
          .then(() => {
            console.log('true');
          })
          .catch((e) => {})
      }

    const getShareButton = () => {

        if (navigator.share) {
            return <Button variant="light" onClick={ mobileShare } disabled={ !shared_link }><i class="fa-regular fa-share-from-square"/></Button>
        } else {
            return <Button variant="light" onClick={() => setShowEmailSection(true)} disabled={ show_email_section }><i class="fa-regular fa-envelope"></i></Button>
        }
    }

    const getVendorDisplayName = (vendor) => {
        if (vendor.company_name){
            return vendor.entity_name + " (" + vendor.company_name + ")"
        } else if (vendor.contact_name){
            return vendor.entity_name + " (" + vendor.contact_name + ")"
        } else {
            return vendor.entity_name;
        }
    }

    if (!show_grant_access_dialog) {
        return null;
    }

    return(
        <Modal
            show={show_grant_access_dialog} 
            onHide={ () => {  resetFields(); toggleGrantAccessDialog(false) } }
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="grantAccessModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Share vendors</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container gx-0">
                {display_mode === DisplayMode.VENDOR_LIST_MODE && 
                    <>
                        <div class="row">
                            <div class="col">
                                Share vendors with  <strong>{contact.first_name} {contact.last_name}</strong>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col"><small>{countSelectedVendors()} vendor selected</small></div>
                        </div>

                        { vendors && vendors.length > 0 && 
                            <ListGroup className="mt-1 vendorList overflow-auto">
                                <ListGroup.Item className="bg-light">
                                    <div class="row">
                                        <div class="col-1 text-left ps-1">
                                            <input 
                                                class="form-check-input vendorCheckbox" 
                                                type="checkbox" 
                                                id="addAllVendors"
                                                value=""
                                                onChange={toggleAllSelected}
                                                checked={all_vendors_selected}
                                            />
                                        </div>
                                        <div class="col-11 text-left fw-bold ps-2">Vendors</div>
                                    </div>
                                </ListGroup.Item>
                                {vendors.map(
                                vendor => {
                                    return  <ListGroup.Item className="">
                                                <div class="row">
                                                    <div class="col-1 text-left ps-1">
                                                        <input 
                                                            class="form-check-input vendorCheckbox" 
                                                            type="checkbox" 
                                                            value=""
                                                            id={vendor.ID}
                                                            onChange={() => toggleVendorSelection(vendor.ID)}
                                                            checked={selected_vendors[vendor.ID]}
                                                        />
                                                    </div>
                                                    <div class="col-11 text-truncate ps-2">
                                                        <OverlayTrigger key={vendor.ID} placement="auto"
                                                            overlay={ <Tooltip id={vendor.ID}>{getVendorDisplayName(vendor)}</Tooltip>}>
                                                            <small>{getVendorDisplayName(vendor)}</small>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                })
                                }
                            </ListGroup>
                        }
                    
                        <div class="row mt-3">
                            <div class="col text-center">
                                {
                                    submitting ? 
                                        <Button variant="primary" disabled>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            Submitting...
                                        </Button>
                                    :
                                        <Button variant="primary" onClick={ setAccess } disabled={shareButtonDisabled()}>{getButtonText()}</Button>
                                }
                                
                            </div>
                        </div>
                    </>
                }
                {display_mode === DisplayMode.LINK_MODE &&
                    <>
                        {
                            countSelectedVendors() === 0 ?
                            <>
                                <div class="row">
                                    <div class="col">You are no longer sharing any vendors with <strong>{contact.first_name} {contact.last_name}</strong>.
                                    </div>   
                                </div>
                            </>
                            :
                            <>
                                <div class="row">
                                    <div class="col text-truncate">
                                        <OverlayTrigger key="vendor_names" placement="auto"
                                            overlay={ 
                                                <Popover id="vendor_names">
                                                    <Popover.Header as="h3">Vendors</Popover.Header>
                                                    <Popover.Body>
                                                        {getVendorNames()}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                                <a class="text-decoration-underline" role="button">{getVendorCountText()}</a>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col"><small>With this shared link, <strong>{contact.first_name} {contact.last_name}</strong> gains
                                        access to your selected vendors. No signup required.</small></div>
                                    </div>      

                                <div class="row mt-3"><div class="col"><small>Shared link</small></div></div>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group">

                                            { submitting && !shared_link ? 
                                                <span><Spinner animation="border" role="status" size="small"/>Fetching shared link...</span>
                                            :
                                                <input type="input" class="form-control" value={shared_link}/>
                                            }
                                            

                                            {link_copied_to_clipboard ? 
                                                <Button variant="success" onClick={ shareLinkCopyClicked }>Copied</Button>
                                            :
                                                <Button variant="light" onClick={ shareLinkCopyClicked }>Copy</Button>
                                            }
                                        </div>
                                    </div>
                                    <div class="col-auto"> 
                                        {getShareButton()}
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col"><small>This link is unique. To share with another contact, create a new link.</small></div>
                                </div>

                                {show_email_section && 
                                    <>
                                        {! email_sent ?
                                            <>
                                                <div class="row mt-4">
                                                    <div class="col"><small>Email shared link to contact</small></div>
                                                </div>
                                                <div class="row mt-1 mb-3">
                                                    <div class="col">
                                                        {
                                                            submitting ?
                                                                <Spinner animation="border" role="status" size="sm"/>
                                                            :
                                                                <div class="input-group">
                                                                    <input type="email" class="form-control" placeholder="Email" value={selected_contact_email} onChange={(e) => setSelectedContactEmail(e.target.value)}/>
                                                                    <Button variant="primary" onClick={ sendEmail } disabled={!isValidEmail(selected_contact_email)}>Send</Button>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div class="row mt-4">
                                                <div class="col fw-bold"><small>Email successfully sent</small></div>
                                            </div>
                                        }
                                    </>
                                }    
                            </>
                        }

                    </>
                }
                </div>
            </Modal.Body>
        </Modal>
    );
}

function ContactSection(props) {

    const { contact, fetchAgentContacts, setSelectedContact, setShowAccessModal } = props;

    const [show_contact_modal, setShowContactModal] = useState(false);
    const [confirm_delete_contact_dialog, setConfirmDeleteContactDialog] = useState(false);
    const [show_archive_modal, setShowArchiveModal] = useState(false);
    const [archive_mode, setArchiveMode] = useState('');  // determines which view the UI should show now. Archived/unarchived list
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    let history = useHistory();

    const toggleArchive = (value) => {
        setSubmitting(true);

        var request = { 
            contact: {
                id: contact.id,
                is_archived: value,
            },
            action: 'toggle_archive',
        }

        generic_put_api(AGENT_CONTACTS_URL, request)
        .then(
            (result) => {
                if (result.status === 204) {
                    setSubmitting(false);
                    fetchAgentContacts();
                    setShowArchiveModal(false);
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                history.push('/login');
            } else {

            }
        });
    }

    const deleteContact = () => {
        setDeleting(true);

        var request = { 
            contact: {
                id: contact.id
            }
        }

        generic_delete_api(AGENT_CONTACTS_URL, request)
        .then(
            (result) => {
                if (result.status === 204) {
                    setConfirmDeleteContactDialog(false);
                    setDeleting(false);
                    fetchAgentContacts();
                }
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                history.push('/login');
            }
        });

    }

    const editDropDown = (contact) => {
        return <Dropdown size="sm" >
                    <Dropdown.Toggle variant="link">
                        <i class="fa-solid fa-ellipsis"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={ () => setShowContactModal(true) }>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={ () => setConfirmDeleteContactDialog(true) }>Delete</Dropdown.Item>
                        { contact.is_archived ? 
                            <Dropdown.Item onClick={ ()=> {setArchiveMode('unarchive'); setShowArchiveModal(true)}}>Unarchive</Dropdown.Item>
                        :   <Dropdown.Item onClick={ ()=> {setArchiveMode('archive'); setShowArchiveModal(true)} }>Archive</Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
    }

    const deletionModal = (contact) => {
        return <Modal
                    show={confirm_delete_contact_dialog} 
                    onHide={ () => setConfirmDeleteContactDialog(false) }
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="container">
                            <div class="row"><div class="col">{contact.first_name} {contact.last_name}</div></div>
                            <div class="row mt-4"><div class="col text-center"> 
                            {deleting?
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                :
                                <Button variant="primary" onClick={ () => deleteContact(contact) }>Confirm</Button>
                            }
                            </div></div>
                        </div>
                    </Modal.Body>
                </Modal>
    }

    return (
        <div class="clientSection">
            <ContactForm
                show_contact_modal={ show_contact_modal }
                setShowContactModal={ setShowContactModal }
                contact={ contact }
                contactModalMode="update"
                fetchAgentContacts={ fetchAgentContacts }
                key={ contact.updated_at }
            />
            
            <Modal
                show={show_archive_modal} 
                onHide={ () => setShowArchiveModal(false) }
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            archive_mode === 'archive' && <>Archive contact</>
                        }
                        {
                            archive_mode === 'unarchive' && <>Restore contact</>
                        }
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                {
                                    archive_mode === 'archive' && <>Do you want to archive <strong>{contact.first_name} {contact.last_name}</strong>?</>
                                }
                                {
                                    archive_mode === 'unarchive' && <>Do you want to restore <strong>{contact.first_name} {contact.last_name}</strong>?</>
                                }
                                
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col text-center">
                            { submitting ? 
                                    <Button variant="primary" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        Submitting...
                                    </Button>
                                :
                                    <Button variant="primary" onClick={ () => toggleArchive(archive_mode==='archive') }>Confirm</Button>
                            }       
                            </div>        
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {deletionModal(contact)}                
            
            <ListGroup.Item className="text-start p-0">
                {/* Desktop */}
                <div class="container desktop">
                    <div class="row align-items-center">
                        {/* medium sized container and larger */}
                        <div class="col-4 fw-semibold text-truncate medium-container"><small>{contact.first_name} {contact.last_name}</small></div>
                        
                        {/* smaller than medium container */}
                        <div class="col-5 fw-semibold text-truncate small-container"><small>{contact.first_name} {contact.last_name}</small></div>
                        
                        {/* medium sized container and larger */}
                        <div class="col-4 gx-0 fw-light text-truncate medium-container"><small>{contact.email}</small></div>
                        <div class="col-2 gx-0 fw-light medium-container"><small>{formatPhoneNumber(contact.phone)}</small></div>
                        
                        {/* smaller than medium container */}
                        <div class="col-4 small-container">
                            <div class="row"><div class="fw-light text-truncate"><small>{contact.email}</small></div></div>
                            <div class="row"><div class="fw-light"><small>{formatPhoneNumber(contact.phone)}</small></div></div>
                        </div>

                        <div class="col-auto gx-0">
                        {
                            !contact.is_archived &&
                                <Button variant="link" onClick={() => {setSelectedContact(contact); setShowAccessModal(true)}}>
                                    <small>Share <br/>vendors</small>
                                </Button>
                        }
                        </div>
                        <div class="col gx-0 text-center">{editDropDown(contact)}</div>
                    </div>
                </div>

                {/* Mobile */}
                <div class="container mobile">
                    <div class="row align-items-center">
                        <div class="col-9">
                            <div class="row"><div class="col fw-semibold text-truncate"><small>{contact.first_name} {contact.last_name}</small></div></div>
                            <div class="row"><div class="col fw-light text-truncate"><small>{contact.email}</small></div></div>
                            <div class="row"><div class="col fw-light"><small>{formatPhoneNumber(contact.phone)}&nbsp;</small></div></div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col text-center gx-0">
                                {
                                        !contact.is_archived &&
                                            <Button variant="link" onClick={() => {setSelectedContact(contact); setShowAccessModal(true)}}>
                                                <small>Share <br/> vendors</small>
                                            </Button>
                                }
                                </div>
                                <div class="col text-center gx-0">{ editDropDown(contact) }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroup.Item>
            
        </div> 
    )
}

function LearnMoreModal(props) {
    const {show_learn_more_modal, hideLearnMoreModal } = props;

    if (!show_learn_more_modal) { return null; }

    return(
        <Modal
            show={show_learn_more_modal} 
            onHide={ hideLearnMoreModal }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>My clients</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div class="container lh">
                    <div class="row">
                        <div class="col fw-bold">How can I input my clients?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            Enter your clients individually, or use the import functionality to bulk import from your Gmail account. If your 
                            clients are stored in another service (e.g. Microsoft), let  us know and we will help you to import them.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">Are my clients' information safe with you?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            We will never share or disclose any of your clients' information to a third-party. 
                            Your clients' information belongs to you. Refer to our <a role="button" class="text-decoration-underline" href="/privacy_policy">Privacy Policy</a> for more information.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">What can I do after adding clients?</div>
                    </div>
                    <div class="row">
                        <div class="col">
                           Select a contact and share (some) vendors with them. You can pick individual vendors from your favorites list to share with your contact.
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col fw-bold">I shared some vendors with a contact and now I want to add/remove vendors.</div>
                    </div>
                    <div class="row">
                        <div class="col">
                           No problem. Click on "Share vendors" next to their name and update the list of vendors. 
                           
                           To remove all access for this contact, uncheck all vendors and click on <strong>Update vendors</strong>.
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AgentContacts;