import React, { Component } from "react";
import {Card} from 'react-bootstrap';
import {AuthContext} from 'auth_context';
import { generic_get_api } from "api/generic_api";
import { AGENT_SETTINGS_URL } from "utils/constants";
import { EntityRole } from "utils/constants";

import {trackEvent, EventNames} from 'utils/mixpanel';


class Guides extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.fetchContactSettings = this.fetchContactSettings.bind(this);
    }

    static contextType = AuthContext;

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'privacy'});
        this.fetchContactSettings();
    }

    fetchContactSettings()
    {
        generic_get_api(AGENT_SETTINGS_URL)
        .then(
            data => {
                this.setState({ 
                    helpful_hints: data.settings.helpful_hints,
                })
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    render() {

        return(
            <div class="guidesEnvelope">
                <div class="guides">
                    <div class="container guidesWrapper mt-5">
                        <div class="row">
                            <div class="col display-6">Guides &amp; manuals</div>
                        </div>
                        <div class="row mt-4">
                            <div class="col">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <p>Your center for guides and manuals for anything home related.</p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            </div>
                        </div>
                        { this.context.logged_in_entity_role === EntityRole.AGENT_CONTACT && this.state.helpful_hints &&
                            <>
                                <div class="row mt-5">
                                    <div class="col h5">Helpful hints from your agent</div>
                                </div>
                                <div class="row mt-2 ms-2">
                                    <div class="col" >
                                        <span dangerouslySetInnerHTML={{__html: this.state.helpful_hints}} />
                                    </div>
                                </div>
                            </>
                        }

                        <div class="row mt-5">
                            <div class="col h5">California state license board documents</div>
                        </div>
                        <div class="row mt-2 ms-2">
                            <div class="col">
                                <a role="button" href="https://www.cslb.ca.gov/Resources/GuidesAndPublications/WYSKPamphlet.pdf" target="_blank">
                                    Before hiring a contractor checklist
                                </a>
                            </div>
                        </div>
                        <div class="row mt-1 ms-2">
                            <div class="col">
                                <a role="button" href="https://www.cslb.ca.gov/Resources/GuidesAndPublications/AfterADisasterGuide.pdf" target="_blank">
                                    Avoiding construction scams 
                                </a>
                            </div>
                        </div>
                        <div class="row mt-1 ms-2">
                            <div class="col">
                                <a role="button" href="https://www.cslb.ca.gov/Resources/GuidesAndPublications/ConstructionComplaintGuide.pdf" target="_blank">
                                    How to file a construction complaint
                                </a>
                            </div>
                        </div>
                        <div class="row mt-1 ms-2">
                            <div class="col">
                                <a role="button" href="https://www.cslb.ca.gov/Resources/GuidesAndPublications/OwnerBuildersPamphlet.pdf" target="_blank">
                                    Owner-builder considerations
                                </a>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col h5">Kitchen upgrade guides</div>
                        </div>
                        <div class="row mt-2 ms-2">
                            <div class="col">
                                <a role="button" href="/guides/kitchen_back_splash">
                                    Replace your kitchen backsplash
                                </a>
                            </div>
                        </div>
                        <div class="row mt-1 ms-2">
                            <div class="col">
                                <a role="button" href="/guides/kitchen_countertop">
                                    Replace your old countertops
                                </a>
                            </div>
                        </div>
                        <div class="row mt-1 ms-2">
                            <div class="col">
                                <a role="button" href="/guides/kitchen_appliances">
                                    Get brand new appliances for your kitchen
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Guides;