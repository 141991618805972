import React, { Component, useState } from "react";
import { useHistory } from "react-router-dom";


import { PERMIT_TAGS_ADMIN } from 'utils/constants';
import ContactUs from 'pages/contact_us';
import spinnerGif from 'assets/reputation/spinner_150.gif';
import starWhite from 'assets/reputation/star_white.png';
import starYellow from 'assets/reputation/star_yellow.png';
import {trackEvent, EventNames} from 'utils/mixpanel';
import dog_200_200 from 'assets/reputation/dog_200_200.jpg';
import review_intro from 'assets/reputation/review_intro.png';
import ReactTooltip from "react-tooltip";
import {Card, Button, Spinner, Modal} from 'react-bootstrap';
import { generic_get_api, generic_post_api } from "api/generic_api";

class PermitsAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permits: [],
        }

        this.fetchPermits = this.fetchPermits.bind(this);
        this.setTag = this.setTag.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.fetchPermits();
    }

    fetchPermits() {
        generic_get_api(PERMIT_TAGS_ADMIN)
        .then(
            data=>{
                this.setState({permits: data})
            }
        )

    }

    submit() {
        
        generic_post_api({ permits: this.state.permits }, PERMIT_TAGS_ADMIN)
        .then(
            result=>{
                if (result.status === 200) {

                }
            }
        )
    }

    setTag(permit_number, tag, value) {
        let current_permits = this.state.permits;

        for (let i = 0; i < current_permits.length; i++) {
            if (current_permits[i].permit_number === permit_number) {
                current_permits[i].tags[tag] = value;
                break;
            }
        }
        this.setState({ permits: current_permits });
    }

    render() {
        return(
            <div class="container">
                <div class="row"><div class="col"><Button variant="primary" onClick={this.submit}>Submit</Button></div></div>
                { this.state.permits.map(
                    permit => {
                        return  <>
                                    <div class="row mt-5">
                                        <div class="col-12 text-center fw-bold">{permit.permit_number}</div>
                                        <div class="col-12 text-center">{permit.description}</div>
                                    </div>
                                    <div  class="row mt-2">
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_kitchen']} 
                                                value="" 
                                                id="e_kitchen" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_kitchen', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_kitchen">
                                                &nbsp; e_kitchen
                                            </label> 
                                        </div>
                                        <div class="col-2">
                                         <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_bathroom']} 
                                                value="" 
                                                id="e_bathroom" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_bathroom', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_bathroom">
                                                &nbsp; e_bathroom
                                            </label> 
                                        </div>
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_light_switch_outlet']} 
                                                value="" 
                                                id="e_light_switch_outlet" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_light_switch_outlet', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_light_switch_outlet">
                                                &nbsp; e_light_switch_outlet
                                            </label> 
                                        </div>

                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_rewiring']} 
                                                value="" 
                                                id="e_rewiring" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_rewiring', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_rewiring">
                                                &nbsp; e_rewiring
                                            </label> 
                                        </div>
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_panel']} 
                                                value="" 
                                                id="e_panel" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_panel', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_panel">
                                                &nbsp; e_panel
                                            </label> 
                                        </div>
                                           
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_fire']} 
                                                value="" 
                                                id="e_fire" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_fire', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_fire">
                                                &nbsp; e_fire
                                            </label> 
                                        </div>
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_car_charger']} 
                                                value="" 
                                                id="e_car_charger" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_car_charger', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_car_charger">
                                                &nbsp; e_car_charger
                                            </label> 
                                        </div>

                                       
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_service_upgrade']} 
                                                value="" 
                                                id="e_service_upgrade" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_service_upgrade', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_service_upgrade">
                                                &nbsp; e_service_upgrade
                                            </label> 
                                        </div>
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_other']} 
                                                value="" 
                                                id="e_other" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_other', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_other">
                                                &nbsp; e_other
                                            </label> 
                                        </div>
                                   
                                        <div class="col-2">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={permit.tags['e_solar']} 
                                                value="" 
                                                id="e_solar" 
                                                onChange={ (e) => this.setTag(permit.permit_number, 'e_solar', e.currentTarget.checked) }
                                            />
                                            <label class="form-check-label" for="e_solar">
                                                &nbsp; e_solar
                                            </label> 
                                        </div>
                                 
                                    </div>
                                    <div class="row"><hr></hr></div>
                                </>
                    }
                )}

            </div>
        )
    }
}


export default PermitsAdmin;