import React, { Component } from "react";

import { VENDOR_REVIEW_URL} from 'utils/constants';
import ContactUs from 'pages/contact_us';
import starWhite from 'assets/reputation/star_white.svg';
import starYellow from 'assets/reputation/star_yellow.svg';
import {trackEvent, EventNames} from 'utils/mixpanel';
import toolTip_16 from 'assets/reputation/tooltip_blue_i.svg';
import {ButtonGroup, ToggleButton, Popover, OverlayTrigger, Button, Card, Tooltip, Spinner, CloseButton} from 'react-bootstrap';
import { generic_post_api } from "api/generic_api";


const VIEWS = {
    QUICK_VIEW: 'quick_view',
    DETAILED_VIEW: 'detailed_view',
    CONFIRMATION: 'confirmation' 
}

class VendorReview extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            view: VIEWS.QUICK_VIEW,
            submitting: false,
            rating: {
                quality: 0,
                communication: 0,
                punctual: 0,
                cleanup: 0,
                would_recommend: null,
                comment: null,
                project_description: null,
                private_feedback: null,
                is_anonymous: null,
            }
        }
        
        this.starOnClick = this.starOnClick.bind(this);
        this.wouldRecommendClick = this.wouldRecommendClick.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.textAreaChanged = this.textAreaChanged.bind(this);
        this.vendorDetailClick = this.vendorDetailClick.bind(this);
        this.setDefaultValuesAndConfirmationView = this.setDefaultValuesAndConfirmationView.bind(this);
        this.anonymousClick = this.anonymousClick.bind(this);
    }

    setDefaultValuesAndConfirmationView() {
        this.setState({ 
            view: VIEWS.CONFIRMATION,
            submitting: false,
            rating: {
                quality: 0,
                communication: 0,
                punctual: 0,
                cleanup: 0,
                would_recommend: null,
                comment: null,
                project_description: null,
                private_feedback: null,
            }
        })
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'vendor_review'})

        let props_state = this.props.location.state;

        if (props_state && props_state.vendor_id && props_state.vendor_name && props_state.vendor_role) {
            this.setState({
                vendor_id: props_state.vendor_id,
                vendor_name: props_state.vendor_name,
                vendor_phone: props_state.vendor_phone,
                vendor_role: props_state.vendor_role,
            })
        } 
    }

    vendorDetailClick () {
        // must go back to details page through a pop of the history stack otherwise the "back to search" button on the vendor details
        // page will not work
        this.props.history.goBack()
    }

    submitReview() {
        this.setState({ submitting: true });

        var request = { 
            rating: this.state.rating,
            vendor_id: this.state.vendor_id,
            vendor_role: this.state.vendor_role,
        }

        generic_post_api(request, VENDOR_REVIEW_URL)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setDefaultValuesAndConfirmationView();
                    window.scrollTo(0, 0);
                }
            }
        ).catch(error => {
                this.setDefaultValuesAndConfirmationView();
                if (error.name === 'LOGIN_ERROR') {
                    this.props.history.push('/login');
                }
            }
        );
    }

    textAreaChanged(property, e) {
        var curr_rating = this.state.rating;
        curr_rating[property] = e.target.value;
        this.setState( { rating: curr_rating } );
    }

    wouldRecommendClick(value) {
        var curr_rating = this.state.rating;
        curr_rating.would_recommend = value;
        this.setState({ rating: curr_rating });
    }

    anonymousClick(value) {
        var curr_rating = this.state.rating;
        curr_rating.is_anonymous = value;
        this.setState({ rating: curr_rating });
    }

    starOnClick(category, value) {
        var curr_rating = this.state.rating;
        curr_rating[category] = value;

        this.setState({
            rating: curr_rating
        })
    }

    render() {
        const { rating } = this.state;

        const popover = (
            <Popover id="popover-basic">
              <Popover.Header as="h3">Anonymous reviews</Popover.Header>
              <Popover.Body>
                Your review will be visible to other agents but your name will <strong>not</strong> show.
              </Popover.Body>
            </Popover>
          );
        return(
            <div class="vendorReviewEnvelope topOfPageMargin">
                <div class="vendorReviewPage">

                    { this.state.view === VIEWS.QUICK_VIEW && 
                        <div class="reviewWrapper">
                            <div class="container col_2">
                                <div class="row mb-5">
                                    <Card >
                                        <Card.Body>
                                            <Card.Text>Your review will only be <strong>visible to other agents</strong>. Clients and vendors cannot see any reviews left by you.
                                            </Card.Text>
                                        
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div class="row mb-5">
                                    <div class="col-10 text-start fs-3 fw-bold">{this.state.vendor_name}</div>
                                    <div class="col-2"><CloseButton onClick={ this.vendorDetailClick }/></div>
                                </div>
                                <div class="row mt-5 gy-2">
                                    <div class="col-12 col-md-6">Do you recommend this vendor?</div>
                                        <div class="col-12 col-md-6"> 
                                        <ButtonGroup className="mb-2">
                                            <ToggleButton
                                                    key="recommend-key-2"
                                                    id="recommend-id-2"
                                                    type="radio"
                                                    variant="outline-danger"
                                                    name="recommend"
                                                    value={false}
                                                    checked={false === rating.would_recommend}
                                                    onChange={() => this.wouldRecommendClick(false)}
                                                > No  
                                            </ToggleButton>
                                            <ToggleButton
                                                    key="recommend-key-1"
                                                    id="recommend-id-1"
                                                    type="radio"
                                                    variant="outline-success"
                                                    name="recommend"
                                                    value={true}
                                                    checked={true === rating.would_recommend}
                                                    onChange={() => this.wouldRecommendClick(true)}
                                                > Yes    
                                            </ToggleButton>
                                         

                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-2 gy-2">
                                    <div class="col-12 col-md-6">Do you like your view to be anonymous?&nbsp;
                                        <OverlayTrigger trigger="hover" placement="auto" overlay={popover}>
                                            <img src={toolTip_16} class="toolTipImage"/>
                                        </OverlayTrigger>
                                    </div>
                                        <div class="col-12 col-md-6"> 
                                        <ButtonGroup className="mb-2">
                                            <ToggleButton
                                                    key="anonymous-key-2"
                                                    id="anonymous-id-2"
                                                    type="radio"
                                                    variant="outline-danger"
                                                    name="anonymous"
                                                    value={false}
                                                    checked={false === rating.is_anonymous}
                                                    onChange={() => this.anonymousClick(false)}
                                                > No  
                                            </ToggleButton>
                                            <ToggleButton
                                                    key="anonymous-key-1"
                                                    id="anonymous-id-1"
                                                    type="radio"
                                                    variant="outline-success"
                                                    name="anonymous"
                                                    value={true}
                                                    checked={true === rating.is_anonymous}
                                                    onChange={() => this.anonymousClick(true)}
                                                > Yes    
                                            </ToggleButton>
                                          

                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div class="row mt-5 gy-2">
                                    <div class="col-12 col-md-6 d-grid gap-2"> 
                                        <Button variant="outline-primary" 
                                            onClick={() => {this.setState({view: VIEWS.DETAILED_VIEW}); window.scrollTo(0, 0);}}  
                                            disabled={ (rating.would_recommend === null || rating.is_anonymous === null) || this.state.submitting }> Add more details </Button>
                                    </div>
                                    <div class="col-12 col-md-6 d-grid gap-2"> 
                                        {this.state.submitting ? 
                                             <Button variant="primary" disabled>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                Submitting...
                                            </Button>
                                        :
                                            <Button  variant="primary" 
                                                onClick={ this.submitReview } 
                                                disabled={ (rating.would_recommend === null || rating.is_anonymous === null) || this.state.submitting } > I am done 
                                            </Button>
                                        }
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    
                                    <div class="col-12 text-center fs-6">Please submit an authentic &amp; fair review for this vendor and their work. 
                                        By submitting a review, you agree to our <a href="/tos" target="_blank">review guidelines</a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    }

                    <ReviewDetailsComponent 
                        vendor={ this.state.vendor }
                        starOnClick={ this.starOnClick }
                        rating={ this.state.rating }
                        view={ this.state.view }
                        textAreaChanged={ this.textAreaChanged }
                        submitReview={ this.submitReview }
                        submitting={ this.state.submitting }
                        vendor_name={ this.state.vendor_name }
                        vendorDetailClick= { this.vendorDetailClick }
                    />

                    <CompleteComponent 
                        view={ this.state.view }
                        vendorDetailClick={ this.vendorDetailClick }
                    />
                </div>
                
            </div>
        );
    }
}

function ReviewDetailsComponent(props) {

    const {vendor_name, rating, starOnClick, textAreaChanged, view, submitReview, submitting, vendorDetailClick } = props;

    if (view != VIEWS.DETAILED_VIEW) { return null; }
    
    const canSubmit = () => {
        let star_ratings_ok = rating.quality > 0 && rating.communication > 0 && rating.punctual > 0 && rating.cleanup > 0;
        let text_fields_ok = rating.comment && rating.project_description;
        return star_ratings_ok && text_fields_ok;
    }

    const getToolTip = (key, header, id, text) => {
        return <OverlayTrigger
                    key={key}
                    placement="auto"
                    overlay={
                        <Popover id={id}>
                            <Popover.Header as="h3">{header}</Popover.Header>
                            <Popover.Body>
                                {text}
                            </Popover.Body>
                        </Popover>
                    }
                    >
                    <img src={toolTip_16} class="toolTipImage"/>
            </OverlayTrigger>
    }

    return (
        <div class="reviewWrapper">
            <div class="container col_2">
                <div class="row">
                    <div class="col fs-3 text-start fw-bold">{vendor_name}</div>
                </div>
                <div class="row mt-5 gy-2">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">
                                <div class="col-auto gx-0 gy-1">Quality of work</div>
                                <div class="col">{getToolTip("quality", "Project quality", "quality-id", "Was the quality of the work according to your expectations?")}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6"> <div class="stars"><StarComponent name="quality" starOnClick={ starOnClick } rating={ rating.quality }/></div></div>
                </div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">
                                <div class="col-auto gx-0 gy-1">Responsiveness / Communicatios</div>
                                <div class="col">{getToolTip("responsiveness", "Vendor's communication", "responsiveness-id", "How easy was it to communicate with this vendor? Did they respond to your inquieries in a timely manner?")}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6"> <div class="stars"><StarComponent name="communication" starOnClick={ starOnClick } rating={ rating.communication }/></div></div>
                </div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">
                                <div class="col-auto gx-0 gy-1">Punctual / On time</div>
                                <div class="col">{getToolTip("punctual", "Vendor's punctuality", "punctual-id", "Did the work start on the agreed-upon time frame? Did the vendor hit the agreed upon milestones? Was the project completed reasonably on time?")}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6"> <div class="stars"><StarComponent name="punctual" starOnClick={ starOnClick } rating={ rating.punctual }/></div></div>
                </div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">
                                <div class="col-auto gx-0 gy-1">Job site clean-up</div>
                                <div class="col">{getToolTip("clean", "Daily/final cleanup", "clean-id", "Did the vendor clean up the job site to your expectations?")}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6"> <div class="stars"><StarComponent name="cleanup" starOnClick={ starOnClick } rating={ rating.cleanup }/></div></div>
                </div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6">Project details</div>
                    <div class="col-12 col-md-6"> 
                        <textarea class="form-control" placeholder="What did the project include, which parts of the house were modified, how long did it take, etc." onChange={(e) => textAreaChanged('project_description', e) } value={ rating.project_description }/>
                    </div>
                </div>
                <div class="row mt-3 gy-2">
                    <div class="col-12 col-md-6">Your review</div>
                    <div class="col-12 col-md-6"> 
                    <textarea class="form-control" placeholder="Your detailed review of vendor and their work" onChange={ (e) => textAreaChanged('comment', e) } value={ rating.comment }/>
                    </div>
                </div>
                
                <div class="row mt-5 gy-2">
                    <div class="col-12 col-md-6 d-grid gap-2"> 
                        <Button variant="secondary" onClick={ vendorDetailClick }  disabled={submitting}> Cancel </Button>
                    </div>
                    <div class="col-12 col-md-6 d-grid gap-2"> 
                        {submitting ? 
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Submitting...
                            </Button>
                        :
                            <Button  variant="primary" onClick={ submitReview } disabled={ !canSubmit() } > Submit </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

function CompleteComponent(props) {

    const { view, vendorDetailClick } = props;

    if (view !== VIEWS.CONFIRMATION) {
        return null;
    }

    return (
        <div class="reviewWrapper">
            <div class="container col_2">
                <div class="row">
                    <div class="col text-center fs-3">Thank you for your review</div></div>
                <div class="row mt-5">
                    <div class="col text-center">
                        <Button  variant="primary" onClick={ vendorDetailClick } > Back to the vendor page </Button>
                    </div>
            </div>
            </div>

           
        </div>
    )
}

function StarComponent (props){

    return(
        <div class="starComponent">
            <div class="star" onClick={() => props.starOnClick(props.name, 1) }>
                { props.rating > 0 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 2) }>
                { props.rating > 1 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 3) }>
                { props.rating > 2 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 4) }>
                { props.rating > 3 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
            <div class="star" onClick={() => props.starOnClick(props.name, 5) }>
                { props.rating > 4 ? <img src={starYellow} /> : <img src={starWhite} /> }
            </div>
        </div>
    );
}

export default VendorReview;